import { combineReducers } from 'redux';
import uiReducer from './ui';
import userReducer from './user';
import statsReducer from './stats';
import overviewReducer from './overview';
import reportsReducer from './reports';
import adminReducer from './admin';
import searchReducer from './search';

// Export all reducers, combined
export default combineReducers({
    ui: uiReducer,
    user: userReducer,
    stats: statsReducer,
    overview: overviewReducer,
    reports: reportsReducer,
    admin: adminReducer,
    search: searchReducer,
});
