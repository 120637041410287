import clone from 'clone';

export const SET_STATS_FILTER = 'SET_STATS_FILTER';

export function setStatsFilter(value) {
    return {
        type: SET_STATS_FILTER,
        value: clone(value),
    };
}
