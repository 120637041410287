import React, { useRef } from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import { DateTime, Interval, Duration } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../../components/InnerWindow';
import TextField from '../../../components/TextField';
import ExpansionPanel from '../../../components/ExpansionPanel';
import closeIcon from '../../../res/images/close_circle.svg';
import locationCircle from '../../../res/images/location_circle.svg';
import LocationViewer from '../../../components/LocationViewer';
import IconButton from '@material-ui/core/IconButton';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import Dialog from '@material-ui/core/Dialog';
import { recipeStatusToDescription } from '../../../modules/MachineUtils';
import MultipleFieldsDisplay from '../../../components/MultipleFieldsDisplay';
import errorIcon from '../../../res/images/incomplete_cleaning.png';
import faultIcon from '../../../res/images/error_triangle.svg';

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        padding: '25px',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    error: {
        color: 'red',
        marginLeft: '5px',
    },
    errorIcon: {
        width: '20px',
        marginLeft: 'auto',
    },
    mapDialog: {
        width: '90%',
    },
    fieldContainer: {
        marginBottom: '7px',
    },
    descriptionContainer: {
        height: 'auto',
    },
    separator: {
        marginBottom: '24px',
        marginTop: '24px',
        height: '1px',
        minHeight: '1px',
        opacity: 0.2,
        backgroundColor: '#7d90aa',
    },
    locationContainer: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#e6e9f1',
        width: '100%',
        height: '90px',
        minHeight: '90px',
        padding: '8px',
    },
    locationDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingRight: '5px',
    },
    mapIconContainer: {
        padding: '0px',
        height: '40px',
        width: '40px',
        alignSelf: 'center',
    },
    machineTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    faultIcon: {
        marginLeft: '10px',
    },
    mapIcon: {
        filter: hexColorToCSSFilter('#7e8cac'),
        height: '40px',
        width: '40px',
    },
    lastTimeContainer: {
        marginBottom: '7px',
    },
    marginTop: {
        marginTop: '7px',
    },
    titleLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#7d90aa',
    },
    expansionPanelContainer: {
        marginBottom: '24px',
    },
    previousClusterContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    removalReason: {
        marginBottom: '7px',
        height: '129px',
    },
});

function TurnOnDisplay(props) {
    const startDate = props.firstTimestamp
        ? props.firstTimestamp.toUTC()
        : null;
    const endDate = props.lastTimestamp ? props.lastTimestamp.toUTC() : null;
    const now = DateTime.local()
        .toUTC()
        .plus({ milliseconds: props.timezoneOffset });
    const interval = startDate ? Interval.fromDateTimes(startDate, now) : null;

    const duration = interval
        ? interval.toDuration(['days', 'hours', 'minutes'])
        : null;

    const fields = [
        {
            label: 'Date',
            value: startDate ? startDate.toFormat('dd MMM') : 'N/A',
            align: 'center',
        },
        {
            label: 'Time',
            value: endDate ? endDate.toFormat('HH:mm') : 'N/A',
            align: 'center',
        },
    ];

    return (
        <MultipleFieldsDisplay
            className={props.className}
            title={props.title}
            fields={fields}
        />
    );
}

/** Utility widget to display "Last clean", "Last maintenance", ... fields - formats the dates, times, etc. */
function LastTimeDisplay(props) {
    const classes = useStyles();
    const capsulesLabel = props.capsulesLabel;
    const date = props.timestamp ? props.timestamp.toUTC() : props.timestamp;
    const error = props.error;
    const now = DateTime.local()
        .toUTC()
        .plus({ milliseconds: props.timezoneOffset });
    const interval = date ? Interval.fromDateTimes(date, now) : null;
    const duration = interval
        ? interval.toDuration(['days', 'hours', 'minutes'])
        : null;

    let fields = [
        {
            label: 'Date',
            value: date ? date.toFormat('dd MMM') : 'N/A',
            align: 'center',
        },
        {
            label: 'Hour',
            value: date ? date.toFormat('HH:mm') : 'N/A',
            align: 'center',
        },
        {
            label: props.elapsedTime ? 'Elapsed Time' : 'Days       Timestamp',
            value: duration ? `${duration.toFormat('dd  |  hh:mm')}` : 'N/A',
            align: 'center',
        },
    ];

    if (!props.hideCapsules) {
        fields.push({
            label: capsulesLabel || 'Capsules',
            value: typeof props.capsules === 'number' ? props.capsules : 'N/A',
            align: 'right',
            capsulesIcon: true,
        });
    }

    return (
        <MultipleFieldsDisplay
            className={props.className}
            title={
                <div className={classes.titleContainer}>
                    {props.title}
                    {error && (
                        <>
                            <div className={classes.error}>({error})</div>
                            <img
                                src={errorIcon}
                                className={classes.errorIcon}
                            />
                        </>
                    )}
                </div>
            }
            fields={fields}
        />
    );
}

/** Formats a site as "site_name (address)", or "site_name" if address not available */
function formatSite(cluster) {
    if (!cluster.site_location || !cluster.site_location.address) {
        return cluster.site_name;
    }

    return `${cluster.site_name} (${cluster.site_location.address})`;
}

/** Formats the title of the window - with machine's alias and status */
function MachineTitle({ machine }) {
    const classes = useStyles();

    return (
        <div className={classes.machineTitle}>
            <span>
                {`${machine.alias} / ${recipeStatusToDescription({
                    recipeStatus: machine.latest_status,
                    isCleaningRecipe: machine.latest_recipe_is_cleaning,
                    timestamp: machine.latest_timestamp,
                })} (${machine.latest_status})`}
            </span>
            {machine.latest_is_cs && (
                <img src={faultIcon} className={classes.faultIcon} />
            )}
        </div>
    );
}

/** Displays machine details/info */
export default function MachineInfo(props) {
    const classes = useStyles();
    const machine = props.machine;
    const [showMap, setShowMap] = useState(false);
    const infoWindow = useRef(null);

    if (!machine) {
        // No machine loaded yet
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={''}
                icon={closeIcon}
                loading={true}
                onIconClick={props.onClose}
            ></InnerWindow>
        );
    }

    // Sort previous clusters by addition date
    machine.previous_clusters.sort((c1, c2) => {
        return c2.add_date - c1.add_date;
    });

    // Find latest cluster
    const latestCluster = machine.previous_clusters[0];

    const lastCoolingTimestamp = machine.last_cooling.timestamp
        ? DateTime.fromMillis(machine.last_cooling.timestamp)
        : null;
    const lastCoolingTime = machine.last_cooling.cooling_time
        ? Duration.fromMillis(machine.last_cooling.cooling_time)
        : null;

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={<MachineTitle machine={machine} />}
            icon={closeIcon}
            onIconClick={props.onClose}
        >
            <div className={classes.content} ref={infoWindow}>
                <TextField
                    leftLabel={'Client:'}
                    leftLabelWidth={'88px'}
                    readOnly
                    defaultValue={latestCluster.client_name}
                    className={classes.fieldContainer}
                />
                <TextField
                    leftLabel={'Site:'}
                    leftLabelWidth={'88px'}
                    readOnly
                    defaultValue={formatSite(latestCluster)}
                    className={classes.fieldContainer}
                />
                <TextField
                    leftLabel={'Cluster:'}
                    leftLabelWidth={'88px'}
                    readOnly
                    defaultValue={latestCluster.cluster_name}
                    className={classes.fieldContainer}
                />
                <TextField
                    leftLabel={'Model:'}
                    leftLabelWidth={'85px'}
                    readOnly
                    defaultValue={machine.model || ''}
                    className={classes.fieldContainer}
                />
                <TextField
                    leftLabel={'Restrictions:'}
                    leftLabelWidth={'110px'}
                    readOnly
                    defaultValue={machine.attributes.join(', ')}
                    className={classes.fieldContainer}
                />
                <TextField
                    topLabel={`${machine.alias} - Description:`}
                    readOnly
                    multiline
                    defaultValue={machine.description}
                    className={classes.descriptionContainer}
                />

                <div className={classes.separator} />

                <MultipleFieldsDisplay
                    title={'Capsules Consumption (Calendar):'}
                    fields={[
                        {
                            label: 'Daily',
                            value: machine.capsule_consumption.daily,
                            align: 'right',
                            capsulesIcon: true,
                        },
                        {
                            label: 'Weekly',
                            value: machine.capsule_consumption.weekly,
                            align: 'right',
                            capsulesIcon: true,
                        },
                        {
                            label: 'Monthly',
                            value: machine.capsule_consumption.monthly,
                            align: 'right',
                            capsulesIcon: true,
                        },
                    ]}
                />

                <div className={classes.separator} />

                {!machine.is_p15 && (
                    <LastTimeDisplay
                        className={classes.lastTimeContainer}
                        title={'Last Init:'}
                        timestamp={
                            machine.last_init.timestamp
                                ? DateTime.fromMillis(
                                      machine.last_init.timestamp
                                  )
                                : null
                        }
                        timezoneOffset={machine.timezone_offset}
                        capsules={machine.last_init.capsules}
                    />
                )}
                {machine.is_p15 && (
                    <TurnOnDisplay
                        className={classes.lastTimeContainer}
                        title={'Last Turn On:'}
                        firstTimestamp={
                            machine.turn_on.first_timestamp
                                ? DateTime.fromMillis(
                                      machine.turn_on.first_timestamp
                                  )
                                : null
                        }
                        lastTimestamp={
                            machine.turn_on.last_timestamp
                                ? DateTime.fromMillis(
                                      machine.turn_on.last_timestamp
                                  )
                                : null
                        }
                        timezoneOffset={machine.timezone_offset}
                    />
                )}

                <LastTimeDisplay
                    className={classes.lastTimeContainer}
                    title={'Last Food Capsule:'}
                    timestamp={
                        machine.last_food.timestamp
                            ? DateTime.fromMillis(machine.last_food.timestamp)
                            : null
                    }
                    timezoneOffset={machine.timezone_offset}
                    hideCapsules={true}
                    elapsedTime={true}
                />

                <LastTimeDisplay
                    className={classes.lastTimeContainer}
                    title={'Last Clean:'}
                    timestamp={
                        machine.last_clean.timestamp
                            ? DateTime.fromMillis(machine.last_clean.timestamp)
                            : null
                    }
                    timezoneOffset={machine.timezone_offset}
                    elapsedTime={true}
                    hideCapsules={true}
                    error={
                        !machine.last_clean.completed &&
                        machine.last_clean.timestamp
                            ? 'Incomplete Cleaning Process'
                            : false
                    }
                />

                {!machine.is_p15 && (
                    <LastTimeDisplay
                        className={classes.lastTimeContainer}
                        title={'Last Deep Clean:'}
                        timestamp={
                            machine.last_deep_clean.timestamp
                                ? DateTime.fromMillis(
                                      machine.last_deep_clean.timestamp
                                  )
                                : null
                        }
                        timezoneOffset={machine.timezone_offset}
                        capsules={machine.last_deep_clean.capsules}
                    />
                )}

                <LastTimeDisplay
                    className={classes.lastTimeContainer}
                    title={'Installation on Site:'}
                    timestamp={DateTime.fromMillis(
                        machine.installation_time.timestamp
                    )}
                    timezoneOffset={machine.timezone_offset}
                    capsules={machine.installation_time.capsules}
                    elapsedTime={true}
                    hideCapsules={true}
                />

                <div className={classes.separator} />

                <TextField
                    leftLabel={'FW Version:'}
                    leftLabelWidth={'194px'}
                    readOnly
                    defaultValue={machine.fw_version || 'N/A'}
                    className={classes.fieldContainer}
                    align={'right'}
                />
                <TextField
                    leftLabel={'BootLoader Version:'}
                    leftLabelWidth={'194px'}
                    readOnly
                    defaultValue={machine.bl_version || 'N/A'}
                    className={classes.fieldContainer}
                    align={'right'}
                />
                <TextField
                    leftLabel={'IMEI:'}
                    leftLabelWidth={'194px'}
                    readOnly
                    defaultValue={machine.imei || 'N/A'}
                    className={classes.fieldContainer}
                    align={'right'}
                />

                <div className={classes.separator} />

                <div className={classes.locationContainer}>
                    <div className={classes.locationDescription}>
                        <div>{latestCluster.client_name}</div>
                        <div>{formatSite(latestCluster)}</div>
                    </div>
                    <IconButton
                        className={classes.mapIconContainer}
                        onClick={() => setShowMap(true)}
                    >
                        <img
                            className={classes.mapIcon}
                            src={locationCircle}
                            alt="Show Map"
                        />
                    </IconButton>
                </div>
                <Dialog
                    onClose={() => setShowMap(false)}
                    open={showMap}
                    style={{ position: 'absolute' }}
                    BackdropProps={{ style: { position: 'absolute' } }}
                    container={() => {
                        return infoWindow.current;
                    }}
                    classes={{ paper: classes.mapDialog }}
                >
                    <LocationViewer
                        locationDescription={`${
                            latestCluster.client_name
                        }\n${latestCluster.site_location.address || ''}`}
                        address={latestCluster.site_location.address}
                        latitude={
                            machine.last_location
                                ? machine.last_location.latitude
                                : null
                        }
                        longitude={
                            machine.last_location
                                ? machine.last_location.longitude
                                : null
                        }
                        onClose={() => setShowMap(false)}
                    />
                </Dialog>

                <div className={classes.separator} />

                <div className={classes.titleLabel}>Location History</div>

                <div className={classes.separator} />

                {machine.previous_clusters.map((previousCluster, index) => {
                    return (
                        <ExpansionPanel
                            className={classes.expansionPanelContainer}
                            key={previousCluster.add_date}
                            rightTitle={`${machine.previous_clusters.length -
                                index}/${machine.previous_clusters.length}`}
                            title={`${
                                previousCluster.client_name
                            } ${DateTime.fromMillis(
                                previousCluster.add_date
                            ).toFormat('dd MMM yyyy')}`}
                        >
                            <div className={classes.previousClusterContainer}>
                                <TextField
                                    leftLabel={'Client:'}
                                    leftLabelWidth={'85px'}
                                    readOnly
                                    defaultValue={previousCluster.client_name}
                                    className={classes.fieldContainer}
                                />
                                <TextField
                                    leftLabel={'Site:'}
                                    leftLabelWidth={'85px'}
                                    readOnly
                                    defaultValue={previousCluster.site_name}
                                    className={classes.fieldContainer}
                                />
                                <TextField
                                    leftLabel={'Cluster:'}
                                    leftLabelWidth={'85px'}
                                    readOnly
                                    defaultValue={previousCluster.cluster_name}
                                    className={classes.fieldContainer}
                                />
                                <TextField
                                    leftLabel={'Inst. Date:'}
                                    leftLabelWidth={'85px'}
                                    readOnly
                                    defaultValue={DateTime.fromMillis(
                                        previousCluster.add_date
                                    ).toFormat('dd MMM yyyy')}
                                    className={classes.fieldContainer}
                                />
                                <TextField
                                    leftLabel={'Inst. By:'}
                                    leftLabelWidth={'85px'}
                                    readOnly
                                    defaultValue={
                                        previousCluster.added_by_user_email
                                            ? previousCluster.added_by_user_email
                                            : `N/A (${previousCluster.added_by_user})`
                                    }
                                    className={classes.fieldContainer}
                                />
                                {previousCluster.removed_date && (
                                    <TextField
                                        leftLabel={'Remo. Date:'}
                                        leftLabelWidth={'85px'}
                                        readOnly
                                        defaultValue={DateTime.fromMillis(
                                            previousCluster.removed_date
                                        ).toFormat('dd MMM yyyy')}
                                        className={classes.fieldContainer}
                                    />
                                )}
                                {previousCluster.removed_date && (
                                    <TextField
                                        leftLabel={'Remo. By:'}
                                        leftLabelWidth={'85px'}
                                        readOnly
                                        defaultValue={
                                            previousCluster.removed_by_user_email
                                                ? previousCluster.removed_by_user_email
                                                : `N/A (${previousCluster.removed_by_user})`
                                        }
                                        className={classes.fieldContainer}
                                    />
                                )}
                                {previousCluster.removed_date && (
                                    <TextField
                                        topLabel={`${machine.alias} - Reason for Change:`}
                                        readOnly
                                        multiline
                                        rows={3}
                                        rowsMax={3}
                                        defaultValue={
                                            previousCluster.removal_reason
                                        }
                                        className={classes.removalReason}
                                    />
                                )}
                            </div>
                        </ExpansionPanel>
                    );
                })}
            </div>
        </InnerWindow>
    );
}
