/** Utility function for triggering a file download from data */
import axios from 'axios';
import FileSaver from 'file-saver';

export async function downloadFile(filename, url) {
    console.log(`downloadFile: ${url}`);

    const response = await axios.get(url, { responseType: 'blob' });
    console.log(`downloadFile got response: ${url}: ${filename}`);

    FileSaver.saveAs(
        new Blob([response.data], { type: 'application/octet-stream' }),
        filename
    );
}
