import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chart from 'chart.js';
import capsuleIcon from '../../res/images/capsules.svg';
import { formatClientTitle } from '../../modules/MachineUtils';
import '../../modules/ChartUtils'; // For hoverLine graph type

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: '#f0f3f9',
    },
    canvasContainer: {
        width: '100%',
        flexGrow: 1,
    },
    canvas: {},
    legend: {
        display: 'flex',
        flexDirection: 'row',
        height: '66px',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '196px',
        minWidth: '196px',
        backgroundColor: '#e3e9f4',
        color: '#586374',
        position: 'relative',
    },
    legendItemCount: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '16px',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            marginRight: '3px',
        },
    },
    legendItemTitle: {
        fontSize: '10px',
        flexGrow: 1,
        textAlign: 'center',
        marginTop: '5px',
    },
    legendItemVerticalLineSeparator: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '2px',
        backgroundColor: '#ffffff',
    },
    legendItemBottomColorBar: {
        width: '100%',
        height: '3px',
    },
});

/** Represents a single client legend item (shows name, capsule count and color)  */
function ClientLegendItem(props) {
    const classes = useStyles();
    const client = props.client;
    const data = props.stats.data[client._id];
    const activeIndex = props.activeIndex;
    const totalCapsules = Object.values(data).reduce((a, b) => a + b, 0);

    return (
        <div className={classes.legendItem}>
            <div className={classes.legendItemCount}>
                <span>
                    {activeIndex ? data[activeIndex] || 0 : totalCapsules}
                </span>
                <img
                    className={classes.legendItemCapsule}
                    src={capsuleIcon}
                    alt={'Capsule Count'}
                />
            </div>
            <div className={classes.legendItemTitle}>
                {formatClientTitle(client)}
            </div>

            <div className={classes.legendItemVerticalLineSeparator} />
            <div
                className={classes.legendItemBottomColorBar}
                style={{ backgroundColor: client.color }}
            />
        </div>
    );
}

/** Shows the legend (shown on top of the graph), with all the clients */
function ClientLegend(props) {
    const classes = useStyles();

    return (
        <div className={classes.legend}>
            {props.clients.map((c) => (
                <ClientLegendItem
                    key={c._id}
                    client={c}
                    activeIndex={props.activeIndex}
                    stats={props.stats}
                />
            ))}
        </div>
    );
}

/** The results graph */
export default function ResultsGraph(props) {
    const classes = useStyles();
    const canvas = useRef(null);
    const stats = props.stats;
    const [chart, setChart] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        if (chart !== null) {
            chart.destroy();
        }

        const context = canvas.current.getContext('2d');

        // Convert stats/data into line datasets
        const data = {
            labels: stats.columns,
            datasets: props.clients.map((c) => ({
                label: formatClientTitle(c),
                fill: false,
                borderColor: c.color,
                data: stats.columns.map((col) => stats.data[c._id][col] || 0),
            })),
        };

        const newChart = new Chart(context, {
            type: 'hoverLine',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                    intersect: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                onHover: (event) => {
                    // Update parent component with current time being hovered on (will update title)
                    if (event.type === 'mousemove') {
                        const items = newChart.getElementsAtXAxis(event);
                        if (items) {
                            const columnIndex = items[0]._index;
                            props.onSelectedTime(stats.columns[columnIndex]);
                            setActiveIndex(stats.columns[columnIndex]);
                            return;
                        }
                    }

                    // Went outside the graph
                    props.onSelectedTime(null);
                    setActiveIndex(null);
                },
            },
        });

        setChart(newChart);

        return () => {
            if (chart !== null) {
                chart.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stats, props.clients]);

    if (!props.stats) {
        return <div className={clsx(classes.graph, props.className)}></div>;
    }

    return (
        <div className={clsx(classes.container, props.className)}>
            <ClientLegend
                clients={props.clients}
                activeIndex={activeIndex}
                stats={props.stats}
            />
            <div className={classes.canvasContainer}>
                <canvas ref={canvas} className={classes.canvas} />
            </div>
        </div>
    );
}
