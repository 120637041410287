import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { makeStyles, Paper, IconButton } from '@material-ui/core';
import { usePosition } from 'use-position';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import closeIcon from '../res/images/close_circle.svg';
import { ReactComponent as MachineIcon } from '../res/images/machine_target.svg';
import { ReactComponent as SiteLocationIcon } from '../res/images/target_circle.svg';
import locationCircleIcon from '../res/images/location_circle.svg';
import { ReactComponent as MyLocationIcon } from '../res/images/my_location.svg';
import Button from './Button';
import Config from '../modules/Config';
import { fitMarkersInBounds } from '../modules/MapUtils';

function Marker(props) {
    if (props.type === 'machine_location') {
        return <MachineIcon width="26px" height="26px" />;
    } else if (props.type === 'site_location') {
        return <SiteLocationIcon width="26px" height="26px" />;
    } else if (props.type === 'my_location') {
        return <MyLocationIcon width="26px" height="26px" />;
    }
}

const useStyles = makeStyles({
    root: {
        backgroundColor: '#f2f2f6',
        paddingTop: '10px',
        paddingBottom: '24px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingBottom: '8px',
        width: '100%',
    },
    locationDescription: {
        flexGrow: 1,
        fontSize: '12px',
        color: '#586374',
        paddingRight: '5px',
        '& pre': {
            fontFamily: 'unset',
            margin: 'unset',
            whiteSpace: 'pre-line',
        },
    },
    closeIcon: {
        width: '40px',
        height: '40px',
    },
    closeButtonRoot: {
        padding: '3px',
        height: '46px',
    },
    mapContainer: {
        height: '200px',
    },
    footer: {
        width: '100%',
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    openInGMaps: {
        width: '222px',
        justifyContent: 'left',
        paddingLeft: '22px',
    },
});

/** Our own type of styled text field, with optional left label and clickable right icon */
export default function LocationViewer(props) {
    const classes = useStyles();
    const mapRef = useRef(null);
    const [mapViewpoint, setMapViewpoint] = useState({
        zoom: 1,
        center: { lat: 0, lng: 0 },
    });
    const [addressCoordinates, setAddressCoordinates] = useState({});
    const currentPosition = usePosition();

    useEffect(() => {
        if (!props.address) return;

        // Look up the address and convert into lat/lng coordinates (geodecoding)
        Geocode.setApiKey(Config.GMAPS_API_KEY);

        Geocode.fromAddress(props.address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setAddressCoordinates({ latitude: lat, longitude: lng });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [props.address]);

    useEffect(() => {
        // Fit all map markers on the map
        const markers = [
            { latitude: props.latitude, longitude: props.longitude },
        ];
        if (addressCoordinates.latitude && addressCoordinates.longitude) {
            markers.push({
                latitude: addressCoordinates.latitude,
                longitude: addressCoordinates.longitude,
            });
        }
        if (currentPosition.latitude && currentPosition.longitude) {
            /* TODO
            markers.push({
                latitude: currentPosition.latitude,
                longitude: currentPosition.longitude,
            });
             */
        }
        fitMarkersInBounds(markers, mapRef.current, (res) => {
            // In case this is the result of a no-longer-relevant call
            if (res.mapMarkers.length !== markers.length) return;

            setMapViewpoint({
                center: res.center,
                zoom: res.zoom > 15 ? 15 : res.zoom,
            });
        });
    }, [
        addressCoordinates.longitude,
        addressCoordinates.latitude,
        props.longitude,
        props.latitude,
        currentPosition.latitude,
        currentPosition.longitude,
    ]);

    const openGMaps = () => {
        window.open(
            `https://maps.google.com/maps?t=k&z=12&t=h&q=${props.latitude}+${props.longitude}`,
            '_blank'
        );
    };

    return (
        <Paper square classes={{ root: classes.root }}>
            <div className={classes.header}>
                <div className={classes.locationDescription}>
                    <pre>{props.locationDescription}</pre>
                </div>
                {props.onClose && (
                    <IconButton
                        classes={{ root: classes.closeButtonRoot }}
                        onClick={props.onClose}
                    >
                        <img
                            className={classes.closeIcon}
                            src={closeIcon}
                            alt="Close"
                        />
                    </IconButton>
                )}
            </div>
            <div ref={mapRef} className={classes.mapContainer}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: Config.GMAPS_API_KEY,
                    }}
                    options={{
                        disableDefaultUI: true,
                    }}
                    zoom={mapViewpoint.zoom}
                    center={mapViewpoint.center}
                >
                    <Marker
                        lat={props.latitude}
                        lng={props.longitude}
                        type="machine_location"
                    />
                    {addressCoordinates &&
                        addressCoordinates.latitude &&
                        addressCoordinates.longitude && (
                            <Marker
                                lat={addressCoordinates.latitude}
                                lng={addressCoordinates.longitude}
                                type="site_location"
                            />
                        )}
                    {currentPosition &&
                        currentPosition.latitude &&
                        currentPosition.longitude && (
                            <Marker
                                lat={currentPosition.latitude}
                                lng={currentPosition.longitude}
                                type="my_location"
                            />
                        )}
                </GoogleMapReact>
            </div>
            <div className={classes.footer}>
                <Button
                    className={classes.openInGMaps}
                    label={'Open in Google Maps'}
                    rightIcon={locationCircleIcon}
                    onClick={openGMaps}
                />
            </div>
        </Paper>
    );
}
