import * as actions from '../actions/search';

const INITIAL_STATE = {
    machines: [],
};

/** Overview Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_SEARCH_MACHINES:
            return { ...state, machines: action.value };
        default:
            return state;
    }
};
