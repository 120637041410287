import Chart from 'chart.js';

/** A line graph that shows a vertical line when the user is hovering over it (the
 * line will appear in the closest X axis intersection / data point */
Chart.defaults.hoverLine = Chart.defaults.line;
Chart.controllers.hoverLine = Chart.controllers.line.extend({
    draw: function(ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            const activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.tooltipPosition().x,
                topY = this.chart.scales['y-axis-0'].top,
                bottomY = this.chart.scales['y-axis-0'].bottom;

            // Draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.setLineDash([1, 4]);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#7d90aa';
            ctx.stroke();
            ctx.restore();
        }
    },
});

// Position tooltip always at the top of the graph
Chart.Tooltip.positioners.alwaysOnTop = function(elements, position) {
    if (!elements.length) {
        return false;
    }
    return {
        x: position.x,
        y: 0,
    };
};
