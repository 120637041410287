import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import SearchField from '../../components/SearchField';
import MachineList from '../../components/MachineList';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import closeIcon from '../../res/images/close_circle.svg';
import filterIcon from '../../res/images/filter_circle_outline.svg';
import capsuleIcon from '../../res/images/capsules.svg';
import upIcon from '../../res/images/up_circle.svg';
import locationIcon from '../../res/images/location_circle.svg';
import clientIcon from '../../res/images/store.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import Checkbox from '../../components/Checkbox';
import { setStatsFilter } from '../../store/actions/stats';
import clone from 'clone';
import ToggleButton from '../../components/ToggleButton';
import Button from '../../components/Button';
import randomColor from 'randomcolor';
import {
    findItem,
    getSelected,
    getSelectedClusters,
    getSelectedSites,
    ID_ALL_CLIENTS,
    markItem,
    markSelectedItems,
    SORT_TYPE_ALPHABETICAL,
    SORT_TYPE_ALPHABETICAL_REVERSE,
    SORT_TYPE_CAPSULE_HIGH_TO_LOW,
    SORT_TYPE_CAPSULE_LOW_TO_HIGH,
    sortItems,
} from '../../modules/MachineUtils';

const useStyles = makeStyles({
    window: {
        width: '414px',
        background: '#00B09E',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchHeader: {
        background: 'linear-gradient(to right, #027c76, #00a89d)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    list: {
        width: '100%',
        flexGrow: 1,
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '125px',
    },
    itemMachineCount: {
        color: '#e2e2e2',
        fontSize: '16px',
        fontWeight: 300,
        marginRight: '3px',
        marginTop: '3px',
        flexGrow: 1,
    },
    itemMachineIcon: {
        width: '31px',
        height: '31px',
        marginRight: '5px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    itemCheck: {},
    sortButtonsContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#40968e',
    },
    sortButton: {
        marginRight: '10px',
        width: '80px',
        borderColor: '#ffffff',
        backgroundColor: '#4cad9e',
        color: '#ffffff',
        '&.Mui-selected': {
            borderColor: '#ffffff',
            backgroundColor: '#2e6f64',
            color: '#ffffff',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    filterSection: {
        paddingTop: '10px',
        paddingLeft: '35px',
        paddingRight: '35px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterIcon: {
        width: '18px',
        height: '18px',
        marginRight: '10px',
        filter: hexColorToCSSFilter('#ffffff'),
        opacity: 0.6,
    },
    filterIconBigger: {
        width: '32px',
        height: '32px',
        marginRight: '3px',
    },
    filterLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#ffffff',
    },
    filterButtonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingTop: '10px',
        paddingLeft: '35px',
        paddingRight: '35px',
    },
    filterButton: {
        width: '120px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    separator: {
        marginBottom: '24px',
        marginTop: '14px',
        height: '1px',
        minHeight: '1px',
        backgroundColor: '#7d90aa',
    },
    closeButton: {
        width: '140px',
        alignSelf: 'center',
        marginTop: '50px',
        marginBottom: '20px',
    },
});

/** Formats the title of the window according to selected filter */
function formatTitle(filter) {
    const allClients = filter.clients.filter((x) => x.region && !x.parent);
    const allSites = filter.clients.filter((x) => x.client);
    const allClusters = filter.clients.filter((x) => x.site);

    if (filter.clients.findIndex((x) => x._id === ID_ALL_CLIENTS) > -1) {
        return 'Clients (All)';
    } else {
        return `Clients (${allClients.length}) Sites (${allSites.length}) Clusters (${allClusters.length})`;
    }
}

/** Assigns random colors to all client/site/cluster filters - happens only when filter changes */
function assignRandomColors(clients) {
    if (clients.length === 0) return;

    const colors = randomColor({
        count: clients.length,
        luminosity: 'dark',
    });

    let i = 0;
    clients.map((x) => (x.color = colors[i++]));
}

/** The search/filter clients inner window */
export default function FilterClients(props) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const filter = useSelector((state) => state.stats.filter);
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState(SORT_TYPE_ALPHABETICAL);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState(new Set());
    const [selectedClientTypes, setSelectedClientTypes] = useState(new Set());

    useEffect(() => {
        const itemsCopy = clone(props.items);
        if (itemsCopy.length > 0) {
            // Sort items
            const allClients = itemsCopy.shift(); // All clients result will always be first
            sortItems(itemsCopy, sortType);
            itemsCopy.unshift(allClients);
        }

        markSelectedItems(itemsCopy, filter.clients);

        setItems(itemsCopy);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        const clients = [
            ...getSelected(items),
            ...getSelectedSites(items),
            ...getSelectedClusters(items),
        ];
        assignRandomColors(clients);
        dispatch(
            setStatsFilter({
                clients: clients,
            })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        // Sort type has changed - update items
        if (items && items.length > 0) {
            const itemsCopy = clone(items);
            const allClients = itemsCopy.shift(); // All clients result will always be first
            sortItems(itemsCopy, sortType);
            itemsCopy.unshift(allClients);

            setItems(itemsCopy);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    const renderRightPart = (item) => {
        const capsuleCount = item.capsule_count;

        return (
            <div className={classes.itemRightSide}>
                <img
                    src={capsuleIcon}
                    className={classes.itemMachineIcon}
                    alt="Capsule Count"
                />
                <div className={classes.itemMachineCount}>{capsuleCount}</div>
                <Checkbox
                    checked={
                        filter.clients.findIndex((x) => x._id === item._id) > -1
                            ? true
                            : false
                    }
                    className={classes.itemCheck}
                    onChange={(v) => {
                        const itemsCopy = clone(items);

                        if (item._id === ID_ALL_CLIENTS) {
                            // Unmark all other items
                            itemsCopy.map((x) => markItem(x, false));

                            itemsCopy[0].selected = v;
                            markItem(itemsCopy, v);
                        } else {
                            const itemCopy = findItem(itemsCopy, item._id);
                            markItem(itemCopy, v);

                            // Uncheck "All clients" option
                            itemsCopy[0].selected = false;
                        }
                        setItems(itemsCopy);
                    }}
                />
            </div>
        );
    };

    const toggleSelectedCountry = (countryId) => {
        const selectedCountriesCopy = clone(selectedCountries);
        if (selectedCountriesCopy.has(countryId)) {
            selectedCountriesCopy.delete(countryId);
        } else {
            selectedCountriesCopy.add(countryId);
        }

        setSelectedCountries(selectedCountriesCopy);
    };

    const toggleSelectedClientType = (clientTypeId) => {
        const selectedClientTypesCopy = clone(selectedClientTypes);
        if (selectedClientTypesCopy.has(clientTypeId)) {
            selectedClientTypesCopy.delete(clientTypeId);
        } else {
            selectedClientTypesCopy.add(clientTypeId);
        }

        setSelectedClientTypes(selectedClientTypesCopy);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={formatTitle(filter)}
            loading={loading}
            icon={closeIcon}
            onIconClick={() => {
                if (loading) return;

                // Reset selection - unselect all clients
                const itemsCopy = clone(items);
                itemsCopy.map((x) => markItem(x, false));
                itemsCopy[0].selected = true; // "All clients" entity
                setItems(itemsCopy);
            }}
            leftIcon={showFilter ? upIcon : filterIcon}
            onLeftIconClick={() => {
                if (loading) return;
                setShowFilter(!showFilter);
            }}
        >
            {showFilter ? (
                <div className={classes.content}>
                    <div className={classes.filterSection}>
                        <img
                            className={classes.filterIcon}
                            src={locationIcon}
                            alt="Location"
                        />
                        <div className={classes.filterLabel}>Location:</div>
                    </div>
                    <div className={classes.filterButtonContainer}>
                        {filter.countries.map((country) => (
                            <ToggleButton
                                key={country._id}
                                className={clsx(
                                    classes.sortButton,
                                    classes.filterButton
                                )}
                                onChange={() =>
                                    toggleSelectedCountry(country._id)
                                }
                                selected={selectedCountries.has(country._id)}
                            >
                                {country.name}
                            </ToggleButton>
                        ))}
                    </div>
                    <div className={classes.separator} />

                    <div className={classes.filterSection}>
                        <img
                            className={clsx(
                                classes.filterIcon,
                                classes.filterIconBigger
                            )}
                            src={clientIcon}
                            alt="Client Type"
                        />
                        <div className={classes.filterLabel}>Client Type:</div>
                    </div>
                    <div className={classes.filterButtonContainer}>
                        {filter.clientTypes.map((clientType) => (
                            <ToggleButton
                                key={clientType._id}
                                className={clsx(
                                    classes.sortButton,
                                    classes.filterButton
                                )}
                                onChange={() =>
                                    toggleSelectedClientType(clientType._id)
                                }
                                selected={selectedClientTypes.has(
                                    clientType._id
                                )}
                            >
                                {clientType.name}
                            </ToggleButton>
                        ))}
                    </div>

                    <Button
                        className={classes.closeButton}
                        label="Close"
                        onClick={() => {
                            setShowFilter(false);
                        }}
                    />
                </div>
            ) : (
                <div className={classes.content}>
                    <div className={classes.sortButtonsContainer}>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() => setSortType(SORT_TYPE_ALPHABETICAL)}
                            selected={sortType === SORT_TYPE_ALPHABETICAL}
                        >
                            A-Z
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_ALPHABETICAL_REVERSE)
                            }
                            selected={
                                sortType === SORT_TYPE_ALPHABETICAL_REVERSE
                            }
                        >
                            Z-A
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_CAPSULE_HIGH_TO_LOW)
                            }
                            selected={
                                sortType === SORT_TYPE_CAPSULE_HIGH_TO_LOW
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={capsuleIcon}
                                alt="Capsule Count"
                            />
                            High
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_CAPSULE_LOW_TO_HIGH)
                            }
                            selected={
                                sortType === SORT_TYPE_CAPSULE_LOW_TO_HIGH
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={capsuleIcon}
                                alt="Capsule Count"
                            />
                            Low
                        </ToggleButton>
                    </div>
                    <div className={classes.searchHeader}>
                        <SearchField
                            className={classes.search}
                            placeholder="Search"
                            onSearch={setSearchText}
                        />
                    </div>
                    <MachineList
                        className={classes.list}
                        items={items}
                        filter={searchText}
                        countries={selectedCountries}
                        clientTypes={selectedClientTypes}
                        renderRightPart={renderRightPart}
                    />
                </div>
            )}
        </InnerWindow>
    );
}
