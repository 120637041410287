import React from 'react';
import filterIcon from '../../res/images/filter_small.svg';
import eyes from '../../res/images/eye.svg';
import cogs from '../../res/images/cogs.svg';
import {
    TOGGLE_BAR_STATE_DASHBOARD,
    TOGGLE_BAR_STATE_FILTER,
    TOGGLE_BAR_STATE_KPIS,
} from '../../store/actions/ui';
import ToggleBar from '../../components/ToggleBar';
import { makeStyles } from '@material-ui/core/styles';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filtersApplied: {
        marginLeft: '35px',
        backgroundColor: '#f4af00',
        borderRadius: '16.5px',
        border: 'solid 1px #000000',
        height: '33px',
        width: 'auto',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filtersAppliedIconContainer: {
        height: '26px',
        width: '26px',
        backgroundColor: '#000000',
        marginRight: '13px',
        marginLeft: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '13px',
    },
    filtersAppliedIcon: {
        height: '15px',
        width: '15px',
        filter: hexColorToCSSFilter('#f4af00'),
    },
    filtersAppliedText: {
        textTransform: 'uppercase',
        color: '#000000',
    },
});

/** Machines view specific toggle bar */
export default function MachinesToggleBar() {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);

    const buttons = [
        {
            title: 'Dashboard',
            value: TOGGLE_BAR_STATE_DASHBOARD,
            icon: eyes,
            url: '/overview',
        },
        {
            title: 'Filter',
            value: TOGGLE_BAR_STATE_FILTER,
            icon: filterIcon,
            url: '/overview/filter',
        },
        /*{
            title: 'KPIs',
            value: TOGGLE_BAR_STATE_KPIS,
            icon: cogs,
            url: '/overview/kpis',
        },*/
    ];

    return (
        <div className={classes.container}>
            <ToggleBar buttons={buttons} />
            {!filter.allMachinesSelected && filter.selectedMachines.length > 0 && (
                <div className={classes.filtersApplied}>
                    <div className={classes.filtersAppliedIconContainer}>
                        <img
                            className={classes.filtersAppliedIcon}
                            src={filterIcon}
                            alt={'Filters Applied'}
                        />
                    </div>
                    <div className={classes.filtersAppliedText}>
                        Filter applied:{' '}
                        <b>Machines ({filter.selectedMachines.length})</b>
                    </div>
                </div>
            )}
            {filter.allMachinesSelected &&
                (filter.selectedCountries.size > 0 ||
                    filter.selectedClientTypes.size > 0) && (
                    <div className={classes.filtersApplied}>
                        <div className={classes.filtersAppliedIconContainer}>
                            <img
                                className={classes.filtersAppliedIcon}
                                src={filterIcon}
                                alt={'Filters Applied'}
                            />
                        </div>
                        <div className={classes.filtersAppliedText}>
                            Filter applied:{' '}
                            {filter.selectedCountries.size > 0 && (
                                <>
                                    Countries:{' '}
                                    <b>
                                        {[...filter.selectedCountries]
                                            .map(
                                                (c) =>
                                                    filter.countries.find(
                                                        (x) => x._id === c
                                                    ).name
                                            )
                                            .join(', ')}
                                    </b>
                                </>
                            )}
                            {filter.selectedClientTypes.size > 0 && (
                                <>
                                    {' '}
                                    Client types:{' '}
                                    <b>
                                        {[...filter.selectedClientTypes]
                                            .map(
                                                (c) =>
                                                    filter.clientTypes.find(
                                                        (x) => x._id === c
                                                    ).name
                                            )
                                            .join(', ')}
                                    </b>
                                </>
                            )}
                        </div>
                    </div>
                )}
        </div>
    );
}
