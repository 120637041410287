import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../res/images/solato_logo.png';
import clsx from 'clsx';
import Authentication from '../modules/Authentication';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    profilePhoto: {
        height: '50px',
        width: '50px',
        objectFit: 'contain',
        borderRadius: '25px',
        marginLeft: '10px',
    },
    logo: {
        width: '200px',
    },
});

/** Top-right logo with profile picture */
export default function LogoWithProfile(props) {
    const classes = useStyles();
    const userDetails = Authentication.getUserDetails();

    return (
        <div className={clsx(props.className, classes.container)}>
            <img className={classes.logo} src={logo} alt={'Solato Logo'} />
            <img
                className={classes.profilePhoto}
                src={userDetails ? userDetails.photo_url : null}
                alt={'Profile'}
            />
        </div>
    );
}
