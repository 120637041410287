import React, { useEffect, useState } from 'react';
import {
    setSideMenuState,
    setToggleBarComponent,
    SIDE_MENU_STATE_ADMIN,
} from '../../store/actions/ui';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import Select from '../../components/Select';
import formIcon from '../../res/images/document.svg';
import Authentication from '../../modules/Authentication';
import DownloadLogsForm from './Forms/DownloadLogs';
import MachineForm from './Forms/MachineForm';
import DownloadLogsByClusterForm from './Forms/DownloadLogsByCluster';
import CreateCapsulesForm from './Forms/CreateCapsules';
import MachinePartsForm from './Forms/MachineParts';
import DownloadConsumptionReportForm from './Forms/DownloadConsumptionReport';
import { setAdminAllMachines } from '../../store/actions/admin';
import UpdateFWForm from './Forms/UpdateFW';
import MachineCoolingMode from './Forms/MachineCoolingMode';

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        position: 'relative',
        width: '500px',
        minHeight: '200px',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
    },
    separator: {
        width: '100%',
        height: '1px',
        minHeight: '1px',
        opacity: 0.2,
        backgroundColor: '#7d90aa',
        marginTop: '24px',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formFieldFull: {
        width: '100%',
    },
});

const ACTION_ADD_EDIT_MACHINE = 'add_edit_machine';
const ACTION_DOWNLOAD_LOGS = 'download_logs';
const ACTION_DOWNLOAD_LOGS_BY_CLUSTER = 'download_logs_by_cluster';
const ACTION_CREATE_CAPSULES = 'create_capsules';
const ACTION_CREATE_MACHINE_PARTS = 'create_machine_parts';
const ACTION_DOWNLOAD_CONSUMPTION_REPORT = 'download_consumption_report';
const ACTION_UPDATE_FW_VERSION = 'update_fw_version';
const ACTION_MACHINE_COOLING_MODE = 'mchine_cooling_mode';

const ADMIN_ACTIONS = [
    { value: ACTION_ADD_EDIT_MACHINE, label: 'Add/Edit Machine' },
    { value: ACTION_DOWNLOAD_LOGS, label: 'Download Logs' },
    {
        value: ACTION_DOWNLOAD_LOGS_BY_CLUSTER,
        label: 'Download Logs (by Cluster)',
    },
    {
        value: ACTION_DOWNLOAD_CONSUMPTION_REPORT,
        label: 'Download Consumption Report',
    },
    { value: ACTION_CREATE_MACHINE_PARTS, label: 'Create Machine Parts' },
    { value: ACTION_CREATE_CAPSULES, label: 'Create Capsules' },
    { value: ACTION_UPDATE_FW_VERSION, label: 'Update FW Version' },
    {
        value: ACTION_MACHINE_COOLING_MODE,
        label: 'Machine Cooling Mode Settings',
    },
];

const gApiClient = Authentication.getAPIClient();

export const reloadMachines = async (dispatch) => {
    const results = await gApiClient.callApi(
        'admin/getAllMachines',
        'GET',
        {},
        {}
    );

    const newMachines = results.data;
    newMachines.sort((a, b) => a.alias.localeCompare(b.alias));
    dispatch(
        setAdminAllMachines(
            Object.fromEntries(newMachines.map((m) => [m._id, m]))
        )
    );
};

/**
 * Main admin page - for managing machines, clusters, etc.
 */
export default function AdminSection(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [formType, setFormType] = useState(null);
    const [loading, setLoading] = useState(false);
    const userDetails = Authentication.getUserDetails() || {};

    useEffect(() => {
        dispatch(setToggleBarComponent(null));
        dispatch(setSideMenuState(SIDE_MENU_STATE_ADMIN));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Only show admin actions the user has permissions for
    const filterAdminActions = (action) => {
        if (action.value === ACTION_ADD_EDIT_MACHINE) {
            if (!userDetails.add_edit_machine && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_DOWNLOAD_LOGS) {
            if (!userDetails.read_logs && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_DOWNLOAD_LOGS_BY_CLUSTER) {
            if (!userDetails.read_logs && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_DOWNLOAD_CONSUMPTION_REPORT) {
            if (!userDetails.read_logs && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_CREATE_CAPSULES) {
            if (!userDetails.create_capsules && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_CREATE_MACHINE_PARTS) {
            if (!userDetails.machine_parts && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_UPDATE_FW_VERSION) {
            if (!userDetails.update_fw && !userDetails.is_admin) {
                return false;
            }
        } else if (action.value === ACTION_MACHINE_COOLING_MODE) {
            if (!userDetails.machine_cooling_mode && !userDetails.is_admin) {
                return false;
            }
        }

        return true;
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'Admin Actions'}
            loading={loading}
        >
            <div className={classes.content}>
                <div className={classes.form}>
                    <div className={classes.formRow}>
                        <Select
                            className={classes.formFieldFull}
                            items={ADMIN_ACTIONS.filter(filterAdminActions)}
                            value={formType}
                            rightIcon={formIcon}
                            hint={'Select Admin Action'}
                            onChange={(value) => {
                                setFormType(value);
                            }}
                        />
                    </div>

                    <div className={classes.separator} />

                    {formType && formType.value === ACTION_ADD_EDIT_MACHINE && (
                        <MachineForm onLoading={setLoading} />
                    )}

                    {formType && formType.value === ACTION_DOWNLOAD_LOGS && (
                        <DownloadLogsForm onLoading={setLoading} />
                    )}

                    {formType &&
                        formType.value === ACTION_DOWNLOAD_LOGS_BY_CLUSTER && (
                            <DownloadLogsByClusterForm onLoading={setLoading} />
                        )}

                    {formType &&
                        formType.value ===
                            ACTION_DOWNLOAD_CONSUMPTION_REPORT && (
                            <DownloadConsumptionReportForm
                                onLoading={setLoading}
                            />
                        )}

                    {formType && formType.value === ACTION_CREATE_CAPSULES && (
                        <CreateCapsulesForm onLoading={setLoading} />
                    )}

                    {formType &&
                        formType.value === ACTION_CREATE_MACHINE_PARTS && (
                            <MachinePartsForm onLoading={setLoading} />
                        )}
                    {formType &&
                        formType.value === ACTION_UPDATE_FW_VERSION && (
                            <UpdateFWForm onLoading={setLoading} />
                        )}
                    {formType &&
                        formType.value === ACTION_MACHINE_COOLING_MODE && (
                            <MachineCoolingMode onLoading={setLoading} />
                        )}
                </div>
            </div>
        </InnerWindow>
    );
}
