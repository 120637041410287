import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton as MUIToggleButton } from '@material-ui/lab';

const useStyles = makeStyles({
    root: {
        height: '42px',
        minHeight: '42px',
        backgroundColor: '#ffffff',
        borderRadius: '21px',
        border: 'solid 2px #2c2925',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '&.Mui-selected': {
            borderLeftStyle: 'solid !important',
            borderLeftWidth: '2px !important',
        },
    },
    shadow: {
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    },
    label: {
        color: '#2c2925',
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    disabled: {
        opacity: 0.3,
    },
});

/** Our own type of styled toggle button. */
export default function ToggleButton(props) {
    const classes = useStyles();

    return (
        <MUIToggleButton
            selected={props.selected}
            className={props.className}
            classes={{
                root: clsx(
                    classes.root,
                    classes.label,
                    props.disabled ? classes.disabled : null,
                    props.embossed ? classes.shadow : null
                ),
            }}
            disabled={props.disabled}
            value={''}
            onChange={() => {
                props.onChange(!props.selected);
            }}
        >
            {props.children}
        </MUIToggleButton>
    );
}
