import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import arrowDownCircleIcon from '../../res/images/arrow_down_circle.svg';
import clearIcon from '../../res/images/clear_circle.svg';
import ReactTooltip from 'react-tooltip';
import List from '@material-ui/core/List';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import { useMachineListChanged } from '../../modules/MachineUtils';
import { STATUS_GROUPS, TYPE_STATUS } from './StatusGroups';
import machineCircleIcon from '../../res/images/machine_circle_arrow.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { setMapSelectedMachines } from '../../store/actions/overview';

const useStyles = makeStyles({
    content: {
        backgroundColor: '#f2f5f7',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        position: 'relative',
        border: '1px solid #000000',
        borderRadius: '16px 16px 0px 0px',
        paddingTop: '25px',
        paddingRight: '10px',
        paddingLeft: '25px',
        justifyContent: 'space-between',
        zIndex: 99999,
    },

    listContainer: {
        height: '100%',
        backgroundColor: '#ffffff',
        border: '1px solid #000000',
        flex: 1,
        marginRight: '18px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '17px',
    },
    button: {
        padding: '0px',
        marginBottom: '10px',
    },
    tooltip: {
        borderRadius: '6px',
        fontSize: '14px',
        color: '#000000',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    list: {
        overflowY: 'auto',
        height: '100%',
        fontSize: '14px',
        color: '#000000',
    },
    listItemLabel: {
        textTransform: 'uppercase',
        minWidth: '70px',
    },
    listItemValue: {
        fontWeight: 'bold',
    },
    listItemValueRight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
    },
    allListsContainer: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    noData: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    listItemSeparator: {
        width: '100%',
        height: '2px',
        borderBottom: '1px dashed #929497',
    },
    listItemIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '7px',
    },
    listItemFullSeparator: {
        width: '100%',
        height: '2px',
        borderBottom: '1px solid #000000',
    },
    openMachineButton: {
        filter: hexColorToCSSFilter('#4d4d4d'),
        cursor: 'pointer',
    },
});

/** The client list box (left-most) - showing the list of clients for the selected machines */
function ClientList(props) {
    const classes = useStyles();

    const machines = props.machines;
    const clientFilter = props.clientFilter;

    const clientsById = {};

    // Build list of clients, with machine counts
    machines.forEach((machine) => {
        const client = machine.parent.parent.parent;
        if (!clientsById[client._id]) {
            clientsById[client._id] = client;
            clientsById[client._id].machineCount = 0;
        }
        clientsById[client._id].machineCount++;
    });

    return (
        <div className={clsx(props.className)}>
            <List className={classes.list}>
                {Object.values(clientsById).map((client) => (
                    <ListItem
                        key={client._id}
                        button
                        selected={
                            clientFilter && clientFilter._id === client._id
                        }
                        onClick={() => {
                            props.onClientFilter(client);
                        }}
                    >
                        <div className={classes.listItemLabel}>Client:</div>
                        <div className={classes.listItemValue}>
                            Client {client.name} ({client.machineCount})
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

/** The cluster list box (second from the left) - showing the list of clusters (grouped by clients) for the selected machines */
function ClusterList(props) {
    const classes = useStyles();

    const machines = props.machines;
    const clusterFilter = props.clusterFilter;

    const clientsById = {};
    const clientMachineCountsById = {};
    const siteMachineCountsById = {};
    const clusterMachineCountsById = {};

    // Build list of clients, with machine counts
    machines.forEach((machine) => {
        const cluster = machine.parent;
        const site = cluster.parent;
        const client = site.parent;

        if (!clientsById[client._id]) {
            clientsById[client._id] = client;
            clientMachineCountsById[client._id] = 0;
        }
        if (!siteMachineCountsById[site._id]) {
            siteMachineCountsById[site._id] = 0;
        }
        if (!clusterMachineCountsById[cluster._id]) {
            clusterMachineCountsById[cluster._id] = 0;
        }

        clientMachineCountsById[client._id]++;
        siteMachineCountsById[site._id]++;
        clusterMachineCountsById[cluster._id]++;
    });

    const clients = Object.values(clientsById);

    return (
        <div className={clsx(props.className)}>
            <List className={classes.list}>
                {clients.map((client) => {
                    return (
                        <div key={client._id}>
                            <ListItem
                                button
                                selected={
                                    clusterFilter &&
                                    clusterFilter._id === client._id
                                }
                                onClick={() => {
                                    props.onClusterFilter(client);
                                }}
                            >
                                <div className={classes.listItemLabel}>
                                    Client:
                                </div>
                                <div className={classes.listItemValue}>
                                    Client {client.name} (
                                    {clientMachineCountsById[client._id] || 0})
                                </div>
                            </ListItem>

                            {client.children.map((site) => (
                                <div key={site._id}>
                                    <ListItem
                                        button
                                        selected={
                                            clusterFilter &&
                                            clusterFilter._id === site._id
                                        }
                                        onClick={() => {
                                            props.onClusterFilter(site);
                                        }}
                                    >
                                        <div
                                            className={classes.listItemLabel}
                                            style={{ marginLeft: '5px' }}
                                        >
                                            Site:
                                        </div>
                                        <div className={classes.listItemValue}>
                                            {site.name} (
                                            {siteMachineCountsById[site._id] ||
                                                0}
                                            )
                                        </div>
                                    </ListItem>

                                    {site.children.map((cluster) => (
                                        <ListItem
                                            key={cluster._id}
                                            button
                                            selected={
                                                clusterFilter &&
                                                clusterFilter._id ===
                                                    cluster._id
                                            }
                                            onClick={() => {
                                                props.onClusterFilter(cluster);
                                            }}
                                        >
                                            <div
                                                className={
                                                    classes.listItemLabel
                                                }
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Cluster:
                                            </div>
                                            <div
                                                className={
                                                    classes.listItemValue
                                                }
                                            >
                                                {cluster.name} (
                                                {clusterMachineCountsById[
                                                    cluster._id
                                                ] || 0}
                                                )
                                            </div>
                                        </ListItem>
                                    ))}
                                </div>
                            ))}

                            <ListItem key={client._id + 'separator'}>
                                <div
                                    className={classes.listItemSeparator}
                                ></div>
                            </ListItem>
                        </div>
                    );
                })}
            </List>
        </div>
    );
}

/** The status list box (third from the left) - showing the list of statuses for the selected machines */
function StatusList(props) {
    const classes = useStyles();

    const machines = props.machines;
    const statusFilter = props.statusFilter;

    const statuses = STATUS_GROUPS.filter((s) => s.type === TYPE_STATUS);
    const warnings = STATUS_GROUPS.filter((s) => s.type !== TYPE_STATUS);
    const machinesByStatuses = {};

    // Filter machines according to the two left-most boxes, if client / site / cluster was selected

    // Count how many machines there are, for each status type
    machines.forEach((machine) => {
        [...machine.statusGroups].forEach((status) => {
            if (!machinesByStatuses[status]) {
                machinesByStatuses[status] = 0;
            }
            machinesByStatuses[status]++;
        });
    });

    return (
        <div className={clsx(props.className)}>
            <List className={classes.list}>
                {statuses.map((status) => (
                    <ListItem
                        key={status.title}
                        button
                        selected={
                            statusFilter && statusFilter.title === status.title
                        }
                        onClick={() => {
                            props.onStatusFilter(status);
                        }}
                    >
                        <div className={classes.listItemLabel}>
                            {status.title}:
                        </div>
                        <div
                            className={clsx(
                                classes.listItemValue,
                                classes.listItemValueRight
                            )}
                        >
                            {machinesByStatuses[status.title] || 0}
                            <img
                                className={classes.listItemIcon}
                                src={status.icon}
                                alt={status.title}
                            />
                        </div>
                    </ListItem>
                ))}

                <ListItem>
                    <div className={classes.listItemFullSeparator}></div>
                </ListItem>

                {warnings.map((status) => (
                    <ListItem
                        key={status.title}
                        button
                        selected={
                            statusFilter && statusFilter.title === status.title
                        }
                        onClick={() => {
                            props.onStatusFilter(status);
                        }}
                    >
                        <div className={classes.listItemLabel}>
                            {status.title}:
                        </div>
                        <div
                            className={clsx(
                                classes.listItemValue,
                                classes.listItemValueRight
                            )}
                        >
                            {machinesByStatuses[status.title] || 0}
                            <img
                                className={classes.listItemIcon}
                                src={status.icon}
                                alt={status.title}
                            />
                        </div>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

/** The machine list box (right most) - showing the list of machines (grouped by client) */
function MachineList(props) {
    const classes = useStyles();

    const machines = props.machines;
    const selectedMachine = props.selectedMachine;

    const machinesByClients = {};

    const openMachine = (machine) => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machine._id}/dashboard`, '_blank');
        win.focus();
    };

    // Build list of machines grouped by client
    machines.forEach((machine) => {
        const client = machine.parent.parent.parent;

        if (!machinesByClients[client._id]) {
            machinesByClients[client._id] = {
                client: client,
                machines: [],
            };
        }
        machinesByClients[client._id].machines.push(machine);
    });

    const statusByTitle = {};
    STATUS_GROUPS.forEach((status) => (statusByTitle[status.title] = status));

    return (
        <div className={clsx(props.className)}>
            <List className={classes.list}>
                {Object.values(machinesByClients).map(
                    ({ machines, client }) => (
                        <div key={client._id}>
                            <ListItem>
                                <div className={classes.listItemLabel}>
                                    {client.name}
                                </div>
                                <div
                                    className={clsx(
                                        classes.listItemValue,
                                        classes.listItemValueRight
                                    )}
                                >
                                    ({machines.length || 0})
                                </div>
                            </ListItem>

                            {machines.map((machine) => (
                                <ListItem
                                    key={machine._id}
                                    button
                                    selected={
                                        selectedMachine &&
                                        selectedMachine._id === machine._id
                                    }
                                    onClick={() => {
                                        props.onSelectedMachine(machine);
                                    }}
                                >
                                    <div className={classes.listItemLabel}>
                                        {machine.alias}
                                    </div>
                                    <div
                                        className={clsx(
                                            classes.listItemValue,
                                            classes.listItemValueRight
                                        )}
                                    >
                                        {[...machine.statusGroups].map(
                                            (status) => (
                                                <img
                                                    key={status}
                                                    className={
                                                        classes.listItemIcon
                                                    }
                                                    src={
                                                        statusByTitle[status]
                                                            .icon
                                                    }
                                                    data-tip={status}
                                                    alt={status}
                                                />
                                            )
                                        )}

                                        <img
                                            className={clsx(
                                                classes.listItemIcon,
                                                classes.openMachineButton
                                            )}
                                            src={machineCircleIcon}
                                            onClick={() => {
                                                openMachine(machine);
                                            }}
                                            alt="Open Machine"
                                        />
                                    </div>
                                </ListItem>
                            ))}
                        </div>
                    )
                )}
            </List>
        </div>
    );
}

/** Map info pane (pops-up from the bottom of the map), showing details on the currently-selected machine(s) on the map */
export default function MapInfoPane(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [clientFilter, setClientFilter] = useState(null);
    const [clusterFilter, setClusterFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const bottomSelectedMachines = useSelector(
        (state) => state.overview.bottomSelectedMachines
    );

    useMachineListChanged(bottomSelectedMachines, (newList) => {
        // User clicked on a machine on the map - clear all filter selections in the bottom info pane
        clearFields();
    });

    const clearFields = () => {
        setClientFilter(null);
        setClusterFilter(null);
        setStatusFilter(null);
        setSelectedMachine(null);
    };

    // Create machine list that is filtered by the client list (left most box)
    const filter1Machines = bottomSelectedMachines.filter(
        (m) => !clientFilter || m.parent.parent.parent._id === clientFilter._id
    );

    // Create machine list that is filtered by the cluster list (second to left box)
    let filter2Machines = filter1Machines;

    if (clusterFilter && clusterFilter.client) {
        // Filter by site
        filter2Machines = filter2Machines.filter(
            (m) => m.parent.parent._id === clusterFilter._id
        );
    } else if (clusterFilter && clusterFilter.region) {
        // Filter by client
        filter2Machines = filter2Machines.filter(
            (m) => m.parent.parent.parent._id === clusterFilter._id
        );
    } else if (clusterFilter && clusterFilter.site) {
        // Filter by cluster
        filter2Machines = filter2Machines.filter(
            (m) => m.parent._id === clusterFilter._id
        );
    }

    // Create machine list that is filtered by status
    const filter3Machines = filter2Machines.filter(
        (m) => !statusFilter || m.statusGroups.has(statusFilter.title)
    );

    return (
        <div className={clsx(classes.content, props.className)}>
            {bottomSelectedMachines.length > 0 && (
                <div className={classes.allListsContainer}>
                    <ClientList
                        className={classes.listContainer}
                        machines={bottomSelectedMachines}
                        clientFilter={clientFilter}
                        onClientFilter={(filter) => {
                            setClientFilter(filter);
                            setClusterFilter(null);
                            setStatusFilter(null);
                            setSelectedMachine(null);
                        }}
                    />
                    <ClusterList
                        className={classes.listContainer}
                        machines={filter1Machines}
                        clusterFilter={clusterFilter}
                        onClusterFilter={(filter) => {
                            setClusterFilter(filter);
                            setStatusFilter(null);
                            setSelectedMachine(null);
                        }}
                    />
                    <StatusList
                        className={classes.listContainer}
                        machines={filter2Machines}
                        statusFilter={statusFilter}
                        onStatusFilter={(filter) => {
                            setStatusFilter(filter);
                            setSelectedMachine(null);
                        }}
                    />
                    <MachineList
                        className={classes.listContainer}
                        machines={filter3Machines}
                        selectedMachine={selectedMachine}
                        onSelectedMachine={(machine) => {
                            setSelectedMachine(machine);
                            // Also highlight machine on map
                            const newSelectedMachines = {};
                            newSelectedMachines[machine._id] = true;
                            dispatch(
                                setMapSelectedMachines(newSelectedMachines)
                            );
                        }}
                    />
                </div>
            )}
            {bottomSelectedMachines.length === 0 && (
                <div
                    className={clsx(classes.allListsContainer, classes.noData)}
                >
                    No data to show
                </div>
            )}
            <div className={classes.buttonContainer}>
                <IconButton className={classes.button} onClick={props.onClose}>
                    <img src={arrowDownCircleIcon} alt={'Close bottom pane'} />
                </IconButton>
                <IconButton
                    className={classes.button}
                    onClick={clearFields}
                    data-tip={'Clear Fields'}
                >
                    <img src={clearIcon} alt={'Clear Fields'} />
                </IconButton>
            </div>

            <ReactTooltip
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                className={classes.tooltip}
            />
        </div>
    );
}
