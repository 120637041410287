import clsx from 'clsx';
import { recipeStatusToDescription } from '../../../modules/MachineUtils';
import { IconButton } from '@material-ui/core';
import leftDoubleArrowIcon from '../../../res/images/left_double_arrow.svg';
import leftArrowIcon from '../../../res/images/left_arrow.svg';
import rightArrowIcon from '../../../res/images/right_arrow.svg';
import Button from '../../../components/Button';
import rightDoubleArrowCircleIcon from '../../../res/images/right_double_arrow_circle.svg';
import rightDoubleArrowIcon from '../../../res/images/right_double_arrow.svg';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import {
    formatTemperature,
    temperatureIsUnavailable,
} from './MachineParameters';
import { DateTime } from 'luxon';
import { usePrevious } from '../../../modules/HookUtils';
import Authentication from '../../../modules/Authentication';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CELL_WIDTH = '94';
const COLUMN_MOVEMENT_COUNT = 4; // How many column should we move left/right when pressing the left/right buttons
const LOGS_REFRESH_RATE = 10000; // How frequently should we get new logs

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
    },
    cell: {
        height: '36px',
        width: `${CELL_WIDTH}px`,
        padding: '14px',
    },
    leftColumnCell: {
        backgroundColor: '#e3e9f4',
        fontSize: '10px',
        color: '#586374',
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        width: '168px',
        height: '37px',
        '&:last-child': {
            height: '36px',
        },
    },
    leftColumn: {
        width: '168px',
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 'bold',
        height: 'fit-content',
        marginRight: '4px',
        boxShadow: '2px 0px 5px 1px rgba(0,0,0,0.34)',
    },
    twoSideContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    leftColumnCellTitle: {
        flexGrow: 1,
        opacity: 0.65,
    },
    leftColumnCellSubtitle: {
        color: '#586374',
        opacity: 0.4,
    },
    dataContainer: {
        width: 0,
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        overflowX: 'hidden',
        height: 'fit-content',
        position: 'relative',
    },
    logColumn: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d1d8e1',
        position: 'relative',
        color: '#586374',
        textAlign: 'center',
        fontWeight: 'bold',
        alignItems: 'center',
        textTransform: 'uppercase',
        width: `${CELL_WIDTH}px`,
    },
    logColumnNew: {
        backgroundColor: '#6ad4cd',
    },
    verticalSeparator: {
        height: '100%',
        width: '1px',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    topRowLogSeparator: {
        backgroundColor: '#7d90aa',
    },
    logSeparator: {
        backgroundColor: '#7d90aa',
        opacity: 0.4,
    },
    logHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    logTime: {
        fontSize: '12px',
        opacity: 0.8,
        width: '100%',
    },
    logDate: {
        fontSize: '6px',
        color: '#586374',
        opacity: 0.8,
        width: '100%',
    },
    logStatus: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    logStatusLabel: {
        fontSize: '8px',
        fontWeight: 'normal',
        opacity: 0.8,
    },
    innerLogSeparator: {
        width: '56px',
        height: '1px',
        backgroundColor: '#ffffff',
        opacity: 0.35,
    },
    fullLogSeparator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#a7b3c5',
        opacity: 0.32,
    },
    contentContainer: {
        height: 'fit-content',
        minHeight: 'fit-content',
        overflowX: 'auto',
        position: 'relative',
    },
    logValue: {
        opacity: 0.64,
        fontSize: '12px',
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dataScrollContainer: {
        position: 'absolute',
        height: '10px',
        minHeight: '10px',
        bottom: 0,
        left: '172px',
        right: 0,
        overflowY: 'auto',
        zIndex: 999,
    },
    dataScroll: {
        width: '1500px',
        height: '1px',
    },
    headerContainer: {
        marginBottom: '4px',
        boxShadow: '2px 0px 5px 1px rgba(0,0,0,0.34)',
    },
    bottomBarContainer: {
        width: '100%',
        height: '46px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowButtonContainer: {
        padding: '0px',
        borderRadius: '15px',
        border: 'solid 2px #7d90aa',
        height: '30px',
        width: '30px',
        '&:active': {
            backgroundColor: '#7d90aa',
        },
        '& img': {
            marginTop: '-2px',
        },
        '& img:active': {
            filter: hexColorToCSSFilter('#ffffff'),
        },
    },
    leftArrowPadding: {
        marginLeft: '22px',
    },
    leftArrowPaddingWide: {
        marginLeft: '86px',
    },
    newDataButton: {
        borderRadius: '15px',
        border: 'solid 2px #7d90aa',
        height: '32px',
        width: '245px',
        backgroundColor: '#f0f3f9',
        justifyContent: 'left',
        paddingLeft: '10px',
        '& div': {
            color: '#7d90aa',
        },
        '& div:last-of-type': {
            height: 'unset',
            marginTop: '1px',
        },
    },
});

function LeftColumnCell(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.cell, classes.leftColumnCell)}>
            <div className={classes.leftColumnCellTitle}>{props.title}</div>
            <div className={classes.leftColumnCellSubtitle}>
                {props.rightTitle}
            </div>
        </div>
    );
}

/** Represents a single log entry header */
function LogHeader(props) {
    const classes = useStyles();

    const date = DateTime.fromMillis(props.timestamp);
    const machineDate = DateTime.fromMillis(props.timestamp)
        .toUTC()
        .plus({ minutes: props.timezoneOffset });

    return (
        <div
            className={clsx(
                classes.logColumn,
                props.isNew ? classes.logColumnNew : null
            )}
        >
            <div
                className={clsx(
                    classes.verticalSeparator,
                    classes.topRowLogSeparator
                )}
            ></div>
            <div className={clsx(classes.cell, classes.logHeader)}>
                <div className={classes.logTime}>
                    {date.toFormat('HH:mm:ss')}
                </div>
                <div className={classes.logDate}>
                    {date.toFormat('dd MMM yyyy')}
                </div>
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logHeader)}>
                <div className={classes.logTime}>
                    {machineDate.toFormat('HH:mm:ss')}
                </div>
                <div className={classes.logDate}>
                    {machineDate.toFormat('dd MMM yyyy')}
                </div>
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logStatus)}>
                <div className={classes.logStatusLabel}>
                    {props.statusDescription}
                </div>
                <div className={classes.logStatusLabel}>({props.status})</div>
            </div>
        </div>
    );
}

/** Represents a single log entry column (the entire column, with all of its values) */
function LogColumn(props) {
    const classes = useStyles();
    const log = props.log;

    return (
        <div
            className={clsx(
                classes.logColumn,
                log.isNew ? classes.logColumnNew : null
            )}
        >
            <div
                className={clsx(
                    classes.verticalSeparator,
                    classes.logSeparator
                )}
            ></div>
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.motorCurrentValue1}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.motorSpeed1}%
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.motor1speedValue}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.compressorSpeed1}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.systemCurrentValue1}
            </div>
            <div className={classes.fullLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.pumpCurrent}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.actuator}%
            </div>
            <div className={classes.fullLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {temperatureIsUnavailable(log.temperatureValue1) ||
                temperatureIsUnavailable(log.temperatureValue2)
                    ? 'N/A'
                    : formatTemperature(
                          (log.temperatureValue1 + log.temperatureValue2) / 2
                      )}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {formatTemperature(log.temperatureValue1)}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {formatTemperature(log.temperatureValue2)}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {formatTemperature(log.temperatureValue3)}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {formatTemperature(log.temperatureValue4)}
            </div>
            <div className={classes.fullLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.hatchIsOpen ? 'OPEN' : 'CLOSED'}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.coverIsOpen
                    ? log.isP15
                        ? 'OPEN'
                        : 'OFF'
                    : log.isP15
                    ? 'CLOSE'
                    : 'ON'}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.spoutIsOpen
                    ? log.isP15
                        ? 'CLOSED'
                        : 'OPEN'
                    : log.isP15
                    ? 'OPENED'
                    : 'CLOSED'}
            </div>
            <div className={classes.innerLogSeparator} />
            <div className={clsx(classes.cell, classes.logValue)}>
                {log.drawerIsOpen ? 'OPEN' : 'CLOSED'}
            </div>
            {log.isP15 && (
                <>
                    <div className={classes.innerLogSeparator} />
                    <div className={clsx(classes.cell, classes.logValue)}>
                        {log.cutterPosition
                            ? log.cutterPosition.toUpperCase()
                            : 'ERR'}
                    </div>
                </>
            )}
        </div>
    );
}

function downloadLatestLogs(machineId, startTime, onNewLogs) {
    const params = startTime ? `start_date=${startTime}` : null;
    gApiClient
        .callApi(
            `admin/getMachineLogs/${machineId}?${params ? params : ''}`,
            'GET',
            {},
            {}
        )
        .then((response) => {
            const logs = response.data;
            // Reverse logs (show them from oldest to newest)
            onNewLogs(logs.reverse());
        });
}

/** Parameters table view */
export default function ParametersTable(props) {
    const classes = useStyles();
    const tableHeader = useRef(null);
    const [dataTableWidth, setDataTableWidth] = useState('0px');
    const [newLogsOffset, setNewLogsOffset] = useState(0);
    const [newDataArrived, setNewDataArrived] = useState(false);
    const dataTable = useRef(null);
    const scrollContainer = useRef(null);
    const [logs, setLogs] = useState(null);
    const prevLogs = usePrevious(logs);
    const machine = props.machine ? props.machine : {};

    let checkForLogsTimer = null;

    const refreshLatestLogs = () => {
        downloadLatestLogs(
            props.machineId,
            logs && logs.length > 0
                ? logs[logs.length - 1].timestamp + 1
                : null,
            (newLogs) => {
                console.log('New logs', newLogs.length, logs ? logs.length : 0);

                props.onNewLogs(newLogs);

                if (newLogs.length === 0) {
                    // No new logs - retry later
                    checkForLogsTimer = setTimeout(
                        refreshLatestLogs,
                        LOGS_REFRESH_RATE
                    );
                    return;
                }

                if (logs && logs.length > 0) {
                    // Append new logs to existing logs
                    newLogs[0].isNew = true; // So we'll mark the first log column of the new batch
                    newLogs = logs.concat(newLogs);
                }

                setLogs(newLogs);
            }
        );
    };

    useEffect(() => {
        // Periodically get new logs
        checkForLogsTimer = setTimeout(
            refreshLatestLogs,
            logs === null ? 0 : LOGS_REFRESH_RATE
        );

        return () => {
            clearTimeout(checkForLogsTimer);
        };
    }, [logs, props.machineId]);

    useEffect(() => {
        // Set floating scrollbar width, according to table contents width
        if (dataTable.current === null) return;
        setDataTableWidth(`${dataTable.current.scrollWidth - 8}px`);

        if (
            prevLogs === null &&
            logs !== null &&
            scrollContainer.current !== null
        ) {
            // First time loading logs - scroll all the way to the right
            setTimeout(() => {
                onAbsoluteRight();
            }, 100);
        }
    }, [logs, prevLogs]);

    useEffect(() => {
        if (logs && logs.length > 0) {
            setNewDataArrived(true);
            setNewLogsOffset(logs.length);
        }
    }, [logs]);

    const onScrollTable = (evt) => {
        dataTable.current.scrollTo(evt.target.scrollLeft, 0);
        tableHeader.current.scrollTo(evt.target.scrollLeft, 0);
    };

    const onAbsoluteLeft = () => {
        scrollContainer.current.scrollTo(0, 0);
    };
    const onLeft = () => {
        // Move 4 columns to the left
        const offset =
            scrollContainer.current.scrollLeft -
            CELL_WIDTH * COLUMN_MOVEMENT_COUNT;
        scrollContainer.current.scrollTo(offset - (offset % CELL_WIDTH), 0);
    };
    const onRight = () => {
        // Move 4 columns to the right
        const offset =
            scrollContainer.current.scrollLeft +
            CELL_WIDTH * COLUMN_MOVEMENT_COUNT;
        scrollContainer.current.scrollTo(offset - (offset % CELL_WIDTH), 0);
    };
    const onAbsoluteRight = () => {
        scrollContainer.current.scrollTo(
            scrollContainer.current.scrollWidth,
            0
        );
    };

    const onScrollToNewData = () => {
        setNewDataArrived(false);

        // Scroll to position of new data
        const offset = CELL_WIDTH * newLogsOffset;
        scrollContainer.current.scrollTo(offset, 0);
    };

    if (logs === null) {
        return <LoadingOverlay loading={true} />;
    }

    return (
        <div className={classes.content}>
            <div
                className={clsx(
                    classes.twoSideContainer,
                    classes.headerContainer
                )}
            >
                <div className={clsx(classes.leftColumn)}>
                    <LeftColumnCell title={'Timestamp'} />
                    <LeftColumnCell title={'Machine Timestamp'} />
                    <LeftColumnCell title={'Status'} />
                </div>
                <div ref={tableHeader} className={classes.dataContainer}>
                    {logs.map((log) => {
                        return (
                            <LogHeader
                                key={log._id}
                                status={log.recipeStatus}
                                statusDescription={recipeStatusToDescription(
                                    log,
                                    true
                                )}
                                timestamp={log.timestamp}
                                isNew={log.isNew}
                                timezoneOffset={log.timezoneOffset}
                            />
                        );
                    })}
                </div>
            </div>
            <div>
                <div
                    className={clsx(
                        classes.twoSideContainer,
                        classes.contentContainer
                    )}
                >
                    <div className={clsx(classes.leftColumn)}>
                        <LeftColumnCell
                            title={'M. Current'}
                            rightTitle={'(A)'}
                        />
                        <LeftColumnCell title={'M. Speed'} rightTitle={'(%)'} />
                        <LeftColumnCell
                            title={'M. Actual Speed'}
                            rightTitle={'(RPM)'}
                        />
                        <LeftColumnCell
                            title={'Comp. Speed'}
                            rightTitle={'(%)'}
                        />
                        <LeftColumnCell
                            title={'Sys. Current'}
                            rightTitle={'(A)'}
                        />
                        <LeftColumnCell
                            title={'Pump Current'}
                            rightTitle={'(A)'}
                        />
                        <LeftColumnCell
                            title={machine.is_p15 ? 'Slider Pos' : 'Actuator'}
                            rightTitle={'(%)'}
                        />
                        <LeftColumnCell title={'TAV'} rightTitle={'(°C)'} />
                        <LeftColumnCell title={'T1'} rightTitle={'(°C)'} />
                        <LeftColumnCell title={'T2'} rightTitle={'(°C)'} />
                        <LeftColumnCell title={'T3'} rightTitle={'(°C)'} />
                        <LeftColumnCell title={'T4'} rightTitle={'(°C)'} />
                        <LeftColumnCell title={'Front Door'} />
                        <LeftColumnCell
                            title={machine.is_p15 ? 'Panel' : 'Cover'}
                        />
                        <LeftColumnCell
                            title={machine.is_p15 ? 'Slider' : 'Spout'}
                        />
                        <LeftColumnCell title={'Drawer'} />
                        {machine.is_p15 && (
                            <LeftColumnCell title={'Cutter Location'} />
                        )}
                    </div>

                    <div ref={dataTable} className={classes.dataContainer}>
                        {logs.map((log) => {
                            return <LogColumn key={log._id} log={log} />;
                        })}
                    </div>
                    <div
                        ref={scrollContainer}
                        className={classes.dataScrollContainer}
                        onScroll={onScrollTable}
                    >
                        <div
                            style={{ width: dataTableWidth }}
                            className={classes.dataScroll}
                        ></div>
                    </div>
                </div>
            </div>
            <div className={classes.bottomBarContainer}>
                <IconButton
                    classes={{ root: classes.arrowButtonContainer }}
                    onClick={onAbsoluteLeft}
                    disableRipple
                >
                    <img
                        src={leftDoubleArrowIcon}
                        alt={'Move all the way left'}
                    />
                </IconButton>
                <IconButton
                    classes={{
                        root: clsx(
                            classes.arrowButtonContainer,
                            classes.leftArrowPadding
                        ),
                    }}
                    onClick={onLeft}
                    disableRipple
                >
                    <img src={leftArrowIcon} alt={'Move left'} />
                </IconButton>

                <IconButton
                    classes={{
                        root: clsx(
                            classes.arrowButtonContainer,
                            classes.leftArrowPaddingWide
                        ),
                    }}
                    onClick={onRight}
                    disableRipple
                >
                    <img src={rightArrowIcon} alt={'Move right'} />
                </IconButton>
                {newDataArrived ? (
                    <Button
                        className={clsx(
                            classes.leftArrowPadding,
                            classes.newDataButton
                        )}
                        label={'New Data Batch Has Arrived'}
                        rightIcon={rightDoubleArrowCircleIcon}
                        onClick={onScrollToNewData}
                    />
                ) : (
                    <IconButton
                        classes={{
                            root: clsx(
                                classes.arrowButtonContainer,
                                classes.leftArrowPadding
                            ),
                        }}
                        onClick={onAbsoluteRight}
                        disableRipple
                    >
                        <img
                            src={rightDoubleArrowIcon}
                            alt={'Move all the way right'}
                        />
                    </IconButton>
                )}
            </div>
        </div>
    );
}
