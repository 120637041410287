import { fitBounds } from 'google-map-react/utils';

/** Returns the bounds (zoom + center), such that the Google map will show all markers */
export function fitMarkersInBounds(mapMarkers, mapRef, onReady) {
    if (!window.google || !window.google.maps) {
        // Wait until GMaps SDK is loaded
        setTimeout(() => {
            fitMarkersInBounds(mapMarkers, mapRef, onReady);
        }, 200);
        return;
    }

    if (!mapMarkers || mapMarkers.length === 0) {
        // No map markers - show default world view
        onReady({
            center: { lat: 0, lng: 0 },
            zoom: 1,
            mapMarkers: mapMarkers,
        });
        return;
    } else if (mapMarkers.length === 1) {
        // Only one map marker - center on it, with a decent zoom
        onReady({
            center: {
                lat: mapMarkers[0].latitude,
                lng: mapMarkers[0].longitude,
            },
            zoom: 13,
            mapMarkers: mapMarkers,
        });
        return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    mapMarkers.forEach((marker) => {
        bounds.extend(
            new window.google.maps.LatLng(marker.latitude, marker.longitude)
        );
    });

    const newBounds = {
        ne: {
            lat: bounds.getNorthEast().lat(),
            lng: bounds.getNorthEast().lng(),
        },
        sw: {
            lat: bounds.getSouthWest().lat(),
            lng: bounds.getSouthWest().lng(),
        },
    };

    const size = {
        width: mapRef.offsetWidth,
        height: mapRef.offsetHeight,
    };

    const { center, zoom } = fitBounds(newBounds, size);

    onReady({
        center: center,
        zoom: zoom,
        mapMarkers: mapMarkers,
    });
}

/**
 * Converts degrees to radians.
 *
 * @param degrees Number of degrees.
 */
function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
}

/**
 * Returns the distance between 2 points of coordinates in Google Maps
 *
 * @see https://stackoverflow.com/a/1502821/4241030
 * @param lat1 Latitude of the point A
 * @param lng1 Longitude of the point A
 * @param lat2 Latitude of the point B
 * @param lng2 Longitude of the point B
 */
export function getDistanceBetweenPoints(lat1, lng1, lat2, lng2) {
    // The radius of the planet earth in meters
    let R = 6378137;
    let dLat = degreesToRadians(lat2 - lat1);
    let dLong = degreesToRadians(lng2 - lng1);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
            Math.cos(degreesToRadians(lat1)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    return distance;
}
