const RADIX64_TABLE =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const RADIX64_BASE = 64;

export default class Radix64 {
    // Decodes from a radix-64 encoding to an integer (e.g. Bci6vr = 1552657387)
    static decodeRadix64(input) {
        let num = 0;

        let i = 0;
        for (let c = input.length - 1; c >= 0; c -= 1) {
            num += RADIX64_TABLE.indexOf(input.charAt(c)) * RADIX64_BASE ** i;
            i += 1;
        }

        return num;
    }

    // Encodes from an integer to a radix-64 string (e.g. 1552657387 = Bci6vr)
    static encodeRadix64(inp) {
        const base = RADIX64_TABLE.length * 1.0;
        let s = '';

        if (inp == null) {
            return s;
        }

        let d = inp;
        while (d > 0) {
            const division = d / base;
            const remainder = Math.round(
                (division - Math.round(division)) * base
            );
            d = Math.round(division);
            s = RADIX64_TABLE.charAt(remainder) + s;
        }

        return s;
    }
}
