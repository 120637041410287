import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    setSideMenuState,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_STATS,
    TOGGLE_BAR_STATE_DASHBOARD,
} from '../../store/actions/ui';
import StatsToggleBar from './StatsToggleBar';
import FilterClients from './FilterClients';
import FilterFlavours from './FilterFlavours';
import FiltersApplied from './FiltersApplied';
import BottomDateFilter from './BottomDateFilter';
import FilterDashboard from './FilterDashboard';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 0,
        flexGrow: 1,
    },
    filterSubWindow: {
        width: '414px',
        maxWidth: '414px',
        marginRight: '31px',
    },
});

/** Sales stats - dashboard view (where you filter by client/flavours) */
export default function StatsDashboard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToggleBarComponent(StatsToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_DASHBOARD));
        dispatch(setSideMenuState(SIDE_MENU_STATE_STATS));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <FilterClients
                className={classes.filterSubWindow}
                items={props.items}
                loading={props.loading}
            />
            <FilterFlavours
                className={classes.filterSubWindow}
                loading={props.loading}
            />
            <FiltersApplied className={classes.filterSubWindow} />
            <FilterDashboard className={classes.filterSubWindow} />
            <BottomDateFilter />
        </div>
    );
}
