import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    setSideMenuState,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_STATS,
    TOGGLE_BAR_STATE_RESULTS,
} from '../../store/actions/ui';
import StatsToggleBar from './StatsToggleBar';
import FiltersApplied from './FiltersApplied';
import BottomDateFilter from './BottomDateFilter';
import ResultsWindow from './ResultsWindow';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 0,
        flexGrow: 1,
    },
    filterSubWindow: {
        width: '414px',
        maxWidth: '414px',
        marginRight: '31px',
    },
    resultsSubWindow: {
        flexGrow: 1,
        marginLeft: '31px',
        width: 0,
    },
});

/** Sales stats - results view (two inner windows - filters applied + graph/table view) */
export default function StatsResults() {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToggleBarComponent(StatsToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_RESULTS));
        dispatch(setSideMenuState(SIDE_MENU_STATE_STATS));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <FiltersApplied className={classes.filterSubWindow} />
            <ResultsWindow className={classes.resultsSubWindow} />
            <BottomDateFilter />
        </div>
    );
}
