import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Authentication from '../modules/Authentication';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import { APP_BAR_HIDDEN, setAppBarVisibility } from '../store/actions/ui';
import bigCheckmarkIcon from '../res/images/big_check_mark.svg';
import clsx from 'clsx';
import queryString from 'query-string';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '100%',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        marginTop: '25px',
        borderRadius: '32px',
        height: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '20px',
        },
    },
    error: {
        color: '#ff0000',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        marginTop: '30px',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundImage: {},
    backToSignIn: {
        color: '#000000',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '20px',
    },
    link: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#000000',
            textDecoration: 'none',
        },
    },
    innerContainer: {
        width: '714px',
        height: '450px',
        backgroundColor: '#e8e8e8',
        position: 'relative',
        boxShadow: '0 9px 40px 20px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
        color: '#00948d',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerTitle: {
        fontSize: '34px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    innerSubTitle: {
        fontSize: '20px',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    successImage: {
        position: 'absolute',
        width: '470px',
        left: 'calc(50% - 235px)',
        top: 'calc(50% - 192px)',
    },
    successContainer: {
        justifyContent: 'center',
    },
});

/** The confirm email screen (the link the user gets when first registering) */
export default function ConfirmEmail() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState({});
    const location = useLocation();
    const { code, username: email } = queryString.parse(location.search);

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    const confirmEmail = () => {
        setError({});

        // Send reset password link to the specified email address
        // Confirm the user
        gApiClient
            .callApiUnauthenticated(
                'confirmEmail',
                'POST',
                {},
                { email: email, code: code }
            )
            .then((response) => {
                const successful = response.data.success;
                const invalidEmail = response.data.invalid_email;
                const maxRetries = response.data.max_retries;
                const emailAlreadyVerified =
                    response.data.email_verified_already;

                if (!successful) {
                    let error;

                    if (emailAlreadyVerified) {
                        error = 'Already confirmed your email address';
                    } else if (maxRetries) {
                        error =
                            "You've tried entering an invalid confirmation code too many times - please resend a new confirmation code";
                    } else if (invalidEmail) {
                        error = `Email address not found - did you register with this email address? ${email}`;
                    } else {
                        error = 'Invalid confirmation code';
                    }

                    setError({ general: error });
                } else {
                    // Show success message, then sign in after a few seconds
                    setSuccess(true);

                    setTimeout(() => {
                        Authentication.getLoginStatus((status) => {
                            console.log('Login status', status);

                            if (
                                status === Authentication.LOGIN_STATUS_LOGGED_IN
                            ) {
                                // Move to main, logged in page
                                history.replace('/');
                            } else {
                                // Move to login page
                                history.replace('/login');
                            }
                        });
                    }, 3000);
                }
            })
            .catch((result) => {
                console.log('error', result);
                setError({ general: result.message });
            });
    };

    useEffect(() => {
        confirmEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img
                    src={bgTop}
                    className={classes.backgroundImage}
                    alt="Background"
                />
                <img
                    src={bgBottom}
                    className={classes.backgroundImage}
                    alt="Background"
                />
            </div>

            {success && (
                <div
                    className={clsx(
                        classes.innerContainer,
                        classes.successContainer
                    )}
                >
                    <img
                        className={classes.successImage}
                        src={bigCheckmarkIcon}
                        alt="Succcess"
                    />
                    <div className={classes.innerTitle}>Success!</div>
                    <div className={classes.innerSubTitle}>
                        Confirmed your email address! Signing in now...
                    </div>
                </div>
            )}

            {!success && (
                <div className={classes.innerContainer}>
                    <div className={classes.innerTitle}>Confirming Email</div>
                    <div className={classes.innerSubTitle}>
                        {!error.general
                            ? "Please wait while we're confirming your email address..."
                            : null}
                    </div>

                    <div className={classes.error}>{error.general}</div>
                </div>
            )}
        </div>
    );
}
