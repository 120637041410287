import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ToggleButton from '../../components/ToggleButton';
import {
    datesToSelectionType,
    SELECTION_TYPE_DAY,
    SELECTION_TYPE_MONTH,
    SELECTION_TYPE_WEEK,
} from './BottomDateFilter';
import Authentication from '../../modules/Authentication';
import DateTime from 'luxon/src/datetime';
import ResultsTable from './ResultsTable';
import { useFilterChanged } from '../../store/reducers/stats';
import switchIcon from '../../res/images/switch_circle.svg';
import ResultsGraph from './ResultsGraph';
import {
    findItem,
    formatClientTitle,
    ID_ALL_CLIENTS,
    ID_ALL_FLAVOURS,
} from '../../modules/MachineUtils';
import { useInterval } from '../../modules/HookUtils';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        flexGrow: 1,
        '& .MuiCardContent-root': {
            overflowY: 'hidden',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        backgroundColor: '#f0f3f9',
    },
    results: {
        flexGrow: 1,
    },
    separator: {
        backgroundColor: '#7d90aa',
        opacity: 0.2,
        height: '1px',
        width: '100%',
        marginBottom: '10px',
    },
    resultTypesButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingBottom: '10px',
    },
    resultsTypeButton: {
        marginRight: '10px',
        width: '110px',
        borderColor: '#7d90aa',
        backgroundColor: '#ffffff',
        color: '#7d90aa',
        opacity: 0.45,
        '&.Mui-selected': {
            borderColor: '#7d90aa',
            backgroundColor: '#ffffff',
            color: '#7d90aa',
            opacity: 1,
        },
    },
});

/** Formats the window title according to input filter type */
function formatTitle(selectionType, resultsType, selectedTime) {
    let title;
    if (selectionType === SELECTION_TYPE_DAY) {
        title = 'Daily View';
    } else if (selectionType === SELECTION_TYPE_WEEK) {
        if (resultsType === SELECTION_TYPE_DAY) {
            title = 'Avg. Day';
        } else {
            title = 'Week View';
        }
    } else if (selectionType === SELECTION_TYPE_MONTH) {
        if (resultsType === SELECTION_TYPE_DAY) {
            title = 'Avg. Day';
        } else if (resultsType === SELECTION_TYPE_WEEK) {
            title = 'Avg. Week';
        } else {
            title = 'Month View';
        }
    }

    if (selectedTime) {
        title += ` (${selectedTime})`;
    }

    return title;
}

/** Goes up the tree from a cluster (=>site/client), and adds one capsule consumption to each (in the specified fieldName) */
function markCapsuleConsumption(stats, selectedItems, item, fieldName) {
    let itemId = item._id;

    if (selectedItems && selectedItems[0] === ID_ALL_CLIENTS) {
        // "All Items" entity
        itemId = ID_ALL_CLIENTS;
    }

    if (selectedItems.indexOf(itemId) > -1) {
        // Only calculate stats if current item was selected for filter by the user
        if (!stats[itemId]) {
            stats[itemId] = {};
        }
        if (!stats[itemId][fieldName]) {
            stats[itemId][fieldName] = 0;
        }

        stats[itemId][fieldName] += 1;
    }

    if (item.parent && itemId !== ID_ALL_CLIENTS) {
        markCapsuleConsumption(stats, selectedItems, item.parent, fieldName);
    }
}

const VIEW_TYPE_TABLE = 'table';
const VIEW_TYPE_GRAPH = 'graph';

/** Calculates capsule consumption stats according to selection and results type - used by both the table and graph views */
function calculateStats(
    selectionType,
    resultsType,
    viewType,
    capsules,
    filter
) {
    const clusterIdToCluster = {}; // Matches between a cluster ID and the actual cluster object in the tree
    const selectedClients = filter.clients.map((c) => c._id);

    let formatKey;
    let columns;

    if (resultsType === SELECTION_TYPE_DAY) {
        formatKey = 'HH:00'; // Hour of day
        // All hours of the day
        columns = Array.from(
            { length: 24 },
            (v, i) => `${i.toString().padStart(2, '0')}:00`
        );
    } else if (resultsType === SELECTION_TYPE_WEEK) {
        formatKey = 'EEEE'; // Day of week
        // All days of the week
        columns = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
    } else if (resultsType === SELECTION_TYPE_MONTH) {
        formatKey = 'dd'; // Day of the month
        // All days of the month
        columns = Array.from(
            { length: 31 },
            (v, i) => `${(i + 1).toString().padStart(2, '0')}`
        );
    }

    // Show capsule consumption by hour/day-of-week/day-of-month (for the selected date range)
    const capsulesByCluster = {};
    capsules.map((c) => {
        // Fix consumption time according to timezone offset
        const consumptionDate = DateTime.fromMillis(c.consumption_date)
            .toUTC()
            .plus({
                milliseconds: c.timezone_offset || 0,
            });

        const key = consumptionDate.toFormat(formatKey);
        const clusterId = c.consuming_cluster_id;

        if (!clusterIdToCluster[clusterId]) {
            clusterIdToCluster[clusterId] = findItem(
                filter.allClients,
                clusterId
            );
        }

        const cluster = clusterIdToCluster[clusterId];
        markCapsuleConsumption(
            capsulesByCluster,
            selectedClients,
            cluster,
            key
        );

        return null;
    });

    // Add any client/sites/clusters that were selected, but did not hold any data
    filter.clients.map((c) => {
        if (!capsulesByCluster[c._id]) {
            capsulesByCluster[c._id] = {};
        }
        return null;
    });

    return {
        data: capsulesByCluster,
        // Columns are the hours of the day, days of the month, etc.
        columns: columns,
    };
}

/** The results inner window (container of the graph/table view, and the daily/weekly/monthly view selector) */
export default function ResultsWindow(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.stats.filter);
    const [resultsType, setResultsType] = useState(SELECTION_TYPE_DAY);
    const [capsules, setCapsules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState(VIEW_TYPE_TABLE);
    const [stats, setStats] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [clients, setClients] = useState([]);

    const selectionType = datesToSelectionType(
        filter.startDate,
        filter.endDate
    );

    const getAPIParams = () => {
        let flavours = [];

        if (filter.flavours.length > 0) {
            if (filter.flavours[0]._id === ID_ALL_FLAVOURS) {
                if (filter.flavours[0].visible !== false) {
                    flavours = filter.allFlavours.slice(1);
                }
            } else {
                flavours = filter.flavours;
            }

            flavours = flavours.filter((f) => f.visible !== false);
        }

        let params = {
            start_date: filter.startDate.toFormat('yyyy-MM-dd'),
            end_date: filter.endDate.toFormat('yyyy-MM-dd'),
            flavours: flavours.map((f) => f._id),
            clusters: filter.clusterIds,
        };

        return params;
    };

    useFilterChanged(filter, () => {
        const params = getAPIParams();
        setLoading(true);

        gApiClient
            .callApi('admin/getCapsuleConsumptions', 'POST', {}, params)
            .then((result) => {
                setCapsules(result.data);
                setLoading(false);
            });
    });

    useInterval(async () => {
        console.log('Refreshing stats');
        const params = getAPIParams();

        gApiClient
            .callApi('admin/getCapsuleConsumptions', 'POST', {}, params)
            .then((result) => {
                if (result.data.length !== capsules.length) {
                    console.log(
                        'Setting stats',
                        result.data.length,
                        capsules.length
                    );
                    setCapsules(result.data);
                }
            });
    }, 10000);

    useEffect(() => {
        setLoading(true);
        const newStats = calculateStats(
            selectionType,
            resultsType,
            viewType,
            capsules,
            filter
        );

        // Convert from client IDs to clients, showing only clients that are marked as visible
        const newClients = Object.keys(newStats.data)
            .map((id) => filter.clients.find((c) => c._id === id))
            .filter((x) => x && x.visible);

        // Sort by display name
        newClients.sort((a, b) =>
            formatClientTitle(a).localeCompare(formatClientTitle(b))
        );

        setClients(newClients);
        setStats(newStats);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionType, resultsType, viewType, capsules, filter.clients]);

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={formatTitle(selectionType, resultsType, selectedTime)}
            loading={loading}
            icon={switchIcon}
            onIconClick={() => {
                setLoading(true);
                setViewType(
                    viewType === VIEW_TYPE_TABLE
                        ? VIEW_TYPE_GRAPH
                        : VIEW_TYPE_TABLE
                );
            }}
        >
            <div className={classes.content}>
                {viewType === VIEW_TYPE_TABLE && !loading && (
                    <ResultsTable
                        className={classes.results}
                        stats={stats}
                        clients={clients}
                    />
                )}
                {viewType === VIEW_TYPE_GRAPH && !loading && (
                    <ResultsGraph
                        className={classes.results}
                        stats={stats}
                        clients={clients}
                        onSelectedTime={(time) => setSelectedTime(time)}
                    />
                )}

                <div className={classes.separator} />
                <div className={classes.resultTypesButtonContainer}>
                    <ToggleButton
                        className={classes.resultsTypeButton}
                        onChange={() => setResultsType(SELECTION_TYPE_DAY)}
                        selected={resultsType === SELECTION_TYPE_DAY}
                    >
                        {formatTitle(selectionType, SELECTION_TYPE_DAY)}
                    </ToggleButton>

                    {selectionType !== SELECTION_TYPE_DAY && (
                        <ToggleButton
                            className={classes.resultsTypeButton}
                            onChange={() => setResultsType(SELECTION_TYPE_WEEK)}
                            selected={resultsType === SELECTION_TYPE_WEEK}
                        >
                            {formatTitle(selectionType, SELECTION_TYPE_WEEK)}
                        </ToggleButton>
                    )}

                    {selectionType !== SELECTION_TYPE_DAY &&
                        selectionType !== SELECTION_TYPE_WEEK && (
                            <ToggleButton
                                className={classes.resultsTypeButton}
                                onChange={() =>
                                    setResultsType(SELECTION_TYPE_MONTH)
                                }
                                selected={resultsType === SELECTION_TYPE_MONTH}
                            >
                                {formatTitle(
                                    selectionType,
                                    SELECTION_TYPE_MONTH
                                )}
                            </ToggleButton>
                        )}
                </div>
            </div>
        </InnerWindow>
    );
}
