import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import InnerWindow from '../../components/InnerWindow';
import closeIcon from '../../res/images/close_circle.svg';
import { useFilterChanged } from '../../store/reducers/overview';
import { KPI_TYPES } from './KPITypes';
import ToggleButton from '../../components/ToggleButton';
import Authentication from '../../modules/Authentication';
import { DateTime, Interval } from 'luxon';
import KPIGraph from './KPIGraph';
import Button from '../../components/Button';
import dateCircleIcon from '../../res/images/date_circle.svg';
import { IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { sameDay } from '../../modules/DateUtils';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles((theme) => ({
    window: {
        background: '#f0f3f9',
        flexGrow: 1,
        '& .MuiCardContent-root': {
            overflowY: 'hidden',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f0f3f9',
        position: 'relative',
        height: 0,
        flexGrow: 1,
    },
    results: {
        flexGrow: 1,
    },
    separator: {
        backgroundColor: '#7d90aa',
        opacity: 0.2,
        height: '1px',
        width: '100%',
        marginBottom: '10px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '10px',
        paddingLeft: '10px',
    },
    buttonContainerLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
    },
    buttonContainerRight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    selectorButton: {
        marginRight: '10px',
        width: '180px',
        borderColor: '#7d90aa',
        backgroundColor: '#ffffff',
        color: '#7d90aa',
        opacity: 0.45,
        '&.Mui-selected': {
            borderColor: '#7d90aa',
            backgroundColor: '#ffffff',
            color: '#7d90aa',
            opacity: 1,
        },
    },
    selectorButtonZoom: {
        width: '140px',
    },
    openDateButton: {
        width: '168px',
        color: '#7d91aa !important',
        backgroundColor: '#eff2f5',
        borderColor: '#7d91aa',
        marginLeft: '20px',
        '& div': {
            color: '#7d91aa !important',
        },
        position: 'absolute',
    },
    openFilterContent: {
        backgroundColor: '#8090a8',
        width: '100%',
        borderBottomLeftRadius: '33px',
        borderBottomRightRadius: '33px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '34px',
        position: 'absolute',
        top: 0,
        zIndex: 10,
    },
    openFilterInnerContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    openFilterTopRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '90px',
    },
    closeButtonImage: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    openFilterMainPart: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f1f3f6',
    },
    selectorsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f1f3f6',
        width: '367px',
    },
    dateSelector: {
        width: '250px',
        height: '48px',
        borderRadius: '24px',
        boxShadow: '0 2px 4px 0 rgba(125, 144, 170, 0.75)',
        border: 'solid 1px #7d90aa',
        color: '#7d90aa',
        marginBottom: '20px',

        '&.Mui-selected': {
            backgroundColor: '#7d90aa',
            color: '#ffffff',
        },
        '&:last-child': {
            marginBottom: '0px',
        },
    },
    dayContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    todayIndicator: {
        width: '6px',
        height: '6px',
        backgroundColor: '#49b271',
        borderRadius: '6px',
        marginTop: '2px',
    },
    highlightDay: {
        background: '#e9ecf0',
        color: '#7d90aa',
    },
    firstHighlight: {
        extend: 'highlight',
        background: '#ccd2dd',
        borderTopLeftRadius: '30%',
        borderBottomLeftRadius: '30%',
    },
    endHighlight: {
        extend: 'highlight',
        background: '#ccd2dd',
        borderTopRightRadius: '30%',
        borderBottomRightRadius: '30%',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    filterLabel: {
        flexGrow: 1,
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        textAlign: 'center',
    },
    filterLeftPart: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '68px',
        paddingLeft: '68px',
        textTransform: 'uppercase',
        color: '#7d90aa',
    },
    filterDate: {
        backgroundColor: '#ffffff',
        fontSize: '20px',
        fontWeight: 'bold',
        minWidth: '249px',
        textAlign: 'center',
    },
    filterDateLabel: {
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '5px',
    },
}));

// Possible zoom types (getPeriod is a function that returns the length of the zoom period - used
// in Luxon's plus/minus methods)
const ZOOM_TYPES = [
    { name: '1 Week', getPeriod: () => ({ weeks: 1 }) },
    { name: '1 Month', getPeriod: () => ({ months: 1 }) },
    { name: '6 Months', getPeriod: () => ({ months: 6 }) },
    { name: '1 Year', getPeriod: () => ({ months: 12 }) },
];

/** Prepares stats for the graph, based on the input data (list of KPIs by machine)
 * and selected KPI */
function getStats(data, selectedKPI, startDate, zoomType) {
    // Columns = all days in the input date range
    const endDate = startDate.plus(zoomType.getPeriod());
    const columns = Interval.fromDateTimes(startDate, endDate)
        .splitBy({ days: 1 })
        .map((d) => d.start.toFormat('dd-MM-yyyy'));

    if (!data) {
        return {
            data: {},
            columns: columns,
        };
    }

    // First, filter by selected KPI only
    const newData = {};

    Object.keys(data).forEach((machineId) => {
        newData[machineId] = data[machineId].filter(
            (kpi) => selectedKPI.key in kpi
        );
    });

    // Next, prepare data to be suitable for the graph itself
    const kpiByMachine = {};

    // First pass - save KPI values for each day
    Object.keys(newData).forEach((machineId) => {
        kpiByMachine[machineId] = {};
        newData[machineId].forEach((kpi) => {
            const date = DateTime.fromMillis(kpi.timestamp).toFormat(
                'dd-MM-yyyy'
            );
            if (!kpiByMachine[machineId][date]) {
                kpiByMachine[machineId][date] = [];
            }
            kpiByMachine[machineId][date].push(kpi[selectedKPI.key]);
        });
    });

    // Second pass - show average/max/etc. value of each day (since we don't
    // show individual entries within a single day)
    Object.keys(kpiByMachine).forEach((machineId) => {
        const kpis = kpiByMachine[machineId];
        Object.keys(kpis).forEach((date) => {
            kpis[date] = selectedKPI.getDailyValue(kpis[date]);
        });
    });

    return {
        data: kpiByMachine,
        columns: columns,
    };
}

/** The top date filter */
function DateFilter(props) {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(props.startDate);

    const setValue = (v) => {
        let newStartDate;
        newStartDate = v.startOf('day');
        setStartDate(newStartDate);
    };

    const onClose = () => {
        // Set filter only on close
        props.onDateSet(startDate);
    };

    const today = DateTime.local();

    return (
        <div className={classes.openFilterContent}>
            <div className={classes.openFilterInnerContent}>
                <div className={classes.openFilterTopRow}>
                    <div className={classes.filterLabel}>Choose a Date</div>
                    <IconButton onClick={onClose}>
                        <img
                            className={classes.closeButtonImage}
                            src={closeIcon}
                            alt={'Close'}
                        />
                    </IconButton>
                </div>
                <div className={classes.openFilterMainPart}>
                    <div className={classes.filterLeftPart}>
                        <div className={classes.filterDate}>
                            {startDate.toFormat('dd-MM-yyyy')}
                        </div>
                        <div className={classes.filterDateLabel}>
                            DD &nbsp;&nbsp; MM &nbsp;&nbsp;&nbsp;&nbsp; YEAR
                        </div>
                    </div>
                    <DatePicker
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={startDate}
                        onChange={setValue}
                        className={classes.picker}
                        disableToolbar={true}
                        renderDay={(day, selectedDate, isInCurrentMonth) => {
                            const dayIsBetween =
                                day >= startDate &&
                                day <= startDate.endOf('day');
                            const isFirstDay = sameDay(day, startDate);
                            const isLastDay = isFirstDay;

                            const dayClassName = clsx(classes.day, {
                                [classes.nonCurrentMonthDay]: !isInCurrentMonth,
                                [classes.highlightNonCurrentMonthDay]:
                                    !isInCurrentMonth && dayIsBetween,
                            });
                            const wrapperClassName = clsx({
                                [classes.highlightDay]: dayIsBetween,
                                [classes.firstHighlight]: isFirstDay,
                                [classes.endHighlight]: isLastDay,
                            });

                            return (
                                <div className={wrapperClassName}>
                                    <IconButton
                                        className={dayClassName}
                                        classes={{
                                            label: classes.dayContainer,
                                        }}
                                    >
                                        <span> {day.toFormat('d')} </span>
                                        {sameDay(day, today) && (
                                            <div
                                                className={
                                                    classes.todayIndicator
                                                }
                                            ></div>
                                        )}
                                    </IconButton>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

/** KPIs Inner window - showing the top machine selection/value bar, the graph and the lower filter bar */
export default function KPIWindow(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);
    const [selectedZoomType, setSelectedZoomType] = useState(ZOOM_TYPES[0]);
    const [startDate, setStartDate] = useState(
        DateTime.local()
            .startOf('day')
            .minus(selectedZoomType.getPeriod())
    );
    const [selectedKPI, setSelectedKPI] = useState(KPI_TYPES[0]);
    const [loading, setLoading] = useState(true);
    const loadingRef = useRef(loading);
    loadingRef.current = loading;
    const [kpisByMachine, setKpisByMachine] = useState();
    const [stats, setStats] = useState(null);
    const [dateOpen, setDateOpen] = useState(false);

    const refreshStats = (showLoading) => {
        if (filter.selectedMachines.length === 0) {
            // No machines
            setLoading(false);

            setKpisByMachine({});

            // Calculate stats
            setStats(getStats({}, selectedKPI, startDate, selectedZoomType));
            return;
        }

        if (showLoading) setLoading(true);

        let params =
            `start_date=${startDate.toMillis()}&` +
            `end_date=${startDate
                .plus(selectedZoomType.getPeriod())
                .endOf('day')
                .toMillis()}&` +
            `machines=${filter.selectedMachines.map((m) => m._id)}`;

        gApiClient
            .callApi(`admin/getKPIs?${params}`, 'GET', {}, {})
            .then((result) => {
                if (showLoading) setLoading(false);
                setKpisByMachine(result.data);

                // Calculate stats
                const newStats = getStats(
                    result.data,
                    selectedKPI,
                    startDate,
                    selectedZoomType
                );

                if (JSON.stringify(newStats) !== JSON.stringify(stats)) {
                    setStats(newStats);
                }
            });
    };

    useFilterChanged(filter, (softRefresh) => {
        // Selected machines changed - refresh stats
        refreshStats(!softRefresh);
    });

    useEffect(() => {
        // Start date or zoom type changed - refresh stats
        refreshStats(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, selectedZoomType]);

    useEffect(() => {
        // Selected KPI changed - refresh stats (no need to re-call API)

        // Calculate stats
        setStats(
            getStats(kpisByMachine, selectedKPI, startDate, selectedZoomType)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKPI]);

    const moveGraph = (direction) => {
        // Move graph back/forward in time
        const period = selectedZoomType.getPeriod();
        // Move a third of the zoom period forward/backward
        const newPeriod = {};
        Object.keys(period).forEach((k) => {
            newPeriod[k] = (period[k] / 3) * direction;
        });

        setStartDate(startDate.plus(newPeriod));
    };

    const DateButton = () => {
        return (
            <Button
                className={classes.openDateButton}
                label={startDate.toFormat('dd MMM yyyy')}
                rightIcon={dateCircleIcon}
                small
                onClick={() => {
                    if (loadingRef.current || props.loading) return;

                    setDateOpen(!dateOpen);
                }}
            />
        );
    };

    const zoomTypeChanged = (zoomType) => {
        DateTime.local()
            .startOf('day')
            .minus(selectedZoomType.getPeriod());

        if (startDate.plus(zoomType.getPeriod()) > DateTime.local()) {
            // Move the beginning of the time window to accommodate for the new zoom type
            setStartDate(
                DateTime.local()
                    .startOf('day')
                    .minus(zoomType.getPeriod())
            );
        }

        setSelectedZoomType(zoomType);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={selectedKPI.title}
            loading={loading || props.loading}
            leftView={<DateButton />}
        >
            <div className={classes.content}>
                {dateOpen && (
                    <DateFilter
                        startDate={startDate}
                        onDateSet={(d) => {
                            setStartDate(d);
                            setDateOpen(false);
                        }}
                    />
                )}
                <KPIGraph
                    className={classes.results}
                    kpi={selectedKPI}
                    stats={stats}
                    onGraphMoved={moveGraph}
                />

                <div className={classes.separator} />

                <div className={classes.buttonContainer}>
                    <div className={classes.buttonContainerLeft}>
                        {KPI_TYPES.map((kpi) => (
                            <ToggleButton
                                key={kpi.key}
                                className={classes.selectorButton}
                                onChange={() => setSelectedKPI(kpi)}
                                selected={selectedKPI.key === kpi.key}
                            >
                                {kpi.title}
                            </ToggleButton>
                        ))}
                    </div>
                    <div className={classes.buttonContainerRight}>
                        {ZOOM_TYPES.map((zoomType) => (
                            <ToggleButton
                                key={zoomType.name}
                                className={clsx(
                                    classes.selectorButton,
                                    classes.selectorButtonZoom
                                )}
                                onChange={() => {
                                    zoomTypeChanged(zoomType);
                                }}
                                selected={
                                    selectedZoomType.name === zoomType.name
                                }
                            >
                                {zoomType.name}
                            </ToggleButton>
                        ))}
                    </div>
                </div>
            </div>
        </InnerWindow>
    );
}
