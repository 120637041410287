import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, Paper, IconButton } from '@material-ui/core';
import searchIcon from '../res/images/magnifying_glass.svg';
import clearSearchIcon from '../res/images/close.svg';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import clsx from 'clsx';

const useStyles = makeStyles({
    paperRoot: {
        background: '#007164',
        boxShadow: 'none',
        borderRadius: '32px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
    },
    inputRoot: {
        height: '38px',
        flex: 1,
    },
    input: {
        height: '100%',
        background: 'rgba(255, 255, 255, 0)',
        fontSize: '14px',
        color: '#f2f5f7',
        padding: '0px 8px 0px 20px',
    },
    iconButton: {
        padding: '6px',
        marginRight: '14px',
    },
    searchIcon: {
        filter: hexColorToCSSFilter('#90D3CB'),
    },
});

/** Our own type of styled search text field */
export default function SearchField(props) {
    const classes = useStyles();
    const [searchText, setSearchText] = useState(props.defaultValue || '');
    const [triggerTimeout, setTriggerTimeout] = useState(null);

    useEffect(() => {
        setSearchText(props.value);
    }, [props.value]);

    const onChange = (event) => {
        const value = event.target.value;
        setSearchText(value);

        // Make sure we don't trigger the onSearch event too frequently (need to make sure user finished typing) -
        // As some event callbacks can be costly - CPU/network wise.

        if (triggerTimeout) clearTimeout(triggerTimeout);

        const timeoutHandle = setTimeout(() => {
            if (props.onSearch) props.onSearch(value);
        }, props.searchTriggerTimeout || 500);

        setTriggerTimeout(timeoutHandle);
    };

    const onRightIconClick = () => {
        if (searchText.length > 0) {
            // Clear search
            setSearchText('');
            if (props.onSearch) props.onSearch('');
        } else {
            // Trigger search
            if (props.onSearch) props.onSearch(searchText);
        }
    };

    return (
        <Paper
            classes={{ root: clsx(classes.paperRoot, props.backgroundClass) }}
            className={props.className}
        >
            <InputBase
                classes={{
                    root: classes.inputRoot,
                    input: clsx(classes.input, props.inputClass),
                }}
                variant="filled"
                type={props.type ? props.type : 'text'}
                readOnly={props.readOnly ? true : false}
                onChange={onChange}
                value={searchText}
                placeholder={props.placeholder}
            />
            <IconButton
                color="primary"
                className={classes.iconButton}
                onClick={onRightIconClick}
            >
                <img
                    src={
                        searchText && searchText.length > 0
                            ? clearSearchIcon
                            : searchIcon
                    }
                    alt={
                        searchText && searchText.length > 0 ? 'Clear' : 'Search'
                    }
                    className={clsx(classes.searchIcon, props.iconClass)}
                />
            </IconButton>
        </Paper>
    );
}
