import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, Paper, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
    paperRoot: {
        background: '#d8d9e6',
        boxShadow: 'none',
        borderRadius: '0px',
        height: '38px',
        minHeight: '38px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    inputRoot: {
        flexGrow: 1,
        alignSelf: 'stretch',
    },
    input: {
        height: '100% !important',
        fontSize: '12px',
        color: '#586374',
        padding: '0px 8px 0px 8px',
    },
    multilineInput: {
        padding: '8px',
        whiteSpace: 'pre',
        minHeight: '33px',
    },
    inputCenter: {
        textAlign: 'center',
    },
    inputRight: {
        textAlign: 'right',
    },
    iconButton: {
        padding: '0px',
    },
    iconButtonImage: {
        width: '24px',
        height: '24px',
    },
    leftLabelContainer: {
        paddingRight: '5px',
        paddingLeft: '6px',
        backgroundColor: '#dee1eb',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    leftLabel: {
        color: '#7d90aa',
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    leftToRightContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
        alignContent: 'stretch',
    },
    topLabel: {
        textAlign: 'left',
        width: '100%',
        paddingLeft: '6px',
        paddingTop: '10px',
        paddingBottom: '10px',
        textTransform: 'uppercase',
        color: '#7d90aa',
        backgroundColor: '#dee1eb',
        fontSize: '12px',
    },
    bottomLabel: {
        textAlign: 'center',
        width: '100%',
        height: '18px',
        paddingTop: '4px',
        paddingBottom: '4px',
        textTransform: 'uppercase',
        color: '#7d90aa',
        backgroundColor: '#dee1eb',
        fontSize: '7px',
    },
});

/** Our own type of styled text field, with optional left label and clickable right icon */
export default function TextField(props) {
    const classes = useStyles();

    const onChange = (event) => {
        if (props.onChange) props.onChange(event.target.value);
    };

    let inputAlignClass = null;
    if (props.align === 'center') {
        inputAlignClass = classes.inputCenter;
    } else if (props.align === 'right') {
        inputAlignClass = classes.inputRight;
    }

    return (
        <Paper
            classes={{ root: classes.paperRoot }}
            className={props.className}
            style={props.style}
        >
            {props.topLabel && (
                <div className={classes.topLabel}>{props.topLabel}</div>
            )}
            <div className={classes.leftToRightContainer}>
                {props.leftLabel && (
                    <div
                        className={classes.leftLabelContainer}
                        style={{ width: props.leftLabelWidth }}
                    >
                        <span className={classes.leftLabel}>
                            {props.leftLabel}
                        </span>
                    </div>
                )}
                {props.readOnly && props.multiline && (
                    <div
                        className={clsx(
                            classes.input,
                            classes.multilineInput,
                            inputAlignClass
                        )}
                    >
                        {props.defaultValue || props.value}
                    </div>
                )}
                {(!props.readOnly || !props.multiline) && (
                    <InputBase
                        classes={{
                            root: classes.inputRoot,
                            input: clsx(
                                classes.input,
                                inputAlignClass,
                                props.inputClass
                            ),
                        }}
                        variant="filled"
                        type={props.type ? props.type : 'text'}
                        readOnly={props.readOnly ? true : false}
                        placeholder={props.placeholder}
                        onChange={onChange}
                        onKeyUp={props.onKeyUp}
                        onKeyDown={props.onKeyDown}
                        onKeyPress={props.onKeyPress}
                        defaultValue={props.defaultValue}
                        value={props.value}
                        multiline={props.multiline}
                        rows={props.rows}
                        rowsMax={props.rowsMax}
                        rowsMin={props.rowsMin}
                    />
                )}
                {props.rightIcon && (
                    <IconButton
                        color="primary"
                        className={classes.iconButton}
                        onClick={
                            props.onRightIconClick
                                ? props.onRightIconClick
                                : null
                        }
                        disabled={props.onRightIconClick ? false : true}
                    >
                        <img
                            src={props.rightIcon}
                            className={classes.iconButtonImage}
                            alt="icon"
                        />
                    </IconButton>
                )}
            </div>
            {props.bottomLabel && (
                <div className={classes.bottomLabel}>{props.bottomLabel}</div>
            )}
        </Paper>
    );
}
