import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Authentication from '../../../modules/Authentication';
import MachineInfo from '../SingleMachineDashboard/MachineInfo';
import {
    setSingleMachine,
    setToggleBarComponent,
    setToggleBarState,
    TOGGLE_BAR_STATE_ACTION,
} from '../../../store/actions/ui';
import MachineRemoteActions from './MachineRemoteActions';
import MachinesToggleBar from '../MachinesToggleBar';
import MachineForms from './MachineForms';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 0,
        flexGrow: 1,
    },
    machineInfo: {
        width: '414px',
    },
    remoteActions: {
        width: '414px',
        marginLeft: '31px',
    },
    forms: {
        flexGrow: 1,
        marginLeft: '31px',
    },
});

const gApiClient = Authentication.getAPIClient();

/** Single machine view - action (machine info, remote actions, forms) */
export default function SingleMachineDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const machine = useSelector((state) => state.ui.singleMachine);
    const latestMachineId = useRef(machine._id);
    let { machineId } = useParams();

    useEffect(() => {
        latestMachineId.current = machineId;
    }, [machineId]);

    useEffect(() => {
        dispatch(setToggleBarComponent(MachinesToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_ACTION));

        refreshMachine();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onExit = () => {
        // Exit back to machine search screen
        history.push('/machines');
    };

    const refreshMachine = () => {
        gApiClient
            .callApi(`admin/getMachineInfo/${machineId}`, 'GET', {}, {})
            .then((response) => {
                const machineInfo = response.data;
                console.log(
                    'refresh machine - single machine action',
                    machineInfo._id,
                    latestMachineId.current
                );
                if (machineInfo._id !== latestMachineId.current) return;

                dispatch(setSingleMachine(machineInfo));
            });
    };

    return (
        <div className={classes.container}>
            <MachineInfo
                machine={machine}
                onClose={onExit}
                className={classes.machineInfo}
            />
            <MachineRemoteActions
                machine={machine}
                className={classes.remoteActions}
            />
            <MachineForms
                machine={machine}
                onLocationChanged={refreshMachine}
                className={classes.forms}
            />
        </div>
    );
}
