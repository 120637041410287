import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import visibilityIcon from '../../res/images/eye.svg';
import noVisibilityIcon from '../../res/images/no_eye.svg';
import filterIcon from '../../res/images/filter_big.svg';
import noFilterIcon from '../../res/images/no_filter.svg';
import clientIcon from '../../res/images/store_filled.svg';
import flavourIcon from '../../res/images/capsules.svg';
import closeIcon from '../../res/images/close_thick.svg';
import { setStatsFilter } from '../../store/actions/stats';
import clone from 'clone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
} from '@material-ui/core';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import collapsedIcon from '../../res/images/three_dots_horizontal.svg';
import { useFilterChanged } from '../../store/reducers/stats';
import {
    formatClientTitle,
    generateAllClientsItem,
    generateAllFlavoursItem,
    ID_ALL_FLAVOURS,
} from '../../modules/MachineUtils';

const useStyles = makeStyles({
    window: {
        width: '414px',
        background: '#00B09E',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        backgroundColor: '#f0f3f9',
    },
    expansionPanelContainer: {
        '& .MuiExpansionPanelDetails-root': {
            padding: '0px',
        },
        '&.Mui-expanded': {
            margin: '0px',
        },
    },
    list: {
        width: '100%',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '62px',
    },
    listItemLabel: {
        flexGrow: 1,
        fontSize: '12px',
        color: '#7d90aa',
        marginLeft: '14px',
    },
    listItemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '0px',
    },
    listItemIcon: {
        padding: '2px',
        marginRight: '11px',
        backgroundColor: 'red',
    },
    listItemIconSmaller: {
        padding: '4px',
    },
    listItemImage: {
        width: '32px',
        height: '32px',
        filter: hexColorToCSSFilter('#ffffff'),
        margin: '-4px',
    },
    listItemAllFiltersClose: {
        marginLeft: '10px',
    },
    listItemImageClose: {
        padding: '4px',
    },
    divider: {
        backgroundColor: '#bec7d4',
        height: '1px',
        position: 'absolute',
        bottom: 0,
        left: '0px',
        right: '0px',
    },
    expansionPanelRoot: {
        backgroundColor: '#dee1eb',
    },
    expansionSummaryRoot: {
        backgroundColor: '#dfe4ed',
        color: '#7d90aa',
        fontSize: '12px',
        height: '60px',
        minHeight: '60px',
        paddingLeft: '14px',
        paddingRight: '0px',
        textTransform: 'uppercase',
        '&.Mui-expanded': {
            height: '60px',
            minHeight: '60px',
        },
    },
    expandSummaryContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&.Mui-expanded img:nth-child(3)': {
            transform: 'rotate(90deg)',
        },
    },
    expandSummaryLabel: {
        marginLeft: '10px',
        flexGrow: 1,
    },
    expansionSummaryExpanded: {
        backgroundColor: '#dfe4ed',
        color: '#7d90aa',
    },
    expansionPanelDetails: {
        backgroundColor: '#f0f3f9',
        padding: '6px',
    },
    expandCollapseIcon: {
        padding: '3px !important',
        filter: hexColorToCSSFilter('#7e8cac'),
        marginRight: '0px',
        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
    title: {
        flexGrow: 1,
    },
});

/** Formats the title of the window according to selected filter */
function formatTitle(clients, flavours) {
    return `Filters Applied (${clients.length + flavours.length})`;
}

/** Renders a single flavour list item */
function FilterFlavour(props) {
    const classes = useStyles();
    const item = props.item;

    const toggleVisibility = () => {
        item.visible = item.visible ? false : true;
        props.onRefreshFilter();
    };

    const removeFilter = () => {
        item.selected = false;
        props.onRefreshFilter();
    };

    return (
        <ListItem disableGutters className={classes.listItem}>
            <div className={classes.listItemLabel}>
                {item._id === ID_ALL_FLAVOURS
                    ? item.title
                    : `${item.title} - ${item._id}`}
            </div>
            <div className={classes.listItemRightSide}>
                <IconButton
                    className={clsx(classes.listItemIcon)}
                    style={{
                        backgroundColor: item.visible ? '#7e8cac' : '#9ebff5',
                    }}
                    onClick={toggleVisibility}
                >
                    <img
                        className={clsx(
                            classes.listItemImage,
                            classes.listItemIconSmaller
                        )}
                        src={item.visible ? filterIcon : noFilterIcon}
                        alt={'Toggle Filter View'}
                    />
                </IconButton>
                <IconButton
                    className={classes.listItemIcon}
                    style={{
                        backgroundColor: item.visible ? '#7e8cac' : '#9ebff5',
                    }}
                    onClick={removeFilter}
                >
                    <img
                        className={clsx(
                            classes.listItemImage,
                            classes.listItemImageClose
                        )}
                        src={closeIcon}
                        alt={'Remove Filter'}
                    />
                </IconButton>
            </div>

            <div className={classes.divider} />
        </ListItem>
    );
}

/** Renders a single client/site/cluster list item */
function FilterClient(props) {
    const classes = useStyles();
    const item = props.item;

    const toggleVisibility = () => {
        item.visible = item.visible ? false : true;
        props.onRefreshFilter();
    };

    const removeFilter = () => {
        item.selected = false;
        props.onRefreshFilter();
    };

    return (
        <ListItem disableGutters className={classes.listItem}>
            <div className={classes.listItemLabel}>
                {formatClientTitle(item)}
            </div>
            <div className={classes.listItemRightSide}>
                <IconButton
                    className={classes.listItemIcon}
                    style={{
                        backgroundColor: item.visible ? item.color : '#9ebff5',
                    }}
                    onClick={toggleVisibility}
                >
                    <img
                        className={classes.listItemImage}
                        src={item.visible ? visibilityIcon : noVisibilityIcon}
                        alt={'Toggle Filter View'}
                    />
                </IconButton>
                <IconButton
                    className={classes.listItemIcon}
                    style={{
                        backgroundColor: item.visible ? item.color : '#9ebff5',
                    }}
                    onClick={removeFilter}
                >
                    <img
                        className={clsx(
                            classes.listItemImage,
                            classes.listItemImageClose
                        )}
                        src={closeIcon}
                        alt={'Remove Filter'}
                    />
                </IconButton>
            </div>

            <div className={classes.divider} />
        </ListItem>
    );
}

/** The filter flavours inner window */
export default function FiltersApplied(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.stats.filter);
    const [filterClientsExpanded, setFilterClientsExpanded] = useState(true);
    const [filterFlavoursExpanded, setFilterFlavoursExpanded] = useState(true);
    const [flavours, setFlavours] = useState([]);
    const [clients, setClients] = useState([]);
    const dispatch = useDispatch();

    useFilterChanged(filter, () => {
        setFlavours(clone(filter.flavours));
        setClients(clone(filter.clients));
    });

    const refreshFilter = () => {
        dispatch(
            setStatsFilter({
                clients: clients.filter((x) => x.selected),
                flavours: flavours.filter((x) => x.selected),
            })
        );
    };

    const resetClientsFilter = (event) => {
        // Reset selection - unselect all clients, add default "All clients" entity
        dispatch(
            setStatsFilter({
                clients: [generateAllClientsItem(filter.allClients)],
            })
        );

        event.stopPropagation();
        event.preventDefault();
    };

    const resetFlavoursFilter = (event) => {
        // Reset selection - unselect all flavours, add default "All Flavours" entity
        dispatch(
            setStatsFilter({
                flavours: [generateAllFlavoursItem(filter.allFlavours)],
            })
        );

        event.stopPropagation();
        event.preventDefault();
    };

    // Sort by display name
    clients.sort((a, b) =>
        formatClientTitle(a).localeCompare(formatClientTitle(b))
    );

    flavours.sort((a, b) => a.title.localeCompare(b.title));

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={formatTitle(clients, flavours)}
            loading={flavours.length === 0 || clients.length === 0}
        >
            <div className={classes.content}>
                <ExpansionPanel
                    className={classes.expansionPanelContainer}
                    expanded={filterClientsExpanded}
                    onChange={(event, expanded) => {
                        setFilterClientsExpanded(expanded);
                    }}
                >
                    <ExpansionPanelSummary
                        classes={{
                            expanded: classes.expansionSummaryExpanded,
                            root: classes.expansionSummaryRoot,
                            content: classes.expandSummaryContent,
                        }}
                    >
                        <img src={clientIcon} alt={'All Filtered Clients'} />
                        <div className={classes.expandSummaryLabel}>
                            All Filtered Clients
                        </div>
                        <img
                            src={collapsedIcon}
                            className={classes.expandCollapseIcon}
                            alt="Expand"
                        />

                        <IconButton
                            className={clsx(
                                classes.listItemIcon,
                                classes.listItemAllFiltersClose
                            )}
                            style={{
                                backgroundColor: '#7e8cac',
                            }}
                            onClick={resetClientsFilter}
                        >
                            <img
                                className={clsx(
                                    classes.listItemImage,
                                    classes.listItemImageClose
                                )}
                                src={closeIcon}
                                alt={'Remove All Filters'}
                            />
                        </IconButton>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        classes={{ root: classes.expansionPanelDetails }}
                    >
                        <List className={classes.list}>
                            {clients.map((c) => (
                                <FilterClient
                                    key={c._id}
                                    item={c}
                                    onRefreshFilter={refreshFilter}
                                />
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    className={classes.expansionPanelContainer}
                    expanded={filterFlavoursExpanded}
                    onChange={(event, expanded) => {
                        setFilterFlavoursExpanded(expanded);
                    }}
                >
                    <ExpansionPanelSummary
                        classes={{
                            expanded: classes.expansionSummaryExpanded,
                            root: classes.expansionSummaryRoot,
                            content: classes.expandSummaryContent,
                        }}
                    >
                        <img src={flavourIcon} alt={'All Filtered Flavours'} />
                        <div className={classes.expandSummaryLabel}>
                            All Filtered Flavours
                        </div>
                        <img
                            src={collapsedIcon}
                            className={classes.expandCollapseIcon}
                            alt="Expand"
                        />

                        <IconButton
                            className={clsx(
                                classes.listItemIcon,
                                classes.listItemAllFiltersClose
                            )}
                            style={{
                                backgroundColor: '#7e8cac',
                            }}
                            onClick={resetFlavoursFilter}
                        >
                            <img
                                className={clsx(
                                    classes.listItemImage,
                                    classes.listItemImageClose
                                )}
                                src={closeIcon}
                                alt={'Remove All Filters'}
                            />
                        </IconButton>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        classes={{ root: classes.expansionPanelDetails }}
                    >
                        <List className={classes.list}>
                            {flavours.map((c) => (
                                <FilterFlavour
                                    key={c._id}
                                    item={c}
                                    onRefreshFilter={refreshFilter}
                                />
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </InnerWindow>
    );
}
