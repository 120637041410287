import * as actions from '../actions/reports';
import { DateTime } from 'luxon';
import { usePrevious } from '../../modules/HookUtils';
import { useEffect } from 'react';
import {
    generateAllFlavoursItem,
    ID_ALL_CLIENTS,
} from '../../modules/MachineUtils';

export const SELECTION_TYPE_ENDING_DATE = 'ending_date';
export const SELECTION_TYPE_DAY = 'day';
export const SELECTION_TYPE_WEEK = 'week';

export const DAYS_BACK_1_DAY = '1_day';
export const DAYS_BACK_7_DAYS = '7_days';
export const DAYS_BACK_1_MONTH = '1_month';
export const DAYS_BACK_3_MONTHS = '3_months';
export const DAYS_BACK_1_YEAR = '1_year';
export const DAYS_BACK_YTD = 'ytd'; // Beginning of year

const INITIAL_STATE = {
    filter: {
        // Each selected flavour/client/etc. is an entire object of that type.
        // Clients/sites/clusters have a "visible" property (=whether or not should they be visible on the graph/table),
        // and also a "color" property (the color display on the graph/table)
        clients: [],
        allClients: [], // All clients available (in a tree structure of client/site/cluster/machines)
        clusterIds: [], // Selected cluster IDs (children of the selected clients/sites)
        flavours: [],
        allFlavours: [generateAllFlavoursItem([])], // All flavours available
        startDate: DateTime.local().startOf('day'),
        endDate: DateTime.local().endOf('day'),
        selectionType: SELECTION_TYPE_ENDING_DATE, // Type of report filter selection (end date, 1 or more consecutive days or weeks)
        selectionCount: 1, // e.g. 3 consecutive weeks
        daysBack: null, // For report types of Change in consumption + Trend analysis - how many days back should we analyze (e.g. 7 days, 30 days, 1 year)
        countries: [],
        clientTypes: [],
        filterClientCategories: null,
        reportType: null,
    },
};

/** Returns a smaller version of the selected flavours list, one that doesn't include capsule counts,
 * so it'll be possible to JSON-stringify it. */
function cleanFlavours(flavours) {
    return flavours.map((c) => ({
        _id: c._id,
        name: c._id,
        selected: c.selected,
        visible: c.visible,
    }));
}

/** Returns a smaller version of the selected clients list, one that doesn't include parent/child references,
 * so it'll be possible to JSON-stringify it. */
function cleanClients(clients) {
    return clients.map((c) => ({
        _id: c._id,
        name: c._id,
        selected: c.selected,
        visible: c.visible,
    }));
}

/** Checks if a filter has changed (the filtered clients, flavours or start/end date have changed) */
export function useFilterChanged(filter, onFilterChanged, dateOnly) {
    const prevFilterStartDate = usePrevious(filter.startDate);
    const prevFilterEndDate = usePrevious(filter.endDate);
    const prevFlavours = usePrevious(filter.flavours);
    const prevClients = usePrevious(filter.clients);
    const prevDaysBack = usePrevious(filter.daysBack);

    useEffect(() => {
        let dateChanged = true,
            flavoursChanged = true,
            clientsChanged = true,
            daysBackChanged = true;

        if (prevFilterStartDate) {
            dateChanged =
                filter.startDate.toMillis() !==
                    prevFilterStartDate.toMillis() ||
                filter.endDate.toMillis() !== prevFilterEndDate.toMillis();
        }

        if (prevDaysBack) {
            daysBackChanged = prevDaysBack !== filter.daysBack;
        } else if (!filter.daysBack) {
            daysBackChanged = false;
        }

        if (dateOnly && (dateChanged || daysBackChanged)) {
            onFilterChanged();
            return;
        } else if (dateOnly) {
            return;
        }

        if (prevFlavours) {
            flavoursChanged =
                JSON.stringify(cleanFlavours(filter.flavours)) !==
                JSON.stringify(cleanFlavours(prevFlavours));
        }
        if (prevClients) {
            clientsChanged =
                JSON.stringify(cleanClients(filter.clients)) !==
                JSON.stringify(cleanClients(prevClients));
        }

        if (
            !dateChanged &&
            !flavoursChanged &&
            !clientsChanged &&
            !daysBackChanged
        )
            return;

        onFilterChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter.startDate,
        filter.endDate,
        filter.flavours,
        filter.clusterIds,
        filter.daysBack,
    ]);
}

/** Finds selected clusters (cluster that belong to selected clients/sites) */
function findSelectedClustersFromItem(item, returnAll) {
    let clusters = [];

    if (item.site) {
        // Reached a cluster
        return item.selected || returnAll ? [item] : [];
    }

    if (item.children) {
        item.children.forEach((c) => {
            clusters = clusters.concat(
                findSelectedClustersFromItem(c, returnAll)
            );
        });

        return clusters;
    }

    return [];
}

/** Finds selected cluster IDs (cluster IDs that belong to selected clients/sites/clusters) */
export function findSelectedClusters(selectedClients, allClients) {
    let clusters = [];

    if (
        selectedClients.length > 0 &&
        selectedClients[0]._id === ID_ALL_CLIENTS
    ) {
        // All clusters
        allClients.forEach((c) => {
            clusters = clusters.concat(findSelectedClustersFromItem(c, true));
        });
    } else {
        selectedClients.forEach((c) => {
            clusters = clusters.concat(findSelectedClustersFromItem(c));
        });
    }

    // Remove any duplicates
    return Array.from(new Set(clusters.map((c) => c._id)));
}

export function getStartDateAccordingToDaysBack(startDate, daysBack) {
    let newStartDate;
    if (!daysBack || daysBack === DAYS_BACK_1_DAY) {
        newStartDate = startDate;
    } else if (daysBack === DAYS_BACK_7_DAYS) {
        newStartDate = startDate.minus({ days: 6 });
    } else if (daysBack === DAYS_BACK_1_MONTH) {
        newStartDate = startDate.minus({ months: 1 });
    } else if (daysBack === DAYS_BACK_3_MONTHS) {
        newStartDate = startDate.minus({ months: 3 });
    } else if (daysBack === DAYS_BACK_1_YEAR) {
        newStartDate = startDate.minus({ years: 1 });
    } else if (daysBack === DAYS_BACK_YTD) {
        newStartDate = startDate.startOf('year');
    }

    return newStartDate.startOf('day');
}

/** Returns start and end dates for APIs usage, depending on filter and report type */
export function getDatesForAPI(filter) {
    if (filter.selectionType === SELECTION_TYPE_ENDING_DATE) {
        const startDate = getStartDateAccordingToDaysBack(
            filter.startDate,
            filter.daysBack
        );
        return [startDate, filter.endDate];
    } else {
        return [filter.startDate, filter.endDate];
    }
}

/** Reports Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_REPORTS_FILTER:
            const newFilter = { ...state.filter, ...action.value }; // Just overwrite any input fields given to the filter

            if (action.value.clients && newFilter.allClients) {
                // Updating selected clients - update selected cluster IDs as well (cluster IDs that belong to those clients/sites/clusters)
                newFilter.clusterIds = findSelectedClusters(
                    action.value.clients,
                    newFilter.allClients
                );
            }

            return { ...state, filter: newFilter };
        default:
            return state;
    }
};
