import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useFilterChanged } from '../../store/reducers/overview';
import filterIcon from '../../res/images/eye.svg';
import noFilterIcon from '../../res/images/no_eye.svg';
import leftIcon from '../../res/images/left_arrow.svg';
import rightIcon from '../../res/images/right_arrow.svg';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import clone from 'clone';
import { setOverviewFilter } from '../../store/actions/overview';
import { cloneItemOnly } from '../../modules/MachineUtils';
import Chart from 'chart.js';
import '../../modules/ChartUtils'; // For hoverLine graph type

const CELL_WIDTH = '106';
const COLUMN_MOVEMENT_COUNT = 4; // How many column should we move left/right when pressing the left/right buttons in the filter bar

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterBar: {
        height: '66px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    filterBarInnerContainer: {
        width: 0,
        display: 'flex',
        flexGrow: '1',
        overflowX: 'hidden',
        overflowY: 'hidden',
        flexDirection: 'row',
    },
    canvasContainer: {
        flexGrow: 1,
        width: '100%',
        position: 'relative',
    },
    filterMachine: {
        width: `${CELL_WIDTH}px`,
        minWidth: `${CELL_WIDTH}px`,
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    machineFilterTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#586374',
        textAlign: 'center',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    machineFilterSubTitle: {
        fontSize: '8px',
        fontWeight: 'bold',
        color: '#586374',
        textAlign: 'center',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    machineFilterColor: {
        display: 'flex',
        flexDirection: 'row',
        height: '24px',
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    machineSeparator: {
        height: '100%',
        width: '1px',
        backgroundColor: '#ffffff',
        position: 'absolute',
        right: '0px',
        top: '0px',
    },
    machineVisibleContainer: {
        backgroundColor: '#ffffff',
        height: '14px',
        width: '14px',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '5px',
        cursor: 'pointer',
    },
    machineVisibleIcon: {
        filter: hexColorToCSSFilter('#586374'),
        height: '12px',
        width: '12px',
    },
    machineNotVisible: {
        opacity: 0.6,
    },
    machineFilterName: {
        textOverflow: 'ellipsis',
        paddingRight: '19px',
        textAlign: 'center',
        width: '100%',
    },
    filterArrow: {
        position: 'absolute',
        height: '100%',
        width: '19px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9,
        cursor: 'pointer',
        backgroundColor: '#afbac9',
        userSelect: 'none',
    },
    filterLeft: {
        left: 0,
        boxShadow: '10px 7px 11px 1px rgba(255,255,255,1)',
    },
    filterRight: {
        right: 0,
        boxShadow: '-10px 7px 11px 1px rgba(255,255,255,1)',
    },
    filterArrowIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        width: '100%',
    },
    graphArrow: {
        width: '27px',
        height: '27px',
        zIndex: 9,
        backgroundColor: '#7d90aa',
        position: 'absolute',
        top: 'calc(50% - 13px)',
        border: '1px solid #000000',
        borderRadius: '15px',
        opacity: 0.5,
        cursor: 'pointer',
    },
    leftGraphArrow: {
        left: '14px',
    },
    rightGraphArrow: {
        right: '14px',
    },
    graphArrowIcon: {
        filter: hexColorToCSSFilter('#000000'),
        width: '100%',
    },
    tooltipContainer: {
        backgroundColor: '#ffffff',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '8px',
        borderStyle: 'solid',
        borderWidth: '2px',
        boxShadow: '0 -7px 5px 0 rgba(0, 0, 0, 0.16)',
        transform: 'translateY(-50%)',
        marginLeft: '10px',
    },
    tooltipOpenMachine: {
        padding: '6px',
        display: 'flex',
        flexDirection: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tooltipOpenMachineIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        width: '24px',
        height: '24px',
    },
    tooltipLeftSide: {
        paddingLeft: '10px',
        paddingRight: '4px',
        paddingBottom: '6px',
        paddingTop: '6px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '14px',
        flexShrink: 0,
        color: '#000000',
    },
    tooltipColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '6px',
    },
    tooltipLabel: {
        minWidth: '105px',
    },
    tooltipValue: {
        fontWeight: 'bold',
    },
});

function test() {
    const unusedVar = 123;

    return unusedVar;
}

/** The top filter bar - shows list of machines, their current KPI value and
 * the ability to toggle visibility on the graph */
function FilterBar(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);
    const dispatch = useDispatch();
    const [scrollOffset, setScrollOffset] = useState(0);
    const filterBar = useRef(null);

    test();

    const kpi = props.kpi;
    const stats = props.stats;
    const currentDate = props.currentDate;

    const machines = filter.selectedMachines;

    useEffect(() => {
        filterBar.current.scrollTo(scrollOffset, 0);
    }, [scrollOffset]);

    const toggleVisibility = (machine) => {
        const selectedMachinesCopy = clone(filter.selectedMachines);
        const machineCopy = selectedMachinesCopy.find(
            (m) => m._id === machine._id
        );
        machineCopy.kpiVisible = !machineCopy.kpiVisible;

        dispatch(
            setOverviewFilter({
                selectedMachines: selectedMachinesCopy,
            })
        );
    };

    const scrollFilter = (direction) => {
        // Move 4 columns to the left
        const newOffset =
            scrollOffset + direction * CELL_WIDTH * COLUMN_MOVEMENT_COUNT;
        setScrollOffset(Math.ceil(newOffset));
    };

    return (
        <div className={classes.filterBar}>
            {scrollOffset > 0 && (
                <div
                    className={clsx(classes.filterArrow, classes.filterLeft)}
                    onClick={() => scrollFilter(-1)}
                >
                    <img
                        className={classes.filterArrowIcon}
                        src={leftIcon}
                        alt={'Left'}
                    />
                </div>
            )}
            {filterBar.current != null &&
                Math.ceil(filterBar.current.scrollLeft) +
                    filterBar.current.offsetWidth +
                    CELL_WIDTH * COLUMN_MOVEMENT_COUNT <
                    filterBar.current.scrollWidth - 10 && (
                    <div
                        className={clsx(
                            classes.filterArrow,
                            classes.filterRight
                        )}
                        onClick={() => scrollFilter(1)}
                    >
                        <img
                            className={classes.filterArrowIcon}
                            src={rightIcon}
                            alt={'Right'}
                        />
                    </div>
                )}
            <div className={classes.filterBarInnerContainer} ref={filterBar}>
                {machines.map((machine) => (
                    <div
                        key={machine._id}
                        className={clsx(
                            classes.filterMachine,
                            !machine.kpiVisible
                                ? classes.machineNotVisible
                                : null
                        )}
                    >
                        <div className={classes.machineFilterTitle}>
                            {currentDate && stats[machine._id][currentDate]
                                ? stats[machine._id][currentDate]
                                : 'N/A'}
                        </div>
                        <div className={classes.machineFilterSubTitle}>
                            {kpi.unit}
                        </div>
                        <div
                            className={classes.machineFilterColor}
                            style={{ backgroundColor: machine.color }}
                        >
                            <div className={classes.machineFilterName}>
                                {machine.alias}
                            </div>
                            <div
                                className={classes.machineVisibleContainer}
                                onClick={() => {
                                    toggleVisibility(machine);
                                }}
                            >
                                <img
                                    className={classes.machineVisibleIcon}
                                    src={
                                        !machine.kpiVisible
                                            ? noFilterIcon
                                            : filterIcon
                                    }
                                    alt={'Machine Visible'}
                                />
                            </div>
                        </div>

                        <div className={classes.machineSeparator} />
                    </div>
                ))}
            </div>
        </div>
    );
}

/** KPI Tooltip - shows above a single machine KPI line */
function KPITooltip(props) {
    const classes = useStyles();
    const { kpi, machine, date, value, pointTop, pointLeft } = props;

    const openMachine = () => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machine._id}/dashboard`, '_blank');
        win.focus();
    };

    return (
        <div
            className={classes.tooltipContainer}
            style={{
                top: pointTop,
                left: pointLeft,
                borderColor: machine ? machine.color : '#7d90aa',
            }}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <div className={classes.tooltipLeftSide}>
                <div className={classes.tooltipColumn}>
                    {machine && (
                        <div className={classes.tooltipLabel}>
                            {kpi.title} ({kpi.unit}):
                        </div>
                    )}
                    {machine && (
                        <div className={classes.tooltipLabel}>Machine:</div>
                    )}
                    <div className={classes.tooltipLabel}>Date:</div>
                </div>

                <div className={classes.tooltipColumn}>
                    {machine && (
                        <div className={classes.tooltipValue}>{value}</div>
                    )}
                    {machine && (
                        <div className={classes.tooltipValue}>
                            {machine.alias}
                        </div>
                    )}
                    <div className={classes.tooltipValue}>{date}</div>
                </div>
            </div>

            {machine && (
                <div
                    className={classes.tooltipOpenMachine}
                    onClick={openMachine}
                    style={{ backgroundColor: machine.color }}
                >
                    <img src={openMachineIcon} alt={'Open machine'} />
                </div>
            )}
        </div>
    );
}

/** KPIs graph - showing the graph and top bar (which shows the list of machines
 * and their current KPI value) */
export default function KPIGraph(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);
    const canvas = useRef(null);
    const stats = props.stats;
    const [chart, setChart] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);
    const [kpiTooltips, setKpiTooltips] = useState([]);
    const [hoveredDatapoints, setHoveredDatapoints] = useState([]);
    const [isTooltipHovered, setTooltipHovered] = useState([]);
    const isTooltipHoveredRef = useRef(isTooltipHovered);
    isTooltipHoveredRef.current = isTooltipHovered;

    // Custom KPI tooltips - display multiple tooltips over any graph lines intersecting the X axis
    const kpiTooltipsGenerator = (tooltip) => {
        if (!tooltip || !tooltip.opacity || tooltip.dataPoints.length === 0) {
            // No intersection with any datapoints
            setHoveredDatapoints([]);
            return;
        }

        if (tooltip.dataPoints.length > 0) {
            setHoveredDatapoints(tooltip.dataPoints);
        }
    };

    useEffect(() => {
        clearTooltips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTooltipHovered]);

    const clearTooltips = () => {
        if (hoveredDatapoints.length === 0 && !isTooltipHoveredRef.current) {
            setKpiTooltips([]);
        }
    };

    useEffect(() => {
        if (!canvas.current || !chart) return;

        if (hoveredDatapoints.length === 0) {
            setTimeout(() => {
                clearTooltips();
            }, 2000);
            return;
        }

        // Hovered data points changed (user is hovering over no point / other points in the graph)
        const positionY = canvas.current.offsetTop;
        const positionX = canvas.current.offsetLeft;
        const datasets = chart.config.data.datasets;

        const dataPoint = hoveredDatapoints[0];
        const newTooltips = [
            {
                machine: datasets[dataPoint.datasetIndex].machine,
                date: dataPoint.xLabel,
                value: dataPoint.yLabel,
                pointTop: positionY + dataPoint.y,
                pointLeft: positionX + dataPoint.x,
            },
        ];

        // Add the date tooltip (showing at the top)
        newTooltips.push({
            date: hoveredDatapoints[0].xLabel,
            pointTop: positionY + 30,
            pointLeft: positionX + hoveredDatapoints[0].x,
        });

        setKpiTooltips(newTooltips);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoveredDatapoints]);

    const refreshChart = () => {
        if (chart !== null) {
            chart.destroy();
        }

        if (!stats || Object.keys(stats.data).length === 0) {
            return;
        }

        const context = canvas.current.getContext('2d');

        // Convert stats/data into line datasets
        const data = {
            labels: stats.columns,
            datasets: filter.selectedMachines
                .filter((m) => m.kpiVisible)
                .map((machine) => ({
                    machine: cloneItemOnly(machine),
                    label: machine.alias,
                    fill: false,
                    borderColor: machine.color,
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    pointHitRadius: 10,
                    pointBackgroundColor: machine.color,
                    data: stats.columns.map((col) =>
                        stats.data[machine._id]
                            ? stats.data[machine._id][col]
                            : null
                    ),
                })),
        };

        const newChart = new Chart(context, {
            type: 'hoverLine',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                spanGaps: true,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false, // Disable default canvas-based tooltips
                    mode: 'point',
                    intersect: false,
                    custom: kpiTooltipsGenerator,
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: { display: false },
                            ticks: { beginAtZero: true },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: { display: false },
                            ticks: { display: false },
                        },
                    ],
                },
                onHover: (event) => {
                    // Update current date being hovered on
                    if (event.type === 'mousemove') {
                        const items = newChart.getElementsAtXAxis(event);
                        if (items && items.length > 0) {
                            const columnIndex = items[0]._index;
                            setCurrentDate(stats.columns[columnIndex]);
                            return;
                        }
                    }

                    // Went outside the graph
                    setCurrentDate(null);
                },
            },
        });

        setChart(newChart);

        return () => {
            if (chart !== null) {
                chart.destroy();
            }
        };
    };

    useEffect(() => {
        refreshChart();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stats]);

    useFilterChanged(filter, (softRefresh) => {
        if (!softRefresh) refreshChart();
    });

    return (
        <div className={clsx(props.className, classes.container)}>
            <FilterBar
                kpi={props.kpi}
                stats={stats ? stats.data : null}
                currentDate={currentDate}
            />
            <div className={classes.canvasContainer}>
                <canvas ref={canvas} className={classes.canvas} />

                {kpiTooltips.map((tooltip) => (
                    <KPITooltip
                        key={
                            tooltip.machine
                                ? tooltip.machine._id + tooltip.date
                                : tooltip.date
                        }
                        machine={tooltip.machine}
                        date={tooltip.date}
                        value={tooltip.value}
                        pointTop={tooltip.pointTop}
                        pointLeft={tooltip.pointLeft}
                        kpi={props.kpi}
                        onMouseEnter={() => {
                            setTooltipHovered(true);
                        }}
                        onMouseLeave={() => {
                            setTooltipHovered(false);
                        }}
                    />
                ))}

                <div
                    className={clsx(classes.graphArrow, classes.leftGraphArrow)}
                    onClick={() => {
                        props.onGraphMoved(-1);
                    }}
                >
                    <img
                        className={classes.graphArrowIcon}
                        src={leftIcon}
                        alt="Move back"
                    />
                </div>
                <div
                    className={clsx(
                        classes.graphArrow,
                        classes.rightGraphArrow
                    )}
                    onClick={() => {
                        props.onGraphMoved(1);
                    }}
                >
                    <img
                        className={classes.graphArrowIcon}
                        src={rightIcon}
                        alt="Move forward"
                    />
                </div>
            </div>
        </div>
    );
}
