import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../../components/InnerWindow';
import clsx from 'clsx';
import switchIcon from '../../../res/images/switch_circle.svg';
import ParametersTable from './ParametersTable';
import ParametersGraph from './ParametersGraph';

const TEMPERATURE_VALUE_NOT_AVAILABLE = 204.8; // When the temperature value is this, this means no sensor is attached

const useStyles = makeStyles({
    window: {
        backgroundColor: '#f0f3f9',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
    },
});

/** Whether or not a temperature value means it's not really available */
export function temperatureIsUnavailable(temperature) {
    return !temperature || temperature === TEMPERATURE_VALUE_NOT_AVAILABLE;
}

/** Formats a temperature value */
export function formatTemperature(temperature) {
    return temperatureIsUnavailable(temperature)
        ? 'N/A'
        : temperature.toFixed(2);
}

const VIEW_TYPE_TABLE = 'table';
const VIEW_TYPE_GRAPH = 'graph';

/** Single machine view (machine details, params, graph, etc.) */
export default function MachineParameters(props) {
    const classes = useStyles();
    const machine = props.machine;
    const [viewType, setViewType] = useState(VIEW_TYPE_TABLE);
    const [latestTimestamp, setLatestTimestamp] = useState(
        machine && machine.latest_timestamp
            ? DateTime.fromMillis(machine.latest_timestamp).toUTC()
            : null
    );

    const onNewLogs = (newLogs) => {
        props.onNewLogs(newLogs);

        if (!newLogs || newLogs.length === 0) return;
        const latestLog = newLogs[newLogs.length - 1];
        setLatestTimestamp(
            DateTime.fromMillis(latestLog.timestamp)
                .toUTC()
                .plus({ minutes: latestLog.timezoneOffset })
        );
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={
                machine
                    ? `${machine.alias} / Parameters (last updated: ${
                          latestTimestamp
                              ? latestTimestamp.toFormat('dd/MM/yyyy HH:mm')
                              : 'N/A'
                      })`
                    : ''
            }
            icon={switchIcon}
            onIconClick={() => {
                setViewType(
                    viewType === VIEW_TYPE_TABLE
                        ? VIEW_TYPE_GRAPH
                        : VIEW_TYPE_TABLE
                );
            }}
        >
            <div className={classes.content}>
                {viewType === VIEW_TYPE_TABLE && (
                    <ParametersTable
                        machineId={props.machineId}
                        onNewLogs={onNewLogs}
                        machine={machine}
                    />
                )}
                {viewType === VIEW_TYPE_GRAPH && (
                    <ParametersGraph
                        machineId={props.machineId}
                        onNewLogs={onNewLogs}
                        machine={machine}
                    />
                )}
            </div>
        </InnerWindow>
    );
}
