import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import clone from 'clone';
import DateTime from 'luxon/src/datetime';
import { IconButton } from '@material-ui/core';
import leftArrow from '../../../res/images/left_arrow.svg';
import rightArrow from '../../../res/images/right_arrow.svg';
import upIcon from '../../../res/images/arrow_up.svg';
import downIcon from '../../../res/images/arrow_down.svg';
import closeIcon from '../../../res/images/close_circle.svg';
import clsx from 'clsx';
import ToggleButton from '../../../components/ToggleButton';
import visibilityIcon from '../../../res/images/eye.svg';
import noVisibilityIcon from '../../../res/images/no_eye.svg';
import {
    formatTemperature,
    temperatureIsUnavailable,
} from './MachineParameters';
import { recipeStatusToDescription } from '../../../modules/MachineUtils';
import Slider from '@material-ui/core/Slider';
import Authentication from '../../../modules/Authentication';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Chart from 'chart.js';

// How many days back should we allow looking at?
const DATE_FILTER_DAY_COUNT = 7;
// Different zoom types (by minute intervals)
const ZOOM_TYPES = [1, 5, 15, 60];
// Different params being displayed on the graph - each parameter has the following structure:
// name = display name
// unit = unit value display
// color = line color on graph
// calculate = function that given input log record, returns the value of the param
// calculateText = function that given the calculated param value, returns a textual display value (e.g. add percentage sign)
const FILTER_PARAMS = [
    {
        name: 'M. Speed',
        unit: 'RPM',
        color: '#567ee6',
        calculate: (l) => l.motor1speedValue,
        calculateText: (v) => v,
    },
    {
        name: 'M. Percentage',
        unit: '%',
        color: '#513b82',
        calculate: (l) => l.motorSpeed1,
        calculateText: (v) => `${v}%`,
    },
    {
        name: 'M. Current',
        unit: 'A',
        color: '#00c06e',
        calculate: (l) => l.motorCurrentValue1,
        calculateText: (v) => v,
    },
    {
        name: 'Comp. Speed',
        unit: '%',
        color: '#3fdf04',
        calculate: (l) => l.compressorSpeed1,
        calculateText: (v) => v,
    },
    {
        name: 'Sys. Current',
        unit: 'A',
        color: '#ff0579',
        calculate: (l) => l.systemCurrentValue1,
        calculateText: (v) => v,
    },
    {
        name: 'Temp. Avg',
        unit: '°C',
        color: '#f6b600',
        calculate: (l) =>
            temperatureIsUnavailable(l.temperatureValue1) ||
            temperatureIsUnavailable(l.temperatureValue2)
                ? null
                : (l.temperatureValue1 + l.temperatureValue2) / 2,
        calculateText: (v) => (v === null ? 'N/A' : formatTemperature(v)),
    },
];

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        width: '100%',
        overflow: 'hidden',
    },
    topBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    graphContainer: {
        flexGrow: 1,
        position: 'relative',
        width: '100%',
    },
    canvas: {},
    bottomBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '5px',
        justifyContent: 'flex-end',
        position: 'relative',
    },
    leftRightContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '50px',
    },
    arrowButton: {
        padding: '2px',
        backgroundColor: '#7e8cac',
        width: '28px',
        height: '28px',
    },
    leftArrowButton: {
        marginRight: '86px',
    },
    arrowButtonImage: {
        width: '28px',
        minWidth: '28px',
        height: '28px',
        minHeight: '28px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    zoomContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    zoomTypeButton: {
        marginRight: '10px',
        width: '110px',
        borderColor: '#7d90aa',
        backgroundColor: '#ffffff',
        color: '#7d90aa',
        opacity: 0.45,
        '&.Mui-selected': {
            borderColor: '#7d90aa',
            backgroundColor: '#ffffff',
            color: '#7d90aa',
            opacity: 1,
        },
    },
    paramFiltersContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    paramContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#e3e9f4',
        width: '106px',
        minWidth: '106px',
        height: '40px',
        minHeight: '40px',
        cursor: 'pointer',
    },
    paramContainerNotVisible: {
        opacity: 0.4,
    },
    paramValue: {
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#586374',
    },
    paramName: {
        textTransform: 'uppercase',
        fontSize: '8px',
        fontWeight: 'bold',
        color: '#586374',
    },
    paramColor: {
        width: '100%',
        height: '4px',
    },
    paramSeparator: {
        width: '2px',
        minWidth: '2px',
        height: '100%',
        backgroundColor: '#ffffff',
        position: 'absolute',
        left: 0,
    },
    paramIconContainer: {
        position: 'absolute',
        right: '5px',
        top: '5px',
        width: '14px',
        height: '14px',
        backgroundColor: '#7E8CAC',
        borderRadius: '7px',
    },
    paramIcon: {
        filter: hexColorToCSSFilter('#e3e9f4'),
        width: '14px',
        height: '14px',
    },
    timeFilterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#93a0b9',
        height: '44px',
        cursor: 'pointer',
        position: 'relative',
    },
    timeField: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#7e8cac',
        height: '40px',
        minHeight: '40px',
        width: '95px',
        minWidth: '95px',
        marginRight: '2px',
    },
    timeValue: {
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#dadee6',
        textAlign: 'center',
    },
    timeLabel: {
        textTransform: 'uppercase',
        fontSize: '8px',
        fontWeight: 'bold',
        color: '#dadee6',
        textAlign: 'center',
    },
    timeUpIcon: {
        marginLeft: '9px',
        marginRight: '9px',
        width: '14px',
        height: '14px',
        marginTop: '-4px',
        alignSelf: 'center',
    },
    timeFilterOpenContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'max-content',
        height: 'max-content',
        backgroundColor: '#7e8cac',
        position: 'absolute',
        left: 0,
        top: '44px',
        paddingLeft: '18px',
        paddingRight: '48px',
        cursor: 'initial',
        zIndex: 100,
    },
    timeFilterDateContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '47px',
    },
    timeFilterDate: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '18px',
        position: 'relative',
        cursor: 'pointer',

        '&:last-child': {
            marginRight: '0px',
        },
    },
    timeFilterDateLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#bec7d4',
        textAlign: 'center',
    },
    timeFilterDateSubLabel: {
        textTransform: 'uppercase',
        fontSize: '8px',
        color: '#bec7d4',
        textAlign: 'center',
    },
    timeFilterLabelSelected: {
        color: '#ffffff',
    },
    timeFilterLabelDot: {
        textAlign: 'center',
        position: 'absolute',
        bottom: '-15px',
        left: 0,
        right: 0,
    },
    timeFilterHour: {
        marginTop: '55px',
    },
    timeFilterRail: {
        color: '#bec7d4',
        opacity: 1,
        height: '3px',
    },
    timeFilterRoot: {
        color: '#bec7d4',
        opacity: 1,
        height: '3px',
    },
    timeFilterThumb: {
        backgroundColor: '#ffffff',
    },
    timeFilterMark: {
        backgroundColor: '#bec7d4',
        height: 8,
        width: 8,
        borderRadius: 4,
        marginTop: -3,
    },
    timeFilterMarkLabel: {
        color: '#bec7d4',
        fontSize: '8px',
    },
    timeFilterValueLabel: {
        fontSize: '12px',
        top: '-20px',
        '& *': {
            background: 'transparent',
            color: '#ffffff',
        },
    },
    timeFilterClose: {
        width: '28px',
        height: '28px',
        padding: 0,
        position: 'absolute',
        right: '12px',
        top: '12px',
    },
    timeFilterCloseIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        width: '28px',
        height: '28px',
    },
});

const gApiClient = Authentication.getAPIClient();

/** The param filter bar (where you can which params will be visible on the graph and showing current values) */
function ParamFilters(props) {
    const classes = useStyles();

    return (
        <div className={classes.paramFiltersContainer}>
            {props.filterParams.map((param) => (
                <div
                    key={param.name}
                    className={clsx(
                        classes.paramContainer,
                        !param.visible ? classes.paramContainerNotVisible : null
                    )}
                    onClick={() => {
                        props.onToggleParam(param);
                    }}
                >
                    <div className={classes.paramValue}>
                        {props.log
                            ? param.calculateText(param.calculate(props.log))
                            : 'N/A'}
                    </div>
                    <div className={classes.paramName}>{param.name}</div>
                    <div
                        className={classes.paramColor}
                        style={{ backgroundColor: param.color }}
                    />
                    <div className={classes.paramSeparator} />
                    <div className={classes.paramIconContainer}>
                        <img
                            className={classes.paramIcon}
                            src={
                                param.visible
                                    ? visibilityIcon
                                    : noVisibilityIcon
                            }
                            alt={'Toggle Visibility'}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

/** The expanded time filter (allows choosing date and time) */
function ExpandedTimeFilter(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(props.startDate);
    const currentDate = DateTime.local();

    return (
        <div
            className={classes.timeFilterOpenContainer}
            onClick={(event) => {
                // Prevent clicking the open date filter from closing it (parent handler)
                event.stopPropagation();
                event.preventDefault();
            }}
        >
            <IconButton
                onClick={() => {
                    props.onClose(selectedDate);
                }}
                className={classes.timeFilterClose}
            >
                <img
                    className={classes.timeFilterCloseIcon}
                    src={closeIcon}
                    alt="Close"
                />
            </IconButton>

            <div className={classes.timeFilterDateContainer}>
                {[...Array(DATE_FILTER_DAY_COUNT + 1).keys()]
                    .reverse()
                    .map((d) => -1 * d)
                    .map((d) => {
                        const date = currentDate.plus({ days: d });
                        const isCurrentDate =
                            +date.startOf('day') ===
                            +selectedDate.startOf('day');

                        return (
                            <div
                                key={d}
                                className={classes.timeFilterDate}
                                onClick={() => {
                                    // Set selected date (change date, but leave minute offset as-is)
                                    const minutes = parseInt(
                                        (selectedDate -
                                            selectedDate.startOf('day')) /
                                            (1000 * 60)
                                    );
                                    const newDate = date
                                        .startOf('day')
                                        .plus({ minutes: minutes });
                                    setSelectedDate(newDate);
                                }}
                            >
                                <div
                                    className={clsx(
                                        classes.timeFilterDateLabel,
                                        isCurrentDate
                                            ? classes.timeFilterLabelSelected
                                            : null
                                    )}
                                >
                                    {date.toFormat('dd MMM')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.timeFilterDateSubLabel,
                                        isCurrentDate
                                            ? classes.timeFilterLabelSelected
                                            : null
                                    )}
                                >
                                    {d < 0 ? `${d} D` : 'Now'}
                                </div>
                                {isCurrentDate && (
                                    <div
                                        className={clsx(
                                            classes.timeFilterLabelSelected,
                                            classes.timeFilterLabelDot
                                        )}
                                    >
                                        •
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
            <Slider
                className={classes.timeFilterHour}
                classes={{
                    rail: classes.timeFilterRail,
                    root: classes.timeFilterRoot,
                    thumb: classes.timeFilterThumb,
                    mark: classes.timeFilterMark,
                    markLabel: classes.timeFilterMarkLabel,
                    valueLabel: classes.timeFilterValueLabel,
                }}
                defaultValue={parseInt(
                    (+selectedDate - +selectedDate.startOf('day')) / (1000 * 60)
                )}
                aria-labelledby="discrete-slider-always"
                step={1}
                min={0}
                max={1439}
                marks={[
                    { value: 0, label: '00:00' },
                    { value: 720, label: '12:00' },
                    { value: 1439, label: '23:59' },
                ]}
                valueLabelDisplay="on"
                valueLabelFormat={(val, index) => {
                    const d = DateTime.local()
                        .startOf('day')
                        .plus({ minutes: val });
                    return d.toFormat('HH:mm');
                }}
                onChangeCommitted={(event, value) => {
                    setSelectedDate(
                        selectedDate.startOf('day').plus({ minutes: value })
                    );
                }}
            />
        </div>
    );
}

/** The time filter bar (where you can choose which time to jump to on the graph) */
function TimeFilter(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const timestamp = props.log
        ? DateTime.fromMillis(props.log.timestamp)
        : props.startDate;

    return (
        <div
            className={classes.timeFilterContainer}
            onClick={() => {
                setIsOpen(!isOpen);
            }}
        >
            <div className={classes.timeField}>
                <div className={classes.timeValue}>
                    {props.log
                        ? `${recipeStatusToDescription(props.log, true)} (${
                              props.log.recipeStatus
                          })`
                        : 'N/A'}
                </div>
                <div className={classes.timeLabel}>Status</div>
            </div>
            <div className={classes.timeField}>
                <div className={classes.timeValue}>
                    {timestamp.toFormat('HH:mm:ss')}
                </div>
                <div className={classes.timeLabel}>Time</div>
            </div>
            <div className={classes.timeField}>
                <div className={classes.timeValue}>
                    {timestamp.toFormat('dd MMM yyyy')}
                </div>
                <div className={classes.timeLabel}>Date</div>
            </div>
            <img
                className={classes.timeUpIcon}
                src={isOpen ? upIcon : downIcon}
                alt={'Close/Open'}
            />
            {isOpen && (
                <ExpandedTimeFilter
                    startDate={props.startDate}
                    onClose={(date) => {
                        // Close the expanded date dialog and set the selected date
                        props.onDateSelected(date);
                        setIsOpen(false);
                    }}
                />
            )}
        </div>
    );
}

/** The top bar (containing the time filter and params filters) */
function TopBar(props) {
    const classes = useStyles();

    return (
        <div className={classes.topBarContainer}>
            <TimeFilter
                log={props.log}
                startDate={props.startDate}
                onDateSelected={props.onDateSelected}
            />
            <ParamFilters
                log={props.log}
                filterParams={props.filterParams}
                onToggleParam={props.onToggleParam}
            />
        </div>
    );
}

/** The graph itself */
function Graph(props) {
    const classes = useStyles();
    const canvas = useRef(null);
    const [chart, setChart] = useState(null);

    useEffect(() => {
        if (chart !== null) {
            chart.destroy();
        }
        if (canvas.current === null) {
            return;
        }

        const context = canvas.current.getContext('2d');
        const newChart = new Chart(context, {
            type: 'hoverLine',
            data: props.stats,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                    intersect: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: true,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                        },
                    ],
                },
                onHover: (event) => {
                    // Update parent component with current time being hovered on (will update title)
                    if (event.type === 'mousemove') {
                        const items = newChart.getElementsAtXAxis(event);
                        if (items && items.length > 0) {
                            const columnIndex = items[0]._index;
                            props.onSelectedLog(props.stats.logs[columnIndex]);
                            return;
                        }
                    }

                    // Went outside the graph
                    //props.onSelectedLog(null);
                },
            },
        });

        setChart(newChart);

        return () => {
            if (chart !== null) {
                chart.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stats]);

    if (props.loading) {
        return (
            <div className={classes.graphContainer}>
                <LoadingOverlay loading={true} />
            </div>
        );
    }

    return (
        <div className={classes.graphContainer}>
            <canvas ref={canvas} className={classes.canvas} />
        </div>
    );
}

/** The bottom bar - arrows + buttons for switching zoom (1/5/15/60 min) */
function BottomBar(props) {
    const classes = useStyles();

    const now = DateTime.local();
    const endOfCurrentTimeWindow = props.startDate.plus({
        minutes: props.zoomType,
    });

    return (
        <div className={classes.bottomBarContainer}>
            <div className={classes.leftRightContainer}>
                <IconButton
                    onClick={props.onPrevPeriod}
                    className={clsx(
                        classes.arrowButton,
                        classes.leftArrowButton
                    )}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={leftArrow}
                        alt={'Previous Period'}
                    />
                </IconButton>
                <IconButton
                    onClick={props.onNextPeriod}
                    className={classes.arrowButton}
                    disabled={endOfCurrentTimeWindow > now}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={rightArrow}
                        alt={'Next Period'}
                    />
                </IconButton>
            </div>
            <div className={classes.zoomContainer}>
                {ZOOM_TYPES.map((z) => (
                    <ToggleButton
                        key={z}
                        className={classes.zoomTypeButton}
                        onChange={() => props.onZoomTypeChanged(z)}
                        selected={props.zoomType === z}
                    >
                        {z} Min
                    </ToggleButton>
                ))}
            </div>
        </div>
    );
}

/** Calculate stats for the graph view - showing the values of different parameters in the specified time span */
function calculateStats(logs, filterParams) {
    const labels = [];
    const dataByParam = {};
    const finalLogs = [];

    logs.map((log) => {
        const time = DateTime.fromMillis(log.timestamp);
        const timeKey = time.toFormat('HH:mm:ss');

        if (labels.length > 0 && labels[labels.length - 1] === timeKey) {
            // Sometimes two consecutive logs with the same timestamp appear - ignore this second one
            return null;
        }

        finalLogs.push(log);
        labels.push(timeKey);

        // Calculate values for different params at this point of time (only for visible params)
        filterParams
            .filter((p) => p.visible)
            .map((param) => {
                const value = param.calculate(log);

                if (!(param.name in dataByParam)) {
                    dataByParam[param.name] = {
                        label: param.name,
                        fill: false,
                        borderColor: param.color,
                        data: [],
                    };
                }

                dataByParam[param.name].data.push(value || 0);

                return null;
            });

        return null;
    });

    return {
        labels: labels,
        datasets: Object.values(dataByParam),
        logs: finalLogs,
    };
}

/** Parameters graph view */
export default function ParametersGraph(props) {
    const classes = useStyles();
    const [zoomType, setZoomType] = useState(1); // Number of minutes
    const [startDate, setStartDate] = useState(
        DateTime.local().minus({ minutes: zoomType }) // Default value should start one minute before current
    );
    const [endDate, setEndDate] = useState(
        startDate.plus({ minutes: zoomType })
    );
    const [filterParams, setFilterParams] = useState(
        FILTER_PARAMS.map((p) => ({ ...p, visible: true }))
    );
    const [currentLog, setCurrentLog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState(null);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        setLoading(true);

        // Start/end dates changed - re-call API to get raw logs
        const params = `start_date=${startDate.toMillis()}&end_date=${endDate.toMillis()}`;

        gApiClient
            .callApi(
                `admin/getMachineLogs/${props.machineId}?${params}`,
                'GET',
                {},
                {}
            )
            .then((response) => {
                setLoading(false);

                // Reverse logs (show them from oldest to newest)
                const newLogs = response.data.reverse();
                setLogs(newLogs);

                // Prepare stats for the graph view
                setStats(calculateStats(newLogs, filterParams));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, props.machineId]);

    useEffect(() => {
        // Filter params changed (user changed visibility of one of the filters)
        setStats(calculateStats(logs, filterParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);

    useEffect(() => {
        // Zoom type changed
        calculateStartAndEndDates(startDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomType]);

    const calculateStartAndEndDates = (newStartDate) => {
        // Set new start date, and calculate new end date based on current zoom level
        setStartDate(newStartDate);
        setEndDate(newStartDate.plus({ minutes: zoomType }));
        setCurrentLog(null);
    };

    const onNextPeriod = () => {
        // Move forward by a third of the current zoom level
        calculateStartAndEndDates(
            startDate.plus({ minutes: Math.ceil(zoomType / 3) })
        );
    };
    const onPrevPeriod = () => {
        // Move backward by a third of the current zoom level
        calculateStartAndEndDates(
            startDate.minus({ minutes: Math.ceil(zoomType / 3) })
        );
    };

    const onToggleParam = (param) => {
        // Toggle the filter param's visibility
        const newParams = clone(filterParams);
        newParams.map((p) => {
            if (p.name !== param.name) return null;

            p.visible = !p.visible;

            return null;
        });

        setFilterParams(newParams);
    };

    const zoomTypeChanged = (newZoom) => {
        if (startDate.plus({ minutes: newZoom }) > DateTime.local()) {
            // Move the beginning of the time window to accommodate for the new zoom type
            setStartDate(DateTime.local().minus({ minutes: newZoom }));
        }

        setZoomType(newZoom);
    };

    return (
        <div className={classes.content}>
            <TopBar
                onToggleParam={onToggleParam}
                filterParams={filterParams}
                log={currentLog}
                startDate={startDate}
                onDateSelected={(d) => {
                    calculateStartAndEndDates(d);
                }}
            />
            <Graph
                loading={loading}
                stats={stats}
                onSelectedLog={setCurrentLog}
            />
            <BottomBar
                startDate={startDate}
                zoomType={zoomType}
                onZoomTypeChanged={zoomTypeChanged}
                onPrevPeriod={onPrevPeriod}
                onNextPeriod={onNextPeriod}
            />
        </div>
    );
}
