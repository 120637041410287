import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Authentication from '../../../modules/Authentication';
import InnerWindow from '../../../components/InnerWindow';
import clsx from 'clsx';
import DateAndTimeField from '../../../components/DateAndTimeField';
import TextField from '../../../components/TextField';
import Select from '../../../components/Select';
import formIcon from '../../../res/images/document.svg';
import backIcon from '../../../res/images/back_circle.svg';
import leftIcon from '../../../res/images/left_circle.svg';
import forwardIcon from '../../../res/images/right_circle.svg';
import siteIcon from '../../../res/images/store.svg';
import saveIcon from '../../../res/images/save.svg';
import checkmarkIcon from '../../../res/images/big_check_mark.svg';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../../res/images/close_circle_black.svg';
import commandFailedIcon from '../../../res/images/machine_error.svg';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';

const LABEL_FIELD_WIDTH = '88px';

const FORM_TYPE_MAINTENANCE = 'maintenance';
const FORM_TYPE_DEEP_CLEAN = 'deep_clean';
const FORM_TYPE_MACHINE_ATTRIBUTION = 'machine_attribution';

const FORM_TYPES = [
    { value: FORM_TYPE_MACHINE_ATTRIBUTION, label: 'Machine Attribution' },
];

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 0,
        flexGrow: 1,
    },
    field: {
        marginBottom: '7px',
    },
    topFields: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'fit-content',
        minHeight: 'fit-content',
    },
    topFieldLeftRow: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: 'fit-content',
    },
    topFieldRightRow: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: '50px',
        height: 'fit-content',
    },
    separator: {
        width: '100%',
        height: '1px',
        minHeight: '1px',
        opacity: 0.2,
        backgroundColor: '#7d90aa',
        marginTop: '24px',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '15px',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formColumn: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formLabelMandatory: {
        color: '#a21c36',
        fontSize: '7px',
        textTransform: 'uppercase',
    },
    formField: {
        width: '50%',
    },
    alignRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    disabledFieldLabel: {
        opacity: 0.4,
    },
    changeReason: {
        width: '100%',
        minHeight: '75px',
        height: 'auto',
    },
    saveButton: {
        width: '222px',
        marginTop: '37px',
        marginBottom: '37px',
    },
    confirmContainer: {
        position: 'absolute',
        zIndex: 1300,
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        background: '#ffffff73',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    confirmBottom: {
        height: '94px',
        backgroundColor: '#7e8cac',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    confirmLabel: {
        color: '#ffffff',
        textTransform: 'uppercase',
        fontSize: '16px',
        marginBottom: '7px',
    },
    confirmButtons: {
        display: 'flex',
        flexDirection: 'row',
    },
    confirmBack: {
        width: '170px',
    },
    confirmSubmit: {
        width: '170px',
        marginLeft: '40px',
    },
    formSubmittedContainer: {
        position: 'absolute',
        zIndex: 1300,
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        backgroundColor: '#00877b',
        backgroundImage: `url(${checkmarkIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'calc(100% - 240px)',
        backgroundPosition: 'center center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formSubmittedIcon: {},
    formSubmittedLabel: {
        color: '#ffffff',
        fontSize: '24px',
        textTransform: 'uppercase',
        fontWeight: 300,
    },
    versionSelect: {
        width: '100%',
        marginRight: '52px',
    },
    noPermissionsContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '99999',
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexGrow: 1,
        backgroundImage: 'linear-gradient(149deg, #97174b, #e73942 114%)',
    },
    commandStatusLabel: {
        textTransform: 'uppercase',
        marginTop: '17px',
        fontSize: '18px',
        textAlign: 'center',
        color: '#ffffff',
    },
    commandStatusIcon: {
        marginTop: '44px',
    },
    commandFlow: {
        display: 'flex',
        flexDirection: 'column',
    },
    closeCommandStatus: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        padding: '3px',
    },
    closeCommandStatusImage: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
});

const gApiClient = Authentication.getAPIClient();

function MaintenanceForm(props) {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        internalCleaning: false,
        tightScrews: false,
        chainStress: false,
        replaceInternalTube: false,
        fwUpdate: false,
        fwVersion: null,
        htmlUpdate: false,
        htmlVersion: null,
        androidUpdate: false,
        androidVersion: null,
        checkCoolingTime: false,
        coolingTime: 0,
        fullKitPreparation: false,
        notes: '',
    });

    props.onFormReady(
        (!formData.fwUpdate || formData.fwVersion) &&
            (!formData.htmlUpdate || formData.htmlVersion) &&
            (!formData.androidUpdate || formData.androidVersion) &&
            (!formData.checkCoolingTime || formData.coolingTime > 0)
    );

    const onFormData = props.onFormData;
    useEffect(() => {
        onFormData(formData);
    }, [formData, onFormData]);

    return (
        <div className={classes.form}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>Internal Cleaning</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={formData.internalCleaning}
                        onChange={(v) => {
                            setFormData({ ...formData, internalCleaning: v });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Tighten Screws</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={formData.tightScrews}
                        onChange={(v) => {
                            setFormData({ ...formData, tightScrews: v });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Chain Stress</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={formData.chainStress}
                        onChange={(v) => {
                            setFormData({ ...formData, chainStress: v });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Replace Internal Tube</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={formData.replaceInternalTube}
                        onChange={(v) => {
                            setFormData({
                                ...formData,
                                replaceInternalTube: v,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>FW Update</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    {formData.fwUpdate && (
                        <TextField
                            className={classes.versionSelect}
                            placeholder={'Version'}
                            defaultValue={
                                formData.fwVersion
                                    ? { value: formData.fwVersion }
                                    : ''
                            }
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    fwVersion: value,
                                });
                            }}
                        />
                    )}

                    <Checkbox
                        checked={formData.fwUpdate}
                        onChange={(v) => {
                            setFormData({
                                ...formData,
                                fwUpdate: v,
                                fwVersion: !v ? null : formData.fwVersion,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>HTML Update</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    {formData.htmlUpdate && (
                        <TextField
                            className={classes.versionSelect}
                            placeholder={'Version'}
                            defaultValue={
                                formData.htmlVersion
                                    ? { value: formData.htmlVersion }
                                    : ''
                            }
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    htmlVersion: value,
                                });
                            }}
                        />
                    )}

                    <Checkbox
                        checked={formData.htmlUpdate}
                        onChange={(v) => {
                            setFormData({
                                ...formData,
                                htmlUpdate: v,
                                htmlVersion: !v ? null : formData.htmlVersion,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Android Update</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    {formData.androidUpdate && (
                        <TextField
                            className={classes.versionSelect}
                            placeholder={'Version'}
                            defaultValue={
                                formData.androidVersion
                                    ? { value: formData.androidVersion }
                                    : ''
                            }
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    androidVersion: value,
                                });
                            }}
                        />
                    )}

                    <Checkbox
                        checked={formData.androidUpdate}
                        onChange={(v) => {
                            setFormData({
                                ...formData,
                                androidUpdate: v,
                                androidVersion: !v
                                    ? null
                                    : formData.androidVersion,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Check Cooling Time</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    {formData.checkCoolingTime && (
                        <DateAndTimeField
                            className={classes.versionSelect}
                            label="Time"
                            type="time_period"
                            value={formData.coolingTime}
                            onSetValue={(value) => {
                                setFormData({
                                    ...formData,
                                    coolingTime: value,
                                });
                            }}
                            leftLabelWidth={'62px'}
                            container={() => {
                                return props.formsWindow.current;
                            }}
                        />
                    )}

                    <Checkbox
                        checked={formData.checkCoolingTime}
                        onChange={(v) => {
                            setFormData({
                                ...formData,
                                checkCoolingTime: v,
                                coolingTime: !v ? 0 : formData.coolingTime,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Full Kit Preparation</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={formData.fullKitPreparation}
                        onChange={(v) => {
                            setFormData({ ...formData, fullKitPreparation: v });
                        }}
                    />
                </div>
            </div>

            <div className={classes.separator} />

            <div className={classes.formRow}>
                <TextField
                    topLabel={`${props.machine.alias} - Maintenance`}
                    multiline
                    rows={3}
                    maxRows={3}
                    className={classes.changeReason}
                    onChange={(v) => {
                        setFormData({ ...formData, notes: v });
                    }}
                    value={formData.notes}
                />
            </div>
        </div>
    );
}

function DeepCleanForm(props) {
    const classes = useStyles();
    const [internalCleaning, setInternalCleaning] = useState(false);
    const [tubeReplacement, setTubeReplacement] = useState(false);
    const [
        suckingTubeInternalCleaning,
        setSuckingTubeInternalCleaning,
    ] = useState(false);
    const [notes, setNotes] = useState('');

    props.onFormReady(true);

    const onFormData = props.onFormData;
    useEffect(() => {
        onFormData({
            notes,
            suckingTubeInternalCleaning,
            tubeReplacement,
            internalCleaning,
        });
    }, [
        notes,
        suckingTubeInternalCleaning,
        tubeReplacement,
        internalCleaning,
        onFormData,
    ]);

    return (
        <div className={classes.form}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>Internal Cleaning</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={internalCleaning}
                        onChange={setInternalCleaning}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>Tube Replacement</div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={tubeReplacement}
                        onChange={setTubeReplacement}
                    />
                </div>
            </div>

            <div className={classes.formRow}>
                <div className={classes.formLabel}>
                    Sucking Tube Internal Cleaning
                </div>
                <div className={clsx(classes.formField, classes.alignRight)}>
                    <Checkbox
                        checked={suckingTubeInternalCleaning}
                        onChange={setSuckingTubeInternalCleaning}
                    />
                </div>
            </div>

            <div className={classes.separator} />

            <div className={classes.formRow}>
                <TextField
                    topLabel={`${props.machine.alias} - Deep Clean`}
                    multiline
                    rows={3}
                    maxRows={3}
                    className={classes.changeReason}
                    onChange={setNotes}
                    value={notes}
                />
            </div>
        </div>
    );
}

function AttributionForm(props) {
    const classes = useStyles();
    const [client, setClient] = useState(null);
    const [site, setSite] = useState(null);
    const [cluster, setCluster] = useState(null);
    const [changeReason, setChangeReason] = useState('');
    const clients = props.clients
        ? props.clients
              .map((x) => ({
                  label: x.name,
                  value: x._id,
                  children: x.children,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    const sites = client
        ? client.children
              .map((x) => ({
                  label: x.name,
                  value: x._id,
                  children: x.children,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    const clusters = site
        ? site.children
              .map((x) => ({ label: x.name, value: x._id }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];

    props.onFormReady(client && site && cluster && changeReason.length > 0);

    const onFormData = props.onFormData;
    useEffect(() => {
        onFormData({
            newClusterId: cluster ? cluster.value : null,
            removalReason: changeReason,
        });
    }, [cluster, changeReason, onFormData]);

    return (
        <div className={classes.form}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>
                    <span>*Select Client:</span>
                    <span
                        style={{
                            display: !props.checkForm || client ? 'none' : null,
                        }}
                        className={classes.formLabelMandatory}
                    >
                        *Please choose a client
                    </span>
                </div>
                <Select
                    className={classes.formField}
                    items={clients}
                    value={client}
                    onChange={(value) => {
                        setCluster(null);
                        setSite(null);
                        setClient(value);
                    }}
                    rightIcon={siteIcon}
                    hint={'Type Client Here'}
                    autoComplete
                />
            </div>

            <div className={classes.formRow}>
                <div
                    className={clsx(
                        classes.formLabel,
                        client ? null : classes.disabledFieldLabel
                    )}
                >
                    <span>*Select Site:</span>
                    <span
                        style={{
                            display: !props.checkForm || site ? 'none' : null,
                        }}
                        className={classes.formLabelMandatory}
                    >
                        *Please choose a site
                    </span>
                </div>
                <Select
                    className={classes.formField}
                    disabled={client ? false : true}
                    items={sites}
                    value={site}
                    onChange={(value) => {
                        setCluster(null);
                        setSite(value);
                    }}
                    rightIcon={siteIcon}
                    hint={'Type Site Here'}
                    autoComplete
                />
            </div>

            <div className={classes.formRow}>
                <div
                    className={clsx(
                        classes.formLabel,
                        site ? null : classes.disabledFieldLabel
                    )}
                >
                    <span>*Select Cluster:</span>
                    <span
                        style={{
                            display:
                                !props.checkForm || cluster ? 'none' : null,
                        }}
                        className={classes.formLabelMandatory}
                    >
                        *Please choose a cluster
                    </span>
                </div>
                <Select
                    className={classes.formField}
                    disabled={site ? false : true}
                    items={clusters}
                    value={cluster}
                    onChange={setCluster}
                    rightIcon={siteIcon}
                    hint={'Type Cluster Here'}
                    autoComplete
                />
            </div>

            <div className={classes.separator} />

            <div className={clsx(classes.formRow, classes.formColumn)}>
                <TextField
                    topLabel={`${props.machine.alias} - Reason for change:`}
                    multiline
                    rows={3}
                    maxRows={3}
                    className={classes.changeReason}
                    onChange={setChangeReason}
                    value={changeReason}
                />
                <span
                    style={{
                        display:
                            !props.checkForm || changeReason.length > 0
                                ? 'none'
                                : null,
                    }}
                    className={classes.formLabelMandatory}
                >
                    *Please fill this section
                </span>
            </div>
        </div>
    );
}

async function submitForm(date, machine, formType, params) {
    let type;

    if (formType === FORM_TYPE_DEEP_CLEAN) {
        type = 'deep_clean';
    } else if (formType === FORM_TYPE_MAINTENANCE) {
        type = 'maintenance';
    }

    const requestParams = {
        event_type: type,
        machine_id: machine._id,
        timestamp: parseInt(
            date.minus({ minutes: new Date().getTimezoneOffset() }).toSeconds()
        ),
        params: params,
    };

    console.log(requestParams);

    return gApiClient.callApi('admin/addEvent', 'POST', {}, requestParams);
}

async function submitMachineLocation(
    user,
    date,
    machine,
    clusterId,
    removalReason
) {
    const params = {
        machine_id: machine._id,
        cluster_id: clusterId,
        removal_reason: removalReason,
        add_date: parseInt(
            date.minus({ minutes: new Date().getTimezoneOffset() }).toSeconds()
        ),
        added_by_user: user._id,
        removed_by_user: user._id,
    };

    console.log(params);

    return gApiClient.callApi(
        'admin/addNewMachineLocation',
        'POST',
        {},
        params
    );
}

/** Single machine - forms inner window (changing machine attribution, deep clean, etc.) */
export default function MachineForms(props) {
    const classes = useStyles();
    const machine = props.machine;
    const [loading, setLoading] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formDate, setFormDate] = useState(DateTime.local());
    const [formType, setFormType] = useState(null);
    const [clients, setClients] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const formsWindow = useRef(null);
    const [formData, setFormData] = useState({});
    const [checkForm, setCheckForm] = useState(false);
    const userDetails = Authentication.getUserDetails();
    const [noPermissions, setNoPermissions] = useState(false);
    const hasLocationPermissions =
        userDetails.is_admin || userDetails.edit_machine_location;
    const formEnabled =
        formType && formType.value === FORM_TYPE_MAINTENANCE && formData
            ? (formData.androidUpdate ||
                  formData.chainStress ||
                  formData.checkCoolingTime ||
                  formData.fullKitPreparation ||
                  formData.fwUpdate ||
                  formData.htmlUpdate ||
                  formData.internalCleaning ||
                  formData.replaceInternalTube ||
                  formData.tightScrews) &&
              formData.notes.length > 0
            : formData.newClusterId;

    useEffect(() => {
        gApiClient
            .callApi(
                'admin/getFilterMachines?no_latest_status=1',
                'GET',
                {},
                {}
            )
            .then((response) => {
                const items = response.data;
                setLoading(false);
                setClients(items);
            });
    }, []);

    if (!machine) {
        // No machine loaded yet
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={''}
                loading={true}
            ></InnerWindow>
        );
    }

    const onSubmitForm = async () => {
        setShowConfirm(false);
        setLoading(true);

        if (
            formType.value === FORM_TYPE_DEEP_CLEAN ||
            formType.value === FORM_TYPE_MAINTENANCE
        ) {
            await submitForm(formDate, machine, formType.value, formData);
        } else if (formType.value === FORM_TYPE_MACHINE_ATTRIBUTION) {
            await submitMachineLocation(
                userDetails,
                formDate,
                machine,
                formData.newClusterId,
                formData.removalReason
            );

            // Notify parent that location has changed for this machine - need to refresh
            props.onLocationChanged();
        }

        setLoading(false);
        setFormSubmitted(true);

        // Hide "Form submitted successfully" after a few seconds
        setTimeout(() => {
            setFormType(null);
            setFormSubmitted(false);
        }, 3000);
    };

    const checkSelectedFormType = (ft) => {
        if (ft.value === FORM_TYPE_MACHINE_ATTRIBUTION) {
            // See if the user has permissions to change a machine's location
            if (!hasLocationPermissions) {
                setNoPermissions(true);
                return;
            }
        }

        setFormType(ft);
    };

    // Find latest cluster
    const latestCluster = machine.previous_clusters[0];

    let formName = 'Forms';

    if (formType !== null) {
        formName = formType.label;
    }

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={`${machine.alias} / ${formName}`}
            loading={loading}
            icon={formType && !loading ? backIcon : null}
            onIconClick={() => {
                setFormType(null);
                setShowConfirm(false);
                setFormSubmitted(false);
            }}
        >
            {noPermissions && (
                <div className={classes.noPermissionsContainer}>
                    <IconButton
                        className={classes.closeCommandStatus}
                        onClick={() => {
                            setNoPermissions(false);
                        }}
                    >
                        <img
                            className={classes.closeCommandStatusImage}
                            src={closeIcon}
                            alt="Close"
                        />
                    </IconButton>

                    <div className={classes.commandFlow}>
                        <img
                            className={classes.commandStatusIcon}
                            alt="No permissions"
                            src={commandFailedIcon}
                        />

                        <div className={classes.commandStatusLabel}>
                            Sorry, you have no permissions to change machine
                            location
                        </div>
                    </div>
                </div>
            )}
            <div
                className={classes.content}
                ref={formsWindow}
                style={{
                    overflow: showConfirm || formSubmitted ? 'hidden' : null,
                }}
            >
                <div className={classes.topFields}>
                    <div className={classes.topFieldLeftRow}>
                        <DateAndTimeField
                            className={classes.field}
                            label="Date:"
                            type="date"
                            value={formDate}
                            onSetValue={setFormDate}
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            noFutureDate={true}
                            container={() => {
                                return formsWindow.current;
                            }}
                        />

                        <DateAndTimeField
                            className={classes.field}
                            label="Time:"
                            type="time"
                            value={formDate}
                            onSetValue={setFormDate}
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            noFutureDate={true}
                            container={() => {
                                return formsWindow.current;
                            }}
                        />

                        <TextField
                            className={classes.field}
                            leftLabel="Technician:"
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            defaultValue={userDetails.email}
                            readOnly
                        />
                    </div>
                    <div className={classes.topFieldRightRow}>
                        <TextField
                            className={classes.field}
                            leftLabel="Client:"
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            defaultValue={latestCluster.client_name}
                            readOnly
                        />
                        <TextField
                            className={classes.field}
                            leftLabel="Site:"
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            defaultValue={latestCluster.site_name}
                            readOnly
                        />
                        <TextField
                            className={classes.field}
                            leftLabel="Cluster:"
                            leftLabelWidth={LABEL_FIELD_WIDTH}
                            defaultValue={latestCluster.cluster_name}
                            readOnly
                        />
                    </div>
                </div>

                <div className={classes.separator} />

                <div className={classes.formRow}>
                    <div className={classes.formLabel}>
                        Select an action from the list:
                    </div>
                    <Select
                        className={classes.formField}
                        items={FORM_TYPES}
                        value={formType}
                        onChange={checkSelectedFormType}
                        rightIcon={formIcon}
                        hint={'Click here to select'}
                    />
                </div>

                {formType !== null && <div className={classes.separator} />}

                {formType !== null &&
                    formType.value === FORM_TYPE_MACHINE_ATTRIBUTION && (
                        <AttributionForm
                            clients={clients}
                            machine={machine}
                            onFormReady={setFormReady}
                            onFormData={setFormData}
                            checkForm={checkForm}
                        />
                    )}

                {formType !== null &&
                    formType.value === FORM_TYPE_DEEP_CLEAN && (
                        <DeepCleanForm
                            machine={machine}
                            onFormReady={setFormReady}
                            onFormData={setFormData}
                            checkForm={checkForm}
                        />
                    )}

                {formType !== null &&
                    formType.value === FORM_TYPE_MAINTENANCE && (
                        <MaintenanceForm
                            machine={machine}
                            onFormReady={setFormReady}
                            onFormData={setFormData}
                            formsWindow={formsWindow}
                            checkForm={checkForm}
                        />
                    )}

                {formType !== null && <div className={classes.separator} />}

                {formType !== null && (
                    <Button
                        className={classes.saveButton}
                        label="Save"
                        disabled={!formEnabled}
                        rightIcon={saveIcon}
                        onClick={
                            !formReady
                                ? setCheckForm
                                : () => {
                                      setCheckForm(false);
                                      setShowConfirm(true);
                                  }
                        }
                    />
                )}

                {showConfirm && (
                    <div className={classes.confirmContainer}>
                        <div className={classes.confirmBottom}>
                            <div className={classes.confirmLabel}>
                                Please re-check the form before submitting
                            </div>
                            <div className={classes.confirmButtons}>
                                <Button
                                    className={classes.confirmBack}
                                    label="Back"
                                    leftIcon={leftIcon}
                                    onClick={() => {
                                        setShowConfirm(false);
                                    }}
                                />
                                <Button
                                    className={classes.confirmSubmit}
                                    label="Submit"
                                    rightIcon={forwardIcon}
                                    onClick={onSubmitForm}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {formSubmitted && (
                    <div className={classes.formSubmittedContainer}>
                        <div className={classes.formSubmittedLabel}>
                            Form submitted successfully
                        </div>
                    </div>
                )}
            </div>
        </InnerWindow>
    );
}
