import clone from 'clone';

export const SET_REPORTS_FILTER = 'SET_REPORTS_FILTER';

export function setReportsFilter(value) {
    return {
        type: SET_REPORTS_FILTER,
        value: clone(value),
    };
}
