import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Authentication from '../../../modules/Authentication';
import DateTime from 'luxon/src/datetime';
import forwardIcon from '../../../res/images/right_circle.svg';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import DateAndTimeField from '../../../components/DateAndTimeField';
import MachineList from '../../../components/MachineList';
import {
    findItem,
    findSelectedMachines,
    findSelectedSites,
    getMachineHierarchy,
    markItem,
    SORT_TYPE_ALPHABETICAL,
    sortItems,
} from '../../../modules/MachineUtils';
import clsx from 'clsx';
import clone from 'clone';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import SearchField from '../../../components/SearchField';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAdminCountries,
    setAdminDefaultLogsConfigs,
    setAdminFilterMachines,
} from '../../../store/actions/admin';

const useStyles = makeStyles({
    list: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: '#F9FAFB',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
        height: 'auto !important',
        borderTop: '1px solid #B5BFCC',
    },
    search: {
        width: '100%',
        height: '50px',
        marginBottom: '10px',
        background: '#E1E5ED',
        alignItems: 'center',
        borderBottom: '1px #B5BDCD solid',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    machineItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#F1F3F8',
        borderRight: '1px solid #B5BFCC',
    },
    machineItemLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: '#828FAD',
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        borderBottom: '1px solid #B5BFCC',
        paddingLeft: '8px',
    },
    machineItemSubLabel: {
        fontSize: '10px',
    },
    itemCheckIcon: {
        backgroundColor: '#E1E6ED66',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckedOnIcon: {
        backgroundColor: '#E1E6ED66',
        '&:before': {
            filter: hexColorToCSSFilter('#344269'),
        },
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    machineItemCheckboxContainer: {
        width: '50px',
        minWidth: '50px',
        height: '100%',
        borderBottom: '1px solid #B5BFCC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    successMessage: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'green',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formRowColumn: {
        flexDirection: 'column',
    },
    formRowTitle: {
        marginBottom: '10px',
        textTransform: 'uppercase',
        color: '#586374',
    },
    formSubRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '170px',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    sortButton: {
        marginRight: '10px',
        marginBottom: '10px',
        paddingLeft: '10px',
        minWidth: '93px',
        height: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        border: '1px solid #7D90AA',
        borderRadius: '4px',
        backgroundColor: '#E1E5ED',
        color: '#7D90AA',
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '10px',
        '&:last-of-type': {
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-selected': {
            backgroundColor: '#7D90AA',
            color: '#E1E5ED',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    buttonLabel: {
        color: '#7D90AA',
        textAlign: 'center',
        fontSize: '10px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    clearLabel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    clearLabelText: {
        marginRight: '12px',
    },
    clearIcon: {
        filter: hexColorToCSSFilter('#939FB9'),
        height: '100%',
        width: '24px',
        borderLeft: '#939FB9 1px solid',
    },
});

const gApiClient = Authentication.getAPIClient();

export default function DownloadConsumptionReportForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const [countries, setCountries] = useState(filter.countries);
    const [machines, setMachines] = useState(filter.filterMachines);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedSites, setSelectedSites] = useState([]);
    const formsWindow = useRef(null);
    const [startDate, setStartDate] = useState(
        DateTime.local().set({ hour: 0, minute: 0, second: 0 })
    );
    const [endDate, setEndDate] = useState(
        DateTime.local().set({ hour: 23, minute: 59, second: 59 })
    );
    const [selectedRegions, setSelectedRegions] = useState(new Set([]));
    const [countrySearchText, setCountrySearchText] = useState('');
    const [clientsSearchText, setClientsSearchText] = useState('');
    const disallowFormSubmission = selectedSites.length === 0;

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];

        if (filter.countries.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getFilterCountries',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminCountries(results.data));
                    setCountries(results.data);
                })()
            );
        }

        if (filter.filterMachines.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getFilterMachines?return_all=1',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminFilterMachines(results.data));
                    setMachines(results.data);
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderListItem = (props) => {
        const item = props.item;
        const isMachine = item.previous_clusters;
        const hasParent = item.parent;
        const level = props.level || 0;
        const isCountryList = props.isCountryList;

        let levelWidth = 0;
        if (level === 1) {
            levelWidth = 10;
        } else if (level === 2) {
            levelWidth = 20;
        } else if (level === 3) {
            levelWidth = 30;
        }

        return (
            <div className={classes.machineItemContainer}>
                <div
                    style={{
                        width: '5px',
                        height: '100%',
                        marginLeft: `${levelWidth}px`,
                        borderRight: '1px solid #B5BFCC',
                        backgroundColor: `${level === 2 ? '#B5BFCC' : null}`,
                    }}
                ></div>
                <div className={classes.machineItemLabel}>
                    <div>{isMachine ? item.alias : item.name}</div>
                    {hasParent && (
                        <div className={classes.machineItemSubLabel}>
                            {getMachineHierarchy(item.parent)}
                        </div>
                    )}
                </div>
                <div className={classes.machineItemCheckboxContainer}>
                    <Checkbox
                        checked={item.selected ? true : false}
                        className={classes.itemCheck}
                        iconClassName={classes.itemCheckIcon}
                        checkedIconClassName={clsx(
                            classes.itemCheckedOnIcon,
                            classes.itemCheckIcon
                        )}
                        onChange={(v) => {
                            if (isCountryList) {
                                const countriesCopy = clone(countries);

                                const itemCopy = findItem(
                                    countriesCopy,
                                    item._id
                                );
                                markItem(itemCopy, v);
                                setCountries(countriesCopy);
                                const newRegions = new Set(
                                    findSelectedMachines(countriesCopy).map(
                                        (x) => x._id
                                    )
                                );
                                // If no regions are selected -> make sure no machines are shown
                                setSelectedRegions(
                                    newRegions.size > 0
                                        ? newRegions
                                        : new Set(['*'])
                                );
                            } else {
                                const machinesCopy = clone(machines);

                                const itemCopy = findItem(
                                    machinesCopy,
                                    item._id
                                );
                                markItem(itemCopy, v);
                                setMachines(machinesCopy);
                                setSelectedSites(
                                    findSelectedSites(machinesCopy)
                                );
                            }
                        }}
                    />
                </div>
            </div>
        );
    };

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            await gApiClient.callApi(
                'admin/generateConsumptionReportBySite',
                'POST',
                {},
                {
                    site_ids: selectedSites.map((m) => m._id).join(','),
                    // Also account for local timezone
                    start_date: startDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis(),
                    end_date: endDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis(),
                    minute_offset: -new Date().getTimezoneOffset(),
                }
            );

            setSuccessMessage(`Report will be sent to your email address`);
        } catch (e) {
            console.error(e);
            const message = e.response?.data?.Message || e.message;
            setError(message);
            props.onLoading(false);
            return;
        }

        props.onLoading(false);
    };

    const unmarkAll = () => {
        const machinesCopy = clone(machines);

        machinesCopy.forEach((m) => {
            markItem(m, false);
        });
        setMachines(machinesCopy);
        setSelectedSites(findSelectedSites(machinesCopy));
    };

    const selectAll = () => {
        const machinesCopy = clone(machines);

        machinesCopy
            .filter((m) => m.visible)
            .forEach((m) => {
                markItem(m, true);
            });
        setMachines(machinesCopy);
        setSelectedSites(findSelectedSites(machinesCopy));
    };

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>Start Date/Time</div>
            </div>
            <div className={classes.formRow} style={{ marginTop: 0 }}>
                <DateAndTimeField
                    className={classes.field}
                    label="Date:"
                    type="date"
                    value={startDate}
                    onSetValue={setStartDate}
                    autoConfirm={true}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
                <DateAndTimeField
                    className={classes.field}
                    label="Time:"
                    type="time"
                    value={startDate}
                    onSetValue={setStartDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
            </div>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>End Date/Time</div>
            </div>
            <div className={classes.formRow} style={{ marginTop: 0 }}>
                <DateAndTimeField
                    className={classes.field}
                    label="Date:"
                    type="date"
                    value={endDate}
                    onSetValue={setEndDate}
                    leftLabelWidth={100}
                    autoConfirm={true}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
                <DateAndTimeField
                    className={classes.field}
                    label="Time:"
                    type="time"
                    value={endDate}
                    onSetValue={setEndDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
            </div>

            <div className={clsx(classes.formRow, classes.formRowColumn)}>
                <div className={classes.formRowTitle}>Countries / Regions</div>
                {countries && countries.length > 0 && (
                    <SearchField
                        className={classes.search}
                        placeholder="Search Countries / Regions"
                        onSearch={setCountrySearchText}
                        inputClass={classes.searchFieldInput}
                        iconClass={classes.searchFieldIcon}
                    />
                )}
                <MachineList
                    className={classes.list}
                    items={countries}
                    filter={countrySearchText}
                    customListItem={(props) =>
                        renderListItem({ ...props, isCountryList: true })
                    }
                />
            </div>

            <div className={clsx(classes.formRow, classes.formRowColumn)}>
                <div className={classes.formRowTitle}>Clients</div>
                {machines && machines.length > 0 && (
                    <SearchField
                        className={classes.search}
                        placeholder="Search Clients"
                        onSearch={setClientsSearchText}
                        inputClass={classes.searchFieldInput}
                        iconClass={classes.searchFieldIcon}
                    />
                )}
                <div className={classes.buttonRow}>
                    <Button
                        className={classes.sortButton}
                        labelClass={clsx(
                            classes.buttonLabel,
                            classes.clearLabel
                        )}
                        label={
                            <>
                                <div className={classes.clearLabelText}>
                                    Clear Selection
                                </div>
                            </>
                        }
                        onClick={unmarkAll}
                    />
                    <Button
                        className={classes.sortButton}
                        labelClass={clsx(
                            classes.buttonLabel,
                            classes.clearLabel
                        )}
                        label={
                            <>
                                <div className={classes.clearLabelText}>
                                    Select All
                                </div>
                            </>
                        }
                        onClick={selectAll}
                    />
                </div>
                <MachineList
                    className={classes.list}
                    items={machines}
                    regions={selectedRegions}
                    childrenFilter={(c) => c.client}
                    filter={clientsSearchText}
                    customListItem={renderListItem}
                />
            </div>

            {error && (
                <div
                    className={classes.formRow}
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <div className={classes.error}>{error}</div>
                </div>
            )}

            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.confirmSubmit}
                    label="Get Logs"
                    disabled={disallowFormSubmission}
                    rightIcon={forwardIcon}
                    onClick={onSubmitForm}
                />
            </div>
            {successMessage && (
                <div className={classes.successMessage}>{successMessage}</div>
            )}
        </div>
    );
}
