import iconReady from '../../res/images/check_mark_circle_green.svg';
import DateTime from 'luxon/src/datetime';
import iconLocked from '../../res/images/lock_circle_yellow.svg';
import iconOffline from '../../res/images/turn_on_circle.svg';
import iconOperationRequired from '../../res/images/exclamation_mark_rectangle_blue.svg';
import iconGroupReady from '../../res/images/machine_group_green.svg';
import iconGroupOffline from '../../res/images/machine_group_yellow.svg';
import iconGroupError from '../../res/images/machine_group_red.svg';
import iconError from '../../res/images/error_triangle.svg';
import { MAX_MACHINE_TIMEOUT } from '../../modules/MachineUtils';

// e.g. Offline, locked, ready
export const TYPE_STATUS = 'status';
// e.g. Long cooling time, high motor current
export const TYPE_WARNING = 'warning';
// e.g. Maintenance required, long clean required
export const TYPE_OPERATION_REQUIRED = 'operation_required';

/** Possible status groups - each dictionary item here has:
 *     * title - display value
 *     * icon - display icon (on the map and other parts of the screens)
 *     * type - is it a machine status, or a warning / operation required? (see TYPE_ constants)
 *     * groupIcon - (for TYPE_STATUS only) if a cluster (group) of machines - display with this icon
 *     * priority - (for TYPE_STATUS only) if a cluster (group) of machines - used to decide which group icon to display -
 *                   since there are multiple machines with different statuses, we use the priority to determine the icon to use.
 *                   If a status with a higher priority (=larger number) is found among the child machines, we use its groupIcon.
 *     * checkMachine - function that receives a machine object, and returns true/false (whether or not it belongs to that status group).
 *
 * Notes:
 *     * Each machine is checked against these groups (checkMachine function) - a single machine can appear under multiple groups.
 *     * Statuses are displayed in the UI according to the order they appear in this array.
 */
export const STATUS_GROUPS = [
    {
        title: 'Ready',
        icon: iconReady,
        groupIcon: iconGroupReady,
        priority: 1,
        type: TYPE_STATUS,
        checkMachine: (m) =>
            [
                'S',
                'L',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '0',
                'P',
                'F',
            ].includes(m.latest_status) &&
            m.latest_timestamp >= DateTime.local() - MAX_MACHINE_TIMEOUT, // Must not be disconnected
    },
    {
        title: 'Disconnected',
        icon: iconOffline,
        groupIcon: iconGroupOffline,
        priority: 4,
        type: TYPE_STATUS,
        checkMachine: (m) =>
            m.latest_timestamp < DateTime.local() - MAX_MACHINE_TIMEOUT,
    },
    {
        title: 'Fault',
        icon: iconError,
        groupIcon: iconGroupError,
        priority: 3,
        type: TYPE_STATUS,
        checkMachine: (m) => m.latest_is_cs,
    },
    {
        title: 'Locked',
        icon: iconLocked,
        groupIcon: iconGroupReady,
        priority: 2,
        type: TYPE_STATUS,
        checkMachine: (m) =>
            m.latest_status === 'K' &&
            !m.latest_is_cs &&
            m.latest_timestamp >= DateTime.local() - MAX_MACHINE_TIMEOUT, // Must not be disconnected
    },
];
