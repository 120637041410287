import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import SearchField from '../../components/SearchField';
import Authentication from '../../modules/Authentication';
import MachineList from '../../components/MachineList';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSideMenuState,
    setSingleMachine,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_MACHINES,
    TOGGLE_BAR_STATE_DASHBOARD,
} from '../../store/actions/ui';
import MachinesToggleBar from './MachinesToggleBar';
import machineIcon from '../../res/images/machine.svg';
import { IconButton } from '@material-ui/core';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import {
    calculateMachineCount,
    findItem,
    getAllChildMachines,
    recipeStatusToDescription,
} from '../../modules/MachineUtils';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { setSearchMachines } from '../../store/actions/search';
import { useInterval } from '../../modules/HookUtils';
import clone from 'clone';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    window: {
        width: '414px',
        background: '#00B09E',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchHeader: {
        background: 'linear-gradient(to right, #027c76, #00a89d)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    list: {
        width: '100%',
        flexGrow: 1,
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemMachineCount: {
        color: '#ffffff',
        opacity: 0.8,
        fontSize: '16px',
        fontWeight: 300,
        marginRight: '5px',
    },
    itemMachineIcon: {
        width: '31px',
        height: '31px',
        filter: hexColorToCSSFilter('#8BD4CF'),
    },
    openMachineIcon: {
        padding: '8px',
    },
    itemMachineState: {
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 300,
        textTransform: 'uppercase',
        marginRight: '8px',
        flexGrow: 1,
        textAlign: 'right',
    },
});

function updateMachine(machine, newMachine) {
    let changed = false;

    if (
        machine.latest_recipe_is_cleaning !==
        newMachine.latest_recipe_is_cleaning
    ) {
        machine.latest_recipe_is_cleaning =
            newMachine.latest_recipe_is_cleaning;
        changed = true;
    }
    if (machine.latest_status !== newMachine.latest_status) {
        machine.latest_status = newMachine.latest_status;
        changed = true;
    }
    if (machine.latest_timestamp !== newMachine.latest_timestamp) {
        machine.latest_timestamp = newMachine.latest_timestamp;
        // Don't count this as a changed machine
    }

    return changed;
}

/** Updates each machine's status in the original clients structure */
function updateMachinesStatus(clients, newClients) {
    const clientsCopy = clone(clients);
    let changed = false;

    let newMachines = [];
    newClients.forEach((c) => {
        newMachines = newMachines.concat(getAllChildMachines(c));
    });

    newMachines.forEach((m) => {
        // Update the machine's status
        const matchingMachine = findItem(clientsCopy, m._id);
        if (matchingMachine) {
            const prevChanged = changed;
            const prevStatus = matchingMachine.latest_status;
            changed = updateMachine(matchingMachine, m) || changed;
            if (changed && !prevChanged) {
                console.log(
                    'Status Changed',
                    prevStatus,
                    m.latest_status,
                    m.alias
                );
            }
        }
    });

    return [clientsCopy, changed];
}

/** The search single machine screen */
export default function SearchMachine() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.search);
    const [searchText, setSearchText] = useState('');
    const items = filter.machines;
    const [loading, setLoading] = useState(false);
    const [itemsExpanded, setItemsExpanded] = useState(false);

    useEffect(() => {
        dispatch(setSingleMachine(null));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_DASHBOARD));
        dispatch(setToggleBarComponent(MachinesToggleBar));
        dispatch(setSideMenuState(SIDE_MENU_STATE_MACHINES));

        if (items.length === 0) {
            setLoading(true);

            gApiClient
                .callApi(
                    'admin/getFilterMachines?no_latest_status=1',
                    'GET',
                    {},
                    {}
                )
                .then((response) => {
                    const items = response.data;
                    items.map((item) => calculateMachineCount(item));

                    // Add client ID
                    items.forEach((c) => {
                        if (c.internal_id) {
                            c.name = `${c.name} (${c.internal_id})`;
                        }
                    });

                    setLoading(false);
                    dispatch(setSearchMachines(items));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshStats = useCallback(() => {
        console.log('SearchMachine - Refreshing stats2');

        if (itemsExpanded) {
            return;
        }

        gApiClient
            .callApi('admin/getFilterMachines', 'GET', {}, {})
            .then((response) => {
                const items = response.data;

                const [newClients, changed] = updateMachinesStatus(
                    filter.machines,
                    items
                );

                if (changed || filter.machines.length === 0) {
                    items.map((item) => calculateMachineCount(item));

                    // Add client ID
                    items.forEach((c) => {
                        if (c.internal_id) {
                            c.name = `${c.name} (${c.internal_id})`;
                        }
                    });

                    dispatch(setSearchMachines(items));
                }
            });
    });

    // Load latest machine stats every 10 seconds
    useInterval(() => {
        refreshStats();
    }, 10000);

    useEffect(() => {
        refreshStats();
    }, [refreshStats]);

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new tab)
        window.open(`/machines/${machineId}/dashboard`, '_blank');
    };

    const renderRightPart = (item) => {
        const isMachine = item.previous_clusters;
        const machineCount = item.machineCount;

        if (item.expanded) {
            setItemsExpanded(true);
        }

        const machineState = isMachine
            ? recipeStatusToDescription({
                  recipeStatus: item.latest_status,
                  isCleaningRecipe: item.latest_recipe_is_cleaning,
                  timestamp: item.latest_timestamp,
              })
            : null;

        if (!isMachine) {
            return (
                <div className={classes.itemRightSide}>
                    <div className={classes.itemMachineCount}>
                        {machineCount}
                    </div>
                    <img
                        src={machineIcon}
                        className={classes.itemMachineIcon}
                        alt="Machine Count"
                    />
                </div>
            );
        } else {
            return (
                <div className={classes.itemRightSide}>
                    <div className={classes.itemMachineState}>
                        {machineState}
                    </div>
                    <IconButton
                        onClick={() => {
                            onOpenMachine(item._id);
                        }}
                        className={classes.openMachineIcon}
                    >
                        <img src={openMachineIcon} alt="Open Machine" />
                    </IconButton>
                </div>
            );
        }
    };

    return (
        <InnerWindow
            className={classes.window}
            title={'Search SG Machine'}
            loading={loading}
        >
            <div className={classes.content}>
                <div className={classes.searchHeader}>
                    <SearchField
                        className={classes.search}
                        placeholder="Search"
                        onSearch={setSearchText}
                    />
                </div>
                <MachineList
                    className={classes.list}
                    items={items}
                    filter={searchText}
                    renderRightPart={renderRightPart}
                />
            </div>
        </InnerWindow>
    );
}
