import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Authentication from '../../modules/Authentication';
import {
    addParent,
    calculateCapsuleCount,
    copyMachineListExpansion,
    generateAllClientsItem,
    generateAllFlavoursItem,
} from '../../modules/MachineUtils';
import {
    setSideMenuState,
    setToggleBarComponent,
    SIDE_MENU_STATE_REPORTS,
} from '../../store/actions/ui';
import ClientsFilter from '../../components/ClientsFilter';
import { makeStyles } from '@material-ui/core/styles';
import { setReportsFilter } from '../../store/actions/reports';
import leftIcon from '../../res/images/arrow_left_square.svg';
import rightIcon from '../../res/images/arrow_right_square.svg';
import { getDatesForAPI, useFilterChanged } from '../../store/reducers/reports';
import ReportsWindow from './ReportsWindow';
import { IconButton } from '@material-ui/core';
import { useInterval } from '../../modules/HookUtils';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 0,
        flexGrow: 1,
    },
    filters: {
        marginRight: '20px',
        width: '63px',
        minWidth: '63px',
        maxWidth: '63px',
    },
    filtersExpanded: {
        width: '414px',
        minWidth: '414px',
        maxWidth: '414px',
    },
    reportsWindow: {
        flexGrow: 1,
        width: 0,
    },
    hideFilterButton: {
        height: '38px',
        width: '38px',
        alignSelf: 'center',
        marginRight: '-20px',
        zIndex: '1000',
    },
});

const gApiClient = Authentication.getAPIClient();

/** Reports Section - parent container everything related to report generation  */
export default function ReportsSection() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [flavours, setFlavours] = useState([]);
    const [clientCategories, setClientCategories] = useState({});
    const [flavourCategories, setFlavourCategories] = useState({});
    const [filterExpanded, setFilterExpanded] = useState(true);
    const filter = useSelector((state) => state.reports.filter);

    useEffect(() => {
        dispatch(setToggleBarComponent(null));
        dispatch(setSideMenuState(SIDE_MENU_STATE_REPORTS));

        if (filter.countries.length === 0) {
            gApiClient
                .callApi('admin/getFilterCountries', 'GET', {}, {})
                .then((response) => {
                    response.data.sort();
                    dispatch(setReportsFilter({ countries: response.data }));
                });
        }

        if (filter.clientTypes.length === 0) {
            gApiClient
                .callApi('admin/getClientTypes', 'GET', {}, {})
                .then((response) => {
                    response.data.sort();
                    dispatch(setReportsFilter({ clientTypes: response.data }));
                });
        }
        if (filter.filterClientCategories === null) {
            gApiClient
                .callApi('admin/getFilterClientCategories', 'GET', {}, {})
                .then((response) => {
                    dispatch(
                        setReportsFilter({
                            filterClientCategories: response.data,
                        })
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load new stats once a minute
    useInterval(async () => {
        console.log('ReportsSection - Refreshing stats', loading);
        if (loading) return;

        const [newClients, recipeTypes] = await downloadClientsAndRecipes();

        // See if the stats have changed - only then update
        const newClientsCapsuleCount = newClients.reduce(
            (a, b) => a + (b.capsule_count || 0),
            0
        );
        const originalClientsCapsuleCount = clients[0].capsule_count; // All clients item

        console.log(
            'ReportsSection - stats ',
            originalClientsCapsuleCount,
            newClientsCapsuleCount
        );

        if (newClientsCapsuleCount !== originalClientsCapsuleCount) {
            console.log('Stats changed, updating clients/flavours');
            updateClientsAndRecipes(newClients, recipeTypes, true);
        }
    }, 60000);

    // Downloads the latest clients and recipes, according to the date filter
    const downloadClientsAndRecipes = async () => {
        let [startDate, endDate] = getDatesForAPI(filter);

        const filterClustersURL = !filter.reportType
            ? 'no_capsule_count=1'
            : '';
        const filterMachinesCall = gApiClient.callApi(
            `admin/getFilterClusters?start_date=${startDate.toFormat(
                'yyyy-MM-dd'
            )}&end_date=${endDate.toFormat(
                'yyyy-MM-dd'
            )}&flavours=${encodeURIComponent(
                filter.allFlavours.map((x) => x._id)
            )}&${filterClustersURL}`,
            'GET',
            {},
            {}
        );

        const recipeTypesURL = !filter.reportType
            ? 'return_all=1'
            : `start_date=${startDate.toFormat(
                  'yyyy-MM-dd'
              )}&end_date=${endDate.toFormat('yyyy-MM-dd')}&return_all=1`;
        const filterRecipeTypesCall = gApiClient.callApi(
            `admin/recipeTypes?${recipeTypesURL}`,
            'GET',
            {},
            {}
        );

        const results = await Promise.all([
            filterMachinesCall,
            filterRecipeTypesCall,
        ]);

        let newClients = results[0].data;
        newClients.map((item) => calculateCapsuleCount(item));
        newClients.map((item) => addParent(item));
        // Add client ID
        newClients.forEach((c) => {
            if (c.internal_id) {
                c.name = `${c.name} (${c.internal_id})`;
            }
        });

        let recipeTypes = results[1].data;
        recipeTypes.sort((a, b) => a.title.localeCompare(b.title));
        // Remove any cleaning/init capsules
        recipeTypes = recipeTypes.filter((x) => !x.is_cleaning_or_init_capsule);

        // Add the "All Flavours" option
        recipeTypes.unshift(generateAllFlavoursItem(recipeTypes));

        return [newClients, recipeTypes];
    };

    // Updates the clients and recipes
    const updateClientsAndRecipes = (newClients, recipeTypes) => {
        const allClientsItem = generateAllClientsItem(newClients);

        // Maintain expansion of the machine tree view
        copyMachineListExpansion(filter.allClients, newClients);

        dispatch(
            setReportsFilter({
                allClients: newClients,
                clients:
                    filter.allClients.length === 0 ||
                    filter.clients.length === 0
                        ? [allClientsItem] // By default, the "All clients" entity is selected (only if this is the first time loading)
                        : filter.clients,
            })
        );

        // Add the "All Clients" option
        newClients.unshift(allClientsItem);

        setClients(newClients);

        dispatch(
            setReportsFilter({
                allFlavours: recipeTypes,
                flavours:
                    filter.allFlavours.length === 0 ||
                    filter.flavours.length === 0
                        ? [recipeTypes[0]] // "All flavours" selected by default (only if this is the first time loading)
                        : filter.flavours,
            })
        );

        setFlavours(recipeTypes);
    };

    // Upon filter date change - reload client and flavour consumption stats,
    // for that date range
    useFilterChanged(
        filter,
        async () => {
            if (!filter.reportType) {
                if (
                    filter.allFlavours.length > 1 &&
                    filter.allClients.length > 1
                ) {
                    // Returning to main report screen, when we previously loaded clients/recipes
                    setClients(filter.allClients);
                    setFlavours(filter.allFlavours);
                    return;
                }
                console.log('ReportsSection - setLoading true 1');
                setLoading(true);
            }

            const [newClients, recipeTypes] = await downloadClientsAndRecipes();
            updateClientsAndRecipes(newClients, recipeTypes);

            console.log('ReportsSection - setLoading false 2');
            setLoading(false);
        },
        true
    );

    const onFlavoursSelected = (selectedFlavours) => {
        if (filter.allFlavours.length <= 1) return;

        console.log('ReportsSection - setLoading true 3');
        setLoading(true);
        dispatch(
            setReportsFilter({
                flavours: selectedFlavours,
            })
        );
    };
    const onClientsSelected = (selectedClients) => {
        if (filter.allClients.length === 0) return;

        dispatch(
            setReportsFilter({
                clients: selectedClients,
            })
        );
    };

    return (
        <div className={classes.container}>
            <ClientsFilter
                className={clsx(
                    classes.filters,
                    filterExpanded ? classes.filtersExpanded : null
                )}
                loading={loading}
                expanded={filterExpanded}
                onExpanded={() => {
                    setFilterExpanded(true);
                }}
                clients={clients}
                flavours={flavours}
                onFlavoursSelected={onFlavoursSelected}
                onClientsSelected={onClientsSelected}
                onClientCategoriesChange={(cats) => {
                    setClientCategories(cats);
                }}
                onFlavourCategoriesChange={(cats) => {
                    setFlavourCategories(cats);
                }}
            />

            <IconButton
                className={classes.hideFilterButton}
                onClick={() => {
                    setFilterExpanded(!filterExpanded);
                }}
            >
                <img
                    src={filterExpanded ? leftIcon : rightIcon}
                    alt={'Expand/Collapse Filter'}
                />
            </IconButton>

            <ReportsWindow
                className={classes.reportsWindow}
                loading={loading}
                setLoading={setLoading}
                clientCategories={clientCategories}
                flavourCategories={flavourCategories}
            />
        </div>
    );
}
