import * as actions from '../actions/admin';

const INITIAL_STATE = {
    allMachines: null,
    clustersById: null,
    usersById: null,
    countries: [],
    filterMachines: [],
    recipeTypes: [],
    defaultCapsuleConfigs: {},
    defaultLogsConfigs: {},
    machinePartTypes: [],
    fwVersions: [],
    productionSites: [],
    recipeCategories: [],
    recipeMappings: {},
};

/** Overview Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_ADMIN_ALL_MACHINES:
            return { ...state, allMachines: action.value };
        case actions.SET_ADMIN_CLUSTERS_BY_ID:
            return { ...state, clustersById: action.value };
        case actions.SET_ADMIN_USERS_BY_ID:
            return { ...state, usersById: action.value };
        case actions.SET_ADMIN_COUNTRIES:
            return { ...state, countries: action.value };
        case actions.SET_ADMIN_FILTER_MACHINES:
            return { ...state, filterMachines: action.value };
        case actions.SET_ADMIN_RECIPE_TYPES:
            return { ...state, recipeTypes: action.value };
        case actions.SET_ADMIN_DEFAULT_CAPSULE_CONFIGS:
            return { ...state, defaultCapsuleConfigs: action.value };
        case actions.SET_ADMIN_DEFAULT_LOGS_CONFIGS:
            return { ...state, defaultLogsConfigs: action.value };
        case actions.SET_ADMIN_MACHINE_PART_TYPES:
            return { ...state, machinePartTypes: action.value };
        case actions.SET_ADMIN_FW_VERSIONS:
            return { ...state, fwVersions: action.value };
        case actions.SET_ADMIN_PRODUCTION_SITES:
            return { ...state, productionSites: action.value };
        case actions.SET_ADMIN_RECIPE_CATEGORIES:
            return { ...state, recipeCategories: action.value };
        case actions.SET_ADMIN_RECIPE_MAPPINGS:
            return { ...state, recipeMappings: action.value };
        default:
            return state;
    }
};
