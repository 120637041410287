import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStatsFilter } from '../../store/actions/stats';
import { useFilterChanged } from '../../store/reducers/stats';
import Authentication from '../../modules/Authentication';
import { useLocation } from 'react-router-dom';
import StatsResults from './StatsResults';
import StatsDashboard from './StatsDashboard';
import {
    addParent,
    calculateCapsuleCount,
    generateAllClientsItem,
    generateAllFlavoursItem,
} from '../../modules/MachineUtils';

const gApiClient = Authentication.getAPIClient();

/** Sales stats - parent container for both dashboard and action screens - loads
 * up the base filters (clients, countries, etc.)  */
export default function StatsSection() {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(0);
    const filter = useSelector((state) => state.stats.filter);
    const location = useLocation();
    const [flavours, setFlavours] = useState([]);

    // One-time loading of filter countries and client types
    useEffect(() => {
        gApiClient
            .callApi('admin/getFilterCountries', 'GET', {}, {})
            .then((response) => {
                response.data.sort();
                dispatch(setStatsFilter({ countries: response.data }));
            });

        gApiClient
            .callApi('admin/getClientTypes', 'GET', {}, {})
            .then((response) => {
                response.data.sort();
                dispatch(setStatsFilter({ clientTypes: response.data }));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Upon filter date change - reload client and flavour consumption stats,
    // for that date range
    useFilterChanged(
        filter,
        async () => {
            setLoading(true);

            const filterMachinesCall = gApiClient.callApi(
                `admin/getFilterClusters?start_date=${filter.startDate.toFormat(
                    'yyyy-MM-dd'
                )}&end_date=${filter.endDate.toFormat('yyyy-MM-dd')}`,
                'GET',
                {},
                {}
            );
            const filterRecipeTypesCall = gApiClient.callApi(
                `admin/recipeTypes?start_date=${filter.startDate.toFormat(
                    'yyyy-MM-dd'
                )}&end_date=${filter.endDate.toFormat(
                    'yyyy-MM-dd'
                )}&return_all=1`,
                'GET',
                {},
                {}
            );

            const results = await Promise.all([
                filterMachinesCall,
                filterRecipeTypesCall,
            ]);

            let clients = results[0].data;
            clients.map((item) => calculateCapsuleCount(item));
            clients.map((item) => addParent(item));

            const allClientsItem = generateAllClientsItem(clients);

            dispatch(
                setStatsFilter({
                    allClients: clients,
                    clients:
                        filter.allClients.length === 0
                            ? [allClientsItem] // By default, the "All clients" entity is selected (only if this is the first time loading)
                            : filter.clients,
                })
            );

            // Add the "All Clients" option
            clients.unshift(allClientsItem);

            setItems(clients);

            let recipeTypes = results[1].data;
            recipeTypes.sort((a, b) => a.title.localeCompare(b.title));
            // Remove any cleaning/init capsules
            recipeTypes = recipeTypes.filter(
                (x) => !x.is_cleaning_or_init_capsule
            );

            // Add the "All Flavours" option
            recipeTypes.unshift(generateAllFlavoursItem(recipeTypes));

            setFlavours(recipeTypes);

            dispatch(
                setStatsFilter({
                    allFlavours: recipeTypes,
                    flavours:
                        filter.allFlavours.length === 0
                            ? [recipeTypes[0]] // "All flavours" selected by default (only if this is the first time loading)
                            : filter.flavours,
                })
            );

            setLoading(false);
        },
        true
    );

    if (location.pathname.startsWith('/stats/results')) {
        // Results screen
        return (
            <StatsResults items={items} flavours={flavours} loading={loading} />
        );
    } else if (location.pathname.startsWith('/stats')) {
        // Dashboard screen
        return (
            <StatsDashboard
                items={items}
                flavours={flavours}
                loading={loading}
            />
        );
    }
}
