import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MachineInfo from './MachineInfo';
import MachineEvents from './MachineEvents';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import Authentication from '../../../modules/Authentication';
import MachineParameters from './MachineParameters';
import clone from 'clone';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSingleMachine,
    setToggleBarComponent,
    setToggleBarState,
    TOGGLE_BAR_STATE_DASHBOARD,
} from '../../../store/actions/ui';
import MachinesToggleBar from '../MachinesToggleBar';
import { useInterval } from '../../../modules/HookUtils';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 0,
        flexGrow: 1,
    },
    machineInfo: {
        width: '414px',
        minWidth: '414px',
    },
    machineEvents: {
        width: '414px',
        minWidth: '414px',
        marginLeft: '31px',
    },
    machineParameters: {
        flexGrow: 1,
        marginLeft: '31px',
    },
});

const gApiClient = Authentication.getAPIClient();

/** Single machine view - dashboard (machine details, params, graph, etc.) */
export default function SingleMachineDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const machine = useSelector((state) => state.ui.singleMachine);
    const dispatch = useDispatch();
    const latestMachineId = useRef(machine ? machine._id : null);

    let { machineId } = useParams();

    useEffect(() => {
        latestMachineId.current = machineId;
    }, [machineId]);

    useEffect(() => {
        dispatch(setToggleBarComponent(MachinesToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_DASHBOARD));

        if (!machine) {
            refreshMachine();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        // Refresh machine stats every 30 seconds
        refreshMachine();
    }, 30000);

    const refreshMachine = () => {
        gApiClient
            .callApi(`admin/getMachineInfo/${machineId}`, 'GET', {}, {})
            .then((response) => {
                const machineInfo = response.data;
                console.log(
                    'refreshMachine - single machine dashboard',
                    machineInfo._id,
                    latestMachineId.current
                );
                if (machineInfo._id !== latestMachineId.current) return;

                dispatch(setSingleMachine(machineInfo));
            });
    };

    const onExit = () => {
        // Exit back to machine search screen
        history.push('/machines');
    };

    const onNewLogs = (newLogs) => {
        // Update machine status based on latest log

        if (!machine) return;
        if (!newLogs || newLogs.length === 0) return;
        console.log('onNewLogs', machine._id, latestMachineId.current);
        if (machine._id !== latestMachineId.current) return;

        const newMachine = clone(machine);
        const latestLog = newLogs[newLogs.length - 1];

        newMachine.latest_status = latestLog.recipeStatus;
        newMachine.latest_recipe_is_cleaning = latestLog.isCleaningRecipe;
        newMachine.latest_timestamp = latestLog.timestamp;
        newMachine.is_p15 = latestLog.isP15;

        dispatch(setSingleMachine(newMachine));
    };

    return (
        <div className={classes.container}>
            <MachineInfo
                machine={machine}
                onClose={onExit}
                className={classes.machineInfo}
            />
            <MachineEvents
                machine={machine}
                className={classes.machineEvents}
            />
            <MachineParameters
                machine={machine}
                machineId={machineId}
                className={classes.machineParameters}
                onNewLogs={onNewLogs}
            />
        </div>
    );
}
