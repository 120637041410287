import * as actions from '../actions/user';
import Authentication from '../../modules/Authentication';

const INITIAL_STATE = {
    loginStatus: Authentication.LOGIN_STATUS_NOT_LOGGED_IN,
    userDetails: null,
};

/** User reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_LOGIN_STATUS:
            return { ...state, loginStatus: action.value };
        case actions.SET_USER_DETAILS:
            return { ...state, userDetails: action.value };
        default:
            return state;
    }
};
