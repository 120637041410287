import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, Select as MUISelect } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import selectIcon from '../res/images/down_arrow.svg';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    rightIconContainer: {
        position: 'absolute',
        right: '4px',
        height: '34px',
    },
    selectRoot: {
        backgroundColor: '#e7eaf0',
        height: '25px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '6px',
        color: '#586374',
        fontSize: '12px',
        textTransform: 'uppercase',
        '&:focus': {
            backgroundColor: '#e7eaf0',
        },
    },
    noUpperCase: {
        textTransform: 'none !important',
    },
    autoCompleteRoot: {
        minHeight: '38px',
        height: 'auto',
    },
    autoCompleteInput: {
        minHeight: '38px',
        color: '#586374',
        fontSize: '12px',

        '& input': {
            textTransform: 'uppercase',
        },

        '&:before, &:after': {
            borderBottom: 'none !important',
        },

        '&.Mui-disabled': {
            opacity: 0.4,
        },
    },
    autoCompleteListBox: {
        padding: 0,
    },
    autoCompleteEndAdornment: {
        top: 0,
        width: '38px',
        height: '38px',
    },
    autoCompletePopupIndicator: {
        padding: 0,
    },
    autoCompletePopupIndicatorOpen: {
        transform: 'none',
    },
    dropdownIconContainer: {
        backgroundColor: '#7e8cac',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '38px',
        width: '38px',
        minWidth: '38px',
    },
    menuItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0px',
        color: '#7d90aa',
        height: '38px',
        '&[data-focus="true"], &[aria-selected="true"]': {
            backgroundColor: '#ffffff',
        },
        '&:hover, &.Mui-selected': {
            backgroundColor: '#00b09e',
            color: '#ffffff',

            '& div:nth-child(2)': {
                backgroundColor: '#009f93',
            },
            '& img': {
                filter: hexColorToCSSFilter('#ffffff'),
            },
        },
    },
    menuList: {
        padding: 0,
        zIndex: 9999,
    },
    separator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#e7e9ee',
        position: 'absolute',
        bottom: 0,
    },
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '38px',
        alignItems: 'center',
        position: 'relative',
    },
    menuItemLabel: {
        fontSize: '12px',
        textTransform: 'uppercase',
        marginLeft: '6px',
        flexGrow: 1,
    },
    rightIcon: {
        backgroundColor: '#e7e8ee',
        height: '38px',
        width: '38px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    selectMenu: {
        backgroundColor: 'red !important',
    },
});

const SelectItem = (props) => {
    const classes = useStyles();

    return (
        <div
            className={classes.menuItemContainer}
            style={{ width: props.width }}
        >
            <div
                className={clsx(
                    classes.menuItemLabel,
                    props.caseSensitive ? classes.noUpperCase : null
                )}
            >
                {props.item.label}
            </div>
            {props.rightIcon && (
                <div className={classes.rightIcon}>
                    <img src={props.rightIcon} alt={props.item.label} />
                </div>
            )}
            {!props.isLast && <div className={classes.separator} />}
        </div>
    );
};

function RenderSelect(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const select = useRef(null);

    return (
        <MUISelect
            className={clsx(props.className)}
            value={props.value ? props.value.value : ''}
            onChange={(event) => {
                const value = event.target.value;
                if (props.onChange) {
                    props.onChange(props.items.find((x) => x.value === value));
                }
            }}
            classes={{
                root: clsx(
                    classes.selectRoot,
                    props.rootClassName,
                    props.caseSensitive ? classes.noUpperCase : null
                ),
            }}
            input={<InputBase />}
            disabled={props.disabled}
            open={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            ref={select}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                    list: classes.menuList,
                },
            }}
            IconComponent={() => {
                return (
                    <div
                        className={classes.dropdownIconContainer}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <img src={selectIcon} alt="Select" />
                    </div>
                );
            }}
            displayEmpty={props.hint ? true : false}
            renderValue={(value) => {
                if (!value) return <div>{props.hint}</div>;

                return (
                    <div>
                        {props.items.find((item) => item.value === value).label}
                    </div>
                );
            }}
        >
            {props.items.map((item, index) => {
                return (
                    <MenuItem
                        key={item.value}
                        classes={{ root: classes.menuItemRoot }}
                        value={item.value}
                    >
                        <SelectItem
                            width={`${
                                select.current ? select.current.offsetWidth : 0
                            }px`}
                            item={item}
                            rightIcon={props.rightIcon}
                            isLast={index === props.items.length - 1}
                            caseSensitive={props.caseSensitive}
                        />
                    </MenuItem>
                );
            })}
        </MUISelect>
    );
}

function DropDownIcon(props) {
    const classes = useStyles();

    return (
        <div className={classes.dropdownIconContainer}>
            <img src={selectIcon} alt="Select" />
        </div>
    );
}

function RenderAutoComplete(props) {
    const classes = useStyles();

    return (
        <Autocomplete
            value={props.value}
            onChange={(event, selectedOption) => {
                if (props.onChange) props.onChange(selectedOption);
            }}
            className={clsx(props.className)}
            options={props.items}
            disabled={props.disabled}
            multiple={props.multiple}
            classes={{
                root: clsx(classes.selectRoot, classes.autoCompleteRoot),
                option: classes.menuItemRoot,
                endAdornment: classes.autoCompleteEndAdornment,
                popupIndicator: classes.autoCompletePopupIndicator,
                inputRoot: clsx(
                    classes.autoCompleteInput,
                    props.caseSensitive ? classes.noUpperCase : null
                ),
                listbox: classes.autoCompleteListBox,
                popupIndicatorOpen: classes.autoCompletePopupIndicatorOpen,
            }}
            autoHighlight
            disableClearable={true}
            getOptionLabel={(option) => option.label}
            renderOption={(item) => (
                <SelectItem
                    item={item}
                    isLast={false}
                    rightIcon={props.rightIcon}
                    caseSensitive={props.caseSensitive}
                />
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        placeholder: props.hint,
                    }}
                    fullWidth
                />
            )}
            popupIcon={<DropDownIcon />}
        />
    );
}

/** Our own stylized select input. */
export default function Select(props) {
    if (!props.autoComplete) {
        return RenderSelect(props);
    } else {
        return RenderAutoComplete(props);
    }
}
