import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OverviewDashboard from './OverviewDashboard';
import Authentication from '../../modules/Authentication';
import {
    addParent,
    calculateMachineCount,
    findAllMachines,
    findItem,
    getAllChildMachines,
    markItemForStatusGroups,
} from '../../modules/MachineUtils';
import { setOverviewFilter } from '../../store/actions/overview';
import OverviewFilter from './OverviewFilter';
import { initializeMachines } from './MachineSelection';
import OverviewKPIs from './OverviewKPIs';
import clone from 'clone';

// Refresh page every 10 seconds
const PAGE_REFRESH_RATE = 10000;

const gApiClient = Authentication.getAPIClient();

function updateMachine(machine, newMachine) {
    let changed = false;

    if (machine.is_offline !== newMachine.is_offline) {
        machine.is_offline = newMachine.is_offline;
        // Don't count this as a changed machine
    }
    if (
        JSON.stringify(machine.latest_location) !==
        JSON.stringify(newMachine.latest_location)
    ) {
        machine.latest_location = newMachine.latest_location;
        changed = true;
    }
    if (
        machine.latest_recipe_is_cleaning !==
        newMachine.latest_recipe_is_cleaning
    ) {
        machine.latest_recipe_is_cleaning =
            newMachine.latest_recipe_is_cleaning;
        changed = true;
    }
    if (machine.latest_recipe_type !== newMachine.latest_recipe_type) {
        machine.latest_recipe_type = newMachine.latest_recipe_type;
        changed = true;
    }
    if (machine.latest_status !== newMachine.latest_status) {
        machine.latest_status = newMachine.latest_status;
        changed = true;
    }
    if (machine.latest_timestamp !== newMachine.latest_timestamp) {
        machine.latest_timestamp = newMachine.latest_timestamp;
        // Don't count this as a changed machine
    }
    if (
        JSON.stringify(machine.required_operations) !==
        JSON.stringify(newMachine.required_operations)
    ) {
        machine.required_operations = newMachine.required_operations;
        changed = true;
    }
    if (
        JSON.stringify(machine.warnings) !== JSON.stringify(newMachine.warnings)
    ) {
        machine.warnings = newMachine.warnings;
        changed = true;
    }

    return changed;
}

/** Updates each machine's status in the original clients structure */
function updateMachinesStatus(filter, newClients) {
    const clientsCopy = clone(filter.clients);
    let changed = false;

    let newMachines = [];
    newClients.forEach((c) => {
        newMachines = newMachines.concat(getAllChildMachines(c));
    });

    newMachines.forEach((m) => {
        // Update the machine's status
        const matchingMachine = findItem(clientsCopy, m._id);
        if (matchingMachine) {
            changed = updateMachine(matchingMachine, m) || changed;
        }
    });

    return [clientsCopy, changed];
}

/** Overview - parent container for both dashboard, filter and KPIs screens - loads
 * up the base filters (clients, countries, etc.)  */
export default function OverviewSection() {
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.overview.filter);
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const loadMachines = (isInitialization) => {
        console.log('loadMachines - clients', isInitialization);

        gApiClient
            .callApi(
                `admin/getFilterMachines?return_machine_events=1`,
                'GET',
                {},
                {}
            )
            .then((results) => {
                let clients = results.data;

                if (!isInitialization) {
                    // This is called when we just want to refresh stats (new status, etc.)

                    // First, update the machines with new status, etc.
                    const [newClients, changed] = updateMachinesStatus(
                        filter,
                        clients
                    );

                    if (changed) {
                        //  Go over the client tree once, marking/tagging each node on the
                        // way (client/site/cluster/machine) to which status groups it belongs to.
                        let changed = false;
                        newClients.forEach((c) => {
                            const res = markItemForStatusGroups(c, true);
                            if (res[0]) changed = true;
                        });

                        // Only update the filter if the status groups changed
                        if (changed) {
                            dispatch(
                                setOverviewFilter({
                                    clients: newClients,
                                })
                            );
                        }
                    }

                    return;
                }

                // Add parent for each client
                clients.map((item) => addParent(item));

                // Count machines for each item in the tree
                clients.forEach((c) => calculateMachineCount(c));

                //  Go over the client tree once, marking/tagging each node on the
                // way (client/site/cluster/machine) to which status groups it belongs to.
                clients.forEach((c) =>
                    // ignoreSelected === true, because no filters are applied by default (= all machines are selected)
                    markItemForStatusGroups(c, true)
                );

                // Add client ID
                clients.forEach((c) => {
                    if (c.internal_id) {
                        c.name = `${c.name} (${c.internal_id})`;
                    }
                });

                // Initialize all machines with random colors
                const allMachines = findAllMachines(clients);
                initializeMachines(allMachines);

                dispatch(
                    setOverviewFilter({
                        clients: clients,
                        // All machines selected by default
                        allMachinesSelected: true,
                        selectedMachines: allMachines,
                    })
                );

                setLoading(false);
            });
    };

    useEffect(() => {
        // Refresh the machines' status every X seconds
        const timer = setInterval(() => {
            loadMachines(false);
        }, PAGE_REFRESH_RATE);

        return () => {
            clearInterval(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.clients]);

    // Load the latest machine tree, with current status
    useEffect(() => {
        if (filter.clients.length === 0) {
            setLoading(true);

            // One-time loading of filter countries and client types
            gApiClient
                .callApi('admin/getFilterCountries', 'GET', {}, {})
                .then((response) => {
                    response.data.sort();
                    dispatch(setOverviewFilter({ countries: response.data }));
                });

            gApiClient
                .callApi('admin/getClientTypes', 'GET', {}, {})
                .then((response) => {
                    response.data.sort();
                    dispatch(setOverviewFilter({ clientTypes: response.data }));
                });
        }

        loadMachines(filter.clients.length === 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (location.pathname.startsWith('/overview/kpis')) {
        // Results screen
        return <OverviewKPIs loading={loading} />;
    } else if (location.pathname.startsWith('/overview/filter')) {
        // Results screen
        return <OverviewFilter loading={loading} />;
    } else if (location.pathname.startsWith('/overview')) {
        // Dashboard screen
        return <OverviewDashboard loading={loading} />;
    }
}
