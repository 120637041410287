export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export function setLoginStatus(value) {
    return {
        type: SET_LOGIN_STATUS,
        value: value,
    };
}

export function setUserDetails(value) {
    return {
        type: SET_USER_DETAILS,
        value: value,
    };
}
