import * as actions from '../actions/stats';
import { DateTime } from 'luxon';
import { usePrevious } from '../../modules/HookUtils';
import { useEffect } from 'react';
import { ID_ALL_CLIENTS } from '../../modules/MachineUtils';

const INITIAL_STATE = {
    filter: {
        // Each selected flavour/client/etc. is an entire object of that type.
        // Clients/sites/clusters have a "visible" property (=whether or not should they be visible on the graph/table),
        // and also a "color" property (the color display on the graph/table)
        clients: [],
        allClients: [], // All clients available (in a tree structure of client/site/cluster/machines)
        clusterIds: [], // Selected cluster IDs (children of the selected clients/sites)
        clientTypes: [], // Possible client types (office, cafe, etc.)
        flavours: [],
        allFlavours: [], // All flavours available
        startDate: DateTime.local().startOf('day'),
        endDate: DateTime.local().endOf('day'),
        countries: [], // List of countries (for filtering)
    },
};

/** Returns a smaller version of the selected clients list, one that doesn't include parent/child references,
 * so it'll be possible to JSON-stringify it. */
function cleanClients(clients) {
    return clients.map((c) => ({
        _id: c._id,
        name: c._id,
        selected: c.selected,
        visible: c.visible,
        capsule_count: c.capsule_count,
    }));
}

/** Checks if a filter has changed (the filtered clients, flavours or start/end date have changed) */
export function useFilterChanged(filter, onFilterChanged, dateOnly) {
    const prevFilterStartDate = usePrevious(filter.startDate);
    const prevFilterEndDate = usePrevious(filter.endDate);
    const prevFlavours = usePrevious(filter.flavours);
    const prevClients = usePrevious(filter.clients);

    useEffect(() => {
        let dateChanged = true,
            flavoursChanged = true,
            clientsChanged = true;

        if (prevFilterStartDate) {
            dateChanged =
                filter.startDate.toMillis() !==
                    prevFilterStartDate.toMillis() ||
                filter.endDate.toMillis() !== prevFilterEndDate.toMillis();
        }

        if (dateOnly && dateChanged) {
            onFilterChanged();
            return;
        } else if (dateOnly) {
            return;
        }

        if (prevFlavours) {
            flavoursChanged =
                JSON.stringify(filter.flavours) !==
                JSON.stringify(prevFlavours);
        }
        if (prevClients) {
            clientsChanged =
                JSON.stringify(cleanClients(filter.clients)) !==
                JSON.stringify(cleanClients(prevClients));
        }

        if (!dateChanged && !flavoursChanged && !clientsChanged) return;

        onFilterChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.startDate, filter.endDate, filter.flavours, filter.clusterIds]);
}

/** Finds selected clusters (cluster that belong to selected clients/sites) */
function findSelectedClustersFromItem(item, returnAll) {
    let clusters = [];

    if (item.site) {
        // Reached a cluster
        return item.selected || returnAll ? [item] : [];
    }

    if (item.children) {
        item.children.forEach((c) => {
            clusters = clusters.concat(
                findSelectedClustersFromItem(c, returnAll)
            );
        });

        return clusters;
    }

    return [];
}

/** Finds selected cluster IDs (cluster IDs that belong to selected clients/sites/clusters) */
export function findSelectedClusters(selectedClients, allClients) {
    let clusters = [];

    if (
        selectedClients.length > 0 &&
        selectedClients[0]._id === ID_ALL_CLIENTS
    ) {
        // All clusters
        allClients.forEach((c) => {
            clusters = clusters.concat(findSelectedClustersFromItem(c, true));
        });
    } else {
        selectedClients.forEach((c) => {
            clusters = clusters.concat(findSelectedClustersFromItem(c));
        });
    }

    // Remove any duplicates
    return Array.from(new Set(clusters.map((c) => c._id)));
}

/** Stats Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_STATS_FILTER:
            const newFilter = { ...state.filter, ...action.value }; // Just overwrite any input fields given to the filter

            if (action.value.clients && newFilter.allClients) {
                // Updating selected clients - update selected cluster IDs as well (cluster IDs that belong to those clients/sites/clusters)
                newFilter.clusterIds = findSelectedClusters(
                    action.value.clients,
                    newFilter.allClients
                );
            }

            return { ...state, filter: newFilter };
        default:
            return state;
    }
};
