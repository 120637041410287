import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Authentication from '../modules/Authentication';
import { setLoginStatus } from '../store/actions/user';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import {
    APP_BAR_HIDDEN,
    APP_BAR_VISIBLE,
    setAppBarVisibility,
} from '../store/actions/ui';
import hidePasswordIcon from '../res/images/eye_password_filled.svg';
import showPasswordIcon from '../res/images/eye_password.svg';
import queryString from 'query-string';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '100%',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        marginTop: '25px',
        borderRadius: '32px',
        height: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '20px',
        },
    },
    error: {
        color: '#ff0000',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        alignSelf: 'flex-start',
        marginLeft: '120px',
        textAlign: 'left',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundImage: {},
    backToSignIn: {
        color: '#000000',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '20px',
    },
    link: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#000000',
            textDecoration: 'none',
        },
    },
    innerContainer: {
        width: '714px',
        height: '530px',
        backgroundColor: '#e8e8e8',
        position: 'relative',
        boxShadow: '0 9px 40px 20px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
        color: '#00948d',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerTitle: {
        fontSize: '34px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    innerSubTitle: {
        fontSize: '20px',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    successImage: {
        position: 'absolute',
        width: '470px',
        left: 'calc(50% - 235px)',
        top: 'calc(50% - 192px)',
    },
    successContainer: {
        justifyContent: 'center',
    },
});

/** The reset password screen - after the user gets a password reset link,
 * he reaches this screen */
export default function ResetPassword() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const location = useLocation();
    const { code, email } = queryString.parse(location.search);

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    const verifyForm = () => {
        if (password.length === 0) {
            setError({ password: 'Required' });
            return false;
        }
        if (password2.length === 0) {
            setError({ password2: 'Required' });
            return false;
        }
        if (password !== password2) {
            setError({ password2: 'Password mismatch' });
            return false;
        }

        return true;
    };

    const onClick = () => {
        changePassword();
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            changePassword();
        }
    };

    const changePassword = () => {
        if (!verifyForm()) return;

        setLoading(true);
        setError({});

        // Change password (using the provided verification code)
        Authentication.confirmPassword(
            email.toLowerCase(),
            code,
            password,
            () => {
                // Password change successful - login the user
                Authentication.loginWithPassword(
                    email,
                    password,
                    () => {
                        // Mark user as logged-in in the store
                        dispatch(
                            setLoginStatus(
                                Authentication.LOGIN_STATUS_LOGGED_IN
                            )
                        );
                        dispatch(setAppBarVisibility(APP_BAR_VISIBLE));

                        // Move to main, logged-in page
                        history.replace('/');
                    },
                    (err) => {
                        // Couldn't login - shouldn't happen
                        setError({ password2: err.message });
                        setLoading(false);
                    },
                    null
                );
            },
            (err) => {
                // Couldn't change password
                console.log('error', err);
                setError({ password: err.message });
                setLoading(false);
            }
        );
    };

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img
                    src={bgTop}
                    className={classes.backgroundImage}
                    alt="Background"
                />
                <img
                    src={bgBottom}
                    className={classes.backgroundImage}
                    alt="Background"
                />
            </div>

            <div className={classes.innerContainer}>
                <div className={classes.innerTitle}>Reset Password</div>
                <div className={classes.innerSubTitle}>
                    Enter your new password
                </div>

                <TextField
                    className={classes.input}
                    onChange={setPassword}
                    type={showPassword ? 'text' : 'password'}
                    onKeyUp={onKeyPress}
                    rightIcon={
                        showPassword ? hidePasswordIcon : showPasswordIcon
                    }
                    onRightIconClick={() => {
                        setShowPassword(!showPassword);
                    }}
                    placeholder={'Password'}
                />
                <div className={classes.error}>{error.password}&nbsp;</div>

                <TextField
                    className={classes.input}
                    onChange={setPassword2}
                    type={showPassword2 ? 'text' : 'password'}
                    onKeyUp={onKeyPress}
                    rightIcon={
                        showPassword2 ? hidePasswordIcon : showPasswordIcon
                    }
                    onRightIconClick={() => {
                        setShowPassword2(!showPassword2);
                    }}
                    placeholder={'Retype Password'}
                />
                <div className={classes.error}>{error.password2}&nbsp;</div>

                <Button
                    loading={loading}
                    className={classes.button}
                    label="Reset Password"
                    onClick={onClick}
                />
                <div className={classes.error}>{error.general}</div>
            </div>
        </div>
    );
}
