import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Authentication from '../modules/Authentication';
import closeIcon from '../res/images/close_circle_black.svg';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { version as clientVersion } from '../../package.json';
import { APP_BAR_HIDDEN, setAppBarVisibility } from '../store/actions/ui';
import clsx from 'clsx';
import LogoWithProfile from '../components/LogoWithProfile';
import { IconButton } from '@material-ui/core';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: 0,
        flexGrow: 1,
        backgroundColor: '#f2f4f7',
        paddingLeft: '34px',
        paddingTop: '31px',
        position: 'relative',
    },
    signOutButton: {
        width: '150px',
        height: '40px',
        borderRadius: '20px',
        marginTop: '30px',
        '& div': {
            fontSize: '14px',
        },
    },
    title: {
        textTransform: 'uppercase',
        color: '#027c76',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '50px',
    },
    header: {
        fontSize: '24px',
        color: '#2c2925',
    },
    profileContainer: {
        paddingLeft: '21px',
        display: 'flex',
        flexDirection: 'row',
    },
    profileColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
    },
    profileLabel: {
        fontSize: '18px',
        color: '#2c2925',
        marginTop: '32px',
    },
    profileValue: {
        fontSize: '14px',
        color: '#2c2925',
        fontWeight: 'bold',
        marginTop: '37px',
    },
    profileEdit: {
        fontSize: '14px',
        color: '#2c2925',
        fontWeight: 'bold',
        marginTop: '36px',
        cursor: 'pointer',
    },
    profileImage: {
        marginTop: '36px',
        width: '124px',
        height: '153px',
        border: 'solid 1px #979797',
        backgroundColor: '#ffffff',
        position: 'relative',
        cursor: 'pointer',
    },
    profileImageContent: {
        width: '100%',
        height: '100%',
        objectFit: 'fill',
        opacity: 0.3,
    },
    profileImagePlus: {
        position: 'absolute',
        fontSize: '40px',
        top: 'calc(50% - 25px)',
        left: 'calc(50% - 10px)',
    },
    header2: {
        marginTop: '100px',
    },
    logo: {
        right: '27px',
        top: '20px',
        position: 'absolute',
    },
    dialogBackground: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: '#58637498',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialog: {
        backgroundColor: '#e8e8e8',
        boxShadow: '0 0 35px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        width: '714px',
        height: '500px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        paddingLeft: '132px',
        paddingRight: '119px',
    },
    highDialog: {
        height: '800px',
    },
    closeDialog: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        padding: '3px',
    },
    dialogTitle: {
        color: '#00948d',
        fontSize: '34px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    dialogProfileTitle: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    dialogSubTitle: {
        color: '#00948d',
        marginTop: '10px',
        fontSize: '20px',
        textAlign: 'center',
    },
    inputTitle: {
        color: '#2c2925',
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginTop: '60px',
        marginBottom: '10px',
        marginLeft: '20px',
        width: '100%',
    },
    smallTopMargin: {
        marginTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        borderRadius: '32px',
        height: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    dialogButton: {
        width: '450px',
        height: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '14px',
        },
    },
    error: {
        color: '#ff0000',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        marginTop: '10px',
        textAlign: 'left',
        alignSelf: 'flex-start',
        marginLeft: '10px',
    },
    profileImageInput: {
        visibility: 'hidden',
    },
});

/** The settings screen */
export default function Settings() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [dialogFieldName, setDialogFieldName] = useState('');
    const [dialogSubTitle, setDialogSubTitle] = useState('');
    const [newValue, setNewValue] = useState('');
    const [newValue2, setNewValue2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [currentPassword, setCurrentPassword] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(
        Authentication.getUserDetails()
    );
    const profileImageInput = useRef();
    const [showProfileImageDialog, setShowProfileImageDialog] = useState(false);
    const [profilePicture, setProfilePicture] = useState();
    const profileImageRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        x: 20,
        y: 10,
        width: 50,
        aspect: 1 / 1,
    });
    const [croppedProfilePicture, setCroppedProfilePicture] = useState();
    const [profileFileType, setProfileFileType] = useState();
    const [serverVersion, setServerVersion] = useState();

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));

        gApiClient
            .callApi('getServerVersion', 'GET', {})
            .then((response) => {
                console.log('Got version ', response.data);
                setServerVersion(response.data.version);
            })
            .catch((result) => {
                console.log('error', result);
                setServerVersion('N/A');
            });
    }, [dispatch]);

    const uploadProfilePhoto = () => {
        setLoading(true);

        setError({});

        gApiClient
            .callApi('setProfilePhoto', 'POST', {}, croppedProfilePicture, {
                'Content-Type': profileFileType,
            })
            .then((response) => {
                console.log('Got user ', response.data);
                Authentication.refreshUserDetails(() => {
                    setLoading(false);
                    setShowProfileImageDialog(false);
                    setUserDetails(Authentication.getUserDetails());
                });
            })
            .catch((result) => {
                console.log('error', result);
                setLoading(false);
                setError({ general: result.message });
            });
    };

    const onProfileImageLoad = useCallback((img) => {
        profileImageRef.current = img;
    }, []);

    const makeClientCrop = async (crop) => {
        if (profileImageRef.current && crop.width && crop.height) {
            createCropPreview(profileImageRef.current, crop);
        }
    };

    const createCropPreview = async (image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            const dataURL = canvas.toDataURL();
            if (!dataURL) {
                reject(new Error('Canvas is empty'));
                return;
            }

            setCroppedProfilePicture(dataURL.split(',')[1]);
        });
    };

    const showFileInput = () => {
        // Show file selection dialog
        profileImageInput.current.click();
    };

    const onFileSelected = (event) => {
        // File was selected (for profile photo)
        if (event.target.files && event.target.files.length > 0) {
            // Read the image file
            const file = event.target.files[0];
            setProfileFileType(file.type);

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                // Show the file dialog, allowing to crop a subset of the photo
                setProfilePicture(reader.result);
                setShowProfileImageDialog(true);
            });
            reader.readAsDataURL(file);
        }
    };

    const signOut = () => {
        Authentication.logOut();
        history.replace('/');
    };

    const changePassword = () => {
        setLoading(true);
        setError({});

        Authentication.changePassword(
            userDetails.email.toLowerCase(),
            currentPassword,
            newValue,
            (result) => {
                console.log('result', result);
                setLoading(false);
                setShowDialog(false);
            },
            (err) => {
                console.log('error', err);
                setLoading(false);
                setError({ general: err.message });
            }
        );
    };

    const saveChanges = () => {
        const params = {};
        if (dialogFieldName === 'Password') {
            if (currentPassword.length === 0) {
                setError({ currentPassword: 'Required' });
                return;
            }
            if (newValue.length === 0) {
                setError({ newPassword: 'Required' });
                return;
            }
            if (newValue2.length === 0) {
                setError({ newPassword2: 'Required' });
                return;
            }
            if (newValue !== newValue2) {
                setError({ newPassword2: 'Password Mismatch' });
                return;
            }

            changePassword();
            return;
        } else if (dialogFieldName === 'Name') {
            const parts = newValue.split(' ');
            params['first_name'] = parts[0];
            params['last_name'] = parts.slice(1).join(' ');
        } else if (dialogFieldName === 'Email') {
            params['email'] = newValue;
        }

        setLoading(true);
        setError({});

        gApiClient
            .callApi('setProfileAttributes', 'POST', {}, params)
            .then((response) => {
                console.log('Got user ', response.data);
                Authentication.refreshUserDetails(() => {
                    setLoading(false);
                    setShowDialog(false);
                    setUserDetails(Authentication.getUserDetails());
                });
            })
            .catch((result) => {
                console.log('error', result);
                let newError = result.message;
                if (dialogFieldName === 'Email') {
                    newError = 'Email address already exists';
                }
                setLoading(false);
                setError({ general: newError });
            });
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            saveChanges();
        }
    };

    const editName = () => {
        setDialogFieldName('Name');
        setDialogSubTitle('Update your name and press Save Changes');
        setNewValue(userDetails.first_name + ' ' + userDetails.last_name);
        setShowDialog(true);
    };

    const editEmail = () => {
        setDialogFieldName('Email');
        setDialogSubTitle(
            'Update your email and press Save Changes - A new confirmation mail will be sent to this address.'
        );
        setNewValue(userDetails.email);
        setShowDialog(true);
    };

    const editPassword = () => {
        setDialogFieldName('Password');
        setDialogSubTitle('Change your password to the NOC');
        setNewValue('');
        setShowDialog(true);
    };

    return (
        <div className={classes.content}>
            {showProfileImageDialog && (
                <div className={classes.dialogBackground}>
                    <div className={clsx(classes.dialog)}>
                        <IconButton
                            className={classes.closeDialog}
                            onClick={() => {
                                setShowProfileImageDialog(false);
                            }}
                        >
                            <img src={closeIcon} alt="Close" />
                        </IconButton>

                        <div
                            className={clsx(
                                classes.dialogTitle,
                                classes.dialogProfileTitle
                            )}
                        >
                            Profile photo:
                        </div>

                        <ReactCrop
                            src={profilePicture}
                            onImageLoaded={onProfileImageLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={makeClientCrop}
                        />

                        <Button
                            loading={loading}
                            className={classes.dialogButton}
                            label="Upload Profile Photo"
                            onClick={uploadProfilePhoto}
                        />
                        <div className={classes.error}>{error.general}</div>
                    </div>
                </div>
            )}
            {showDialog && (
                <div className={classes.dialogBackground}>
                    <div
                        className={clsx(
                            classes.dialog,
                            dialogFieldName === 'Password'
                                ? classes.highDialog
                                : null
                        )}
                    >
                        <IconButton
                            className={classes.closeDialog}
                            onClick={() => {
                                setShowDialog(false);
                            }}
                        >
                            <img src={closeIcon} alt="Close" />
                        </IconButton>

                        <div className={classes.dialogTitle}>
                            Change your {dialogFieldName}:
                        </div>
                        <div className={classes.dialogSubTitle}>
                            {dialogSubTitle}
                        </div>

                        {dialogFieldName === 'Password' && (
                            <div className={classes.inputTitle}>
                                Current {dialogFieldName}:
                            </div>
                        )}
                        {dialogFieldName === 'Password' && (
                            <TextField
                                className={classes.input}
                                onChange={setCurrentPassword}
                                defaultValue={''}
                                type="Password"
                                onKeyUp={onKeyPress}
                                placeholder={'Current Password'}
                            />
                        )}
                        {dialogFieldName === 'Password' && (
                            <div className={classes.error}>
                                {error.currentPassword}
                            </div>
                        )}
                        <div
                            className={clsx(
                                classes.inputTitle,
                                dialogFieldName === 'Password'
                                    ? classes.smallTopMargin
                                    : null
                            )}
                        >
                            New {dialogFieldName}:
                        </div>
                        <TextField
                            className={classes.input}
                            onChange={setNewValue}
                            defaultValue={newValue}
                            onKeyUp={onKeyPress}
                            type={
                                dialogFieldName === 'Password'
                                    ? 'password'
                                    : 'text'
                            }
                            placeholder={dialogFieldName}
                        />
                        {dialogFieldName === 'Password' && (
                            <div className={classes.error}>
                                {error.newPassword}
                            </div>
                        )}

                        {dialogFieldName === 'Password' && (
                            <div
                                className={clsx(
                                    classes.inputTitle,
                                    classes.smallTopMargin
                                )}
                            >
                                Reenter new {dialogFieldName}:
                            </div>
                        )}
                        {dialogFieldName === 'Password' && (
                            <TextField
                                className={classes.input}
                                onChange={setNewValue2}
                                type="Password"
                                defaultValue={''}
                                onKeyUp={onKeyPress}
                                placeholder={'Reenter Password'}
                            />
                        )}
                        {dialogFieldName === 'Password' && (
                            <div className={classes.error}>
                                {error.newPassword2}
                            </div>
                        )}

                        <Button
                            loading={loading}
                            className={classes.dialogButton}
                            label="Save Changes"
                            onClick={saveChanges}
                        />
                        <div className={classes.error}>{error.general}</div>
                    </div>
                </div>
            )}

            <LogoWithProfile className={classes.logo} />

            <div className={classes.title}>Menu</div>

            <div className={classes.header}>Edit your profile</div>
            <div className={classes.profileContainer}>
                <div className={classes.profileColumn}>
                    <div className={classes.profileLabel}>Name:</div>
                    <div className={classes.profileLabel}>Email:</div>
                    <div className={classes.profileLabel}>Password:</div>
                </div>
                <div className={classes.profileColumn}>
                    <div className={classes.profileValue}>
                        {userDetails.first_name} {userDetails.last_name}
                    </div>
                    <div className={classes.profileValue}>
                        {userDetails.email}
                    </div>
                    <div className={classes.profileValue}>*************</div>
                </div>
                <div className={classes.profileColumn}>
                    <div className={classes.profileEdit} onClick={editName}>
                        Edit
                    </div>
                    <div className={classes.profileEdit} onClick={editEmail}>
                        Edit
                    </div>
                    <div className={classes.profileEdit} onClick={editPassword}>
                        Edit
                    </div>
                </div>
                <div className={classes.profileColumn}>
                    <div className={classes.profileLabel}>
                        Upload Image Profile
                    </div>
                </div>
                <div className={classes.profileColumn}>
                    <div
                        className={classes.profileImage}
                        onClick={showFileInput}
                    >
                        <img
                            className={classes.profileImageContent}
                            src={userDetails.photo_url}
                            alt={'Profile'}
                        />
                        <div className={classes.profileImagePlus}>+</div>
                    </div>
                </div>

                <input
                    ref={profileImageInput}
                    className={classes.profileImageInput}
                    type="file"
                    name="profileImage"
                    accept="image/*"
                    onChange={onFileSelected}
                />
            </div>

            <div className={clsx(classes.header, classes.header2)}>
                NOC Settings
            </div>
            <div className={classes.profileContainer}>
                <div className={classes.profileColumn}>
                    <div className={classes.profileLabel}>
                        User type -{' '}
                        {!('stage' in userDetails) || userDetails.stage === null
                            ? 'prod'
                            : userDetails.stage}
                    </div>
                    <div className={classes.profileLabel}>
                        Server version: {serverVersion}
                    </div>
                    <div className={classes.profileLabel}>
                        Client version: {clientVersion}
                    </div>

                    <Button
                        className={classes.signOutButton}
                        label="Sign Out"
                        onClick={signOut}
                    />
                </div>
            </div>
        </div>
    );
}
