/**
 * Contains any methods related to user authentication (against the Solo Gelato APIs) -
 * login, registration, etc. Uses AWS Cognito and API Gateway libraries.
 */

const AWSCognito = require('amazon-cognito-identity-js');
const apigClientFactory = require('aws-api-gateway-client');

const Config = {
    identityPoolId: 'us-east-1:29e951bc-fa7b-4422-9689-119dd1125166',
    appClientId: '4f0jvubd3d73a8l13beepoajjh',
    userPoolId: 'us-east-1_DgDpnrPoh',
    awsRegion: 'us-east-1',
    apiInvokeUrlDev: 'https://api.solo-gelato.com/dev',
    apiInvokeUrlProd: 'https://api.solo-gelato.com/api',
    apiInvokeUrlRC: 'https://api.solo-gelato.com/rc',
    apiInvokeUrlSimulation: 'https://api.solo-gelato.com/simulation',
    devWebsiteDomain: 'da7hfubbms6my.cloudfront.net',
    rcWebsiteDomain: 'd21jz50mrm8kbu.cloudfront.net',
    prodWebsiteDomain: 'noc.solo-gelato.com',
    simulationWebsiteDomain: 'dchjmg21q4x0a.cloudfront.net',
};

// Configure the AWS Cognito client
const gUserPool = new AWSCognito.CognitoUserPool({
    UserPoolId: Config.userPoolId,
    ClientId: Config.appClientId,
});

const STORAGE_KEY_PREFIX = 'solo_gelato_';
const STORAGE_KEY_USER_DETAILS = `${STORAGE_KEY_PREFIX}user_details`;

/** Get the saved access token from the session - refreshes the token if needed.
 * Callback function receives accessToken and error instances */
function getAccessToken(callback) {
    const user = gUserPool.getCurrentUser();

    if (user == null) {
        callback(null, 'User not logged in');
        return;
    }

    user.getSession((err, session) => {
        if (err) {
            callback(null, err);
            return;
        }

        if (session.isValid()) {
            // Return the token
            callback(session.getIdToken().getJwtToken(), null);
            return;
        }

        // Need to refresh token
        user.refreshSession(session.getRefreshToken(), (error, newSession) => {
            if (error) {
                callback(null, error);
                return;
            }

            // Return the new token
            callback(newSession.getIdToken().getJwtToken(), null);
        });
    });
}

export default class Authentication {
    // The various login states
    static LOGIN_STATUS_NOT_LOGGED_IN = 'not_logged_in';
    static LOGIN_STATUS_LOGGED_IN = 'logged_in';
    static LOGIN_STATUS_UNCONFIRMED = 'unconfirmed';
    static LOGIN_STATUS_NO_PERMISSIONS = 'no_permissions';

    static STAGE_PROD = 'prod';
    static STAGE_DEV = 'dev';
    static STAGE_RC = 'rc';
    static STAGE_SIMULATION = 'simulation';

    static userDetails = null;

    static config = Config;

    static getUserDetails() {
        const json = localStorage.getItem(STORAGE_KEY_USER_DETAILS);

        if (json === null) {
            return null;
        }

        return JSON.parse(json);
    }

    static setUserDetails(userDetails) {
        localStorage.setItem(
            STORAGE_KEY_USER_DETAILS,
            JSON.stringify(userDetails)
        );
    }

    static removeUserDetails() {
        localStorage.removeItem(STORAGE_KEY_USER_DETAILS);
    }

    /** Logs the user out */
    static logOut() {
        const cognitoUser = gUserPool.getCurrentUser();

        if (!cognitoUser) {
            return;
        }

        cognitoUser.signOut();
        this.userDetails = null;
        this.removeUserDetails();
    }

    /** Retrieves the logged-in user attributes (email, etc.) */
    static getUserAttributes(callback) {
        const cognitoUser = gUserPool.getCurrentUser();

        if (!cognitoUser) {
            callback('User not logged in', null);
            return;
        }

        cognitoUser.getSession((err, session) => {
            if (err || !session) {
                callback(err, null);
                return;
            }

            cognitoUser.getUserAttributes(callback);
        });
    }

    /** Re-downloads latest user details */
    static refreshUserDetails(callback) {
        const apiClient = this.getAPIClient();
        apiClient
            .callApi('getUser', 'GET', {}, {})
            .then((response) => {
                console.log('refreshUserDetails - Got user ', response.data);
                this.userDetails = response.data;
                this.setUserDetails(this.userDetails);

                callback(this.userDetails);
            })
            .catch((result) => {
                console.log('error', result);
                callback(null);
            });
    }

    /** Checks whether or not the user is logged in -
     * returns LOGIN_STATUS_* result via callback param */
    static getLoginStatus(callback) {
        const cognitoUser = gUserPool.getCurrentUser();

        this.userDetails = this.getUserDetails();
        console.log('getLoginStatus', cognitoUser, this.userDetails);

        if (!cognitoUser) {
            callback(this.LOGIN_STATUS_NOT_LOGGED_IN);
            return;
        }

        // Make sure user has confirmed his email

        if (this.userDetails === null) {
            this.refreshUserDetails((userDetails) => {
                this.getLoginStatus(callback);
            });
        } else if (!this.userDetails.email_verified) {
            callback(this.LOGIN_STATUS_UNCONFIRMED);
        } else if (!this.userDetails.noc_access && !this.userDetails.is_admin) {
            callback(this.LOGIN_STATUS_NO_PERMISSIONS);
        } else {
            callback(this.LOGIN_STATUS_LOGGED_IN);
        }
    }

    static loadingUserDetails = false;

    static getWebsiteStage() {
        if (
            window.location.hostname === Config.devWebsiteDomain ||
            window.location.hostname === 'localhost'
        ) {
            return Authentication.STAGE_DEV;
        } else if (window.location.hostname === Config.rcWebsiteDomain) {
            return Authentication.STAGE_RC;
        } else if (
            window.location.hostname === Config.simulationWebsiteDomain
        ) {
            return Authentication.STAGE_SIMULATION;
        } else {
            return Authentication.STAGE_PROD;
        }
    }

    static redirectToStageWebsiteIfNeeded(user) {
        const userStage =
            user.stage && user.stage !== null
                ? user.stage
                : Authentication.STAGE_PROD;
        const websiteStage = Authentication.getWebsiteStage();

        if (userStage !== websiteStage) {
            // Log-off from this website
            Authentication.logOut();

            setTimeout(() => {
                // Redirect user to correct staging website
                let url;
                if (userStage === Authentication.STAGE_DEV) {
                    url = Config.devWebsiteDomain;
                } else if (userStage === Authentication.STAGE_RC) {
                    url = Config.rcWebsiteDomain;
                } else if (userStage === Authentication.STAGE_SIMULATION) {
                    url = Config.simulationWebsiteDomain;
                } else {
                    url = Config.prodWebsiteDomain;
                }
                window.location.replace(`${window.location.protocol}//${url}`);
            }, 500);
        }
    }

    /** Returns whether or not we should work with the dev or prod server APIs */
    static getStage(apiClient, forceRefreshCb) {
        const that = this;

        if (this.loadingUserDetails) {
            return this.STAGE_DEV;
        }

        this.loadingUserDetails = true;

        this.userDetails = this.getUserDetails();
        console.log(
            'getStage (1) - ',
            this.userDetails,
            this.userDetails === null,
            typeof this.userDetails
        );
        if (this.userDetails === null || forceRefreshCb) {
            // Get user details
            this.userDetails = {};
            this.refreshUserDetails((userDetails) => {
                this.loadingUserDetails = false;

                if (userDetails && forceRefreshCb) {
                    if (
                        'stage' in this.userDetails &&
                        this.userDetails.stage !== null
                    ) {
                        forceRefreshCb(this.userDetails.stage);
                    } else {
                        forceRefreshCb(Authentication.STAGE_PROD);
                    }
                }
            });

            return this.STAGE_DEV;
        }

        that.loadingUserDetails = false;
        console.log('getStage (2) - ', this.userDetails);

        if ('stage' in this.userDetails && this.userDetails.stage !== null) {
            return this.userDetails.stage;
        }

        return this.STAGE_PROD;
    }

    static getBaseUrl(apiClient) {
        const stage = this.getStage(apiClient);
        let host;

        if (stage === this.STAGE_DEV) {
            host = Config.apiInvokeUrlDev;
        } else if (stage === this.STAGE_RC) {
            host = Config.apiInvokeUrlRC;
        } else if (stage === this.STAGE_SIMULATION) {
            host = Config.apiInvokeUrlSimulation;
        } else {
            host = Config.apiInvokeUrlProd;
        }

        const endpoint = /(^https?:\/\/[^/]+)/g.exec(host)[1];
        const pathComponent = host.substring(endpoint.length);

        console.log('getBaseUrl ', host, pathComponent);

        return pathComponent;
    }

    /** Returns an AWS API Gateway client, configured to perform API
     * calls with the appropriate access token */
    static getAPIClient() {
        const host = Config.apiInvokeUrlProd;

        const endpoint = /(^https?:\/\/[^/]+)/g.exec(host)[1];

        const apigClient = apigClientFactory.default.newClient({
            invokeUrl: endpoint,
        });

        // Add a new method for the API client, that injects the authorization header
        // with the most up-to-date access token, before calling the API.
        apigClient.callApi = (path, method, params, body, headers) => {
            const promise = new Promise((resolve, reject) => {
                getAccessToken((accessToken, error) => {
                    if (error || !accessToken) {
                        // Couldn't get access token
                        reject(error);
                        return;
                    }

                    console.log('API - access token', accessToken);

                    const additionalParams = {
                        headers: { authorization: accessToken },
                    };

                    if (headers) {
                        additionalParams.headers = {
                            ...additionalParams.headers,
                            ...headers,
                        };
                    }

                    let finalPath = `${Authentication.getBaseUrl(
                        apigClient
                    )}/${path}`;

                    if (
                        method === 'GET' &&
                        params &&
                        Object.keys(params).length > 0
                    ) {
                        const urlParams = Object.entries(params)
                            .map(
                                ([key, val]) =>
                                    `${encodeURIComponent(
                                        key
                                    )}=${encodeURIComponent(val)}`
                            )
                            .join('&');
                        finalPath = `${finalPath}?${urlParams}`;
                    }

                    apigClient
                        .invokeApi(
                            null,
                            finalPath,
                            method,
                            additionalParams,
                            body
                        )
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            });

            return promise;
        };

        apigClient.callApiUnauthenticated = (path, method, params, body) => {
            const promise = new Promise((resolve, reject) => {
                const finalPath = `${Authentication.getBaseUrl(
                    apigClient
                )}/${path}`;

                apigClient
                    .invokeApi(params, finalPath, method, {}, body)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });

            return promise;
        };

        return apigClient;
    }

    /** Confirms a specific user with a code.  */
    static confirmUsername(username, code, onSuccess, onFailure) {
        const cognitoUser = new AWSCognito.CognitoUser({
            Username: username,
            Pool: gUserPool,
        });

        cognitoUser.confirmRegistration(code, true, (err) => {
            if (err) {
                if (onFailure) onFailure(err);
                return;
            }

            // We'll need to sign out the user - since it appears after confirmation
            // the access token is not fresh and there is no way of re-login without
            // entering username/password combo again.
            cognitoUser.signOut();

            if (onSuccess) onSuccess();
        });
    }

    /** Sends a reset password link to the specified email address. */
    static resetPassword(username, onSuccess, onFailure) {
        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const cognitoUser = new AWSCognito.CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess,
            onFailure,
        });
    }

    /** Changes a user's password (using old password). */
    static changePassword(
        username,
        oldPassword,
        newPassword,
        onSuccess,
        onFailure
    ) {
        const changePasswordInner = (user) => {
            user.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    onFailure(err);
                } else {
                    onSuccess(result);
                }
            });
        };

        const cognitoUser = gUserPool.getCurrentUser();

        cognitoUser.getSession((err, session) => {
            if (err) {
                onFailure(err);
                return;
            }

            if (session.isValid()) {
                // Return the token
                changePasswordInner(cognitoUser);
                return;
            }

            // Need to refresh session
            cognitoUser.refreshSession(
                session.getRefreshToken(),
                (error, newSession) => {
                    if (error) {
                        onFailure(error);
                        return;
                    }

                    // Return the new token
                    changePasswordInner(cognitoUser);
                }
            );
        });
    }

    /** Changes a user's password (using the provided verification code). */
    static confirmPassword(
        username,
        verificationCode,
        newPassword,
        onSuccess,
        onFailure
    ) {
        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const cognitoUser = new AWSCognito.CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess,
            onFailure,
        });
    }

    /** Registers a user with a username/email/password combo. */
    static registerUser(username, email, password, onSuccess, onFailure) {
        const attributeList = [];

        const attributeEmail = new AWSCognito.CognitoUserAttribute({
            Name: 'email',
            Value: email,
        });

        attributeList.push(attributeEmail);

        gUserPool.signUp(
            username,
            password,
            attributeList,
            null,
            (err, result) => {
                if (err) {
                    console.log('error', err);
                    if (onFailure) onFailure(err);
                    return;
                }

                const user = result.user;
                if (onSuccess) onSuccess(user);
            }
        );
    }

    /** Authenticates the user with a username/password combo. */
    static loginWithPassword(
        username,
        password,
        onSuccess,
        onFailure,
        onNewPasswordRequired
    ) {
        const authenticationData = {
            Username: username,
            Password: password,
        };

        const userData = {
            Username: username,
            Pool: gUserPool,
        };

        const authenticationDetails = new AWSCognito.AuthenticationDetails(
            authenticationData
        );
        const cognitoUser = new AWSCognito.CognitoUser(userData);

        // Authenticate the user
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess() {
                if (onSuccess) onSuccess();
            },
            onFailure(err) {
                if (onFailure) onFailure(err);
            },
            newPasswordRequired(userAttributes) {
                console.log('newPasswordRequired', userAttributes);
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete authentication.

                // The api doesn't accept this field back
                const newUserAttributes = userAttributes;
                delete newUserAttributes.email_verified;

                if (onNewPasswordRequired) {
                    const newPassword = onNewPasswordRequired(
                        newUserAttributes
                    );

                    // Change password
                    cognitoUser.completeNewPasswordChallenge(
                        newPassword,
                        userAttributes,
                        this
                    );
                }
            },
        });
    }
}
