import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import InnerWindow from './InnerWindow';
import flavourIcon from '../res/images/flavour_border.svg';
import clientIcon from '../res/images/client_border.svg';
import settingsIcon from '../res/images/settings_border.svg';
import settingsOnIcon from '../res/images/settings_border_selected.svg';
import eyeIcon from '../res/images/eye_outline.svg';
import noEyeIcon from '../res/images/no_eye_outline.svg';
import arrowDownIcon from '../res/images/arrow_drop_down.svg';
import expandIcon from '../res/images/expand.svg';
import capsuleIcon from '../res/images/capsule.svg';
import clearIcon from '../res/images/close.svg';
import MachineList from './MachineList';
import clone from 'clone';
import {
    findItem,
    getSelected,
    getSelectedClusters,
    getSelectedSites,
    markItem,
    SORT_TYPE_ALPHABETICAL,
    SORT_TYPE_ALPHABETICAL_REVERSE,
    SORT_TYPE_CAPSULE_HIGH_TO_LOW,
    SORT_TYPE_CAPSULE_LOW_TO_HIGH,
    ID_ALL_CLIENTS,
    sortItems,
    ID_ALL_FLAVOURS,
    getMachineHierarchy,
} from '../modules/MachineUtils';
import Checkbox from './Checkbox';
import { markSelectedItems } from '../modules/MachineUtils';
import { useSelector } from 'react-redux';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import ToggleButton from './ToggleButton';
import Button from './Button';
import SearchField from './SearchField';
import FlavourList from './FlavourList';
import Authentication from '../modules/Authentication';
import ExpansionPanel from './ExpansionPanel';

const useStyles = makeStyles({
    window: {
        flexGrow: 1,
        border: '1px solid #B5BFCC',
    },
    separator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#344269',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
        backgroundColor: '#C4CBD7',
    },
    collapsedTitleContainer: {
        justifyContent: 'center',
    },
    collapsedTitle: {
        textTransform: 'uppercase',
        fontSize: '18px',
        transform: 'rotate(90deg) translateX(-50%)',
        whiteSpace: 'nowrap',
        color: '#314867',
    },
    flavourCategoriesContainer: {
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '21px',
        backgroundColor: '#C4CBD7',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
        height: '100%',
    },
    flavourCategory: {
        marginBottom: '20px',
        backgroundColor: '#C4CCD6',
        border: '1px solid #314867',
        borderRadius: '4px',
        height: '60px',
        paddingRight: '0px',
    },
    flavourCategoryLabel: {
        color: '#314867',
        fontSize: '16px',
    },
    flavourFilterVisibility: {
        width: '40px',
        height: '40px',
        filter: hexColorToCSSFilter('#000000'),
        cursor: 'pointer',
    },
    categoryContainer: {
        margin: '15px !important',
        border: '1px solid #314867',
        borderRadius: '4px',
    },
    categoryTitle: {
        color: '#314867',
        fontSize: '16px',
    },
    categoryTitleExpanded: {
        backgroundColor: '#C4CBD7',
        color: '#314867',
        borderRadius: '4px',
    },
    categoryExpanded: {
        borderRadius: '4px',
        padding: '0px',
    },
    categoryLeftIcon: {
        '& img': {
            filter: hexColorToCSSFilter('#314867'),
        },
        '&.Mui-expanded': {
            transform: 'rotate(180deg)',
            '& img': {
                filter: hexColorToCSSFilter('#314867'),
            },
        },
    },
    categoryValue: {
        backgroundColor: '#E1E5ED',
        color: '#314867',
        fontSize: '16px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
    },
    categoryValueRoot: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    categoryValueInner: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingRight: '8px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    clientCategoriesList: {
        height: '100%',
        backgroundColor: '#C4CBD7',
    },
    list: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: '#F9FAFB',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
    },
    flavourList: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '125px',
    },
    itemMachineCount: {
        color: '#e2e2e2',
        fontSize: '16px',
        fontWeight: 300,
        marginRight: '3px',
        marginTop: '3px',
        flexGrow: 1,
    },
    itemMachineIcon: {
        width: '31px',
        height: '31px',
        marginRight: '5px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    itemFlavourCount: {
        color: '#484848',
        fontSize: '16px',
        fontWeight: 300,
        marginRight: '3px',
        marginTop: '3px',
        flexGrow: 1,
    },
    itemFlavourIcon: {
        width: '31px',
        height: '31px',
        marginRight: '5px',
        filter: hexColorToCSSFilter('#000000'),
    },
    itemCheck: {},
    flavourCategoryCheckIcon: {
        border: '1px solid #5A6C84',
    },
    itemCheckIcon: {
        backgroundColor: '#E1E6ED66',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckedOnIcon: {
        backgroundColor: '#E1E6ED66',
        '&:before': {
            filter: hexColorToCSSFilter('#344269'),
        },
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckCategoryIcon: {
        border: '1px solid #5B6885',
    },
    sortButtonsContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E1E5ED',
    },
    sortButton: {
        marginRight: '10px',
        minWidth: '93px',
        height: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        border: '1px solid #7D90AA',
        borderRadius: '4px',
        backgroundColor: '#E1E5ED',
        color: '#7D90AA',
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '10px',
        '&:last-of-type': {
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-selected': {
            backgroundColor: '#7D90AA',
            color: '#E1E5ED',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    buttonLabel: {
        color: '#7D90AA',
        textAlign: 'center',
        fontSize: '10px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    clearLabel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    clearLabelText: {
        marginRight: '12px',
    },
    clearIcon: {
        filter: hexColorToCSSFilter('#939FB9'),
        height: '100%',
        width: '24px',
        borderLeft: '#939FB9 1px solid',
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchHeader: {
        background: '#E1E5ED',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px #B5BDCD solid',
    },
    categoryValues: {
        width: '100%',
    },
    filterVisibility: {
        width: '26px',
        height: '20px',
        marginLeft: 'auto',
        cursor: 'pointer',
        marginRight: '5px',
    },
    filterCapsuleCount: {
        marginLeft: 'auto',
    },
    machineItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#F9FAFB',
    },
    machineItemLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: '#828FAD',
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        borderBottom: '1px solid #B5BFCC',
        paddingLeft: '8px',
    },
    machineItemSubLabel: {
        fontSize: '10px',
    },
    machineItemCapsuleCount: {
        marginLeft: 'auto',
        width: '80px',
        minWidth: '80px',
        height: '100%',
        borderLeft: '1px solid #B5BFCC',
        borderRight: '1px solid #B5BFCC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        color: '#828FAD',
        borderBottom: '1px solid #B5BFCC',
    },
    machineItemCheckboxContainer: {
        width: '50px',
        minWidth: '50px',
        height: '100%',
        borderBottom: '1px solid #B5BFCC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchField: {
        background: '#ffffff',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        height: '40px',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    flavourListItem: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #B5BFCC',
        borderRadius: '10px',
        marginTop: '10px',
        width: '100%',
        padding: 0,
        height: '60px',
        display: 'flex',
        flexDirection: 'row',
    },
    flavourItemLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: '#828FAD',
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        paddingLeft: '8px',
    },
    flavourItemSubLabel: {
        fontSize: '12px',
    },
    flavourItemCapsuleCount: {
        marginLeft: 'auto',
        width: '80px',
        minWidth: '80px',
        height: '100%',
        borderLeft: '1px solid #B5BFCC',
        borderRight: '1px solid #B5BFCC',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        color: '#828FAD',
    },
    flavourItemCheckboxContainer: {
        width: '50px',
        minWidth: '50px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/** Categories view of flavours - allows filtering by categories */
function FlavoursFilterCategories(props) {
    const classes = useStyles();
    const categories = props.categories;

    return (
        <List className={classes.flavourCategoriesContainer}>
            {Object.keys(categories).map((category) => (
                <ListItem key={category} className={classes.flavourCategory}>
                    <div className={classes.flavourCategoryLabel}>
                        {category}
                    </div>
                    <img
                        className={classes.filterVisibility}
                        src={categories[category].visible ? eyeIcon : noEyeIcon}
                        alt={'Visible'}
                        onClick={() => {
                            props.onVisible(
                                category,
                                !categories[category].visible
                            );
                        }}
                    />
                    <Checkbox
                        checked={categories[category].selected}
                        className={classes.itemCheck}
                        iconClassName={clsx(
                            classes.itemCheckIcon,
                            classes.flavourCategoryCheckIcon
                        )}
                        checkedIconClassName={clsx(
                            classes.itemCheckedOnIcon,
                            classes.itemCheckIcon,
                            classes.flavourCategoryCheckIcon
                        )}
                        onChange={(v) => {
                            props.onSelected(
                                category,
                                !categories[category].selected
                            );
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
}

/** Categories view of clients - allows filtering by categories */
function ClientsFilterCategories(props) {
    const classes = useStyles();
    const categories = props.categories;

    const sortedCategories = Object.keys(categories);
    sortedCategories.sort((a, b) => categories[a].order - categories[b].order);

    return (
        <div className={classes.clientCategoriesList}>
            {sortedCategories.map((category) => (
                <ExpansionPanel
                    className={classes.categoryContainer}
                    key={category}
                    title={categories[category].name}
                    leftIcon={arrowDownIcon}
                    leftIconClass={classes.categoryLeftIcon}
                    expandedSummaryClass={classes.categoryTitleExpanded}
                    expansionSummaryClass={classes.categoryTitleExpanded}
                    titleClass={classes.categoryTitle}
                    expandedDetailsClass={classes.categoryExpanded}
                >
                    <List className={classes.categoryValues} disablePadding>
                        {Object.keys(categories[category].values)
                            .sort((a, b) =>
                                categories[category].values[
                                    a
                                ].label.localeCompare(
                                    categories[category].values[b].label
                                )
                            )
                            .map((value, index) => (
                                <ListItem
                                    key={value}
                                    className={classes.categoryValue}
                                    disableGutters
                                    classes={{
                                        root: classes.categoryValueRoot,
                                    }}
                                >
                                    {index === 0 && (
                                        <div className={classes.separator} />
                                    )}
                                    <div className={classes.categoryValueInner}>
                                        <div>
                                            {
                                                categories[category].values[
                                                    value
                                                ].label
                                            }
                                        </div>
                                        <img
                                            className={classes.filterVisibility}
                                            alt={'Visible'}
                                            src={
                                                categories[category].values[
                                                    value
                                                ].visible
                                                    ? eyeIcon
                                                    : noEyeIcon
                                            }
                                            onClick={() => {
                                                props.onVisible(
                                                    category,
                                                    value,
                                                    !categories[category]
                                                        .values[value].visible
                                                );
                                            }}
                                        />
                                        <Checkbox
                                            checked={
                                                categories[category].values[
                                                    value
                                                ].selected
                                            }
                                            className={classes.itemCheck}
                                            iconClassName={
                                                classes.itemCheckIcon
                                            }
                                            checkedIconClassName={clsx(
                                                classes.itemCheckedOnIcon,
                                                classes.itemCheckIcon,
                                                classes.itemCheckCategoryIcon
                                            )}
                                            onChange={(v) => {
                                                props.onSelected(
                                                    category,
                                                    value,
                                                    !categories[category]
                                                        .values[value].selected
                                                );
                                            }}
                                        />
                                    </div>

                                    {index <
                                        Object.keys(categories[category].values)
                                            .length -
                                            1 && (
                                        <div className={classes.separator} />
                                    )}
                                </ListItem>
                            ))}
                    </List>
                </ExpansionPanel>
            ))}
        </div>
    );
}

const FILTER_TYPE_CLIENTS = 'clients';
const FILTER_TYPE_FLAVOURS = 'flavours';

const gApiClient = Authentication.getAPIClient();

/** Tree view of countries/clients/sites/clusters/etc., allowing to filter by any of these and also by flavours */
export default function ClientsFilter(props) {
    const classes = useStyles();
    const [filterType, setFilterType] = useState(FILTER_TYPE_CLIENTS);
    const [clients, setClients] = useState([]);
    const [flavours, setFlavours] = useState([]);
    const [sortType, setSortType] = useState(SORT_TYPE_ALPHABETICAL);
    const [showMarked, setShowMarked] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [searchText, setSearchText] = useState('');
    const filter = useSelector((state) => state.reports.filter);
    const [clientCategories, setClientCategories] = useState({});
    const [flavourCategories, setFlavourCategories] = useState({});
    const [initializedCategories, setInitializedCategories] = useState(false);

    useEffect(() => {
        if (!filter.filterClientCategories) return;
        if (!filter.countries) return;

        const categories = filter.filterClientCategories;

        if (
            !('country' in categories) &&
            filter.countries &&
            filter.countries.length > 0
        ) {
            categories['country'] = {
                name: 'Country',
                order: 0,
                values: Object.fromEntries(
                    filter.countries.map((c) => [c._id, c.name])
                ),
            };
            Object.keys(categories['country']['values']).forEach((k) => {
                categories['country']['values'][k] = {
                    label: categories['country']['values'][k],
                    selected: false,
                    visible: true,
                };
            });

            setClientCategories(categories);
        }
    }, [filter.countries, filter.filterClientCategories]);

    useEffect(() => {
        if (!filter.filterClientCategories) return;
        if (initializedCategories) return;

        const categories = filter.filterClientCategories;

        Object.keys(categories).forEach((c) => {
            Object.keys(categories[c]['values']).forEach((k) => {
                if (typeof categories[c]['values'][k] === 'string') {
                    categories[c]['values'][k] = {
                        label: categories[c]['values'][k],
                        selected: false,
                        visible: true,
                    };
                }
            });
        });
        setInitializedCategories(true);
        setClientCategories(categories);
    }, [filter.filterClientCategories, initializedCategories]);

    useEffect(() => {
        props.onClientCategoriesChange(clientCategories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientCategories]);

    useEffect(() => {
        props.onFlavourCategoriesChange(flavourCategories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flavourCategories]);

    const switchFilterType = () => {
        if (filterType === FILTER_TYPE_CLIENTS) {
            setFilterType(FILTER_TYPE_FLAVOURS);
            setSearchText('');
        } else {
            setFilterType(FILTER_TYPE_CLIENTS);
            setSearchText('');
        }
    };

    useEffect(() => {
        const clientsCopy = clone(props.clients);
        if (clientsCopy.length > 0) {
            // Sort items
            const allClients = clientsCopy.shift(); // All clients result will always be first
            sortItems(clientsCopy, sortType);
            clientsCopy.unshift(allClients);
        }

        markSelectedItems(clientsCopy, filter.clients);
        setClients(clientsCopy);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clients]);

    useEffect(() => {
        const flavoursCopy = clone(props.flavours);
        if (flavoursCopy.length > 0) {
            // Sort items
            const allFlavours = flavoursCopy.shift(); // All flavours result will always be first
            sortItems(flavoursCopy, sortType);
            flavoursCopy.unshift(allFlavours);
        }

        markSelectedItems(flavoursCopy, filter.flavours);
        setFlavours(flavoursCopy);

        const categories = {};
        flavoursCopy.forEach((flavour) => {
            flavour.categories.forEach((category) => {
                if (!categories[category]) {
                    categories[category] = {
                        capsule_count: 0,
                        visible: true,
                        selected: true,
                    };
                }
                categories[category].capsule_count += flavour.capsule_count;
            });
        });
        if (
            !flavourCategories ||
            Object.keys(flavourCategories).length !==
                Object.keys(categories).length
        ) {
            setFlavourCategories(categories);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flavours]);

    useEffect(() => {
        const newFlavours = getSelected(flavours);
        if (newFlavours.length !== filter.flavours.length) {
            props.onFlavoursSelected(newFlavours);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flavours]);

    useEffect(() => {
        const newClients = [
            ...getSelected(clients),
            ...getSelectedSites(clients),
            ...getSelectedClusters(clients),
        ];

        if (newClients.length !== filter.clients.length) {
            props.onClientsSelected(newClients);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients]);

    useEffect(() => {
        // Sort type has changed - update clients and flavours

        if (clients && clients.length > 0) {
            const clientsCopy = clone(clients);
            const allClients = clientsCopy.shift(); // All clients result will always be first
            sortItems(clientsCopy, sortType);
            clientsCopy.unshift(allClients);

            setClients(clientsCopy);
        }

        if (flavours && flavours.length > 0) {
            const flavoursCopy = clone(flavours);
            const allFlavours = flavoursCopy.shift(); // All flavours result will always be first
            sortItems(flavoursCopy, sortType);
            flavoursCopy.unshift(allFlavours);

            setFlavours(flavoursCopy);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    const renderMachineItem = (props) => {
        const item = props.item;
        const isMachine = item.previous_clusters;
        const hasParent = item.parent;
        const level = props.level || 0;
        let capsuleCount = item.capsule_count;

        if (item._id === ID_ALL_CLIENTS) {
            const newCount = clients
                .filter((c) => c.visible && c._id !== ID_ALL_CLIENTS)
                .reduce((acc, c) => acc + c.capsule_count, 0);
            capsuleCount = newCount;
        }

        let levelWidth = 0;
        if (level === 1) {
            levelWidth = 10;
        } else if (level === 2) {
            levelWidth = 20;
        }

        return (
            <div className={classes.machineItemContainer}>
                <div
                    style={{
                        width: '5px',
                        height: '100%',
                        marginLeft: `${levelWidth}px`,
                        borderRight: '1px solid #B5BFCC',
                        backgroundColor: `${level === 2 ? '#B5BFCC' : null}`,
                    }}
                ></div>
                <div className={classes.machineItemLabel}>
                    <div>{isMachine ? item.alias : item.name}</div>
                    {hasParent && (
                        <div className={classes.machineItemSubLabel}>
                            {getMachineHierarchy(item.parent)}
                        </div>
                    )}
                </div>
                <div className={classes.machineItemCapsuleCount}>
                    {capsuleCount}
                </div>
                <div className={classes.machineItemCheckboxContainer}>
                    <Checkbox
                        checked={
                            filter.clients.findIndex(
                                (x) => x._id === item._id
                            ) > -1
                                ? true
                                : false
                        }
                        className={classes.itemCheck}
                        iconClassName={classes.itemCheckIcon}
                        checkedIconClassName={clsx(
                            classes.itemCheckedOnIcon,
                            classes.itemCheckIcon
                        )}
                        onChange={(v) => {
                            const clientsCopy = clone(clients);

                            if (item._id === ID_ALL_CLIENTS) {
                                // Unmark all other items
                                clientsCopy.map((x) => markItem(x, false));

                                clientsCopy[0].selected = v;
                                markItem(clientsCopy, v);
                            } else {
                                const itemCopy = findItem(
                                    clientsCopy,
                                    item._id
                                );
                                markItem(itemCopy, v);

                                // Uncheck "All clients" option
                                clientsCopy[0].selected = false;
                            }
                            setClients(clientsCopy);
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderFlavourItem = (props) => {
        const item = props.item;
        const capsuleCount = item.capsule_count;

        // Hide flavours with zero capsule count if they're not ready for production
        if (!item.is_ready_for_production && capsuleCount === 0) return null;

        return (
            <ListItem button={false} className={classes.flavourListItem}>
                <div className={classes.flavourItemLabel}>
                    <div>{item.title}</div>
                    {item._id !== ID_ALL_FLAVOURS && (
                        <div className={classes.flavourItemSubLabel}>
                            ({!item.is_ready_for_production && '*'}
                            {item._id})
                        </div>
                    )}
                </div>
                <div className={classes.flavourItemCapsuleCount}>
                    {capsuleCount}
                    <img src={capsuleIcon} alt="Capsule Count" />
                </div>
                <div className={classes.flavourItemCheckboxContainer}>
                    <Checkbox
                        checked={
                            filter.flavours.findIndex(
                                (x) => x._id === item._id
                            ) > -1
                                ? true
                                : false
                        }
                        className={classes.itemCheck}
                        iconClassName={classes.itemCheckIcon}
                        checkedIconClassName={clsx(
                            classes.itemCheckedOnIcon,
                            classes.itemCheckIcon
                        )}
                        onChange={(v) => {
                            const flavoursCopy = clone(flavours);

                            if (item._id === ID_ALL_FLAVOURS) {
                                // Unmark all other items
                                flavoursCopy.forEach((x) => {
                                    x.selected = false;
                                });

                                flavoursCopy[0].selected = v;
                            } else {
                                const itemCopy = findItem(
                                    flavoursCopy,
                                    item._id
                                );
                                itemCopy.selected = v;

                                // Uncheck "All flavours" option
                                flavoursCopy[0].selected = false;
                            }
                            setFlavours(flavoursCopy);
                        }}
                    />
                </div>
            </ListItem>
        );
    };

    const unmarkAll = () => {
        if (filterType === FILTER_TYPE_CLIENTS) {
            // Unmark all clusters/clients, just leaving the "All clients" marked
            const clientsCopy = clone(clients);
            clientsCopy.map((x) => markItem(x, false));
            clientsCopy[0].selected = true;
            markItem(clientsCopy, false);
            setClients(clientsCopy);
        } else {
            // Unmark all flavours, just leaving the "All flavours" marked
            const flavoursCopy = clone(flavours);
            flavoursCopy.forEach((x) => {
                x.selected = false;
            });
            // Check "All flavours" option
            flavoursCopy[0].selected = true;
            setFlavours(flavoursCopy);
        }
    };

    const onClientFilterSelected = (category, value, selected) => {
        const newCategories = clone(clientCategories);
        newCategories[category].values[value].selected = selected;

        setClientCategories(newCategories);

        if (!selected) {
            // Also unselect any clients that fall under that category
            const clientsCopy = clone(clients);
            clientsCopy
                .filter((x) => x[category] === value)
                .map((x) => markItem(x, false));

            // Re-check "All Clients" if no other client is selected
            if (clientsCopy.every((x) => !x.selected)) {
                clientsCopy[0].selected = true;
            }

            setClients(clientsCopy);
        }
    };

    const onClientFilterVisible = (category, value, visible) => {
        const newCategories = clone(clientCategories);
        newCategories[category].values[value].visible = visible;

        setClientCategories(newCategories);
    };

    const onFlavourFilterSelected = (category, selected) => {
        const newCategories = clone(flavourCategories);
        newCategories[category].selected = selected;
        setFlavourCategories(newCategories);
    };

    const onFlavourFilterVisible = (category, visible) => {
        const newCategories = clone(flavourCategories);
        newCategories[category].visible = visible;
        setFlavourCategories(newCategories);
    };

    if (!props.expanded) {
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                contentClassName={classes.collapsedTitleContainer}
                icon={expandIcon}
                onIconClick={props.onExpanded}
            >
                <div className={classes.collapsedTitle}>Filters Tab</div>
            </InnerWindow>
        );
    }

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={filterType === FILTER_TYPE_CLIENTS ? 'Clients' : 'Flavours'}
            icon={filterType === FILTER_TYPE_CLIENTS ? flavourIcon : clientIcon}
            onIconClick={switchFilterType}
            leftIcon={!showSettings ? settingsIcon : settingsOnIcon}
            onLeftIconClick={() => {
                setShowSettings(!showSettings);
            }}
            loading={props.loading}
        >
            {showSettings && (
                <div className={classes.content}>
                    {filterType === FILTER_TYPE_CLIENTS && (
                        <ClientsFilterCategories
                            categories={clientCategories}
                            onSelected={onClientFilterSelected}
                            onVisible={onClientFilterVisible}
                        />
                    )}
                    {filterType === FILTER_TYPE_FLAVOURS && (
                        <FlavoursFilterCategories
                            categories={flavourCategories}
                            onSelected={onFlavourFilterSelected}
                            onVisible={onFlavourFilterVisible}
                        />
                    )}
                </div>
            )}
            {!showSettings && (
                <div className={classes.content}>
                    <div className={classes.searchHeader}>
                        <SearchField
                            className={classes.search}
                            placeholder="Search"
                            onSearch={setSearchText}
                            value={searchText}
                            backgroundClass={classes.searchField}
                            inputClass={classes.searchFieldInput}
                            iconClass={classes.searchFieldIcon}
                        />
                    </div>

                    <div className={classes.sortButtonsContainer}>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(
                                    sortType === SORT_TYPE_ALPHABETICAL
                                        ? SORT_TYPE_ALPHABETICAL_REVERSE
                                        : SORT_TYPE_ALPHABETICAL
                                )
                            }
                            selected={
                                sortType === SORT_TYPE_ALPHABETICAL ||
                                sortType === SORT_TYPE_ALPHABETICAL_REVERSE
                            }
                        >
                            {sortType === SORT_TYPE_ALPHABETICAL_REVERSE
                                ? 'Z-A'
                                : 'A-Z'}
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(
                                    sortType === SORT_TYPE_CAPSULE_HIGH_TO_LOW
                                        ? SORT_TYPE_CAPSULE_LOW_TO_HIGH
                                        : SORT_TYPE_CAPSULE_HIGH_TO_LOW
                                )
                            }
                            selected={
                                sortType === SORT_TYPE_CAPSULE_HIGH_TO_LOW ||
                                sortType === SORT_TYPE_CAPSULE_LOW_TO_HIGH
                            }
                        >
                            {sortType === SORT_TYPE_CAPSULE_LOW_TO_HIGH
                                ? 'Low - High'
                                : 'High - Low'}
                        </ToggleButton>

                        <ToggleButton
                            className={clsx(
                                classes.sortButton,
                                classes.buttonLabel
                            )}
                            onChange={() => setShowMarked(!showMarked)}
                            selected={showMarked}
                        >
                            View Checked
                        </ToggleButton>

                        <Button
                            className={classes.sortButton}
                            labelClass={clsx(
                                classes.buttonLabel,
                                classes.clearLabel
                            )}
                            label={
                                <>
                                    <div className={classes.clearLabelText}>
                                        Clear
                                    </div>
                                    <img
                                        className={classes.clearIcon}
                                        src={clearIcon}
                                        alt="Clear"
                                    />
                                </>
                            }
                            onClick={unmarkAll}
                        />
                    </div>

                    {filterType === FILTER_TYPE_CLIENTS && (
                        <MachineList
                            className={classes.list}
                            items={clients}
                            filter={searchText}
                            countries={[]}
                            clientTypes={[]}
                            categories={clientCategories}
                            hideNonMarked={showMarked}
                            customListItem={renderMachineItem}
                        />
                    )}
                    {filterType === FILTER_TYPE_FLAVOURS && (
                        <FlavourList
                            className={clsx(classes.list, classes.flavourList)}
                            flavours={flavours}
                            filter={searchText}
                            categories={flavourCategories}
                            hideNonMarked={showMarked}
                            customListItem={renderFlavourItem}
                        />
                    )}
                </div>
            )}
        </InnerWindow>
    );
}
