import * as actions from '../actions/overview';
import { usePrevious } from '../../modules/HookUtils';
import { useEffect } from 'react';
import { findAllMachines } from '../../modules/MachineUtils';

const INITIAL_STATE = {
    filter: {
        // Each selected flavour/client/etc. is an entire object of that type.
        // Clients/sites/clusters/machines have a "selected" property (=whether or not should they be visible in the machines dashboard,
        // and for machines - whether or not they should visible on the map). Machines also have a "color" property (this
        // is used when showing them in the KPIs graph)
        clients: [],
        // Selected machine objects (machines that will be visible on the map)
        selectedMachines: [],
        allMachinesSelected: false, // Are all machines selected?
        clientTypes: [], // Possible client types (office, cafe, etc.)
        countries: [], // List of countries (for filtering)
        selectedCountries: new Set(),
        selectedClientTypes: new Set(),
    },
    // Machines that should be highlighted on the map (key = machine ID) - we use a map instead of an array for quick access that's needed
    mapSelectedMachines: {},
    // Machines that are viewed in the bottom view that pops up
    bottomSelectedMachines: [],
};

/** Returns a smaller version of the selected clients list, one that doesn't include parent/child references,
 * so it'll be possible to JSON-stringify it. */
function cleanMachineList(clients) {
    return clients.map((c) => {
        const newItem = {};
        Object.keys(c)
            .filter(
                (k) =>
                    k !== 'children' &&
                    k !== 'parent' &&
                    k !== 'selectedMachines'
            )
            .forEach((k) => {
                newItem[k] = c[k];
            });
        return newItem;
    });
}

/** Checks if a filter has changed (the filtered machines) */
export function useFilterChanged(filter, onFilterChanged) {
    const prevMachines = usePrevious(filter.selectedMachines);
    const allMachines = findAllMachines(filter.clients);
    const prevAllMachines = usePrevious(allMachines);

    useEffect(() => {
        let selectedMachinesChanged = true;
        let allMachinesChanged = true;

        if (prevMachines) {
            selectedMachinesChanged =
                JSON.stringify(cleanMachineList(filter.selectedMachines)) !==
                JSON.stringify(cleanMachineList(prevMachines));
        }
        if (prevAllMachines) {
            allMachinesChanged =
                JSON.stringify(cleanMachineList(allMachines)) !==
                JSON.stringify(cleanMachineList(prevAllMachines));
        }

        if (!selectedMachinesChanged && !allMachinesChanged) return;

        onFilterChanged(
            !selectedMachinesChanged &&
                allMachines &&
                prevAllMachines &&
                allMachines.length === prevAllMachines.length
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.selectedMachines, filter.clients]);
}

/** Overview Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_OVERVIEW_BOTTOM_SELECTED_MACHINES:
            return { ...state, bottomSelectedMachines: action.value };

        case actions.SET_OVERVIEW_MAP_SELECTED_MACHINES:
            return { ...state, mapSelectedMachines: action.value };

        case actions.SET_OVERVIEW_FILTER:
            const newFilter = { ...state.filter, ...action.value }; // Just overwrite any input fields given to the filter
            return { ...state, filter: newFilter };
        default:
            return state;
    }
};
