import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles({
    listContainer: {
        overflowY: 'auto',
        transform: 'scaleX(-1)',
        height: 0,
        position: 'relative',
    },
    list: {
        transform: 'scaleX(-1)',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    divider: {
        backgroundColor: '#bec7d4',
        height: '1px',
        position: 'absolute',
        bottom: 0,
        left: '14px',
        right: '14px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '66px',
    },
    itemLabel: {
        fontSize: '12px',
        color: '#000000',
        flexGrow: 1,
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

/** UI base for flavours */
function FlavourListItem(props) {
    const classes = useStyles();

    const item = props.item;

    if (!item.visible) {
        // This item doesn't match - don't show it
        return null;
    }

    return (
        <div>
            <ListItem
                button={false}
                className={clsx(classes.listItem, props.className)}
                style={props.style}
            >
                <div className={classes.itemLabel}>{item.title}</div>

                <div className={classes.itemRightSide}>
                    {props.renderRightPart ? props.renderRightPart(item) : null}
                </div>
                <div className={classes.divider} />
            </ListItem>
        </div>
    );
}

/** Simplistic string comparison/search function */
function matchesSearch(title, filter) {
    return title.toLowerCase().indexOf(filter.toLowerCase().trim()) > -1;
}

/** Filters items according to search string */
function filterItem(item, filter, categories, hideNonMarked) {
    // Mark as matched/not-matched
    item.matched = matchesSearch(item.title, filter);
    if (hideNonMarked && !item.selected) item.matched = false;
    item.visible = item.matched;

    let skipItem = false;
    if (categories && Object.keys(categories).length > 0 && item.categories) {
        // Filter by categories
        Object.keys(categories).forEach((category) => {
            if (
                !categories[category].selected &&
                item.categories.includes(category)
            ) {
                skipItem = true;
            }
        });
    }

    if (skipItem) {
        item.matched = false;
        item.visible = false;

        return;
    }

    return item.matched;
}

/** Filters all result items according to search string */
function filterAllItems(items, filter, categories, hideNonMarked) {
    let shouldFilter =
        (filter && filter.length > 0) ||
        (categories && Object.keys(categories).length > 0) ||
        hideNonMarked;

    if (shouldFilter) {
        items.forEach((item) => {
            filterItem(item, filter, categories, hideNonMarked);
        });
    } else {
        // No filter
        items.forEach((item) => {
            item.matched = false;
            item.visible = true;
        });
    }

    return items;
}

/** A list showing flavours */
export default function FlavourList(props) {
    const classes = useStyles();
    let [items, setItems] = useState([...props.flavours]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const newItems = filterAllItems(
            props.flavours,
            props.filter,
            props.categories,
            props.hideNonMarked
        );

        setItems([...newItems]);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter, props.flavours, props.categories, props.hideNonMarked]);

    return (
        <div className={clsx(classes.listContainer, props.className)}>
            {!loading && (
                <List className={classes.list}>
                    {items.map((m) =>
                        props.customListItem ? (
                            m.visible ? (
                                <props.customListItem item={m} key={m._id} />
                            ) : null
                        ) : (
                            <FlavourListItem
                                item={m}
                                key={m._id}
                                renderRightPart={props.renderRightPart}
                                className={props.listItemClassName}
                            />
                        )
                    )}
                </List>
            )}
            <LoadingOverlay loading={loading} />
        </div>
    );
}
