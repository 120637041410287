import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import closeIcon from '../../res/images/close.svg';
import { IconButton } from '@material-ui/core';
import leftArrow from '../../res/images/left_arrow.svg';
import rightArrow from '../../res/images/right_arrow.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { DateTime } from 'luxon';
import { DatePicker } from '@material-ui/pickers';
import { sameDay } from '../../modules/DateUtils';
import Button from '../../components/Button';
import { setReportsFilter } from '../../store/actions/reports';
import Select from '../../components/Select';
import {
    SELECTION_TYPE_ENDING_DATE,
    SELECTION_TYPE_DAY,
    SELECTION_TYPE_WEEK,
    useFilterChanged,
} from '../../store/reducers/reports';
import { SHOW_BY_MONTH, SHOW_BY_WEEK } from './ReportsWindow';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ffffff00',
        zIndex: 999,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    overlay: {
        top: 0,
        backgroundColor: '#ffffffcc',
    },
    closedFilterContainer: {
        height: '68px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    closedFilterPre: {
        backgroundColor: '#8090a8',
        width: '22px',
        height: '17px',

        '&::before': {
            content: '',
            backgroundColor: '#0074D9',
            bottom: '-10px',
            height: '50px',
            width: '25px',
            borderTopLeftRadius: '25px',
        },
    },
    closedFilterPost: {
        backgroundColor: '#8090a8',
        width: '22px',
        height: '17px',
    },
    closedFilterContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#8090a8',
        width: '445px',
        height: '65px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        cursor: 'pointer',
    },
    invertCornerLeft: {
        borderBottomRightRadius: '30px',
        backgroundColor: '#F2F5F9',
        width: '22px',
        height: '17px',
    },
    invertCornerRight: {
        borderBottomLeftRadius: '30px',
        backgroundColor: '#F2F5F9',
        width: '22px',
        height: '17px',
    },
    arrowButton: {
        padding: '2px',
        marginRight: '11px',
        marginLeft: '11px',
        backgroundColor: '#344269',
        width: '36px',
        height: '36px',
    },
    arrowButtonImage: {
        width: '36px',
        minWidth: '36px',
        height: '36px',
        minHeight: '36px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#E1E5ED'),
    },
    filterLabel: {
        flexGrow: 1,
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    openFilterContent: {
        backgroundColor: '#8090a8',
        width: '100%',
        borderTopLeftRadius: '33px',
        borderTopRightRadius: '33px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '34px',
    },
    openFilterInnerContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },
    openFilterTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontSize: '24px',
        textAlign: 'center',
        paddingTop: '22px',
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
    },
    openFilterSubTitle: {
        color: '#FFFFFF',
        fontSize: '22px',
        textAlign: 'center',
        marginTop: '22px',
    },
    openFilterTopRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '80px',
        width: '100%',
        backgroundColor: '#5A6C84',
        marginBottom: '44px',
        marginTop: '26px',
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        backgroundColor: '#5B6885',
        padding: '3px',
    },
    closeButtonImage: {
        width: '36px',
        minWidth: '36px',
        height: '36px',
        minHeight: '36px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    openFilterMainPart: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f1f3f6',
        width: '667px',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f1f3f6',
        width: '367px',
        paddingRight: '33px',
        paddingLeft: '33px',
    },
    dayContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    todayIndicator: {
        width: '6px',
        height: '6px',
        backgroundColor: '#49b271',
        borderRadius: '6px',
        marginTop: '2px',
    },
    todayLabel: {
        color: '#49b271',
    },
    highlightDay: {
        background: '#F0F1F6',
        color: '#7d90aa',
        borderTop: '2px solid #B5BFCC',
        borderBottom: '2px solid #B5BFCC',
    },
    firstHighlight: {
        extend: 'highlight',
        background: '#F0F1F6',
        borderTopLeftRadius: '30%',
        borderBottomLeftRadius: '30%',
        borderTop: '2px solid #B5BFCC',
        borderLeft: '2px solid #B5BFCC',
        borderBottom: '2px solid #B5BFCC',
    },
    endHighlight: {
        extend: 'highlight',
        background: '#F0F1F6',
        borderTopRightRadius: '30%',
        borderBottomRightRadius: '30%',
        borderTop: '2px solid #B5BFCC',
        borderRight: '2px solid #B5BFCC',
        borderBottom: '2px solid #B5BFCC',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    okButton: {
        width: '100%',
        height: '30px',
        maxHeight: '30px',
        minHeight: '30px',
        marginTop: '28px',
        backgroundColor: '#344269',
    },
    okLabelButton: {
        color: '#FFFFFF',
        textSize: '18px',
    },
    selectedDate: {
        width: '100%',
        height: '44px',
        border: '1px solid #7D90AA',
        borderRadius: '4px',
        fontSize: '18px',
        color: '#314867',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '11px',
    },
    dateSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '51px',
        alignItems: 'center',
        border: '1px solid #7D90AA',
        borderRadius: '4px',
    },
    selectedDateLabel: {
        fontSize: '12px',
        color: '#5A6C84',
        width: '50%',
        textAlign: 'center',
    },
    currentDateLabel: {
        fontSize: '14px',
        color: '#5A6C84',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    selector: {
        width: '50%',
        borderLeft: '1px solid #7D90AA',
    },
    selectorRoot: {
        backgroundColor: '#B5BDCD',
        color: '#314867',
        fontWeight: 'bold',
        fontSize: '18px',
        justifyContent: 'center',
        paddingRight: '0px !important',
    },
}));

/** Formats the start and date (used as a title) - assumes input dates are Luxon DateTime instances */
export function formatDateTitle(startDate, endDate) {
    if (sameDay(startDate, endDate)) {
        // Day filter
        return startDate.toFormat('dd.MM.yyyy');
    }

    // Week filter / multiple day filter
    return `${startDate.toFormat('dd.MM.yyyy')} - ${endDate.toFormat(
        'dd.MM.yyyy'
    )}`;
}

/** Closed (collapsed) view of the filter - just showing currently selected date and arrows */
function ClosedFilter(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.reports.filter);
    const dispatch = useDispatch();
    const [dispatchTimer, setDispatchTimer] = useState(null);
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);
    let diff;

    if (
        filter.selectionType === SELECTION_TYPE_WEEK ||
        (filter.selectionType === SELECTION_TYPE_ENDING_DATE &&
            props.showBy === SHOW_BY_WEEK)
    ) {
        diff = { weeks: 1 };
    } else if (
        filter.selectionType === SELECTION_TYPE_ENDING_DATE &&
        props.showBy === SHOW_BY_MONTH
    ) {
        diff = { months: 1 };
    } else {
        diff = { days: 1 };
    }

    useFilterChanged(
        filter,
        () => {
            setStartDate(filter.startDate);
            setEndDate(filter.endDate);
        },
        true
    );

    // When moving back/forward in the time periods, wait for the user to
    // stop moving around before actually updating the filter's start/end dates.
    const updateFilterLater = (newStartDate, newEndDate) => {
        if (dispatchTimer !== null) clearTimeout(dispatchTimer);

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        setDispatchTimer(
            setTimeout(() => {
                dispatch(
                    setReportsFilter({
                        startDate: newStartDate,
                        endDate: newEndDate,
                    })
                );
                setDispatchTimer(null);
            }, 500)
        );
    };

    const onPreviousPeriod = () => {
        let newStartDate, newEndDate;

        newStartDate = startDate.minus(diff);
        newEndDate = endDate.minus(diff);

        updateFilterLater(newStartDate, newEndDate);
    };
    const onNextPeriod = () => {
        let newStartDate, newEndDate;

        newStartDate = startDate.plus(diff);
        newEndDate = endDate.plus(diff);

        updateFilterLater(newStartDate, newEndDate);
    };

    return (
        <div className={classes.closedFilterContainer}>
            <div className={classes.closedFilterContent}>
                <IconButton
                    className={classes.arrowButton}
                    onClick={onPreviousPeriod}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={leftArrow}
                        alt={'Previous Period'}
                    />
                </IconButton>

                <div className={classes.filterLabel} onClick={props.onOpen}>
                    {formatDateTitle(startDate, endDate)}
                </div>

                <IconButton
                    className={classes.arrowButton}
                    onClick={onNextPeriod}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={rightArrow}
                        alt={'Next Period'}
                    />
                </IconButton>
            </div>
        </div>
    );
}

function getSelectorItems(selectionType) {
    const count =
        selectionType === SELECTION_TYPE_DAY
            ? MAX_DAYS_SELECTION
            : MAX_WEEKS_SELECTION;
    return [...Array(count)].map((_, x) => ({ label: x + 1, value: x + 1 }));
}

// Max number of consecutive weeks/days you choose using the selector
const MAX_WEEKS_SELECTION = 12;
const MAX_DAYS_SELECTION = 7;

/** Open (expanded) view of the filter - showing full calendar view and day/week selector */
function OpenFilter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.reports.filter);
    const [selectionCount, setSelectionCount] = useState({
        value: filter.selectionCount,
        label: filter.selectionCount,
    });
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);

    useEffect(() => {
        setValue(startDate, selectionCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue(endDate, selectionCount, filter.selectionType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionCount]);

    const setValue = (
        v,
        count = selectionCount,
        type = filter.selectionType
    ) => {
        let newStartDate, newEndDate;

        if (type === SELECTION_TYPE_ENDING_DATE) {
            newStartDate = v.startOf('day');
            newEndDate = v.endOf('day');
        } else if (type === SELECTION_TYPE_DAY) {
            newStartDate = v.startOf('day').minus({ days: count.value - 1 });
            newEndDate = v.endOf('day');
        } else if (type === SELECTION_TYPE_WEEK) {
            newStartDate = v
                .startOf('day')
                .minus({ days: 6 })
                .minus({ weeks: count.value - 1 });
            newEndDate = v.endOf('day');
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const onClose = () => {
        props.onClose();
    };

    const onConfirm = () => {
        // Update filter
        dispatch(
            setReportsFilter({
                startDate: startDate,
                endDate: endDate,
                selectionCount: selectionCount.value,
            })
        );

        props.onClose();
    };

    let diff;

    if (
        filter.selectionType === SELECTION_TYPE_WEEK ||
        (filter.selectionType === SELECTION_TYPE_ENDING_DATE &&
            props.showBy === SHOW_BY_WEEK)
    ) {
        diff = { weeks: 1 };
    } else if (
        filter.selectionType === SELECTION_TYPE_ENDING_DATE &&
        props.showBy === SHOW_BY_MONTH
    ) {
        diff = { months: 1 };
    } else {
        diff = { days: 1 };
    }

    const onPreviousPeriod = () => {
        let newStartDate, newEndDate;

        newStartDate = startDate.minus(diff);
        newEndDate = endDate.minus(diff);

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };
    const onNextPeriod = () => {
        let newStartDate, newEndDate;

        newStartDate = startDate.plus(diff);
        newEndDate = endDate.plus(diff);

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const today = DateTime.local();

    return (
        <div className={classes.openFilterContent}>
            <div className={classes.openFilterInnerContent}>
                <div className={classes.openFilterTitle}>
                    <div>Time Filter</div>
                    <IconButton
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <img
                            className={classes.closeButtonImage}
                            src={closeIcon}
                            alt={'Close'}
                        />
                    </IconButton>
                </div>
                <div className={classes.openFilterSubTitle}>
                    Please select a date.
                    <br />
                    For this report type - You can select{' '}
                    {filter.selectionType === SELECTION_TYPE_ENDING_DATE ? (
                        <span>
                            the <u>ending</u> date.
                        </span>
                    ) : (
                        <span>
                            up to{' '}
                            {filter.selectionType === SELECTION_TYPE_DAY
                                ? MAX_DAYS_SELECTION
                                : MAX_WEEKS_SELECTION}{' '}
                            consecutive{' '}
                            {filter.selectionType === SELECTION_TYPE_DAY
                                ? 'days'
                                : 'weeks'}
                        </span>
                    )}
                </div>
                <div className={classes.openFilterTopRow}>
                    <IconButton
                        className={classes.arrowButton}
                        onClick={onPreviousPeriod}
                    >
                        <img
                            className={classes.arrowButtonImage}
                            src={leftArrow}
                            alt={'Previous Period'}
                        />
                    </IconButton>
                    <div className={classes.filterLabel}>
                        {formatDateTitle(startDate, endDate)}
                    </div>
                    <IconButton
                        className={classes.arrowButton}
                        onClick={onNextPeriod}
                    >
                        <img
                            className={classes.arrowButtonImage}
                            src={rightArrow}
                            alt={'Next Period'}
                        />
                    </IconButton>
                </div>
                <div className={classes.openFilterMainPart}>
                    <DatePicker
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={startDate}
                        onChange={setValue}
                        className={classes.picker}
                        disableToolbar={true}
                        renderDay={(day, selectedDate, isInCurrentMonth) => {
                            const dayIsBetween =
                                day >= startDate && day <= endDate;
                            const isFirstDay = sameDay(day, startDate);
                            const isLastDay = sameDay(day, endDate);

                            const dayClassName = clsx(classes.day, {
                                [classes.nonCurrentMonthDay]: !isInCurrentMonth,
                                [classes.highlightNonCurrentMonthDay]:
                                    !isInCurrentMonth && dayIsBetween,
                            });
                            const wrapperClassName = clsx({
                                [classes.highlightDay]: dayIsBetween,
                                [classes.firstHighlight]: isFirstDay,
                                [classes.endHighlight]: isLastDay,
                            });
                            const isToday = sameDay(day, today);

                            return (
                                <div className={wrapperClassName}>
                                    <IconButton
                                        className={dayClassName}
                                        classes={{
                                            label: clsx(
                                                classes.dayContainer,
                                                isToday && classes.todayLabel
                                            ),
                                        }}
                                    >
                                        <span> {day.toFormat('d')} </span>
                                        {isToday && (
                                            <div
                                                className={
                                                    classes.todayIndicator
                                                }
                                            ></div>
                                        )}
                                    </IconButton>
                                </div>
                            );
                        }}
                    />

                    <div className={classes.rightContainer}>
                        {filter.selectionType !==
                            SELECTION_TYPE_ENDING_DATE && (
                            <div className={classes.dateSelectorContainer}>
                                <div className={classes.selectedDateLabel}>
                                    Number of{' '}
                                    {filter.selectionType === SELECTION_TYPE_DAY
                                        ? 'days:'
                                        : 'weeks:'}
                                </div>
                                <Select
                                    className={classes.selector}
                                    rootClassName={classes.selectorRoot}
                                    items={getSelectorItems(
                                        filter.selectionType
                                    )}
                                    value={selectionCount}
                                    onChange={(value) => {
                                        setSelectionCount(value);
                                    }}
                                    hint={`Number of ${
                                        filter.selectionType ===
                                        SELECTION_TYPE_DAY
                                            ? 'days'
                                            : 'weeks'
                                    }`}
                                />
                            </div>
                        )}
                        <div className={classes.currentDateLabel}>
                            {filter.selectionType === SELECTION_TYPE_ENDING_DATE
                                ? 'Ending Date'
                                : 'Selected Dates'}
                        </div>
                        <div className={classes.selectedDate}>
                            {formatDateTitle(startDate, endDate)}
                        </div>
                        <Button
                            className={classes.okButton}
                            labelClass={classes.okLabelButton}
                            label={'OK'}
                            onClick={onConfirm}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

/** Floating bottom date filter (allows selecting a specific end date, oor a range of days/weeks) */
export default function BottomDateFilter(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={isOpen ? clsx(classes.content, classes.overlay) : null}>
            {isOpen ? (
                <OpenFilter
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    showBy={props.showBy}
                />
            ) : (
                <ClosedFilter
                    onOpen={() => {
                        setIsOpen(true);
                    }}
                    showBy={props.showBy}
                />
            )}
        </div>
    );
}
