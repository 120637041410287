import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import closeIcon from '../../res/images/close_circle.svg';
import { setStatsFilter } from '../../store/actions/stats';
import { IconButton } from '@material-ui/core';
import leftArrow from '../../res/images/left_arrow.svg';
import rightArrow from '../../res/images/right_arrow.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { DateTime } from 'luxon';
import ToggleButton from '../../components/ToggleButton';
import { DatePicker } from '@material-ui/pickers';
import { sameDay } from '../../modules/DateUtils';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ffffff00',
        zIndex: 99999,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    overlay: {
        top: 0,
        backgroundColor: '#ffffffcc',
    },
    closedFilterContainer: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    closedFilterPre: {
        backgroundColor: '#8090a8',
        width: '22px',
        height: '17px',

        '&::before': {
            content: '',
            backgroundColor: '#0074D9',
            bottom: '-10px',
            height: '50px',
            width: '25px',
            borderTopLeftRadius: '25px',
        },
    },
    closedFilterPost: {
        backgroundColor: '#8090a8',
        width: '22px',
        height: '17px',
    },
    closedFilterContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#8090a8',
        width: '445px',
        height: '48px',
        borderTopLeftRadius: '33px',
        borderTopRightRadius: '33px',
        cursor: 'pointer',
    },
    invertCornerLeft: {
        borderBottomRightRadius: '30px',
        backgroundColor: '#ffffff',
        width: '22px',
        height: '17px',
    },
    invertCornerRight: {
        borderBottomLeftRadius: '30px',
        backgroundColor: '#ffffff',
        width: '22px',
        height: '17px',
    },
    arrowButton: {
        padding: '2px',
        marginRight: '11px',
        marginLeft: '11px',
        backgroundColor: '#c0c7d3',
        width: '24px',
        height: '24px',
    },
    arrowButtonImage: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#8090a8'),
    },
    filterLabel: {
        flexGrow: 1,
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    openFilterContent: {
        backgroundColor: '#8090a8',
        width: '100%',
        borderTopLeftRadius: '33px',
        borderTopRightRadius: '33px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '34px',
    },
    openFilterInnerContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    openFilterTopRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '90px',
    },
    closeButtonImage: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        marginTop: '-2px',
        filter: hexColorToCSSFilter('#ffffff'),
    },
    openFilterMainPart: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f1f3f6',
    },
    selectorsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f1f3f6',
        width: '367px',
    },
    dateSelector: {
        width: '250px',
        height: '48px',
        borderRadius: '24px',
        boxShadow: '0 2px 4px 0 rgba(125, 144, 170, 0.75)',
        border: 'solid 1px #7d90aa',
        color: '#7d90aa',
        marginBottom: '20px',

        '&.Mui-selected': {
            backgroundColor: '#7d90aa',
            color: '#ffffff',
        },
        '&:last-child': {
            marginBottom: '0px',
        },
    },
    dayContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    todayIndicator: {
        width: '6px',
        height: '6px',
        backgroundColor: '#49b271',
        borderRadius: '6px',
        marginTop: '2px',
    },
    highlightDay: {
        background: '#e9ecf0',
        color: '#7d90aa',
    },
    firstHighlight: {
        extend: 'highlight',
        background: '#ccd2dd',
        borderTopLeftRadius: '30%',
        borderBottomLeftRadius: '30%',
    },
    endHighlight: {
        extend: 'highlight',
        background: '#ccd2dd',
        borderTopRightRadius: '30%',
        borderBottomRightRadius: '30%',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
}));

/** Formats the start and date (used as a title) - assumes input dates are Luxon DateTime instances */
function formatDateTitle(startDate, endDate) {
    if (sameDay(startDate, endDate)) {
        // Day filter
        return startDate.toFormat('dd MMM yyyy');
    }

    // Week/Month filter
    return `${startDate.toFormat('dd MMM yyyy')} - ${endDate.toFormat(
        'dd MMM yyyy'
    )}`;
}

/** Closed (collapsed) view of the filter - just showing currently selected date and arrows */
function ClosedFilter(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.stats.filter);
    const dispatch = useDispatch();
    const diff = filter.endDate.diff(filter.startDate, 'days');
    const diffDays = { days: Math.round(diff.days) };
    const [dispatchTimer, setDispatchTimer] = useState(null);
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);

    // When moving back/forward in the time periods, wait for the user to
    // stop moving around before actually updating the filter's start/end dates.
    const updateFilterLater = (newStartDate, newEndDate) => {
        if (dispatchTimer !== null) clearTimeout(dispatchTimer);

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        setDispatchTimer(
            setTimeout(() => {
                dispatch(
                    setStatsFilter({
                        startDate: newStartDate,
                        endDate: newEndDate,
                    })
                );
                setDispatchTimer(null);
            }, 500)
        );
    };

    const onPreviousPeriod = () => {
        let newStartDate, newEndDate;

        if (
            datesToSelectionType(filter.startDate, filter.endDate) ===
            SELECTION_TYPE_MONTH
        ) {
            if (filter.startDate.day === 1) {
                // Whole month selection
                newStartDate = startDate.minus({ months: 1 }).startOf('month');
                newEndDate = endDate.minus({ months: 1 }).endOf('month');
            } else {
                // Instead of just moving X days backwards - move to same period on the previous month (same days of the month)
                newStartDate = startDate.minus({ months: 1 });
                newEndDate = endDate.minus({ months: 1 });
            }
        } else {
            newStartDate = startDate.minus(diffDays);
            newEndDate = endDate.minus(diffDays);
        }

        updateFilterLater(newStartDate, newEndDate);
    };
    const onNextPeriod = () => {
        let newStartDate, newEndDate;

        if (
            datesToSelectionType(filter.startDate, filter.endDate) ===
            SELECTION_TYPE_MONTH
        ) {
            if (filter.startDate.day === 1) {
                // Whole month selection
                newStartDate = startDate.plus({ months: 1 }).startOf('month');
                newEndDate = endDate.plus({ months: 1 }).endOf('month');
            } else {
                // Instead of just moving X days forward - move to same period on the next month (same days of the month)
                newStartDate = startDate.plus({ months: 1 });
                newEndDate = endDate.plus({ months: 1 });
            }
        } else {
            newStartDate = startDate.plus(diffDays);
            newEndDate = endDate.plus(diffDays);
        }

        updateFilterLater(newStartDate, newEndDate);
    };

    return (
        <div className={classes.closedFilterContainer}>
            <div className={classes.closedFilterPre}>
                <div className={classes.invertCornerLeft}></div>
            </div>
            <div className={classes.closedFilterContent}>
                <IconButton
                    className={classes.arrowButton}
                    onClick={onPreviousPeriod}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={leftArrow}
                        alt={'Previous Period'}
                    />
                </IconButton>

                <div className={classes.filterLabel} onClick={props.onOpen}>
                    {formatDateTitle(startDate, endDate)}
                </div>

                <IconButton
                    className={classes.arrowButton}
                    onClick={onNextPeriod}
                >
                    <img
                        className={classes.arrowButtonImage}
                        src={rightArrow}
                        alt={'Next Period'}
                    />
                </IconButton>
            </div>
            <div className={classes.closedFilterPost}>
                <div className={classes.invertCornerRight}></div>
            </div>
        </div>
    );
}

export const SELECTION_TYPE_DAY = 'day';
export const SELECTION_TYPE_WEEK = 'week';
export const SELECTION_TYPE_MONTH = 'month';

/** Figures out selection type (day/week/month) according to the two input dates */
export function datesToSelectionType(d1, d2) {
    const diff = d2.diff(d1, 'days');
    const diffDays = Math.round(diff.days);

    if (diffDays === 1) {
        return SELECTION_TYPE_DAY;
    } else if (diffDays === 7) {
        return SELECTION_TYPE_WEEK;
    } else {
        return SELECTION_TYPE_MONTH;
    }
}

/** Open (expanded) view of the filter - showing full calendar view and day/week/month selector */
function OpenFilter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.stats.filter);
    const [selectionType, setSelectionType] = useState(
        datesToSelectionType(filter.startDate, filter.endDate)
    );
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);

    const setValue = (v, type = selectionType) => {
        let newStartDate, newEndDate;

        if (type === SELECTION_TYPE_DAY) {
            newStartDate = v.startOf('day');
            newEndDate = v.endOf('day');
        } else if (type === SELECTION_TYPE_WEEK) {
            newStartDate = v.startOf('day');
            newEndDate = v.endOf('day').plus({ days: 6 });
        } else if (type === SELECTION_TYPE_MONTH) {
            newStartDate = v.startOf('day');
            newEndDate = v
                .plus({ months: 1 })
                .set({ day: newStartDate.day })
                .minus({ days: 1 })
                .endOf('day');
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const setType = (type) => {
        setValue(startDate, type);
        setSelectionType(type);
    };

    const onClose = () => {
        // Set filter only on close
        dispatch(
            setStatsFilter({
                startDate: startDate,
                endDate: endDate,
            })
        );

        props.onClose();
    };

    const today = DateTime.local();

    return (
        <div className={classes.openFilterContent}>
            <div className={classes.openFilterInnerContent}>
                <div className={classes.openFilterTopRow}>
                    <div className={classes.filterLabel}>
                        {formatDateTitle(startDate, endDate)}
                    </div>
                    <IconButton onClick={onClose}>
                        <img
                            className={classes.closeButtonImage}
                            src={closeIcon}
                            alt={'Close'}
                        />
                    </IconButton>
                </div>
                <div className={classes.openFilterMainPart}>
                    <div className={classes.selectorsContainer}>
                        <ToggleButton
                            className={classes.dateSelector}
                            onChange={() => {
                                setType(SELECTION_TYPE_DAY);
                            }}
                            selected={selectionType === SELECTION_TYPE_DAY}
                        >
                            Day
                        </ToggleButton>

                        <ToggleButton
                            className={classes.dateSelector}
                            onChange={() => {
                                setType(SELECTION_TYPE_WEEK);
                            }}
                            selected={selectionType === SELECTION_TYPE_WEEK}
                        >
                            Week
                        </ToggleButton>

                        <ToggleButton
                            className={classes.dateSelector}
                            onChange={() => {
                                setType(SELECTION_TYPE_MONTH);
                            }}
                            selected={selectionType === SELECTION_TYPE_MONTH}
                        >
                            Month
                        </ToggleButton>
                    </div>
                    <DatePicker
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={startDate}
                        onChange={setValue}
                        className={classes.picker}
                        disableToolbar={true}
                        renderDay={(day, selectedDate, isInCurrentMonth) => {
                            const dayIsBetween =
                                day >= startDate && day <= endDate;
                            const isFirstDay = sameDay(day, startDate);
                            const isLastDay = sameDay(day, endDate);

                            const dayClassName = clsx(classes.day, {
                                [classes.nonCurrentMonthDay]: !isInCurrentMonth,
                                [classes.highlightNonCurrentMonthDay]:
                                    !isInCurrentMonth && dayIsBetween,
                            });
                            const wrapperClassName = clsx({
                                [classes.highlightDay]: dayIsBetween,
                                [classes.firstHighlight]: isFirstDay,
                                [classes.endHighlight]: isLastDay,
                            });

                            return (
                                <div className={wrapperClassName}>
                                    <IconButton
                                        className={dayClassName}
                                        classes={{
                                            label: classes.dayContainer,
                                        }}
                                    >
                                        <span> {day.toFormat('d')} </span>
                                        {sameDay(day, today) && (
                                            <div
                                                className={
                                                    classes.todayIndicator
                                                }
                                            ></div>
                                        )}
                                    </IconButton>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

/** Floating bottom date filter (allows selecting a specific day/week/month) */
export default function BottomDateFilter(props) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={clsx(classes.content, isOpen ? classes.overlay : null)}>
            {isOpen ? (
                <OpenFilter
                    onClose={() => {
                        setIsOpen(false);
                    }}
                />
            ) : (
                <ClosedFilter
                    onOpen={() => {
                        setIsOpen(true);
                    }}
                />
            )}
        </div>
    );
}
