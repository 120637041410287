import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, IconButton } from '@material-ui/core';
import LoadingOverlay from './LoadingOverlay';
import clsx from 'clsx';

const useStyles = makeStyles({
    headerTitle: {
        fontSize: '18px',
        color: '#7d90aa',
        textAlign: 'center',
        flexGrow: 1,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '100%',
        zIndex: 0,
    },
    rightPart: {
        marginLeft: 'auto',
    },
    headerRoot: {
        position: 'relative',
        background: '#e3eaf3',
        borderBottom: '1px solid #afbac9',
        boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.07)',
        height: '59px',
        minHeight: '59px',
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    leftIcon: {
        maxWidth: 30,
        maxHeight: 30,
    },
    cardRoot: {
        background: '#e3eaf37f',
        boxShadow: 'none',
        borderRadius: '12px',
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
    },
    contentRoot: {
        padding: '0px',
        '&:last-child': {
            padding: '0px',
        },
        position: 'relative',
        flex: '1 0 auto',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    disableScrolling: {
        overflow: 'hidden',
    },
    iconButton: {
        width: '30px',
        height: '30px',
    },
});

/** An inner window / card layout - e.g. displaying machine details, remote actions, forms, etc.
 * Each inner window has a title, content and optional top-right clickable icon. */
export default function InnerWindow(props) {
    const classes = useStyles();

    return (
        <Card className={props.className} classes={{ root: classes.cardRoot }}>
            <div className={classes.headerRoot}>
                <div className={classes.headerTitle}>{props.title}</div>
                {props.leftIcon && (
                    <IconButton onClick={props.onLeftIconClick} size="large">
                        <img
                            src={props.leftIcon}
                            className={classes.leftIcon}
                            alt={props.title}
                        />
                    </IconButton>
                )}
                {props.leftView && props.leftView}
                {props.icon && (
                    <IconButton
                        onClick={props.onIconClick}
                        className={classes.rightPart}
                    >
                        <img
                            src={props.icon}
                            alt={props.title}
                            className={classes.iconButton}
                        />
                    </IconButton>
                )}
                {props.rightView && props.rightView}
            </div>
            <CardContent
                classes={{
                    root: clsx(
                        classes.contentRoot,
                        props.loading ? classes.disableScrolling : null,
                        props.contentClassName
                    ),
                }}
            >
                <LoadingOverlay loading={props.loading} />
                {props.children}
            </CardContent>
        </Card>
    );
}
