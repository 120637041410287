import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import capsulesIcon from '../../res/images/capsules.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { formatClientTitle, ID_ALL_CLIENTS } from '../../modules/MachineUtils';

const useStyles = makeStyles({
    table: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        backgroundColor: '#f0f3f9',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    timeColumn: {
        width: '110px',
        minWidth: '110px',
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '35px',
        minHeight: '35px',
        textAlign: 'center',
    },
    timeCell: {
        color: '#586374',
        fontSize: '10px',
        fontWeight: 'bold',
    },
    clientColumn: {
        width: '94px',
        minWidth: '94px',
    },
    clientCell: {
        color: '#ffffff',
        fontSize: '12px',
    },
    separator: {
        height: '1px',
        backgroundColor: '#ffffff',
        opacity: 0.22,
        width: '60%',
    },
    clientCellContent: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerCell: {
        height: '90px',
        minHeight: '90px',
    },
    clientHeaderCell: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    clientHeaderLabel: {
        flexGrow: 1,
    },
    capsuleIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        marginLeft: '5px',
    },
    totalCapsulesContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    totalCapsulesLabel: {
        paddingTop: '4px',
    },
});

/** The left most column (the time column) */
function TimeColumn(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.column, classes.timeColumn)}>
            <div
                className={clsx(
                    classes.cell,
                    classes.timeCell,
                    classes.headerCell
                )}
            ></div>
            {props.values.map((v) => (
                <div key={v} className={clsx(classes.cell, classes.timeCell)}>
                    {v}
                </div>
            ))}
        </div>
    );
}

/** A column representing one client/site/cluster */
function ClientColumn(props) {
    const classes = useStyles();

    const totalCapsules = Object.values(props.data).reduce((a, b) => a + b, 0);

    return (
        <div className={clsx(classes.column, classes.clientColumn)}>
            <div
                className={clsx(
                    classes.cell,
                    classes.clientCell,
                    classes.headerCell,
                    classes.clientHeaderCell
                )}
                style={{ backgroundColor: props.client.color }}
            >
                <div className={classes.clientHeaderLabel}>
                    {props.client._id === ID_ALL_CLIENTS
                        ? props.client.name
                        : formatClientTitle(props.client)}
                </div>
                <div className={classes.totalCapsulesContainer}>
                    <div className={classes.totalCapsulesLabel}>
                        {totalCapsules}
                    </div>
                    <img
                        className={classes.capsuleIcon}
                        src={capsulesIcon}
                        alt={'Total capsules'}
                    />
                </div>
            </div>
            {props.columns.map((v) => (
                <div
                    key={v}
                    className={clsx(classes.cell, classes.clientCell)}
                    style={{ backgroundColor: `${props.client.color}99` }}
                >
                    <div className={classes.clientCellContent}>
                        {props.data[v] || 0}
                    </div>
                    <div className={classes.separator} />
                </div>
            ))}
        </div>
    );
}

/** The results table */
export default function ResultsTable(props) {
    const classes = useStyles();

    if (!props.stats) {
        return <div className={clsx(classes.table, props.className)}></div>;
    }

    const stats = props.stats;

    return (
        <div className={clsx(classes.table, props.className)}>
            <TimeColumn values={stats.columns} />
            {props.clients.map((client) => (
                <ClientColumn
                    key={client._id}
                    client={client}
                    columns={stats.columns}
                    data={stats.data[client._id]}
                />
            ))}
        </div>
    );
}
