import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { hexColorToCSSFilter } from '../modules/CSSUtils';

const useStyles = makeStyles({
    root: {
        border: '1px solid #027c76',
        borderRadius: '24px',
        paddingLeft: '20px',
        paddingRight: '5px',
        '&$selected': {
            backgroundColor: '#027c76',
        },
        '&$selected $label': {
            color: '#ffffff',
        },
        '& img': {
            filter: hexColorToCSSFilter('#027c76'),
        },
        '&$selected img': {
            filter: hexColorToCSSFilter('#ffffff'),
        },
        '&:last-child': {
            paddingRight: '15px',
        },
    },
    selected: {},
    label: {
        color: '#027c76',
        textTransform: 'none',
    },
    icon: {
        marginLeft: '10px',
        marginTop: '-2px',
        alignSelf: 'baseline',
    },
});

/** Each individual toggle bar button, with text and icon */
function ToggleBarButton(props) {
    const classes = useStyles();

    return (
        <ToggleButton
            classes={{
                root: classes.root,
                selected: classes.selected,
                label: classes.label,
            }}
            {...props}
            key={props.key}
            value={props.value}
        >
            <span>{props.title}</span>
            <img src={props.icon} className={classes.icon} alt={props.title} />
        </ToggleButton>
    );
}

/** Toggle bar at the top, visible throughout all screens (shows Dashboard / Timeline / SG KPI / Action) */
export default function ToggleBar(props) {
    const toggleBarState = useSelector((state) => state.ui.toggleBarState);
    const history = useHistory();

    const handleChange = (event, value) => {
        if (!value) return;
        props.buttons.forEach((button) => {
            if (button.value === value) {
                history.push(button.url);
            }
        });
    };

    return (
        <ToggleButtonGroup
            size="small"
            value={toggleBarState}
            exclusive
            onChange={handleChange}
            className={props.className}
        >
            {props.buttons
                .filter((b) => !b.hidden)
                .map((button) => {
                    return (
                        <ToggleBarButton
                            key={button.value}
                            value={button.value}
                            title={button.title}
                            icon={button.icon}
                            disabled={button.disabled}
                        />
                    );
                })}
        </ToggleButtonGroup>
    );
}
