import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import machineIcon from '../../../res/images/machine.svg';
import DateTime from 'luxon/src/datetime';
import forwardIcon from '../../../res/images/right_circle.svg';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import DateAndTimeField from '../../../components/DateAndTimeField';
import { useDispatch, useSelector } from 'react-redux';
import { reloadMachines } from '../AdminSection';
import { setAdminDefaultLogsConfigs } from '../../../store/actions/admin';

const useStyles = makeStyles({
    successMessage: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'green',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formSubRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '170px',
    },
});

const gApiClient = Authentication.getAPIClient();

export default function DownloadLogsForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const defaultConfigs = filter.defaultLogsConfigs;
    const machinesById = filter.allMachines || {};
    const machineItems = Object.values(machinesById).map((m) => ({
        value: m._id,
        label: m.alias,
    }));
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const formsWindow = useRef(null);
    const [startDate, setStartDate] = useState(
        DateTime.local().set({ hour: 0, minute: 0, second: 0 })
    );
    const [endDate, setEndDate] = useState(
        DateTime.local().set({ hour: 23, minute: 59, second: 59 })
    );
    const [perMachineReport, setPerMachineReport] = useState(true);
    const [rawLogsOnly, setRawLogsOnly] = useState(false);
    const [reducedReportOnly, setReducedReportOnly] = useState(false);
    const [summaryReport, setSummaryReport] = useState(false);
    const [csvFileOnly, setCsvFileOnly] = useState(false);
    const [allMachinesReport, setAllMachinesReport] = useState(true);
    const disallowFormSubmission =
        selectedMachines.length === 0 ||
        (!perMachineReport &&
            !rawLogsOnly &&
            !csvFileOnly &&
            !allMachinesReport);

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];

        if (!filter.allMachines) {
            promises.push(reloadMachines(dispatch));
        }

        if (Object.keys(filter.defaultLogsConfigs).length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getDefaultDownloadLogsConfigs',
                        'GET',
                        {}
                    );
                    dispatch(setAdminDefaultLogsConfigs(results.data));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!Object.keys(defaultConfigs).length) return;

        setPerMachineReport(defaultConfigs.per_machine);
        setRawLogsOnly(defaultConfigs.raw_logs_only);
        setReducedReportOnly(defaultConfigs.reduced_report);
        setSummaryReport(defaultConfigs.summary_report);
        setCsvFileOnly(defaultConfigs.raw_logs_csv);
        setAllMachinesReport(defaultConfigs.all_machines);
    }, [defaultConfigs]);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            if (perMachineReport) {
                await gApiClient.callApi(
                    'admin/generateCSVFile',
                    'POST',
                    {},
                    {
                        machine_ids: selectedMachines
                            .map((m) => m._id)
                            .join(','),
                        // Also account for local timezone
                        start_date: startDate
                            .minus({ minutes: new Date().getTimezoneOffset() })
                            .toMillis(),
                        end_date: endDate
                            .minus({ minutes: new Date().getTimezoneOffset() })
                            .toMillis(),
                        minute_offset: -new Date().getTimezoneOffset(),
                        raw_logs_only: rawLogsOnly ? 1 : 0,
                        summary_report: summaryReport ? 1 : 0,
                        reduced_report: reducedReportOnly ? 1 : 0,
                        csv_only: csvFileOnly ? 1 : 0,
                    }
                );
            }
            if (allMachinesReport) {
                await gApiClient.callApi(
                    'admin/generateAllMachinesReport',
                    'POST',
                    {},
                    {
                        machine_ids: selectedMachines
                            .map((m) => m._id)
                            .join(','),
                        // Also account for local timezone
                        start_date: startDate
                            .minus({ minutes: new Date().getTimezoneOffset() })
                            .toMillis(),
                        end_date: endDate
                            .minus({ minutes: new Date().getTimezoneOffset() })
                            .toMillis(),
                        minute_offset: -new Date().getTimezoneOffset(),
                    }
                );
            }

            setSuccessMessage(`Report will be sent to your email address`);
        } catch (e) {
            console.error(e);
            const message = e.response?.data?.Message || e.message;
            setError(message);
            props.onLoading(false);
            return;
        }

        props.onLoading(false);
    };

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>Start Date/Time</div>
            </div>
            <div className={classes.formRow} style={{ marginTop: 0 }}>
                <DateAndTimeField
                    className={classes.field}
                    label="Date:"
                    type="date"
                    value={startDate}
                    onSetValue={setStartDate}
                    autoConfirm={true}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
                <DateAndTimeField
                    className={classes.field}
                    label="Time:"
                    type="time"
                    value={startDate}
                    onSetValue={setStartDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
            </div>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>End Date/Time</div>
            </div>
            <div className={classes.formRow} style={{ marginTop: 0 }}>
                <DateAndTimeField
                    className={classes.field}
                    label="Date:"
                    type="date"
                    value={endDate}
                    onSetValue={setEndDate}
                    leftLabelWidth={100}
                    autoConfirm={true}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
                <DateAndTimeField
                    className={classes.field}
                    label="Time:"
                    type="time"
                    value={endDate}
                    onSetValue={setEndDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
            </div>

            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    multiple
                    autoComplete
                    items={machineItems}
                    renderValue={(selected) => selected.join(', ')}
                    value={selectedMachines.map((machine) =>
                        machineItems.find((m) => m.value === machine._id)
                    )}
                    onChange={(machines) => {
                        setSelectedMachines(
                            machines.map((m) => machinesById[m.value])
                        );
                    }}
                    rightIcon={machineIcon}
                    hint={'Machine'}
                />
            </div>

            <div className={classes.formRow}>
                <Checkbox
                    checked={perMachineReport}
                    onChange={setPerMachineReport}
                />
                <div className={classes.formLabel}>
                    Create Per-Machine Report
                </div>
            </div>
            {perMachineReport && (
                <>
                    <div className={classes.formSubRow}>
                        <Checkbox
                            checked={reducedReportOnly}
                            onChange={(v) => {
                                if (v) {
                                    setSummaryReport(false);
                                    setCsvFileOnly(false);
                                    setRawLogsOnly(false);
                                }
                                setReducedReportOnly(v);
                            }}
                        />
                        <div className={classes.formLabel}>
                            Reduced Per-Machine Report (don’t generate a tab per
                            capsule)
                        </div>
                    </div>
                    <div className={classes.formSubRow}>
                        <Checkbox
                            checked={summaryReport}
                            onChange={(v) => {
                                if (v) {
                                    setReducedReportOnly(false);
                                    setCsvFileOnly(false);
                                    setRawLogsOnly(false);
                                }
                                setSummaryReport(v);
                            }}
                        />
                        <div className={classes.formLabel}>
                            Summary report (Machine info, Capsules, MST, Alerts)
                        </div>
                    </div>
                    <div className={classes.formSubRow}>
                        <Checkbox
                            checked={rawLogsOnly}
                            onChange={(v) => {
                                if (v) {
                                    setReducedReportOnly(false);
                                    setSummaryReport(false);
                                    setCsvFileOnly(false);
                                }

                                setRawLogsOnly(v);
                            }}
                        />
                        <div className={classes.formLabel}>Raw logs only</div>
                    </div>
                    <div className={classes.formSubRow}>
                        <Checkbox
                            checked={csvFileOnly}
                            onChange={(v) => {
                                if (v) {
                                    setReducedReportOnly(false);
                                    setSummaryReport(false);
                                    setRawLogsOnly(false);
                                }

                                setCsvFileOnly(v);
                            }}
                        />
                        <div className={classes.formLabel}>
                            Raw logs only (CSV file)
                        </div>
                    </div>
                </>
            )}
            <div className={classes.formRow}>
                <Checkbox
                    checked={allMachinesReport}
                    onChange={setAllMachinesReport}
                />
                <div className={classes.formLabel}>All Machines Report</div>
            </div>

            {error && (
                <div
                    className={classes.formRow}
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <div className={classes.error}>{error}</div>
                </div>
            )}

            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.confirmSubmit}
                    label="Get Logs"
                    disabled={disallowFormSubmission}
                    rightIcon={forwardIcon}
                    onClick={onSubmitForm}
                />
            </div>
            {successMessage && (
                <div className={classes.successMessage}>{successMessage}</div>
            )}
        </div>
    );
}
