import * as actions from '../actions/ui';

const INITIAL_STATE = {
    sideMenuState: actions.SIDE_MENU_STATE_MACHINES,
    toggleBarComponent: null, // Each top-level view (machines, stats, etc.) has a different set of toggle bar buttons
    singleMachine: null, // Machines view - current machine info object being viewed
    toggleBarState: actions.TOGGLE_BAR_STATE_DASHBOARD,
    appBarVisible: actions.APP_BAR_VISIBLE,
};

/** UI Reducer */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_APP_BAR_VISIBILITY:
            return { ...state, appBarVisible: action.value };
        case actions.SET_TOGGLE_BAR_STATE:
            return { ...state, toggleBarState: action.value };
        case actions.SET_SIDE_MENU_STATE:
            return { ...state, sideMenuState: action.value };
        case actions.SET_SINGLE_MACHINE:
            return { ...state, singleMachine: action.value };
        case actions.SET_TOGGLE_BAR_COMPONENT:
            return { ...state, toggleBarComponent: action.value };
        default:
            return state;
    }
};
