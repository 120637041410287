import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Collapse } from '@material-ui/core';
import expandedIcon from '../res/images/three_dots_vertical.svg';
import collapsedIcon from '../res/images/three_dots_horizontal.svg';
import levelIcon from '../res/images/chevron_right.svg';
import LoadingOverlay from './LoadingOverlay';
import { ID_ALL_CLIENTS } from '../modules/MachineUtils';

const useStyles = makeStyles({
    listContainer: {
        overflowY: 'auto',
        transform: 'scaleX(-1)',
        height: 0,
        position: 'relative',
    },
    list: {
        transform: 'scaleX(-1)',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    divider: {
        backgroundColor: '#bec7d4',
        height: '1px',
        position: 'absolute',
        bottom: 0,
        left: '14px',
        right: '14px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '66px',
    },
    listItemRootNoPadding: {
        padding: '0px',
    },
    itemLabel: {
        fontSize: '12px',
        color: '#ffffff',
        flexGrow: 1,
    },
    levelIcon: {
        marginRight: '1px',
        '&:last-of-type': {
            marginRight: '10px',
        },
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    expandIconContainer: {
        flexGrow: 1,
        marginRight: '10px',
    },
    expandIcon: {
        padding: '6px',
    },
});

function setItemExpanded(item) {
    if (!item.children) return;

    item.children.forEach((x) => {
        x.expanded = true;
        setItemExpanded(x);
    });
}

/** UI base for all list item types (client/site/cluster/machine) */
function BaseListItem(props) {
    const classes = useStyles();

    const item = props.item;
    const level = props.level || 0;
    let expendable = item.children && item.children.length > 0;
    const isMachine = item.previous_clusters;

    if (expendable && props.childrenFilter) {
        if (props.childrenFilter(item)) expendable = false;
    }

    const [expanded, setExpanded] = useState(false);

    if (!item.visible) {
        // This item doesn't match - don't show it
        return null;
    }

    const onExpand = () => {
        item.expanded = !item.expanded;
        setExpanded(!expanded);

        if (item.children && item.expanded && props.collapseAllTheWay) {
            setItemExpanded(item);
        }
    };

    if (props.customListItem) {
        return (
            <div>
                <ListItem
                    button={expendable}
                    className={clsx(classes.listItem, props.listItemClass)}
                    style={props.style}
                    onClick={expendable ? onExpand : null}
                    disableGutters={true}
                    classes={{ root: classes.listItemRootNoPadding }}
                >
                    {props.customListItem({
                        item: item,
                        level: level,
                    })}
                </ListItem>
                {expendable && (
                    <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            {item.children.map((m) => (
                                <BaseListItem
                                    item={m}
                                    key={m._id}
                                    level={level + 1}
                                    customListItem={props.customListItem}
                                    childrenFilter={props.childrenFilter}
                                    listItemClass={props.listItemClass}
                                />
                            ))}
                        </List>
                    </Collapse>
                )}
            </div>
        );
    }

    return (
        <div>
            <ListItem
                button={expendable}
                className={clsx(classes.listItem, props.listItemClass)}
                style={props.style}
                onClick={expendable ? onExpand : null}
            >
                {[...Array(level)].map((e, i) => (
                    <img
                        src={levelIcon}
                        key={i}
                        alt="level"
                        className={clsx(
                            classes.levelIcon,
                            props.colors
                                ? props.colors.itemLevelIconClass
                                : null
                        )}
                    />
                ))}

                <div
                    className={classes.itemLabel}
                    style={
                        props.colors && props.colors.labelColor
                            ? { color: props.colors.labelColor }
                            : null
                    }
                >
                    {isMachine ? item.alias : item.name}
                </div>

                <div className={classes.itemRightSide}>
                    <div className={classes.expandIconContainer}>
                        {expendable && !props.hideExpansionMarks && (
                            <img
                                className={
                                    props.colors
                                        ? props.colors.expansionIconClass
                                        : null
                                }
                                src={
                                    item.expanded ? expandedIcon : collapsedIcon
                                }
                                alt={item.expanded ? 'Collapse' : 'Expand'}
                            />
                        )}
                    </div>
                    {props.renderRightPart ? props.renderRightPart(item) : null}
                </div>
                <div className={classes.divider} />
            </ListItem>
            {expendable && (
                <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {item.children.map((m) => (
                            <GenericListItem
                                item={m}
                                key={m._id}
                                level={level + 1}
                                renderRightPart={props.renderRightPart}
                                colors={props.colors}
                                hideExpansionMarks={props.hideExpansionMarks}
                                customListItem={props.customListItem}
                                listItemClass={props.listItemClass}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </div>
    );
}

/** Represents a single list item (client/site/cluster/machine) */
function GenericListItem(props) {
    const item = props.item;

    if (!item.visible) {
        // This item doesn't match - don't show it
        return null;
    }

    const bgLevelColors = [
        '#00B09E',
        '#00988E',
        '#007F7E',
        '#006069',
        '#003d46',
    ];

    return (
        <BaseListItem
            item={props.item}
            style={{
                backgroundColor:
                    props.colors && props.colors.backgroundColors
                        ? props.colors.backgroundColors[props.level]
                        : bgLevelColors[props.level],
            }}
            level={props.level}
            renderRightPart={props.renderRightPart}
            colors={props.colors}
            hideExpansionMarks={props.hideExpansionMarks}
            customListItem={props.customListItem}
            childrenFilter={props.childrenFilter}
            listItemClass={props.listItemClass}
        />
    );
}

/** Simplistic string comparison/search function */
function matchesSearch(title, filter) {
    return title.toLowerCase().indexOf(filter.toLowerCase().trim()) > -1;
}

/** Recursively filters items according to search string */
function filterItem(
    item,
    filter,
    countries,
    regions,
    clientTypes,
    categories,
    models,
    hideNonMarked,
    parentVisible,
    changeExpansion
) {
    // Mark as matched/not-matched
    item.matched = matchesSearch(item.name || item.alias, filter);
    if (hideNonMarked && !item.selected && item._id !== ID_ALL_CLIENTS)
        item.matched = false;
    //  If parent item visible, then we should show all children as well
    item.visible = parentVisible || item.matched;

    let skipItem = false;

    if (!item.children) {
        if (models && models.length > 0) {
            // Filter by machine model
            if (!models.includes(item.machine_model)) {
                skipItem = true;
            }
        }
    }
    if (countries && countries.size > 0 && item.country) {
        // Filter by countries
        if (!countries.has(item.country)) {
            skipItem = true;
        }
    }
    if (regions && regions.size > 0 && item.region && item.client_type) {
        // Filter by regions (only at client level)
        if (!regions.has(item.region)) {
            skipItem = true;
        }
    }
    if (clientTypes && clientTypes.size > 0 && item.client_type) {
        // Filter by client types
        if (!clientTypes.has(item.client_type)) {
            skipItem = true;
        }
    }
    if (categories && Object.keys(categories).length > 0 && item.region) {
        // Filter by categories
        Object.keys(categories).forEach((cat) => {
            if (
                Object.values(categories[cat].values).every((x) => !x.selected)
            ) {
                // All possible values in this category are not selected - treat it as all selected
            } else {
                Object.keys(categories[cat].values).forEach((val) => {
                    if (
                        !categories[cat].values[val].selected &&
                        (!item[cat] || item[cat] === val)
                    ) {
                        skipItem = true;
                    }
                });
            }
        });
    }

    if (skipItem) {
        item.matched = false;
        item.visible = false;

        // Unmatch all children nodes under this client
        if (item.children) {
            item.children.forEach((item) => {
                markItemVisibility(item, false, true);
            });
        }

        return;
    }

    let childrenMatched = false;

    if (item.children) {
        // Search current item's children as well
        childrenMatched = item.children
            .map((child) => {
                return filterItem(
                    child,
                    filter,
                    countries,
                    regions,
                    clientTypes,
                    categories,
                    models,
                    hideNonMarked,
                    item.visible,
                    changeExpansion
                );
            })
            .some((x) => x);

        // If any of the current item's children are matched as well - show the current item (=parent) as well
        const childrenVisible = item.children.some((x) => x.visible);

        item.visible = item.visible || childrenVisible;

        if (!childrenMatched) {
            if (models && models.length > 0) {
                item.visible = false;
                item.matched = false;
            }
        }

        if (changeExpansion) {
            if (childrenMatched && filter && filter.length > 0) {
                // One of the current item's children (whether direct or indirect children) matched - show it expanded
                item.expanded = true;
            } else {
                item.expanded = false;
            }
        }
    }

    return childrenMatched || item.matched;
}

/** Recursively marks item and its children as viewable (i.e. no filter) */
function markItemVisibility(item, visible, changeExpansion) {
    item.matched = false;
    item.visible = visible;
    if (changeExpansion) {
        item.expanded = false;
    }

    if (item.children) {
        // Search current item's children as well
        item.children.map((child) => {
            return markItemVisibility(child, visible, changeExpansion);
        });
    }
}

/** Returns a list of all single machines under this item that were matched, such that *none* of their parents (cluster/site/client)
 * were matched as well. Also marks those parents on the way, as non-visible. */
function getMachineResults(item) {
    if (!item.visible) {
        // Item not visible anyhow - stop recursion.
        return [];
    }

    if (!item.children) {
        // Reached a machine result
        return [item];
    }

    // Reached a client/site/cluster

    if (item.matched) {
        // Current item matched, stop recursion for this branch (we're looking for machines *without* any parents that matched)
        return [];
    }

    let results = [];

    item.children.map((child) => {
        results = results.concat(getMachineResults(child));
        return results;
    });

    if (results.some((x) => x.matched)) {
        // This item some machines that matched - mark it as non-visible (since we're not gonna show any
        // of the parents in the results, just gonna show the machines directly in the root results list).
        item.visible = false;
    }

    return results;
}

/** Filters all result items according to search string */
function filterAllItems(
    items,
    filter,
    countries,
    regions,
    clientTypes,
    categories,
    models,
    hideNonMarked,
    changeExpansion
) {
    let shouldFilter =
        (filter && filter.length > 0) ||
        (countries && countries.size > 0) ||
        (regions && regions.size > 0) ||
        (clientTypes && clientTypes.size > 0) ||
        (categories && Object.keys(categories).length > 0) ||
        (models && models.length > 0) ||
        hideNonMarked;

    if (shouldFilter) {
        items.map((item) => {
            return filterItem(
                item,
                filter,
                countries,
                regions,
                clientTypes,
                categories,
                models,
                hideNonMarked,
                false,
                changeExpansion
            );
        });

        // Add single machine results - if a machine was matched, but none of its parents (cluster/site/client)
        // were matched, it should be visible as a separate root result (instead of expanding the client/site/cluster tree).
        let machines = [];
        items.map((item) => {
            machines = machines.concat(getMachineResults(item));
            return machines;
        });

        items = items.concat(machines);
    } else {
        // No filter
        items.map((item) => {
            return markItemVisibility(item, true, changeExpansion);
        });
    }

    return items;
}

/** An expandable list showing a client/site/cluster/machine hierarchy */
export default function MachineList(props) {
    const classes = useStyles();

    // Clients list, where is client contains children (= sites), and each site contains clusters, and then machines.
    let [items, setItems] = useState([...props.items]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const changeExpansion =
            (props.items.length !== items.length && items.length > 0) ||
            (props.filter && props.filter.length > 0);

        const newItems = filterAllItems(
            props.items,
            props.filter,
            props.countries,
            props.regions,
            props.clientTypes,
            props.categories,
            props.models,
            props.hideNonMarked,
            changeExpansion
        );
        setItems(newItems);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.filter,
        props.items,
        props.countries,
        props.regions,
        props.clientTypes,
        props.categories,
        props.hideNonMarked,
        props.models,
    ]);

    return (
        <div className={clsx(classes.listContainer, props.className)}>
            {!loading && (
                <List className={classes.list}>
                    {items.map((m) =>
                        props.customListItem ? (
                            <BaseListItem
                                item={m}
                                key={m._id}
                                level={0}
                                customListItem={props.customListItem}
                                childrenFilter={props.childrenFilter}
                                listItemClass={props.listItemClass}
                                collapseAllTheWay={props.collapseAllTheWay}
                            />
                        ) : (
                            <GenericListItem
                                item={m}
                                key={m._id}
                                level={0}
                                renderRightPart={props.renderRightPart}
                                colors={props.colors}
                                hideExpansionMarks={props.hideExpansionMarks}
                                customListItem={props.customListItem}
                                childrenFilter={props.childrenFilter}
                                listItemClass={props.listItemClass}
                                collapseAllTheWay={props.collapseAllTheWay}
                            />
                        )
                    )}
                </List>
            )}
            <LoadingOverlay loading={loading} />
        </div>
    );
}
