import clone from 'clone';

export const SET_ADMIN_ALL_MACHINES = 'SET_ADMIN_ALL_MACHINES';
export const SET_ADMIN_CLUSTERS_BY_ID = 'SET_ADMIN_CLUSTERS_BY_ID';
export const SET_ADMIN_USERS_BY_ID = 'SET_ADMIN_USERS_BY_ID';
export const SET_ADMIN_COUNTRIES = 'SET_ADMIN_COUNTRIES';
export const SET_ADMIN_FW_VERSIONS = 'SET_ADMIN_FW_VERSIONS';
export const SET_ADMIN_FILTER_MACHINES = 'SET_ADMIN_FILTER_MACHINES';
export const SET_ADMIN_RECIPE_TYPES = 'SET_ADMIN_RECIPE_TYPES';
export const SET_ADMIN_DEFAULT_CAPSULE_CONFIGS =
    'SET_ADMIN_DEFAULT_CAPSULE_CONFIGS';
export const SET_ADMIN_DEFAULT_LOGS_CONFIGS = 'SET_ADMIN_DEFAULT_LOGS_CONFIGS';
export const SET_ADMIN_MACHINE_PART_TYPES = 'SET_ADMIN_MACHINE_PART_TYPES';
export const SET_ADMIN_PRODUCTION_SITES = 'SET_ADMIN_PRODUCTION_SITES';
export const SET_ADMIN_RECIPE_CATEGORIES = 'SET_ADMIN_RECIPE_CATEGORIES';
export const SET_ADMIN_RECIPE_MAPPINGS = 'SET_ADMIN_RECIPE_MAPPINGS';

export function setAdminAllMachines(value) {
    return { type: SET_ADMIN_ALL_MACHINES, value: clone(value) };
}
export function setAdminClustersById(value) {
    return { type: SET_ADMIN_CLUSTERS_BY_ID, value: clone(value) };
}
export function setAdminUsersById(value) {
    return { type: SET_ADMIN_USERS_BY_ID, value: clone(value) };
}
export function setAdminCountries(value) {
    return { type: SET_ADMIN_COUNTRIES, value: clone(value) };
}
export function setAdminFilterMachines(value) {
    return { type: SET_ADMIN_FILTER_MACHINES, value: clone(value) };
}
export function setAdminRecipeTypes(value) {
    return { type: SET_ADMIN_RECIPE_TYPES, value: clone(value) };
}
export function setAdminDefaultCapsuleConfigs(value) {
    return { type: SET_ADMIN_DEFAULT_CAPSULE_CONFIGS, value: clone(value) };
}
export function setAdminDefaultLogsConfigs(value) {
    return { type: SET_ADMIN_DEFAULT_LOGS_CONFIGS, value: clone(value) };
}
export function setAdminMachinePartTypes(value) {
    return { type: SET_ADMIN_MACHINE_PART_TYPES, value: clone(value) };
}
export function setAdminFWVersions(value) {
    return { type: SET_ADMIN_FW_VERSIONS, value: clone(value) };
}
export function setAdminProductionSites(value) {
    return { type: SET_ADMIN_PRODUCTION_SITES, value: clone(value) };
}
export function setAdminRecipeCategories(value) {
    return { type: SET_ADMIN_RECIPE_CATEGORIES, value: clone(value) };
}
export function setAdminRecipeMappings(value) {
    return { type: SET_ADMIN_RECIPE_MAPPINGS, value: clone(value) };
}
