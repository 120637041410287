import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Authentication from '../modules/Authentication';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import {
    APP_BAR_HIDDEN,
    APP_BAR_VISIBLE,
    setAppBarVisibility,
} from '../store/actions/ui';
import emailIcon from '../res/images/email.svg';
import lockIcon from '../res/images/lock.svg';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '100vh',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        borderRadius: '32px',
        height: '64px',
        minHeight: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        minHeight: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '20px',
        },
    },
    error: {
        color: '#ffe118',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 0,
    },
    leftContent: {
        zIndex: 9,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    register: {
        color: '#ffffff',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '20px',
    },
    registerLink: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#ffffff',
            textDecoration: 'none',
        },
    },
});

/** The login screen */
export default function Login() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    const verifyForm = () => {
        if (email.length === 0) {
            setError({ email: 'Required' });
            return false;
        }
        if (password.length === 0) {
            setError({ password: 'Required' });
            return false;
        }

        return true;
    };

    const onClick = () => {
        login();
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            login();
        }
    };

    const login = () => {
        if (!verifyForm()) return;

        setLoading(true);
        setError({});

        // Login with username/password combo
        Authentication.loginWithPassword(
            email.toLowerCase().trim(),
            password,
            () => {
                // Mark user as logged-in in the store
                dispatch(setAppBarVisibility(APP_BAR_VISIBLE));

                // Move to main, logged-in page
                history.replace('/');
            },
            (err) => {
                // Couldn't login
                setLoading(false);
                console.error(err);
                if (err.code === 'UserNotFoundException') {
                    setError({ email: 'Email not found' });
                } else if (err.code === 'NotAuthorizedException') {
                    setError({ password: 'Invalid password' });
                } else {
                    setError({ general: err.message });
                }
            }
        );
    };

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img src={bgTop} alt={'Background'} />
                <img src={bgBottom} alt={'Background'} />
            </div>

            <div className={classes.leftContent}>
                <TextField
                    className={classes.input}
                    onChange={setEmail}
                    onKeyUp={onKeyPress}
                    rightIcon={emailIcon}
                    placeholder={'Email'}
                />
                <div className={classes.error}>{error.email}&nbsp;</div>
                <TextField
                    className={classes.input}
                    type="password"
                    onChange={setPassword}
                    rightIcon={lockIcon}
                    onKeyUp={onKeyPress}
                    placeholder={'Password'}
                />
                <div className={classes.error}>{error.password}&nbsp;</div>
                <Button
                    loading={loading}
                    className={classes.button}
                    label="Login"
                    onClick={onClick}
                />
                <div className={classes.error}>{error.general}</div>

                <div className={classes.register}>
                    Don't have an account?
                    <a className={classes.registerLink} href="/register">
                        Sign up
                    </a>
                </div>
                <div className={classes.register}>
                    <a className={classes.registerLink} href="/forgot-password">
                        Forgot
                    </a>
                    your password?
                </div>
            </div>
        </div>
    );
}
