import React from 'react';
import filter from '../../res/images/filter_small.svg';
import eyes from '../../res/images/eye.svg';
import {
    TOGGLE_BAR_STATE_DASHBOARD,
    TOGGLE_BAR_STATE_RESULTS,
} from '../../store/actions/ui';
import ToggleBar from '../../components/ToggleBar';

/** Machines view specific toggle bar */
export default function MachinesToggleBar() {
    const buttons = [
        {
            title: 'Dashboard',
            value: TOGGLE_BAR_STATE_DASHBOARD,
            icon: eyes,
            url: '/stats',
        },
        {
            title: 'Results',
            value: TOGGLE_BAR_STATE_RESULTS,
            icon: filter,
            url: '/stats/results',
        },
    ];

    return <ToggleBar buttons={buttons} />;
}
