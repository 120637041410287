import React from 'react';
import { Drawer, List, ListItem, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import toolWrench from '../res/images/tool_wrench.svg';
import machine from '../res/images/machine.svg';
import recipeType from '../res/images/flavour_border.svg';
import clock from '../res/images/clock.svg';
import graph from '../res/images/graph.svg';
import hamburger from '../res/images/hamburger.svg';
import closeIcon from '../res/images/close.svg';
import {
    SIDE_MENU_STATE_MACHINES,
    SIDE_MENU_STATE_OVERVIEW,
    SIDE_MENU_STATE_TIMELINE,
    SIDE_MENU_STATE_STATS,
    SIDE_MENU_STATE_REPORTS,
    setSideMenuState,
    SIDE_MENU_STATE_ADMIN,
    SIDE_MENU_STATE_FW_UPDATE_STATUS,
    SIDE_MENU_STATE_RECIPE_TYPES,
} from '../store/actions/ui';
import Authentication from '../modules/Authentication';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import { useHistory, useLocation, Link } from 'react-router-dom';

const useStyles = makeStyles({
    drawer: {
        width: '84px',
        mWidth: '84px',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '84px',
        minWidth: '84px',
        background: 'linear-gradient(to bottom, #027c76, #00a89d)',
    },
    listItemRoot: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '19px',
        paddingBottom: '19px',
        justifyContent: 'center',
    },
    listItemLabel: {
        textTransform: 'uppercase',
        marginTop: '10px',
        color: '#ffffff',
        opacity: '0.5',
        fontSize: '10px',
        textAlign: 'center',
    },
    listItemLabelSelected: {
        opacity: '1',
    },
    listItemIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        opacity: '0.5',
        width: '28px',
        height: '28px',
    },
    listItemIconSelected: {
        opacity: '1',
    },
    menuButton: {
        marginTop: '20px',
        marginBottom: '63px',
    },
    settingsIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
});

/** Side menu item (e.g. Overview, Machines, Timeline) - with text and icon */
function SideMenuItem(props) {
    const classes = useStyles();
    const sideMenuState = useSelector((state) => state.ui.sideMenuState);
    const dispatch = useDispatch();
    const history = useHistory();

    const onItemClick = () => {
        dispatch(setSideMenuState(props.value));
        history.push(props.url);
    };

    return (
        <ListItem
            {...props}
            button
            key={props.value}
            classes={{ root: classes.listItemRoot }}
            onClick={onItemClick}
            component={Link}
            to={props.url}
        >
            <img
                className={clsx(
                    classes.listItemIcon,
                    sideMenuState === props.value
                        ? classes.listItemIconSelected
                        : null
                )}
                src={props.icon}
                alt={props.title}
            />
            <span
                className={clsx(
                    classes.listItemLabel,
                    sideMenuState === props.value
                        ? classes.listItemLabelSelected
                        : null
                )}
            >
                {props.title}
            </span>
        </ListItem>
    );
}

/** Side menu visible throughout all screens */
export default function SideMenu() {
    const classes = useStyles();
    const loginStatus = useSelector((state) => state.user.loginStatus);
    const isLoggedIn =
        loginStatus !== Authentication.LOGIN_STATUS_NOT_LOGGED_IN;
    const hasNOCPermissions =
        loginStatus === Authentication.LOGIN_STATUS_LOGGED_IN;
    const userDetails = Authentication.getUserDetails() || {};
    const showAdminMenu =
        loginStatus === Authentication.LOGIN_STATUS_LOGGED_IN &&
        (userDetails.is_admin ||
            userDetails.add_edit_machine ||
            userDetails.create_capsules ||
            userDetails.read_logs ||
            userDetails.machine_parts ||
            userDetails.update_fw);
    const history = useHistory();
    const location = useLocation();
    const isSettings = location.pathname === '/settings';

    const onMenuClick = () => {
        const url = isSettings ? '/' : '/settings';
        history.replace(url);
    };

    return (
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="left"
        >
            <Button
                disabled={!isLoggedIn}
                className={classes.menuButton}
                onClick={onMenuClick}
            >
                <img
                    className={classes.settingsIcon}
                    src={isSettings ? closeIcon : hamburger}
                    alt={'Menu'}
                />
            </Button>
            <List>
                {hasNOCPermissions && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_OVERVIEW}
                        title={'Overview'}
                        icon={toolWrench}
                        url="/overview"
                    />
                )}
                {hasNOCPermissions && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_MACHINES}
                        title={'Machines'}
                        icon={machine}
                        url="/machines"
                    />
                )}
                {false &&
                    hasNOCPermissions &&
                    Authentication.getWebsiteStage() ===
                        Authentication.STAGE_PROD && (
                        <SideMenuItem
                            disabled
                            value={SIDE_MENU_STATE_TIMELINE}
                            title={'Timeline'}
                            icon={clock}
                        />
                    )}
                {false && hasNOCPermissions && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_STATS}
                        title={'Stats'}
                        icon={graph}
                        url="/stats"
                    />
                )}
                {hasNOCPermissions &&
                    (userDetails.reports || userDetails.is_admin) && (
                        <SideMenuItem
                            value={SIDE_MENU_STATE_REPORTS}
                            title={'Reports'}
                            icon={graph}
                            url="/reports"
                        />
                    )}
                {showAdminMenu && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_ADMIN}
                        title={'Admin'}
                        icon={graph}
                        url="/admin"
                    />
                )}
                {(userDetails.update_fw || userDetails.is_admin) && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_FW_UPDATE_STATUS}
                        title={'FW Update Status'}
                        icon={machine}
                        url="/fw-update-status"
                    />
                )}
                {(userDetails.add_edit_recipe || userDetails.is_admin) && (
                    <SideMenuItem
                        value={SIDE_MENU_STATE_RECIPE_TYPES}
                        title={'Recipes'}
                        icon={recipeType}
                        url="/recipe-types"
                    />
                )}
            </List>
        </Drawer>
    );
}
