import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import backButton from '../../res/images/grid.svg';
import excel from '../../res/images/excel.svg';
import { setReportsFilter } from '../../store/actions/reports';
import { useDispatch, useSelector } from 'react-redux';
import {
    DAYS_BACK_1_DAY,
    DAYS_BACK_1_MONTH,
    DAYS_BACK_1_YEAR,
    DAYS_BACK_3_MONTHS,
    DAYS_BACK_7_DAYS,
    DAYS_BACK_YTD,
    SELECTION_TYPE_DAY,
    SELECTION_TYPE_ENDING_DATE,
    SELECTION_TYPE_WEEK,
    getDatesForAPI,
} from '../../store/reducers/reports';
import { useFilterChanged } from '../../store/reducers/reports';
import { ID_ALL_FLAVOURS } from '../../modules/MachineUtils';
import Authentication from '../../modules/Authentication';
import { DateTime } from 'luxon';
import BottomDateFilter from './BottomDateFilter';
import ReportGraph from './ReportGraph';
import ToggleButton from '../../components/ToggleButton';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import report1Background from '../../res/images/report1.png';
import report2Background from '../../res/images/report2.png';
import report3Background from '../../res/images/report3.png';
import report4Background from '../../res/images/report4.png';
import report5Background from '../../res/images/report5.png';
import Button from '../../components/Button';
import { useDebounce, useInterval } from '../../modules/HookUtils';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    window: {
        flexGrow: 1,
    },
    graph: {
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
    },
    reportTypesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexBasis: '50%',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '45px',
        paddingTop: '42px',
        paddingBottom: '42px',
    },
    reportTypeButton: {
        width: '400px',
        height: '180px',
        backgroundColor: '#0C2C6A',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        border: '1px solid #C4CCD6',
        borderRadius: '14px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '10px',
        cursor: 'pointer',
    },
    topSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '17px',
        paddingTop: '17px',
        paddingLeft: '15px',
    },
    selectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    showByContainer: {
        marginLeft: 'auto',
    },
    selector: {
        marginRight: '10px',
        width: '82px',
        minWidth: '82px',
        height: '42px',
        minHeight: '42px',
        borderColor: '#7D90AA',
        borderWidth: '1px',
        borderRadius: '5px',
        backgroundColor: '#E1E6ED',
        color: '#7D90AA',
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 'normal',
        '&.Mui-selected': {
            backgroundColor: '#7D90AA',
            color: '#FFFFFF',
        },
    },
    showBy: {
        textTransform: 'uppercase',
        color: '#7D90AA',
        fontSize: '16px',
        marginRight: '10px',
    },
    reportSentContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#00000099',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    reportSentDialog: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '550px',
        backgroundColor: '#FFF500',
        boxShadow: '0px 4px 6px #5A6C8480',
        borderRadius: '14px',
        padding: '20px',
    },
    reportSentIcon: {
        width: '150px',
        marginBottom: '40px',
        filter: hexColorToCSSFilter('#C1D900'),
    },
    reportSentText: {
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: '50px',
    },
    reportSentClose: {
        width: '20px',
        height: '20px',
        filter: hexColorToCSSFilter('#000000'),
        marginLeft: 'auto',
    },
    dialogButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dialogButton: {
        width: '214px',
        height: '40px',
        backgroundColor: '#7D8A024D',
        border: '2px solid #7D8A02',
        borderRadius: '20px',
    },
    dialogButtonOk: {
        marginLeft: '35px',
        backgroundColor: '#739600',
    },
    dialogButtonLabelCancel: {
        color: '#749700',
    },
    dialogButtonLabelOk: {
        color: '#EDFF00',
    },
});

export const REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS =
    'actual_consumption_hourly';
export const REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS =
    'average_consumption_hourly';
export const REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS =
    'actual_consumption_daily';
export const REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS =
    'actual_consumption_trend_analysis';
export const REPORT_TYPE_CHANGE_IN_CONSUMPTION = 'change_in_consumption';

export const SHOW_BY_HOUR = 'by_hour';
export const SHOW_BY_DAY = 'by_day';
export const SHOW_BY_WEEK = 'by_week';
export const SHOW_BY_MONTH = 'by_month';

function getReportTitle(reportType) {
    if (reportType === REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS) {
        return 'Actual Consumption on An Hourly Basis';
    } else if (reportType === REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS) {
        return 'Avg. Consumption on An Hourly Basis';
    } else if (reportType === REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS) {
        return 'Actual Consumption on A Daily Basis';
    } else if (reportType === REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS) {
        return 'Actual Consumption Trend Analysis';
    } else if (reportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION) {
        return '% / # Change in Consumption';
    }

    return 'N/A';
}

const NO_SEND_REPORT = 0;
const CONFIRM_SEND_REPORT = 1;
const REPORT_SENT = 2;

/** Report Window (top level) */
export default function ReportsWindow(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.reports.filter);
    const [reportType, setReportType] = useState(null);
    const loading = props.loading;
    const setLoading = props.setLoading;
    const [stats, setStats] = useState(null);
    const [daysBack, setDaysBack] = useState(null);
    const [showBy, setShowBy] = useState(SHOW_BY_DAY);
    const [isPercent, setIsPercent] = useState(true);
    const [reportSent, setReportSent] = useState(NO_SEND_REPORT);
    const userDetails = Authentication.getUserDetails();
    const clientCategories = props.clientCategories;
    const flavourCategories = props.flavourCategories;
    const [originalAPIParams, setOriginalAPIParams] = useState();
    const debouncedFilter = useDebounce(originalAPIParams, 2000);

    const convertToDateTime = (stats) => {
        const columnsToDateTime = {};
        Object.keys(stats.columns_to_epoch).forEach((k) => {
            columnsToDateTime[k] = DateTime.fromMillis(
                stats.columns_to_epoch[k]
            ).setZone('UTC');
        });

        return {
            allCapsules: stats.all_capsules,
            data: stats.data,
            columns: stats.columns,
            columnsToDateTime: columnsToDateTime,
        };
    };

    useEffect(() => {
        dispatch(setReportsFilter({ reportType: null }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setReportsFilter({ daysBack: daysBack }));

        if (daysBack === DAYS_BACK_1_DAY) {
            setShowBy(SHOW_BY_HOUR);
        } else if (daysBack === DAYS_BACK_7_DAYS) {
            setShowBy(SHOW_BY_HOUR);
        } else if (daysBack === DAYS_BACK_1_MONTH) {
            setShowBy(SHOW_BY_DAY);
        } else if (daysBack === DAYS_BACK_3_MONTHS) {
            setShowBy(SHOW_BY_DAY);
        } else if (daysBack === DAYS_BACK_1_YEAR) {
            setShowBy(SHOW_BY_DAY);
        } else if (daysBack === DAYS_BACK_YTD) {
            setShowBy(SHOW_BY_MONTH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysBack]);

    useEffect(() => {
        // New capsule stats arrived (or report type changed) - recalculate the stats and refresh the graph,
        // according to the selected report type
        if (reportType === null) {
            return;
        }

        const params = getAPIParams();

        console.log('ReportsWindow - setLoading true 1');
        setLoading(true);

        gApiClient
            .callApi('admin/getConsumptionReport', 'POST', {}, params)
            .then((result) => {
                setStats(convertToDateTime(result.data));
                console.log('ReportsWindow - setLoading false 2');
                setLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType, showBy, isPercent, clientCategories, flavourCategories]);

    // Load new stats once a minute
    useInterval(() => {
        console.log('ReportsWindow - Refreshing stats', loading, reportType);
        if (loading) return;
        if (reportType === null) return;

        const params = getAPIParams();

        gApiClient
            .callApi('admin/getConsumptionReport', 'POST', {}, params)
            .then((result) => {
                const newStats = convertToDateTime(result.data);

                console.log(
                    'ReportsWindow - checking if stats changed',
                    newStats.allCapsules,
                    stats.allCapsules
                );

                // Only refresh stats if they changed
                if (newStats.allCapsules !== stats.allCapsules) {
                    setStats(newStats);
                }
            });
    }, 60000);

    const getAPIParams = () => {
        let flavours = [];

        if (filter.flavours.length > 0) {
            if (filter.flavours[0]._id === ID_ALL_FLAVOURS) {
                if (filter.flavours[0].visible !== false) {
                    flavours = filter.allFlavours.slice(1);
                }
            } else {
                flavours = filter.flavours;
            }

            flavours = flavours.filter((f) => f.selected !== false);
        }

        let [startDate, endDate] = getDatesForAPI(filter);

        let params = {
            start_date: startDate,
            end_date: endDate,
            flavours: flavours.map((f) => f._id),
            clusters: filter.clusterIds,
            report_type: reportType,
            show_by: showBy,
            days_back: daysBack,
            is_percent: isPercent,
            flavour_categories: flavourCategories,
            client_categories: clientCategories,
        };

        params.start_date = params.start_date.toFormat('yyyy-MM-dd');
        params.end_date = params.end_date.toFormat('yyyy-MM-dd');

        return params;
    };

    useFilterChanged(filter, () => {
        // Filter changed (dates/clusters/flavours) - Get capsule consumption stats
        const params = getAPIParams();

        setOriginalAPIParams(params);
    });

    useEffect(() => {
        console.log('ReportsWindow - setLoading true 3');
        setLoading(true);

        gApiClient
            .callApi('admin/getConsumptionReport', 'POST', {}, debouncedFilter)
            .then((result) => {
                setStats(convertToDateTime(result.data));
                console.log('ReportsWindow - setLoading false 4');
                setLoading(false);
            });
    }, [debouncedFilter, setLoading]);

    const moveToReportType = (newReportType) => {
        // Update date filter type according to report type
        let newFilter = {};

        newFilter.endDate = DateTime.local().endOf('day');

        if (newReportType === REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS) {
            newFilter.selectionType = SELECTION_TYPE_DAY;
            newFilter.startDate = newFilter.endDate.startOf('day');
            newFilter.daysBack = DAYS_BACK_1_DAY;
        } else if (
            newReportType === REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS ||
            newReportType === REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
        ) {
            newFilter.selectionType = SELECTION_TYPE_WEEK;
            newFilter.startDate = newFilter.endDate
                .minus({ days: 6 })
                .startOf('day');
            newFilter.daysBack = DAYS_BACK_1_DAY;
        } else if (
            newReportType === REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS ||
            newReportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION
        ) {
            newFilter.selectionType = SELECTION_TYPE_ENDING_DATE;
            newFilter.startDate = newFilter.endDate.startOf('day');
            newFilter.daysBack =
                newReportType === REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS
                    ? DAYS_BACK_1_DAY
                    : DAYS_BACK_1_MONTH;

            setDaysBack(newFilter.daysBack);
            setShowBy(
                newReportType === REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS
                    ? SHOW_BY_HOUR
                    : SHOW_BY_DAY
            );

            if (newReportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION) {
                setIsPercent(true);
            }
        }

        newFilter.selectionCount = 1;

        newFilter.reportType = newReportType;
        dispatch(setReportsFilter(newFilter));
        setReportType(newReportType);
    };

    const generateReport = () => {
        console.log('ReportsWindow - setLoading true 5');
        setLoading(true);

        let params = getAPIParams();

        gApiClient
            .callApi('admin/generateNewConsumptionReport', 'POST', {}, params)
            .then((result) => {
                console.log('ReportsWindow - setLoading false 6');
                setLoading(false);
                setReportSent(REPORT_SENT);
            });
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={
                reportType === null
                    ? 'Select Report Type'
                    : getReportTitle(reportType)
            }
            loading={loading}
            leftIcon={reportType !== null ? backButton : null}
            onLeftIconClick={() => {
                moveToReportType(null);
            }}
            icon={reportType !== null ? excel : null}
            onIconClick={() => {
                setReportSent(CONFIRM_SEND_REPORT);
            }}
        >
            <div className={classes.content}>
                {reportType === null && (
                    <div className={classes.reportTypesContainer}>
                        <div
                            className={classes.reportTypeButton}
                            style={{
                                backgroundImage: `url('${report1Background}')`,
                            }}
                            onClick={() =>
                                moveToReportType(
                                    REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS
                                )
                            }
                        >
                            {getReportTitle(
                                REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS
                            )}
                        </div>
                        <div
                            className={classes.reportTypeButton}
                            style={{
                                backgroundImage: `url('${report3Background}')`,
                            }}
                            onClick={() =>
                                moveToReportType(
                                    REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
                                )
                            }
                        >
                            {getReportTitle(
                                REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
                            )}
                        </div>
                        <div
                            className={classes.reportTypeButton}
                            style={{
                                backgroundImage: `url('${report4Background}')`,
                            }}
                            onClick={() =>
                                moveToReportType(
                                    REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS
                                )
                            }
                        >
                            {getReportTitle(
                                REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS
                            )}
                        </div>
                    </div>
                )}
                {(reportType ===
                    REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS ||
                    reportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION) && (
                    <div className={classes.topSelectorContainer}>
                        <div className={classes.selectorContainer}>
                            {reportType ===
                                REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS && (
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() =>
                                        setDaysBack(DAYS_BACK_1_DAY)
                                    }
                                    selected={daysBack === DAYS_BACK_1_DAY}
                                >
                                    1D
                                </ToggleButton>
                            )}
                            {reportType ===
                                REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS && (
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() =>
                                        setDaysBack(DAYS_BACK_7_DAYS)
                                    }
                                    selected={daysBack === DAYS_BACK_7_DAYS}
                                >
                                    7D
                                </ToggleButton>
                            )}
                            <ToggleButton
                                className={classes.selector}
                                onChange={() => setDaysBack(DAYS_BACK_1_MONTH)}
                                selected={daysBack === DAYS_BACK_1_MONTH}
                            >
                                1M
                            </ToggleButton>
                            {reportType ===
                                REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS && (
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() =>
                                        setDaysBack(DAYS_BACK_3_MONTHS)
                                    }
                                    selected={daysBack === DAYS_BACK_3_MONTHS}
                                >
                                    3M
                                </ToggleButton>
                            )}
                            <ToggleButton
                                className={classes.selector}
                                onChange={() => setDaysBack(DAYS_BACK_1_YEAR)}
                                selected={daysBack === DAYS_BACK_1_YEAR}
                            >
                                1Y
                            </ToggleButton>
                            {reportType ===
                                REPORT_TYPE_CHANGE_IN_CONSUMPTION && (
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() => setDaysBack(DAYS_BACK_YTD)}
                                    selected={daysBack === DAYS_BACK_YTD}
                                >
                                    YTD
                                </ToggleButton>
                            )}
                        </div>
                        <div
                            className={clsx(
                                classes.selectorContainer,
                                classes.showByContainer
                            )}
                        >
                            <div className={classes.showBy}>Show By:</div>
                            {reportType ===
                                REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS && (
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() => setShowBy(SHOW_BY_HOUR)}
                                    selected={showBy === SHOW_BY_HOUR}
                                    disabled={
                                        ![
                                            DAYS_BACK_1_DAY,
                                            DAYS_BACK_7_DAYS,
                                        ].includes(daysBack)
                                    }
                                >
                                    Hour
                                </ToggleButton>
                            )}
                            <ToggleButton
                                className={classes.selector}
                                onChange={() => setShowBy(SHOW_BY_DAY)}
                                selected={showBy === SHOW_BY_DAY}
                                disabled={[
                                    DAYS_BACK_1_DAY,
                                    DAYS_BACK_YTD,
                                ].includes(daysBack)}
                            >
                                Day
                            </ToggleButton>
                            <ToggleButton
                                className={classes.selector}
                                onChange={() => setShowBy(SHOW_BY_WEEK)}
                                selected={showBy === SHOW_BY_WEEK}
                                disabled={[
                                    DAYS_BACK_1_DAY,
                                    DAYS_BACK_7_DAYS,
                                    DAYS_BACK_YTD,
                                ].includes(daysBack)}
                            >
                                Week
                            </ToggleButton>
                            <ToggleButton
                                className={classes.selector}
                                onChange={() => setShowBy(SHOW_BY_MONTH)}
                                selected={showBy === SHOW_BY_MONTH}
                                disabled={
                                    ![
                                        DAYS_BACK_3_MONTHS,
                                        DAYS_BACK_1_YEAR,
                                        DAYS_BACK_YTD,
                                    ].includes(daysBack)
                                }
                            >
                                Month
                            </ToggleButton>
                        </div>

                        {reportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION && (
                            <div
                                className={clsx(
                                    classes.selectorContainer,
                                    classes.showByContainer
                                )}
                            >
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() => setIsPercent(true)}
                                    selected={isPercent}
                                >
                                    %
                                </ToggleButton>
                                <ToggleButton
                                    className={classes.selector}
                                    onChange={() => setIsPercent(false)}
                                    selected={!isPercent}
                                >
                                    Diff
                                </ToggleButton>
                            </div>
                        )}
                    </div>
                )}
                {reportType !== null && (
                    <ReportGraph
                        className={classes.graph}
                        stats={stats}
                        reportType={reportType}
                        daysBack={daysBack}
                        showBy={showBy}
                        isPercent={
                            isPercent &&
                            reportType === REPORT_TYPE_CHANGE_IN_CONSUMPTION
                        }
                        legend={
                            reportType !== REPORT_TYPE_CHANGE_IN_CONSUMPTION
                        }
                    />
                )}
                {reportType !== null && <BottomDateFilter showBy={showBy} />}
                {reportSent !== NO_SEND_REPORT && (
                    <div className={classes.reportSentContainer}>
                        <div className={classes.reportSentDialog}>
                            {reportSent === CONFIRM_SEND_REPORT && (
                                <div className={classes.reportSentText}>
                                    Export Excel Report?
                                </div>
                            )}
                            {reportSent === REPORT_SENT && (
                                <>
                                    <img
                                        className={classes.reportSentIcon}
                                        src={excel}
                                        alt={'Export to Excel Report'}
                                    />
                                    <div className={classes.reportSentText}>
                                        Excel report will be sent to:{' '}
                                        <b>{userDetails.email}</b>
                                    </div>
                                </>
                            )}
                            <div className={classes.dialogButtonContainer}>
                                {reportSent === CONFIRM_SEND_REPORT && (
                                    <Button
                                        className={classes.dialogButton}
                                        labelClass={
                                            classes.dialogButtonLabelCancel
                                        }
                                        label="Cancel"
                                        onClick={() => {
                                            setReportSent(NO_SEND_REPORT);
                                        }}
                                    />
                                )}
                                <Button
                                    className={clsx(
                                        classes.dialogButton,
                                        classes.dialogButtonOk
                                    )}
                                    label="OK"
                                    labelClass={classes.dialogButtonLabelOk}
                                    onClick={() => {
                                        if (
                                            reportSent === CONFIRM_SEND_REPORT
                                        ) {
                                            generateReport();
                                        } else {
                                            setReportSent(NO_SEND_REPORT);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </InnerWindow>
    );
}
