import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import closeIcon from '../../res/images/close_circle.svg';
import { setStatsFilter } from '../../store/actions/stats';
import clone from 'clone';
import ToggleButton from '../../components/ToggleButton';
import { usePrevious } from '../../modules/HookUtils';
import { ID_ALL_FLAVOURS } from '../../modules/MachineUtils';

const useStyles = makeStyles({
    window: {
        width: '414px',
        background: '#00B09E',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
    },
    sortButton: {
        marginRight: '10px',
        width: '100px',
        height: '100px',
        minHeight: '100px',
        borderColor: '#ffffff',
        backgroundColor: '#4cad9e',
        color: '#ffffff',
        fontSize: '10px',
        '&.Mui-selected': {
            borderColor: '#ffffff',
            backgroundColor: '#2e6f64',
            color: '#ffffff',
        },
    },
    filterSection: {
        paddingTop: '10px',
        paddingLeft: '25px',
        paddingRight: '25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'fit-content',
        minHeight: 'fit-content',
    },
    filterLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#ffffff',
        marginBottom: '10px',
    },
    filterButtonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        height: 'fit-content',
        minHeight: 'fit-content',
        overflow: 'hidden',
        flexGrow: 1,
    },
    filterButton: {
        marginBottom: '15px',
        textAlign: 'center',
    },
    filterButtonContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterCapsuleCount: {
        fontWeight: 'normal',
    },
    filterButtonId: {
        textTransform: 'none',
    },
});

/** Formats the title of the window according to selected filter */
function formatTitle(filter) {
    if (filter.flavours.findIndex((x) => x._id === ID_ALL_FLAVOURS) > -1) {
        return 'Flavours (All)';
    } else {
        return `Flavours (${filter.flavours.length})`;
    }
}

/** The filter flavours inner window */
export default function FilterFlavours(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.stats.filter);
    const dispatch = useDispatch();
    const [flavours, setFlavours] = useState([]);
    const [categories, setCategories] = useState(new Set());
    const selectedFlavourIds = new Set();
    const prevAllFlavours = usePrevious(filter.allFlavours) || [];

    filter.flavours.map((f) => selectedFlavourIds.add(f._id));

    useEffect(() => {
        if (filter.allFlavours === null) return;
        if (
            JSON.stringify(filter.allFlavours) ===
            JSON.stringify(prevAllFlavours)
        )
            return;

        // Loaded all flavours

        setFlavours(filter.allFlavours);

        // Build categories from flavours
        const newCategories = new Set();
        filter.allFlavours.map((f) =>
            f.categories.map((c) => newCategories.add(c))
        );

        setCategories(newCategories);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.allFlavours]);

    const setSelectedFlavourIds = (ids, newFlavours) => {
        let idToFlavour = {};
        const flavs = newFlavours ? newFlavours : flavours;

        flavs.map((f) => {
            idToFlavour[f._id] = f;
            return null;
        });

        const filterFlavours = [...ids].map((id) => ({
            ...idToFlavour[id],
            visible: true,
            selected: true,
        }));

        dispatch(
            setStatsFilter({
                flavours: filterFlavours,
                allFlavours: flavs,
            })
        );
    };

    const toggleSelectedFlavour = (flavourId) => {
        const selectedFlavoursCopy = clone(selectedFlavourIds);
        if (selectedFlavoursCopy.has(flavourId)) {
            if (flavourId === ID_ALL_FLAVOURS) {
                // Remove all flavours
                selectedFlavoursCopy.clear();
            } else {
                selectedFlavoursCopy.delete(flavourId);
            }
        } else {
            selectedFlavoursCopy.add(flavourId);
            if (flavourId !== ID_ALL_FLAVOURS) {
                selectedFlavoursCopy.delete(ID_ALL_FLAVOURS);
            }
        }

        setSelectedFlavourIds(selectedFlavoursCopy);
    };

    const selectUnSelectAllFlavours = () => {
        // Select / Unselect all flavours
        const selectedFlavoursCopy = new Set();
        if (!selectedFlavourIds.has(ID_ALL_FLAVOURS)) {
            selectedFlavoursCopy.add(ID_ALL_FLAVOURS);
        }

        setSelectedFlavourIds(selectedFlavoursCopy);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={formatTitle(filter)}
            loading={props.loading}
            icon={closeIcon}
            onIconClick={() => {
                if (props.loading) return;

                // Reset selection - choose all flavours
                const selectedFlavoursCopy = new Set();
                selectedFlavoursCopy.add(flavours[0]._id);
                setSelectedFlavourIds(selectedFlavoursCopy);
            }}
        >
            <div className={classes.content}>
                <div className={classes.filterSection}>
                    <div className={classes.filterLabel}>All Flavours:</div>
                    <ToggleButton
                        className={clsx(
                            classes.sortButton,
                            classes.filterButton
                        )}
                        onChange={selectUnSelectAllFlavours}
                        selected={selectedFlavourIds.has(ID_ALL_FLAVOURS)}
                    >
                        <div className={classes.filterButtonContent}>
                            <div>All Flavours</div>
                            <div className={classes.filterCapsuleCount}>
                                {flavours.length > 0
                                    ? flavours[0].capsule_count
                                    : ''}
                            </div>
                        </div>
                    </ToggleButton>
                </div>

                {[...categories].map((category) => (
                    <div key={category} className={classes.filterSection}>
                        <div className={classes.filterLabel}>{category}:</div>
                        <div className={classes.filterButtonContainer}>
                            {flavours
                                .filter(
                                    (f) => f.categories.indexOf(category) > -1
                                )
                                .map((flavour) => (
                                    <ToggleButton
                                        key={flavour._id}
                                        className={clsx(
                                            classes.sortButton,
                                            classes.filterButton
                                        )}
                                        onChange={() =>
                                            toggleSelectedFlavour(flavour._id)
                                        }
                                        selected={selectedFlavourIds.has(
                                            flavour._id
                                        )}
                                    >
                                        <div
                                            className={
                                                classes.filterButtonContent
                                            }
                                        >
                                            <div>{flavour.title}</div>
                                            <div
                                                className={
                                                    classes.filterButtonId
                                                }
                                            >
                                                {flavour._id}
                                            </div>
                                            <div
                                                className={
                                                    classes.filterCapsuleCount
                                                }
                                            >
                                                {flavour.capsule_count}
                                            </div>
                                        </div>
                                    </ToggleButton>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </InnerWindow>
    );
}
