import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import recipeIcon from '../../../res/images/flavour_border.svg';
import forwardIcon from '../../../res/images/right_circle.svg';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import { downloadFile } from '../../../modules/UrlUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setAdminMachinePartTypes } from '../../../store/actions/admin';

const useStyles = makeStyles({
    successMessage: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'green',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formRowNarrow: {
        marginTop: '0px',
    },
    formSubRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '240px',
    },
    addButton: {
        width: '120px',
    },
});

const gApiClient = Authentication.getAPIClient();

export default function MachinePartsForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const machinePartTypes = filter.machinePartTypes;
    const [error, setError] = useState(null);
    const [partCount, setPartCount] = useState(0);
    const [partType, setPartType] = useState(null);
    const disallowFormSubmission = partCount === 0 || !partType;
    machinePartTypes.sort((a, b) => a.solo_pn.localeCompare(b.solo_pn));
    const machinePartItems = machinePartTypes.map((x) => ({
        value: x.solo_pn,
        label: `${x.solo_pn} - ${x.manufactor} - ${x.manufactor_pn} - ${x.part_name}`,
    }));

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];

        if (filter.machinePartTypes.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/machinePartTypes',
                        'GET',
                        {}
                    );
                    dispatch(setAdminMachinePartTypes(results.data));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            const params = {
                solo_pn: partType,
                count: partCount,
                minute_offset: new Date().getTimezoneOffset() * -1,
            };

            const response = await gApiClient.callApi(
                'admin/createMachineParts',
                'POST',
                {},
                params
            );

            const url = response.data.url;
            console.log(url);
            await downloadFile(`Machine Parts - Codes - ${partType}.xlsx`, url);
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        props.onLoading(false);
    };

    return (
        <div>
            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    items={machinePartItems}
                    value={
                        partType
                            ? machinePartItems.find((r) => r.value === partType)
                            : null
                    }
                    onChange={(m) => {
                        setPartType(m.value);
                    }}
                    rightIcon={recipeIcon}
                    hint={'Part Type'}
                    caseSensitive={true}
                    autoComplete
                />
            </div>
            <div className={classes.formRow}>
                <TextField
                    className={classes.field}
                    style={{ marginBottom: 0, marginLeft: 5, width: 180 }}
                    value={partCount}
                    leftLabel={'Count:'}
                    leftLabelWidth={60}
                    fullWidth
                    onChange={(v) => {
                        setPartCount(v ? parseInt(v) : 0);
                    }}
                />
            </div>

            {error && (
                <div
                    className={classes.formRow}
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <div className={classes.error}>{error}</div>
                </div>
            )}

            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.confirmSubmit}
                    label="Create Machine Parts"
                    disabled={disallowFormSubmission}
                    rightIcon={forwardIcon}
                    onClick={onSubmitForm}
                />
            </div>
        </div>
    );
}
