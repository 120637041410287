import React from 'react';
import { useSelector } from 'react-redux';
import checkMark from '../../res/images/check_mark.svg';
import cogs from '../../res/images/cogs.svg';
import eyes from '../../res/images/eye.svg';
import {
    TOGGLE_BAR_STATE_DASHBOARD,
    TOGGLE_BAR_STATE_TIMELINE,
    TOGGLE_BAR_STATE_SG_KPI,
    TOGGLE_BAR_STATE_ACTION,
} from '../../store/actions/ui';
import ToggleBar from '../../components/ToggleBar';
import Authentication from '../../modules/Authentication';

/** Machines view specific toggle bar */
export default function MachinesToggleBar() {
    const singleMachine = useSelector((state) => state.ui.singleMachine);

    const buttons = [
        {
            title: 'Dashboard',
            value: TOGGLE_BAR_STATE_DASHBOARD,
            icon: eyes,
            url: 'dashboard',
        },
        {
            title: 'Timeline',
            value: TOGGLE_BAR_STATE_TIMELINE,
            icon: eyes,
            disabled: true,
            hidden: true,
        },
        {
            title: 'SG KPI',
            value: TOGGLE_BAR_STATE_SG_KPI,
            icon: cogs,
            disabled: true,
            hidden: true,
        },
        {
            title: 'Action',
            value: TOGGLE_BAR_STATE_ACTION,
            icon: checkMark,
            disabled: singleMachine === null,
            url: 'action',
        },
    ];

    return <ToggleBar buttons={buttons} />;
}
