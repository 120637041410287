import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import InnerWindow from '../../components/InnerWindow';
import clsx from 'clsx';
import { STATUS_GROUPS, TYPE_STATUS } from './StatusGroups';
import ReactTooltip from 'react-tooltip';
import clientSiteViewIcon from '../../res/images/client_site_view.svg';
import clientSiteViewEnabledIcon from '../../res/images/client_site_view_blue.svg';
import arrowUpCircleIcon from '../../res/images/arrow_up_circle.svg';
import { IconButton } from '@material-ui/core';
import clone from 'clone';
import DashboardMap from './DashboardMap';
import MapInfoPane from './MapInfoPane';
import { useMachineListChanged } from '../../modules/MachineUtils';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: 0,
        flexGrow: 1,
    },
    map: {
        flexGrow: 1,
    },
    bottomLegend: {
        height: '48px',
        minHeight: '48px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '23px',
        paddingRight: '23px',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#000000',
    },
    legendIcon: {
        marginLeft: '13px',
        height: '24px',
        width: '24px',
        cursor: 'pointer',
    },
    legendIconDisabled: {
        opacity: 0.4,
    },
    tooltip: {
        textTransform: 'none',
        borderRadius: '16px',
    },
    warningsText: {
        marginLeft: '47px',
    },
    openBottomContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    openBottom: {
        padding: '0px',
    },
    mapInfoPane: {
        position: 'absolute',
        height: '250px',
        width: '100%',
        bottom: '48px',
    },
});

/** The small legend at the bottom of the map */
function Legend(props) {
    const classes = useStyles();
    const statusGroups = props.statusGroups;

    return (
        <div className={clsx(props.className, classes.bottomLegend)}>
            <div>Status</div>
            {statusGroups
                .filter((s) => s.type === TYPE_STATUS)
                .map((s) => (
                    <img
                        key={s.title}
                        className={clsx(
                            classes.legendIcon,
                            !s.visible ? classes.legendIconDisabled : null
                        )}
                        src={s.icon}
                        data-tip={s.title}
                        onClick={() => {
                            props.onStatusClicked(s);
                        }}
                        alt={s.title}
                    />
                ))}
            <div className={classes.warningsText}>Warnings</div>
            {statusGroups
                .filter((s) => s.type !== TYPE_STATUS)
                .map((s) => (
                    <img
                        key={s.title}
                        className={clsx(
                            classes.legendIcon,
                            !s.visible ? classes.legendIconDisabled : null
                        )}
                        src={s.icon}
                        data-tip={s.title}
                        onClick={() => {
                            props.onStatusClicked(s);
                        }}
                        alt={s.title}
                    />
                ))}
            <ReactTooltip
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                className={classes.tooltip}
            />

            <div className={classes.warningsText}>Client Site View</div>

            <img
                className={classes.legendIcon}
                src={
                    props.clientSiteViewEnabled
                        ? clientSiteViewEnabledIcon
                        : clientSiteViewIcon
                }
                onClick={() =>
                    props.onClientSiteViewChanged(!props.clientSiteViewEnabled)
                }
                alt={'Enable/Disable Client Site View'}
            />

            <div className={classes.openBottomContainer}>
                {!props.infoPaneOpen && (
                    <IconButton
                        className={classes.openBottom}
                        onClick={props.onOpenInfoPane}
                    >
                        <img src={arrowUpCircleIcon} alt={'Show bottom pane'} />
                    </IconButton>
                )}
            </div>
        </div>
    );
}

/** Dashboard map container - showing the machines map, bottom legend and info pane */
export default function DashboardMapContainer(props) {
    const classes = useStyles();
    const [infoPaneOpen, setInfoPaneOpen] = useState(false);
    const [clientSiteViewEnabled, setClientSiteViewEnabled] = useState(false);
    const [visibleMachines, setVisibleMachines] = useState([]);
    const [statusGroups, setStatusGroups] = useState(
        STATUS_GROUPS.map((s) => ({
            ...s,
            visible: true,
        }))
    );
    const bottomSelectedMachines = useSelector(
        (state) => state.overview.bottomSelectedMachines
    );

    useMachineListChanged(bottomSelectedMachines, (newList) => {
        if (newList.length > 0) {
            // User clicked on a machine on the map - need top open info pane
            setInfoPaneOpen(true);
        }
    });

    // Show/hide specific status from map
    const onLegendStatusClicked = (selectedStatus) => {
        const newStatusGroup = clone(statusGroups);
        const changedStatus = newStatusGroup.find(
            (s) => s.title === selectedStatus.title
        );
        changedStatus.visible = !changedStatus.visible;
        setStatusGroups(newStatusGroup);
    };

    return (
        <InnerWindow
            title={`Machines (${visibleMachines.length})`}
            className={clsx(classes.window, props.className)}
            loading={props.loading}
        >
            <div className={classes.content}>
                <DashboardMap
                    className={classes.map}
                    statusGroups={statusGroups}
                    clientSiteViewEnabled={clientSiteViewEnabled}
                    onVisibleMachinesChanged={setVisibleMachines}
                />
                {infoPaneOpen && (
                    <MapInfoPane
                        className={classes.mapInfoPane}
                        onClose={() => {
                            setInfoPaneOpen(false);
                        }}
                    />
                )}
                <Legend
                    statusGroups={statusGroups}
                    onStatusClicked={onLegendStatusClicked}
                    infoPaneOpen={infoPaneOpen}
                    onClientSiteViewChanged={setClientSiteViewEnabled}
                    clientSiteViewEnabled={clientSiteViewEnabled}
                    onOpenInfoPane={() => {
                        setInfoPaneOpen(true);
                    }}
                />
            </div>
        </InnerWindow>
    );
}
