import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Router, MainRoutes } from './routes';
import TopBar from './components/TopBar';
import SideMenu from './components/SideMenu';
import { usePrevious } from './modules/HookUtils';
import Authentication from './modules/Authentication';
import { setUserDetails } from './store/actions/user';
import clsx from 'clsx';
import { APP_BAR_VISIBLE } from './store/actions/ui';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    rightContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: 0,
        flexShrink: 0,
        height: '100%',
    },
    noPadding: {
        padding: 0,
    },
}));

/** Refreshes user details, used to get user's email, etc., but also to check whether or not he's operational or not -
 * i.e. should work with the dev APIs and website or the prod one. */
function refreshUserDetails(dispatch) {
    console.log('App - refreshUserDetails');

    const apiClient = Authentication.getAPIClient();
    apiClient
        .callApi('getUser', 'GET', {}, {})
        .then((response) => {
            console.log('Got user ', response.data);

            // Update user details in store
            const user = response.data;
            dispatch(setUserDetails(user));

            // Redirect to dev website, if needed (i.e. current user is not operational, but current NOC website URL is prod)
            Authentication.redirectToStageWebsiteIfNeeded(user);
        })
        .catch((result) => {
            console.log('error', result);
        });
}

/** Base layout for all screens */
export default function App() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loginStatus = useSelector((state) => state.user.loginStatus);
    const prevLoginStatus = usePrevious(loginStatus);
    const userDetails = useSelector((state) => state.user.userDetails);
    const appBarVisible = useSelector((state) => state.ui.appBarVisible);

    if (
        (loginStatus === Authentication.LOGIN_STATUS_LOGGED_IN &&
            prevLoginStatus !== loginStatus) ||
        (userDetails === null &&
            loginStatus === Authentication.LOGIN_STATUS_LOGGED_IN)
    ) {
        // User just logged-in, or user details are null - Refresh user details
        refreshUserDetails(dispatch);
    }

    return (
        <Router>
            <React.Fragment>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <div className={classes.root}>
                        <SideMenu />
                        <div className={classes.rightContent}>
                            <TopBar />
                            <main
                                className={clsx(
                                    classes.content,
                                    appBarVisible === APP_BAR_VISIBLE
                                        ? null
                                        : classes.noPadding
                                )}
                            >
                                <MainRoutes />
                            </main>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        </Router>
    );
}
