import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Authentication from '../modules/Authentication';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import { APP_BAR_HIDDEN, setAppBarVisibility } from '../store/actions/ui';
import emailIcon from '../res/images/email.svg';
import bigCheckmarkIcon from '../res/images/big_check_mark.svg';
import clsx from 'clsx';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '100%',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        marginTop: '25px',
        borderRadius: '32px',
        height: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '20px',
        },
    },
    error: {
        color: '#ff0000',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        alignSelf: 'flex-start',
        marginLeft: '120px',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundImage: {},
    backToSignIn: {
        color: '#000000',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '20px',
    },
    link: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#000000',
            textDecoration: 'none',
        },
    },
    innerContainer: {
        width: '714px',
        height: '450px',
        backgroundColor: '#e8e8e8',
        position: 'relative',
        boxShadow: '0 9px 40px 20px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
        color: '#00948d',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerTitle: {
        fontSize: '34px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    innerSubTitle: {
        fontSize: '20px',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    successImage: {
        position: 'absolute',
        width: '470px',
        left: 'calc(50% - 235px)',
        top: 'calc(50% - 192px)',
    },
    successContainer: {
        justifyContent: 'center',
    },
});

/** The forgot password screen (asking to send reset password link) */
export default function ForgotPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    const verifyForm = () => {
        if (email.length === 0) {
            setError({ email: 'Required' });
            return false;
        }
        if (
            !email.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            setError({ email: 'Invalid email format' });
            return false;
        }

        return true;
    };

    const onClick = () => {
        resetPassword();
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            resetPassword();
        }
    };

    const resetPassword = () => {
        if (!verifyForm()) return;

        setLoading(true);
        setError({});

        // Send reset password link to the specified email address
        Authentication.resetPassword(
            email.toLowerCase(),
            () => {
                setSuccess(true);
                setLoading(false);
            },
            (err) => {
                // Couldn't send password reset link.
                console.log('error', err);
                setLoading(false);
                if (err.code === 'UserNotFoundException') {
                    setError({ email: 'Email not found' });
                } else {
                    setError({ general: err.message });
                }
            }
        );
    };

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img
                    src={bgTop}
                    className={classes.backgroundImage}
                    alt="Background"
                />
                <img
                    src={bgBottom}
                    className={classes.backgroundImage}
                    alt="Background"
                />
            </div>

            {success && (
                <div
                    className={clsx(
                        classes.innerContainer,
                        classes.successContainer
                    )}
                >
                    <img
                        className={classes.successImage}
                        src={bigCheckmarkIcon}
                        alt="Success"
                    />
                    <div className={classes.innerTitle}>Success!</div>
                    <div className={classes.innerSubTitle}>
                        Password reset link sent to your email address.
                    </div>
                </div>
            )}

            {!success && (
                <div className={classes.innerContainer}>
                    <div className={classes.innerTitle}>
                        Forgot your password?
                    </div>
                    <div className={classes.innerSubTitle}>
                        Enter the email address you're using for the NOC, and
                        we'll send you an email to reset your password.
                    </div>

                    <TextField
                        className={classes.input}
                        onChange={setEmail}
                        onKeyUp={onKeyPress}
                        rightIcon={emailIcon}
                        placeholder={'Email'}
                    />
                    <div className={classes.error}>{error.email}&nbsp;</div>

                    <Button
                        loading={loading}
                        className={classes.button}
                        label="Request Email"
                        onClick={onClick}
                    />
                    <div className={classes.error}>{error.general}</div>

                    <div className={classes.backToSignIn}>
                        Back to
                        <a className={classes.link} href="/login">
                            Sign In
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}
