import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import capsulesIcon from '../../res/images/capsules.svg';
import { ID_ALL_FLAVOURS } from '../../modules/MachineUtils';
import MultipleFieldsDisplay from '../../components/MultipleFieldsDisplay';
import { DateTime } from 'luxon';
import infoIcon from '../../res/images/info.svg';
import closeIcon from '../../res/images/close_circle.svg';
import {
    datesToSelectionType,
    SELECTION_TYPE_DAY,
    SELECTION_TYPE_MONTH,
    SELECTION_TYPE_WEEK,
} from './BottomDateFilter';
import Authentication from '../../modules/Authentication';
import { IconButton } from '@material-ui/core';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { useFilterChanged } from '../../store/reducers/stats';

const gApiClient = Authentication.getAPIClient();

const useStyles = makeStyles({
    window: {
        width: '414px',
        backgroundColor: '#f0f3f9',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f0f3f9',
        padding: '31px',
    },
    infoContainer: {
        backgroundImage: 'linear-gradient(150deg, #0d49b1, #2282e7)',
    },
    infoContent: {
        backgroundColor: '#ffffff00',
        color: '#ffffff',
        position: 'relative',
        paddingTop: '15px',
    },
    infoClose: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginRight: '10px',
        '& img': {
            filter: hexColorToCSSFilter('#ffffff'),
            width: '42px',
            height: '42px',
        },
    },
    infoTitle: {
        fontSize: '28px',
        textAlign: 'center',
        marginBottom: '51px',
    },
    infoText: {
        fontSize: '18px',
    },
    label: {
        textTransform: 'uppercase',
        color: '#7d90aa',
        fontSize: '12px',
    },
    separator: {
        backgroundColor: '#7d90aa',
        opacity: 0.2,
        height: '1px',
        width: '100%',
        marginTop: '15px',
        marginBottom: '15px',
    },
    periodSummary: {
        marginBottom: '7px',
    },
    multipleFieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e6e9f1',
        width: '100%',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '7px',
    },
    topPart: {
        display: 'flex',
        flexDirection: 'row',
        alignItem: 'center',
    },
    title: {
        marginBottom: '8px',
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#7d90aa',
        flexGrow: 1,
    },
    innerFieldsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    field: {
        backgroundColor: '#d5dbe6',
        position: 'relative',
        width: '106px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '3px',
    },
    fieldLabel: {
        color: '#586374',
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    fieldIcon: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    fieldValue: {
        fontStyle: 'italic',
    },
});

/** Shows a grouping of text fields and labels - showing capsule consumption for
 * a certain period (e.g. Daily capsules, this week's capsules, etc.) */
function PeriodCapsuleContainer(props) {
    const classes = useStyles();
    let date1Display, date2Display;
    const today = DateTime.local();
    const stats = props.stats;
    const capsuleDiff = stats.capsuleCount - stats.capsuleCount2;
    let capsuleDiffPercentage;

    if (capsuleDiff === 0) {
        capsuleDiffPercentage = 0;
    } else if (stats.capsuleCount2 === 0) {
        capsuleDiffPercentage = capsuleDiff * 100;
    } else {
        capsuleDiffPercentage = (capsuleDiff / stats.capsuleCount2) * 100;
    }

    if (props.type === SELECTION_TYPE_DAY) {
        // If it's today's date - show current time, otherwise show end of day
        date1Display =
            +stats.startDate.startOf('day') === +today.startOf('day')
                ? today.toFormat('HH:mm')
                : '23:59';

        date2Display = stats.startDate2.toFormat('EEE d MMM');
    } else {
        // If start/end dates are in the same month, show as '3-10 Sep 2020',
        // Otherwise show as '27 Aug - 10 Sep 2020'.
        const startFormat = stats.startDate.hasSame(stats.endDate, 'month')
            ? 'd'
            : 'd MMM';
        const startFormat2 = stats.startDate2.hasSame(stats.endDate2, 'month')
            ? 'd'
            : 'd MMM';

        date1Display =
            `${stats.startDate.toFormat(startFormat)} - ` +
            `${stats.endDate.toFormat('d MMM yyyy')}`;
        date2Display =
            `${stats.startDate2.toFormat(startFormat2)} - ` +
            `${stats.endDate2.toFormat('d MMM yyyy')}`;
    }

    return (
        <MultipleFieldsDisplay
            className={classes.periodSummary}
            title={props.title}
            rightIcon={infoIcon}
            onRightIconClick={props.onInfoClick}
            fields={[
                {
                    label: date1Display,
                    value: stats.capsuleCount,
                    align: 'center',
                    capsulesIcon: true,
                    width: '166px',
                },
                {
                    label: date2Display,
                    value: `${
                        capsuleDiffPercentage > 0 ? '+' : ''
                    }${capsuleDiffPercentage.toFixed(1)}%`,
                    align: 'center',
                    width: '83px',
                },
                {
                    label: ' ',
                    value: `${
                        capsuleDiffPercentage > 0 ? '+' : ''
                    }${capsuleDiff}`,
                    align: 'center',
                    capsulesIcon: true,
                    width: '83px',
                },
            ]}
        />
    );
}

/** Calls a server API to calculate capsule consumption stats for a specific date range */
function getCapsuleStats(
    startDate,
    endDate,
    filter,
    returnClients,
    returnFlavours
) {
    let flavours = [];

    if (filter.flavours.length > 0) {
        if (filter.flavours[0]._id === ID_ALL_FLAVOURS) {
            if (filter.flavours[0].visible !== false) {
                flavours = filter.allFlavours.slice(1);
            }
        } else {
            flavours = filter.flavours;
        }
        flavours = flavours.filter((f) => f.visible !== false);
    }

    let params = {
        start_date: startDate.toFormat('yyyy-MM-dd'),
        end_date: endDate.toFormat('yyyy-MM-dd'),
        flavours: flavours.map((f) => f._id),
        clusters: filter.clusterIds,
    };

    if (returnClients) {
        params.return_clients = 1;
    }
    if (returnFlavours) {
        params.return_recipe_types = 1;
    }

    return gApiClient.callApi(
        `admin/getGeneralCapsuleConsumptionStats`,
        'POST',
        {},
        params
    );
}

/** Calls several APIs for two periods (e.g. daily capsules - today and yesterday */
async function getStatsForPeriod(
    filter,
    startDate,
    endDate,
    startDate2,
    endDate2
) {
    console.log('Getting stats for ', startDate.toISO(), endDate.toISO());
    console.log('Getting stats #2 for ', startDate2.toISO(), endDate2.toISO());
    const firstPeriod = getCapsuleStats(startDate, endDate, filter);
    const secondPeriod = getCapsuleStats(startDate2, endDate2, filter);

    const results = await Promise.all([firstPeriod, secondPeriod]);

    return {
        startDate: startDate,
        endDate: endDate,
        startDate2: startDate2,
        endDate2: endDate2,
        capsuleCount: results[0].data.total_capsules,
        capsuleCount2: results[1].data.total_capsules,
    };
}

/** A component showing top 3 flavours/clients */
function TopItems(props) {
    const classes = useStyles();

    if (!props.stats) return null;

    // Sort items by count (=value)
    let itemsByCount = Object.entries(props.stats);
    itemsByCount.sort((a, b) => b[1] - a[1]);

    // Choose top 3 items (and convert from item ID to display value)
    const items = itemsByCount
        .slice(0, 3)
        .map((x) => ({ id: x[0], title: props.mapping[x[0]], value: x[1] }));

    return (
        <div className={classes.multipleFieldsContainer}>
            <div className={classes.topPart}>
                <div className={classes.title}>{props.title}</div>
            </div>
            <div className={classes.innerFieldsContainer}>
                {items.map((field) => {
                    return (
                        <div key={field.id} className={classes.field}>
                            <div className={classes.fieldLabel}>
                                <div>{field.title}</div>
                                <div className={classes.fieldValue}>
                                    {field.value}
                                </div>
                            </div>
                            <img
                                className={classes.fieldIcon}
                                src={capsulesIcon}
                                alt="Capsules"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

/** Component showing capsules by client type */
function CapsulesByClientType(props) {
    const classes = useStyles();

    const capsulesByClientType = {};
    props.clientTypes.map((x) => (capsulesByClientType[x._id] = 0));

    Object.entries(props.stats).map(([clientId, capsuleCount]) => {
        const clientType = props.mapping[clientId];
        capsulesByClientType[clientType] += capsuleCount;
        return null;
    });

    return (
        <MultipleFieldsDisplay
            className={classes.periodSummary}
            title={'Capsules By Client Type:'}
            fields={props.clientTypes.map((x) => ({
                label: x.name,
                value: capsulesByClientType[x._id],
                align: 'center',
            }))}
        />
    );
}

/** The filter dashboard inner window (showing daily/weekly/monthly capsules counts, top 3 clients, top 3 flavours) */
export default function FilterDashboard(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.stats.filter);
    const filterType = datesToSelectionType(filter.startDate, filter.endDate);
    const [dailyCapsules, setDailyCapsules] = useState(null);
    const [weeklyCapsules, setWeeklyCapsules] = useState(null);
    const [monthlyCapsules, setMonthlyCapsules] = useState(null);
    const [capsulesByClient, setCapsulesByClient] = useState({});
    const [capsulesByFlavour, setCapsulesByFlavour] = useState({});
    const [showInfo, setShowInfo] = useState(false);

    const idToClientName = {};
    filter.allClients.map((c) => (idToClientName[c._id] = c.name));
    const idToClientType = {};
    filter.allClients.map((c) => (idToClientType[c._id] = c.client_type));

    const idToFlavourName = {};
    filter.allFlavours.map((c) => (idToFlavourName[c._id] = c.title));

    useFilterChanged(filter, () => {
        setMonthlyCapsules(null);
        setWeeklyCapsules(null);
        setDailyCapsules(null);
        setCapsulesByClient({});
        setCapsulesByFlavour({});

        if (filterType === SELECTION_TYPE_DAY) {
            // Get stats for input day and the day before
            getStatsForPeriod(
                filter,
                filter.startDate,
                filter.endDate,
                filter.startDate.minus({ days: 1 }),
                filter.endDate.minus({ days: 1 })
            ).then((response) => setDailyCapsules(response));

            // Get stats for this week (Monday until input day) and the week before
            const startOfWeek = filter.startDate.startOf('week');
            getStatsForPeriod(
                filter,
                startOfWeek,
                filter.endDate,
                startOfWeek.minus({ days: 7 }),
                startOfWeek.minus({ days: 1 }).endOf('day')
            ).then((response) => setWeeklyCapsules(response));

            // Get stats for this month (1st of the month until input day) and the month before
            const startOfMonth = filter.startDate.startOf('month');
            getStatsForPeriod(
                filter,
                startOfMonth,
                filter.endDate,
                startOfMonth.minus({ months: 1 }).startOf('month'),
                startOfMonth.minus({ months: 1 }).endOf('month')
            ).then((response) => setMonthlyCapsules(response));
        } else if (filterType === SELECTION_TYPE_WEEK) {
            // Get stats for input week and the week before
            getStatsForPeriod(
                filter,
                filter.startDate,
                filter.endDate,
                filter.startDate.minus({ days: 7 }),
                filter.endDate.minus({ days: 7 })
            ).then((response) => setWeeklyCapsules(response));

            // Get stats for this month (1st of the month until input week) and the month before
            const startOfMonth = filter.startDate.startOf('month');
            getStatsForPeriod(
                filter,
                startOfMonth,
                filter.endDate,
                startOfMonth.minus({ months: 1 }).startOf('month'),
                startOfMonth.minus({ months: 1 }).endOf('month')
            ).then((response) => setMonthlyCapsules(response));
        } else if (filterType === SELECTION_TYPE_MONTH) {
            // Get stats for input month and the month before
            let prevPeriodStart, prevPeriodEnd;
            if (filter.startDate.day === 1) {
                // Whole month selection
                prevPeriodStart = filter.startDate
                    .minus({ months: 1 })
                    .startOf('month');
                prevPeriodEnd = filter.startDate
                    .minus({ months: 1 })
                    .endOf('month');
            } else {
                // Instead of just moving X days backward - move to same period on the previous month (same days of the month)
                prevPeriodStart = filter.startDate.minus({ months: 1 });
                prevPeriodEnd = filter.endDate.minus({ months: 1 });
            }

            getStatsForPeriod(
                filter,
                filter.startDate,
                filter.endDate,
                prevPeriodStart,
                prevPeriodEnd
            ).then((response) => setMonthlyCapsules(response));
        }

        // Get top flavours/clients
        getCapsuleStats(
            filter.startDate,
            filter.endDate,
            filter,
            true,
            true
        ).then((response) => {
            setCapsulesByClient(response.data.capsules_by_client);
            setCapsulesByFlavour(response.data.capsules_by_recipe_type);
        });
    });

    if (!monthlyCapsules || filter.allClients.length === 0) {
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={'Dashboard'}
                loading={true}
            ></InnerWindow>
        );
    }

    return (
        <InnerWindow
            className={clsx(
                classes.window,
                showInfo ? classes.infoContainer : null,
                props.className
            )}
            title={'Dashboard'}
        >
            {showInfo ? (
                <div className={clsx(classes.content, classes.infoContent)}>
                    <IconButton
                        className={classes.infoClose}
                        onClick={() => setShowInfo(false)}
                    >
                        <img src={closeIcon} alt="Close" />
                    </IconButton>
                    <div className={classes.infoTitle}>Info</div>
                    <div className={classes.infoText}>
                        In the <b>left box</b>, you can see the capsules
                        consumption for the dates you have chosen in the time
                        filter.
                        <br /> <br />
                        In the <b>right box</b>, you can see the consumption for
                        the previous time period. <br /> <br />
                        <u>For example:</u> <br />
                        If you choose to see the consumption of this week, on
                        the right you will see the consumption of the previous
                        week.
                    </div>
                </div>
            ) : (
                <div className={classes.content}>
                    <div className={classes.label}>Total Capsule Count:</div>

                    <div className={classes.separator} />

                    {filterType === SELECTION_TYPE_DAY && dailyCapsules && (
                        <PeriodCapsuleContainer
                            title={'Daily Capsules:'}
                            type={SELECTION_TYPE_DAY}
                            stats={dailyCapsules}
                            onInfoClick={() => setShowInfo(true)}
                        />
                    )}
                    {(filterType === SELECTION_TYPE_DAY ||
                        filterType === SELECTION_TYPE_WEEK) &&
                        weeklyCapsules && (
                            <PeriodCapsuleContainer
                                title={"This Week's Capsules:"}
                                type={SELECTION_TYPE_WEEK}
                                stats={weeklyCapsules}
                                onInfoClick={() => setShowInfo(true)}
                            />
                        )}
                    {(filterType === SELECTION_TYPE_DAY ||
                        filterType === SELECTION_TYPE_WEEK ||
                        filterType === SELECTION_TYPE_MONTH) &&
                        monthlyCapsules && (
                            <PeriodCapsuleContainer
                                title={"This Month's Capsules:"}
                                type={SELECTION_TYPE_MONTH}
                                stats={monthlyCapsules}
                                onInfoClick={() => setShowInfo(true)}
                            />
                        )}

                    <div className={classes.separator} />

                    <TopItems
                        title={'Top 3 Clients:'}
                        stats={capsulesByClient}
                        mapping={idToClientName}
                    />

                    <TopItems
                        title={'Top 3 Flavours:'}
                        stats={capsulesByFlavour}
                        mapping={idToFlavourName}
                    />

                    <div className={classes.separator} />

                    <CapsulesByClientType
                        clientTypes={filter.clientTypes}
                        stats={capsulesByClient}
                        mapping={idToClientType}
                    />
                </div>
            )}
        </InnerWindow>
    );
}
