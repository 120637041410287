import React, { useCallback, useEffect, useState } from 'react';
import {
    setSideMenuState,
    setToggleBarComponent,
    SIDE_MENU_STATE_FW_UPDATE_STATUS,
} from '../../store/actions/ui';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import Authentication from '../../modules/Authentication';
import { useInterval } from '../../modules/HookUtils';
import downloadingIcon from '../../res/images/fota_downloading.png';
import failedIcon from '../../res/images/fota_failed.png';
import waitingIcon from '../../res/images/fota_waiting.png';
import doneIcon from '../../res/images/fota_done.png';
import safeFWIcon from '../../res/images/fota_safe_fw.png';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        position: 'relative',
        width: '1000px',
        minHeight: '200px',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
    },
    table: {
        marginTop: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1.5fr 1fr 1fr',
        gap: '0px',
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    tableCell: {
        borderBottomWidth: '1px',
        borderBottomColor: 'rgb(209 213 219)',
        paddingBottom: '10px',
        paddingTop: '10px',
        borderBottomStyle: 'solid',
        wordWrap: 'break-word',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tableContent: {
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    fwStatus: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: '24px',
        marginRight: '10px',
    },
    smallIcon: {
        height: '24px',
        marginLeft: '0px',
    },
});

const gApiClient = Authentication.getAPIClient();

const fwStatusToImage = (status) => {
    if (status === 'downloading') {
        return downloadingIcon;
    } else if (status === 'done') {
        return doneIcon;
    } else if (status === 'waiting') {
        return waitingIcon;
    } else if (status === 'failed') {
        return failedIcon;
    }
};

const fwStatusToText = (status) => {
    if (status === 'downloading') {
        return 'Downloading';
    } else if (status === 'done') {
        return 'Done';
    } else if (status === 'waiting') {
        return 'Waiting';
    } else if (status === 'failed') {
        return 'Failed';
    }
};

/**
 * FW Update Status
 */
export default function FWUpdateStatus(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState([]);

    const refreshStats = useCallback(() => {
        gApiClient
            .callApi('admin/getFWUpdateStatus', 'GET', {}, {})
            .then((response) => {
                const items = response.data;

                items.sort((a, b) => {
                    if (a.status !== b.status) {
                        if (a.status === 'failed') return -1;
                        if (b.status === 'failed') return 1;
                        if (a.status === 'downloading') return -1;
                        if (b.status === 'downloading') return 1;
                        if (a.status === 'waiting') return -1;
                        if (b.status === 'waiting') return 1;
                    }
                    // Ignore seconds component
                    const ts1 =
                        Math.floor(a.date_added / 1000 / 60) * 60 * 1000;
                    const ts2 =
                        Math.floor(b.date_added / 1000 / 60) * 60 * 1000;

                    if (ts1 !== ts2) {
                        return b.date_added - a.date_added;
                    }

                    if (a.machine_location !== b.machine_location) {
                        return a.machine_location.localeCompare(
                            b.machine_location
                        );
                    }
                    return a.machine_alias.localeCompare(b.machine_alias);
                });
                setStats(items);
                setLoading(false);
            });
    });

    useEffect(() => {
        dispatch(setToggleBarComponent(null));
        dispatch(setSideMenuState(SIDE_MENU_STATE_FW_UPDATE_STATUS));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load latest FW Update Stats every 20 seconds
    useInterval(() => {
        refreshStats();
    }, 20000);

    useEffect(() => {
        refreshStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'FW Update Status'}
            loading={loading}
        >
            <div className={classes.content}>
                <div className={classes.table}>
                    <div className={classes.tableHeader}>Timestamp</div>
                    <div className={classes.tableHeader}>Location</div>
                    <div className={classes.tableHeader}>Machine</div>
                    <div className={classes.tableHeader}>FW Version</div>
                    <div className={classes.tableHeader}>Update Status</div>
                </div>
                <div className={clsx(classes.table, classes.tableContent)}>
                    {stats.map((x) => (
                        <>
                            <div className={classes.tableCell}>
                                {DateTime.fromMillis(x.date_added).toFormat(
                                    'dd/MM/yyyy HH:mm'
                                )}
                            </div>
                            <div className={classes.tableCell}>
                                {x.machine_location}
                            </div>
                            <div className={classes.tableCell}>
                                {x.machine_alias}
                            </div>
                            <div className={classes.tableCell}>
                                <div>{x.latest_fw_version}</div>
                                {x.safe_fw && (
                                    <img
                                        className={classes.smallIcon}
                                        src={safeFWIcon}
                                    />
                                )}
                            </div>
                            <div
                                className={clsx(
                                    classes.tableCell,
                                    classes.fwStatus
                                )}
                            >
                                <img
                                    className={classes.icon}
                                    src={fwStatusToImage(x.status)}
                                />
                                <span>{fwStatusToText(x.status)}</span>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </InnerWindow>
    );
}
