import clone from 'clone';

export const SET_OVERVIEW_FILTER = 'SET_OVERVIEW_FILTER';
export const SET_OVERVIEW_MAP_SELECTED_MACHINES =
    'SET_OVERVIEW_MAP_SELECTED_MACHINES';
export const SET_OVERVIEW_BOTTOM_SELECTED_MACHINES =
    'SET_OVERVIEW_BOTTOM_SELECTED_MACHINES';

export function setOverviewFilter(value) {
    return {
        type: SET_OVERVIEW_FILTER,
        value: clone(value),
    };
}

export function setMapSelectedMachines(value) {
    return {
        type: SET_OVERVIEW_MAP_SELECTED_MACHINES,
        value: value,
    };
}

export function setBottomSelectedMachines(value) {
    return {
        type: SET_OVERVIEW_BOTTOM_SELECTED_MACHINES,
        value: value,
    };
}
