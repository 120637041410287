import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import eyeOutlineIcon from '../../res/images/eye_outline.svg';
import noEyeOutlineIcon from '../../res/images/no_eye_outline.svg';
import timeIcon from '../../res/images/time.svg';
import calendarIcon from '../../res/images/calendar.svg';
import capsuleIcon from '../../res/images/capsule.svg';
import '../../modules/ChartUtils';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import clone from 'clone';
import {
    REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS,
    REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS,
    REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS,
    REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS,
    REPORT_TYPE_CHANGE_IN_CONSUMPTION,
    SHOW_BY_DAY,
    SHOW_BY_HOUR,
    SHOW_BY_MONTH,
    SHOW_BY_WEEK,
} from './ReportsWindow';
import { DateTime } from 'luxon';
import {
    DAYS_BACK_1_DAY,
    DAYS_BACK_1_MONTH,
    DAYS_BACK_1_YEAR,
    DAYS_BACK_3_MONTHS,
    DAYS_BACK_7_DAYS,
    DAYS_BACK_YTD,
    getStartDateAccordingToDaysBack,
} from '../../store/reducers/reports';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: '#f0f3f9',
    },
    toolTip: {
        position: 'absolute',
        zIndex: 999,
        pointerEvents: 'none',
        backgroundColor: '#7E8CAB',
        padding: '10px',
        color: '#FFFFFF',
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '3px',
        transform: 'translateX(-50%)',
        width: '180px',
        minWidth: '180px',
    },
    toolTipSingleLine: {
        backgroundColor: '#8834FF',
        width: '238px',
        minWidth: '238px',
        textTransform: 'uppercase',
    },
    toolTipIcon: {
        marginLeft: 'auto',
        width: '24px',
    },
    toolTipLeftIcon: {
        filter: hexColorToCSSFilter('#FFFFFF'),
        width: '25px',
        marginLeft: '10px',
    },
    toolTipLabel: {
        marginLeft: 'auto',
    },
    toolTipLabelInner: {
        textAlign: 'center',
    },
    canvasContainer: {
        width: '100%',
        flexGrow: 1,
        paddingLeft: '30px',
    },
    canvas: {},
    legend: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        justifyContent: 'center',
        height: 'auto',
        minHeight: 'auto',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
        backgroundColor: '#F9FAFC',
        border: '1px solid #B5BFCC',
        padding: '8px',
        gap: '10px',
    },
    legendItem: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        minWidth: '220px',
        backgroundColor: '#ffffff',
        color: '#586374',
        position: 'relative',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        height: '61px',
        minHeight: '61px',
        maxHeight: '61px',
    },
    legendItemCount: {
        fontSize: '16px',
        width: '100%',
        height: '50%',
        textAlign: 'center',
        color: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontWeight: 'bold',
            marginRight: '5px',
        },
    },
    legendItemTitle: {
        fontSize: '14px',
        textAlign: 'center',
        width: '100%',
        height: '50%',
        color: '#7D90AA',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    legendItemToggleVisible: {
        filter: hexColorToCSSFilter('#7E8CAB'),
        width: '20px',
        position: 'absolute',
        right: '5px',
    },
});

const GRAPH_COLORS = [
    '#B46403',
    '#E52F14',
    '#FFD108',
    '#0D752A',
    '#F4ABBB',
    '#A0A5A9',
    '#9D1856',
    '#000000',
    '#023A88',
    '#93CCBA',
    '#16A3A7',
    '#F07E09',
    '#82B415',
    '#AF98D1',
    '#72A6DA',
    '#C8397E',
];

/** Formats the tooltip title */
function getToolTipTitle(date, reportType, daysBack, showBy, dataset) {
    let title;

    if (
        [
            REPORT_TYPE_ACTUAL_CONSUMPTION_HOURLY_BASIS,
            REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS,
        ].includes(reportType) ||
        (reportType === REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS &&
            daysBack === DAYS_BACK_1_DAY &&
            showBy === SHOW_BY_HOUR)
    ) {
        title = [
            `${date.toFormat('HH:mm')} - ${date
                .plus({ hours: 1 })
                .toFormat('HH:mm')}`,
        ];
    } else if (reportType === REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS) {
        // Show the day-of-week + date for that specific dataset (since we can show
        // several datasets (= several weeks) for that same day-of-week
        let dateStart = DateTime.fromFormat(
            dataset.label.split('-')[0].trim(),
            'dd.MM.yyyy'
        );
        while (dateStart.weekday !== date.weekday) {
            dateStart = dateStart.plus({ days: 1 });
        }
        title = [
            date.toFormat('EEEE') + '\n' + dateStart.toFormat('dd.MM.yyyy'),
        ];
    } else if (
        [
            REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS,
            REPORT_TYPE_CHANGE_IN_CONSUMPTION,
        ].includes(reportType)
    ) {
        if (showBy === SHOW_BY_HOUR) {
            title = [date.toFormat('dd.MM.yyyy'), date.toFormat('EEE HH:mm')];
        } else if (showBy === SHOW_BY_DAY) {
            title = [date.toFormat('dd.MM.yyyy'), date.toFormat('EEE')];
        } else if (showBy === SHOW_BY_WEEK) {
            title = [date.startOf('week').toFormat("'Week of' dd-MM")];
        } else if (showBy === SHOW_BY_MONTH) {
            title = [date.toFormat('MMM yyyy')];
        }
    } else {
        title = [date.toFormat('HH:mm')];
    }

    return title;
}

/** Represents a single client legend item (shows name, capsule count and color)  */
function ClientLegendItem(props) {
    const classes = useStyles();
    const groupName = props.groupName;
    const data = props.stats.data[groupName];
    const activeIndex = props.activeIndex;
    const totalCapsules = Object.values(data).reduce((a, b) => a + b, 0);

    return (
        <div
            className={classes.legendItem}
            onClick={props.onClick}
            style={{ opacity: props.visible ? 1 : 0.4 }}
        >
            <div
                className={classes.legendItemCount}
                style={{ backgroundColor: props.color }}
            >
                <span>
                    {activeIndex ? data[activeIndex] || 0 : totalCapsules}
                </span>
                / {totalCapsules}
            </div>
            <div className={classes.legendItemTitle}>
                {groupName}
                <img
                    className={classes.legendItemToggleVisible}
                    src={props.visible ? eyeOutlineIcon : noEyeOutlineIcon}
                    alt={'Visible'}
                />
            </div>
        </div>
    );

    /*
                    <img
                    className={classes.legendItemToggleVisible}
                    src={props.visible ? eyeIcon : noEyeIcon}
                    alt={'Capsule Count'}
                />
     */
}

/** Sorts the data sets by date */
function sortedDataSet(data) {
    const getDate = (s) => {
        return DateTime.fromFormat(s.split('-')[0].trim(), 'dd.MM.yyyy');
    };
    return Object.keys(data).sort((a, b) => {
        const d1 = getDate(a),
            d2 = getDate(b);
        return (d1 > d2) - (d1 < d2);
    });
}

/** Shows the legend (shown on top of the graph), with all groups (by day/week) */
function Legend(props) {
    const classes = useStyles();

    return (
        <div className={classes.legend}>
            {sortedDataSet(props.stats.data).map((groupName) => (
                <ClientLegendItem
                    key={groupName}
                    groupName={groupName}
                    activeIndex={props.activeIndex}
                    stats={props.stats}
                    visible={props.itemsVisibility[groupName]}
                    color={props.itemColors[groupName]}
                    onClick={() => {
                        props.onVisibilityToggle(groupName);
                    }}
                />
            ))}
        </div>
    );
}

/** Graph Tooltip */
function ToolTip(props) {
    const classes = useStyles();
    const isSingleLineGraph = [
        REPORT_TYPE_CHANGE_IN_CONSUMPTION,
        REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS,
    ].includes(props.reportType);

    return (
        <div
            className={clsx(
                classes.toolTip,
                isSingleLineGraph ? classes.toolTipSingleLine : null
            )}
            style={{
                top: `${props.position.top}px`,
                left: `${props.position.left}px`,
                backgroundColor:
                    props.reportType ===
                    REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
                        ? props.activeDataset.backgroundColor
                        : null,
            }}
        >
            {isSingleLineGraph && (
                <div className={classes.toolTipCapsuleCount}>
                    {props.value}
                    {props.isPercent ? '%' : ''}
                </div>
            )}
            {isSingleLineGraph && (
                <img
                    className={classes.toolTipLeftIcon}
                    src={capsuleIcon}
                    alt="Capsule Count"
                />
            )}
            <div className={classes.toolTipLabel}>
                {props.title.map((title) => (
                    <div key={title} className={classes.toolTipLabelInner}>
                        {title}
                    </div>
                ))}
            </div>
            {!isSingleLineGraph && (
                <img
                    className={classes.toolTipIcon}
                    src={
                        props.reportType ===
                        REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
                            ? calendarIcon
                            : timeIcon
                    }
                    alt="Time"
                />
            )}
        </div>
    );
}

/** Report graph with legend - used for report types:
 * Actual consumption on an hourly basis
 * Average consumption on an hourly basis
 * Actual consumption on a daily basis
 */
export default function ReportGraph(props) {
    const classes = useStyles();
    const canvas = useRef(null);
    const stats = props.stats;
    const [chart, setChart] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [itemsVisibility, setItemsVisibility] = useState({});
    const [itemColors, setItemColors] = useState({});
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTipTitle, setToolTipTitle] = useState([]);
    const [toolTipValue, setToolTipValue] = useState(null);
    const [toolTipPosition, setToolTipPosition] = useState({});
    const [activeDataset, setActiveDataset] = useState(null);

    const refreshChart = () => {
        if (canvas === null || canvas.current === null) return;
        if (!stats.data) return;

        if (chart !== null) {
            try {
                // See https://github.com/chartjs/Chart.js/issues/5559
                chart.destroy();
            } catch (e) {
                console.error(e);
            }
        }

        const context = canvas.current.getContext('2d');

        const isLineGraph = [
            REPORT_TYPE_AVERAGE_CONSUMPTION_HOURLY_BASIS,
            REPORT_TYPE_CHANGE_IN_CONSUMPTION,
            REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS,
        ].includes(props.reportType);

        const isSingleLineGraph = [
            REPORT_TYPE_CHANGE_IN_CONSUMPTION,
            REPORT_TYPE_ACTUAL_CONSUMPTION_TREND_ANALYSIS,
        ].includes(props.reportType);

        // Convert stats/data into datasets
        const data = {
            labels: stats.columns,
            datasets: sortedDataSet(stats.data)
                .filter((x) => itemsVisibility[x])
                .map((groupName) => {
                    const p = {
                        label: groupName,
                        backgroundColor: isSingleLineGraph
                            ? '#6F76FF'
                            : itemColors[groupName],
                        fill: isLineGraph ? false : true,
                        data: stats.columns.map(
                            (col) => stats.data[groupName][col] || 0
                        ),
                        // Don't show points that are zero
                        pointRadius: stats.columns.map((col) =>
                            stats.data[groupName][col] ? 3 : 0
                        ),
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            labels: {
                                title: {
                                    color: '#7D90AA',
                                    fontSize: '12px',
                                },
                            },
                            display: function(context) {
                                // Decide when to show labels on the line graph

                                if (isSingleLineGraph) {
                                    if (
                                        (props.daysBack === DAYS_BACK_7_DAYS &&
                                            props.showBy === SHOW_BY_HOUR) ||
                                        (props.daysBack ===
                                            DAYS_BACK_3_MONTHS &&
                                            props.showBy === SHOW_BY_DAY) ||
                                        ([
                                            DAYS_BACK_1_YEAR,
                                            DAYS_BACK_YTD,
                                        ].includes(props.daysBack) &&
                                            props.showBy !== SHOW_BY_MONTH)
                                    )
                                        return false;
                                }

                                return (
                                    context.dataset.data[context.dataIndex] !==
                                    0
                                );
                            },
                            formatter: (value, index, values) => {
                                if (value !== 0) {
                                    return (
                                        value.toString() +
                                        (props.isPercent ? '%' : '')
                                    );
                                } else {
                                    return '';
                                }
                            },
                        },
                    };

                    if (isLineGraph) {
                        p.borderColor = isSingleLineGraph
                            ? '#6F76FF'
                            : itemColors[groupName];
                        p.datalabels.labels.title.backgroundColor = isSingleLineGraph
                            ? '#6F76FF'
                            : itemColors[groupName];
                        p.datalabels.labels.title.color = '#FFFFFF';
                        p.datalabels.labels.title.height = '170px';
                    }

                    return p;
                }),
        };

        const chartOptions = {
            type: isLineGraph ? 'line' : 'bar',
            data: data,
            plugins: [ChartDataLabels],
            options: {
                layout: {
                    padding: {
                        top: 80,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                    mode: 'x',
                    intersect: false,
                    callbacks: {
                        title: function(tooltipItems, data) {
                            setActiveDataset(
                                data.datasets[tooltipItems[0].datasetIndex]
                            );
                            return getToolTipTitle(
                                stats.columnsToDateTime[tooltipItems[0].xLabel],
                                props.reportType,
                                props.daysBack,
                                props.showBy,
                                data.datasets[tooltipItems[0].datasetIndex]
                            );
                        },
                    },
                    custom: function(tooltipModel) {
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            setShowToolTip(false);
                            return;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            setToolTipTitle(tooltipModel.title);
                            setToolTipValue(tooltipModel.dataPoints[0].value);
                        }

                        const extraLeftBuffer =
                            props.reportType ===
                            REPORT_TYPE_ACTUAL_CONSUMPTION_DAILY_BASIS
                                ? 30
                                : 0;

                        let left =
                            window.pageXOffset +
                            tooltipModel.caretX +
                            extraLeftBuffer;
                        let buffer = isSingleLineGraph ? 130 : 100;

                        if (left > this._chart.width - buffer) {
                            left = this._chart.width - buffer + extraLeftBuffer;
                        } else if (left < buffer) {
                            left = buffer;
                        }

                        // `this` will be the overall tooltip
                        setToolTipPosition({
                            top:
                                5 +
                                window.pageYOffset +
                                (this._chart.canvas
                                    ? this._chart.canvas.offsetTop
                                    : 0),
                            left: left,
                        });
                        setShowToolTip(true);
                    },
                },
                scales: {
                    xAxes: [
                        {
                            barValueSpacing: 5,
                            barDatasetSpacing: 20,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                onHover: (event) => {
                    // Update parent component with current time being hovered on (will update title)
                    if (event.type === 'mousemove') {
                        const items = newChart.getElementsAtXAxis(event);
                        if (items && items[0]) {
                            const columnIndex = items[0]._index;
                            setActiveIndex(stats.columns[columnIndex]);
                            return;
                        }
                    }

                    // Went outside the graph
                    setActiveIndex(null);
                },
            },
        };

        let maxTicks;
        let hidePoints;
        let tickFormat;
        let disableTickRotation;

        if (isSingleLineGraph) {
            if (props.daysBack === DAYS_BACK_7_DAYS) {
                // 7 days
                maxTicks = 7;
                tickFormat = 'dd.MM EEE';
                disableTickRotation = true;
            } else if (props.daysBack === DAYS_BACK_1_MONTH) {
                if (props.showBy === SHOW_BY_DAY) {
                    // Calculate difference in days (= number of ticks to display)
                    const endDate =
                        stats.columnsToDateTime[
                            stats.columns[stats.columns.length - 1]
                        ];
                    if (endDate) {
                        const date = getStartDateAccordingToDaysBack(
                            endDate,
                            props.daysBack
                        );
                        maxTicks = endDate.diff(date).as('days');
                    } else {
                        maxTicks = 1;
                    }
                    tickFormat = 'dd.MM EEEEE';
                }
            } else if (props.daysBack === DAYS_BACK_3_MONTHS) {
                // 4 months (since a 3 month day back period can be spread over 4 months max)
                maxTicks = 4;
                tickFormat = 'MMM yyyy';
                disableTickRotation = true;

                if (props.showBy === SHOW_BY_DAY) {
                    hidePoints = true;
                }
            } else if (
                [DAYS_BACK_1_YEAR, DAYS_BACK_YTD].includes(props.daysBack)
            ) {
                // 13 months (since a 1 year back period can be spread over 13 months max)
                maxTicks = 13;
                tickFormat = 'MMM yyyy';
                disableTickRotation = true;

                if (props.showBy !== SHOW_BY_MONTH) {
                    hidePoints = true;
                }
            }
        }

        if (hidePoints) {
            // Don't show points on the graph
            chartOptions.options.elements = {
                point: {
                    radius: 0,
                },
            };
        }

        if (maxTicks) {
            // Don't show all ticks
            chartOptions.options.scales.xAxes[0].ticks = {
                autoSkip: true,
                maxTicksLimit: maxTicks,
                callback: (value, index, values) => {
                    const date = stats.columnsToDateTime[value];
                    return date.toFormat(tickFormat).toUpperCase();
                },
            };

            if (disableTickRotation) {
                // Disable tick label rotation
                chartOptions.options.scales.xAxes[0].ticks.maxRotation = 0;
                chartOptions.options.scales.xAxes[0].ticks.minRotation = 0;
            }
        }

        const newChart = new Chart(context, chartOptions);

        setChart(newChart);
    };

    useEffect(() => {
        if (!props.stats) return;

        // Assign colors
        setItemColors(
            Object.keys(props.stats.data)
                .map((groupName, index) => [groupName, GRAPH_COLORS[index]])
                .reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {})
        );

        // All items are visible by default
        setItemsVisibility(
            Object.keys(props.stats.data).reduce(
                (acc, cur) => ({ ...acc, [cur]: true }),
                {}
            )
        );

        return () => {
            if (chart !== null) {
                try {
                    // See https://github.com/chartjs/Chart.js/issues/5559
                    chart.destroy();
                } catch (e) {
                    console.error(e);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stats]);

    useEffect(() => {
        // Items' visibility changed - recreate chart
        refreshChart();

        return () => {
            if (chart !== null) {
                try {
                    // See https://github.com/chartjs/Chart.js/issues/5559
                    chart.destroy();
                } catch (e) {
                    console.error(e);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsVisibility]);

    if (!props.stats) {
        return <div className={clsx(classes.graph, props.className)}></div>;
    }

    return (
        <div className={clsx(classes.container, props.className)}>
            {props.legend && (
                <Legend
                    activeIndex={activeIndex}
                    stats={props.stats}
                    itemsVisibility={itemsVisibility}
                    itemColors={itemColors}
                    onVisibilityToggle={(groupName) => {
                        const newVisibility = clone(itemsVisibility);
                        newVisibility[groupName] = !newVisibility[groupName];
                        setItemsVisibility(newVisibility);
                    }}
                />
            )}
            <div className={classes.canvasContainer}>
                <canvas ref={canvas} className={classes.canvas} />
            </div>

            {showToolTip && (
                <ToolTip
                    title={toolTipTitle}
                    value={toolTipValue}
                    position={toolTipPosition}
                    reportType={props.reportType}
                    isPercent={props.isPercent}
                    activeDataset={activeDataset}
                />
            )}
        </div>
    );
}
