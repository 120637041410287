import React, { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TextField from '../components/TextField';
import Button from '../components/Button';
import Authentication from '../modules/Authentication';
import { APP_BAR_HIDDEN, setAppBarVisibility } from '../store/actions/ui';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import userIcon from '../res/images/user.svg';
import emailIcon from '../res/images/email.svg';
import showPasswordIcon from '../res/images/eye_password.svg';
import hidePasswordIcon from '../res/images/eye_password_filled.svg';
import bigCheckmarkIcon from '../res/images/big_check_mark.svg';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '300vh',
        width: '100%',
        overflowY: 'scroll',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        borderRadius: '32px',
        height: '64px',
        minHeight: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        minHeight: '64px',
        borderRadius: '32px',
        marginTop: '20px',
        '& div': {
            fontSize: '20px',
        },
    },
    error: {
        color: '#ffe118',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 0,
    },
    leftContent: {
        zIndex: 9,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    login: {
        color: '#ffffff',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '10px',
    },
    loginLink: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#ffffff',
            textDecoration: 'none',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    successContainer: {
        width: '714px',
        height: '563px',
        backgroundColor: '#e8e8e8',
        position: 'relative',
        boxShadow: '0 9px 40px 20px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
        color: '#00948d',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    successImage: {
        position: 'absolute',
        width: '470px',
        left: 'calc(50% - 235px)',
        top: 'calc(50% - 192px)',
    },
    successTitle: {
        fontSize: '60px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: '201px',
    },
    successSubTitle: {
        fontSize: '24px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
});

/** The registration screen */
export default function Register() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    const register = () => {
        setLoading(true);
        setError({});

        // Register the user - use his email address as the username.
        Authentication.registerUser(
            email.toLowerCase(),
            email.toLowerCase(),
            password,
            () => {
                // Sign up successful - login the user
                Authentication.loginWithPassword(
                    email.toLowerCase(),
                    password,
                    () => {
                        setShowSuccess(true);
                    },
                    (err) => {
                        // Couldn't login - shouldn't happen
                        setError({ password2: err.message });
                        setLoading(false);
                    },
                    null
                );
            },
            (err) => {
                // Couldn't register user
                setError({ password2: err.message });
                setLoading(false);
            }
        );
    };

    const verifyForm = () => {
        if (name.length === 0) {
            setError({ name: 'Required' });
            return false;
        }
        if (email.length === 0) {
            setError({ email: 'Required' });
            return false;
        }
        if (
            !email.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            setError({ email: 'Invalid email' });
            return false;
        }
        if (password.length === 0) {
            setError({ password: 'Required' });
            return false;
        }
        if (password2.length === 0) {
            setError({ password2: 'Required' });
            return false;
        }
        if (password !== password2) {
            setError({ password2: 'Password mismatch' });
            return false;
        }

        setError({});
        return true;
    };

    const onClick = () => {
        if (!verifyForm()) return;

        register();
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            onClick();
        }
    };

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img src={bgTop} alt={'Background'} />
                <img src={bgBottom} alt={'Background'} />
            </div>

            <div className={classes.leftContent}>
                {showSuccess && (
                    <div className={classes.successContainer}>
                        <img
                            className={classes.successImage}
                            src={bigCheckmarkIcon}
                            alt="Success"
                        />
                        <div className={classes.successTitle}>Success!</div>
                        <div className={classes.successSubTitle}>
                            Thank you for registering, you can log into the NOC
                            using the link sent to your email
                        </div>
                    </div>
                )}

                {!showSuccess && (
                    <div className={classes.form}>
                        <TextField
                            className={classes.input}
                            onChange={setName}
                            onKeyUp={onKeyPress}
                            rightIcon={userIcon}
                            placeholder={'Name'}
                        />
                        <div className={classes.error}>{error.name}&nbsp;</div>

                        <TextField
                            className={classes.input}
                            onChange={setEmail}
                            onKeyUp={onKeyPress}
                            rightIcon={emailIcon}
                            placeholder={'Email'}
                        />
                        <div className={classes.error}>{error.email}&nbsp;</div>

                        <TextField
                            className={classes.input}
                            onChange={setPassword}
                            type={showPassword ? 'text' : 'password'}
                            onKeyUp={onKeyPress}
                            rightIcon={
                                showPassword
                                    ? hidePasswordIcon
                                    : showPasswordIcon
                            }
                            onRightIconClick={() => {
                                setShowPassword(!showPassword);
                            }}
                            placeholder={'Password'}
                        />
                        <div className={classes.error}>
                            {error.password}&nbsp;
                        </div>

                        <TextField
                            className={classes.input}
                            onChange={setPassword2}
                            type={showPassword2 ? 'text' : 'password'}
                            onKeyUp={onKeyPress}
                            rightIcon={
                                showPassword2
                                    ? hidePasswordIcon
                                    : showPasswordIcon
                            }
                            onRightIconClick={() => {
                                setShowPassword2(!showPassword2);
                            }}
                            placeholder={'Retype Password'}
                        />
                        <div className={classes.error}>
                            {error.password2}&nbsp;
                        </div>

                        <Button
                            loading={loading}
                            className={classes.button}
                            label="Register"
                            onClick={onClick}
                        />

                        <div className={classes.login}>
                            Already have an account?
                            <a className={classes.loginLink} href="/login">
                                Sign in
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
