import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    setSideMenuState,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_OVERVIEW,
    TOGGLE_BAR_STATE_KPIS,
} from '../../store/actions/ui';
import OverviewToggleBar from './OverviewToggleBar';
import OverviewFiltersApplied from './OverviewFiltersApplied';
import KPIWindow from './KPIWindow';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
    },
    filtersAppliedSubWindow: {
        width: '414px',
        minWidth: '414px',
        maxWidth: '414px',
        marginRight: '31px',
    },
    kpiSubWindow: {
        flexGrow: 1,
        marginRight: '31px',
    },
});

/** Overview KPIs - showing KPIs of machines (two inner windows - filters applied and KPIs graph) */
export default function OverviewFilter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToggleBarComponent(OverviewToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_KPIS));
        dispatch(setSideMenuState(SIDE_MENU_STATE_OVERVIEW));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <OverviewFiltersApplied
                className={classes.filtersAppliedSubWindow}
                loading={props.loading}
            />
            <KPIWindow
                className={classes.kpiSubWindow}
                loading={props.loading}
            />
        </div>
    );
}
