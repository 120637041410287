import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        height: '42px',
        minHeight: '42px',
        backgroundColor: '#ffffff',
        borderRadius: '21px',
        border: 'solid 2px #2c2925',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    rootSmall: {
        height: '32px',
        minHeight: '32px',
    },
    label: {
        color: '#2c2925',
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    rightIconContainer: {
        position: 'absolute',
        right: '4px',
        height: '34px',
    },
    iconContainerSmall: {
        height: '24px',
    },
    leftIconContainer: {
        position: 'absolute',
        left: '4px',
        height: '34px',
    },
    loadingRoot: {
        color: '#3d8e88',
    },
    disabled: {
        opacity: 0.3,
    },
});

/** Our own type of styled button. */
export default function Button(props) {
    const classes = useStyles();

    return (
        <ButtonBase
            focusRipple
            className={clsx(
                classes.root,
                props.small ? classes.rootSmall : null,
                props.disabled ? classes.disabled : null,
                props.className
            )}
            onClick={props.onClick}
            disabled={props.disabled || props.loading}
        >
            {props.leftIcon && (
                <div
                    className={clsx(
                        classes.leftIconContainer,
                        props.small ? classes.iconContainerSmall : null
                    )}
                >
                    <img
                        src={props.leftIcon}
                        alt={props.label}
                        className={
                            props.small ? classes.iconContainerSmall : null
                        }
                    />
                </div>
            )}
            <div className={clsx(classes.label, props.labelClass)}>
                {props.loading ? (
                    <CircularProgress
                        classes={{ root: classes.loadingRoot }}
                        size={28}
                        thickness={5}
                    />
                ) : (
                    props.label
                )}
            </div>
            {props.rightIcon && (
                <div
                    className={clsx(
                        classes.rightIconContainer,
                        props.small ? classes.iconContainerSmall : null
                    )}
                >
                    <img
                        src={props.rightIcon}
                        alt={props.label}
                        className={
                            props.small ? classes.iconContainerSmall : null
                        }
                    />
                </div>
            )}
        </ButtonBase>
    );
}
