/** Possible KPI types. Each dictionary item here has:
 *      * title - Display title
 *      * key - key/property name of KPI (e.g. "portion_time")
 *      * unit - unit description (e.g. "Seconds")
 *      * getDailyValue(values) - function, that given an array of values for
 *          a single day, will return the representative value for that day (e.g. average, max)
 */

export const KPI_TYPES = [
    {
        title: 'Portion Time',
        key: 'portion_time',
        unit: 'Seconds',
        getDailyValue: (values) =>
            parseInt(
                values.reduce((prev, curr) => prev + curr) / values.length
            ), // Average
    },
    {
        title: 'Cooling Time',
        key: 'cooling_time',
        unit: 'Seconds',
        getDailyValue: (values) =>
            parseInt(
                values.reduce((prev, curr) => prev + curr) / values.length
            ), // Average
    },
    {
        title: 'Initial Cooling Time',
        key: 'initial_cooling_time',
        unit: 'Seconds',
        getDailyValue: (values) => parseInt(Math.max(...values)), // Max value
    },
];
