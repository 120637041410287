import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useState } from 'react';
import { DateTime, Interval, Duration } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../../components/InnerWindow';
import blockerIcon from '../../../res/images/event_blocker.png';
import errorIcon from '../../../res/images/event_error.png';
import recoveryIcon from '../../../res/images/event_recovery.png';
import warningIcon from '../../../res/images/event_warning.png';
import Authentication from '../../../modules/Authentication';
import { useDebounce } from '../../../modules/HookUtils';
import filterIcon from '../../../res/images/filter_off.png';
import filterOnIcon from '../../../res/images/filter_on.png';
import filterSetIcon from '../../../res/images/filter_set.png';
import Checkbox from '../../../components/Checkbox';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import SearchField from '../../../components/SearchField';
import Select from '../../../components/Select';
import Radix64 from '../../../modules/Radix64';

const useStyles = makeStyles({
    window: {
        background: '#f0f3f9',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        padding: '25px',
    },
    separator: {
        marginBottom: '24px',
        marginTop: '24px',
        height: '1px',
        minHeight: '1px',
        opacity: 0.2,
        backgroundColor: '#7d90aa',
    },
    dateHeader: {
        fontSize: 16,
        color: '#87A6D3',
        marginTop: 10,
    },
    eventContainer: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomColor: '#87A6D3',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
    },
    eventTime: {
        width: 50,
        marginRight: 20,
    },
    eventDescription: {},
    eventIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    itemCheckIcon: {
        backgroundColor: '#E1E6ED66',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckedOnIcon: {
        backgroundColor: '#E1E6ED66',
        '&:before': {
            filter: hexColorToCSSFilter('#344269'),
        },
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    flavourCategoryCheckIcon: {
        border: '1px solid #5A6C84',
    },
    filterItem: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        maxHeight: 50,
        alignItems: 'center',
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px #B5BDCD solid',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    searchField: {
        background: '#ffffff',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        height: '40px',
    },
    selector: {
        marginRight: 10,
        marginLeft: 'auto',
    },
    selectorRoot: {
        backgroundColor: '#E4EAF2',
    },
});

const gApiClient = Authentication.getAPIClient();

const TIME_BACK_OPTIONS = [
    { label: 'Today', value: 1 },
    { label: 'Last Week', value: 7 },
    { label: 'Last Month', value: 30 },
];

const CATEGORY_TO_ICON = {
    Recovery: recoveryIcon,
    Blockers: blockerIcon,
    Errors: errorIcon,
    Warnings: warningIcon,
};

const FILTER_CATEGORIES = ['Recovery', 'Blockers', 'Errors', 'Warnings'];

/** Displays machine events */
export default function MachineEvents(props) {
    const classes = useStyles();
    const machine = props.machine;
    const infoWindow = useRef(null);
    const [daysBack, setDaysBack] = useState(TIME_BACK_OPTIONS[0]);
    const [selectedEventCategories, setSelectedEventCategories] = useState([]);
    const [eventToDescription, setEventToDescription] = useState({});
    const [eventToCategory, setEventToCategory] = useState({});
    const [events, setEvents] = useState([]);
    const [groupedEvents, setGroupedEvents] = useState({});
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 300);
    const [loading, setLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        gApiClient
            .callApi('admin/getEventTypes', 'GET', {}, {})
            .then((response) => {
                setEventToDescription(response.data);
            });

        gApiClient
            .callApi('admin/getEventCategories', 'GET', {}, {})
            .then((response) => {
                const mapping = {};
                Object.keys(response.data).forEach((cat) => {
                    response.data[cat].forEach((e) => {
                        mapping[e] = cat;
                    });
                });
                setEventToCategory(mapping);
            });
    }, []);

    useEffect(() => {
        // Filter events according to search text and selected categories
        let categoriesToFilterBy = FILTER_CATEGORIES;

        if (selectedEventCategories.length > 0) {
            categoriesToFilterBy = selectedEventCategories;
        }

        let filteredEvents = events.filter((e) =>
            categoriesToFilterBy.includes(eventToCategory[e.event_type])
        );

        if (debouncedSearchText.length > 0) {
            filteredEvents = filteredEvents.filter(
                (e) =>
                    eventToDescription[parseInt(e.event_type)]
                        .toLowerCase()
                        .indexOf(debouncedSearchText.toLowerCase()) > -1
            );
        }

        // Next, group by date
        const groups = {};
        filteredEvents.forEach((e) => {
            const key = DateTime.fromMillis(e.timestamp).toFormat('dd MMM');
            if (!(key in groups)) {
                groups[key] = [];
            }

            groups[key].push(e);
        });

        setGroupedEvents(groups);
    }, [
        debouncedSearchText,
        eventToCategory,
        eventToDescription,
        events,
        machine,
        selectedEventCategories,
        selectedEventCategories.length,
    ]);

    useEffect(() => {
        if (!machine) return;

        setLoading(true);

        gApiClient
            .callApi(
                `admin/getMachineEvents?machine_id=${machine._id}&days=${daysBack.value}`,
                'GET',
                {},
                {}
            )
            .then((response) => {
                setEvents(
                    response.data.map((x) => ({
                        ...x,
                        timestamp:
                            x.timestamp +
                            new Date().getTimezoneOffset() * 60 * 1000 +
                            machine.timezone_offset,
                        event_type: Radix64.decodeRadix64(x.event_type),
                    }))
                );
                setLoading(false);
            });
    }, [daysBack, machine]);

    function Event({ event }) {
        return (
            <div className={classes.eventContainer}>
                <div className={classes.eventTime}>
                    {DateTime.fromMillis(event.timestamp).toFormat('HH:mm:ss')}
                </div>
                <img
                    className={classes.eventIcon}
                    src={CATEGORY_TO_ICON[eventToCategory[event.event_type]]}
                />
                <div className={classes.eventDescription}>
                    {eventToDescription[event.event_type]}
                </div>
            </div>
        );
    }

    const SelectTimeBack = () => (
        <Select
            className={classes.selector}
            rootClassName={classes.selectorRoot}
            items={TIME_BACK_OPTIONS}
            value={daysBack}
            onChange={(value) => {
                setDaysBack(value);
            }}
        />
    );

    if (!machine) {
        // No machine loaded yet
        return (
            <InnerWindow
                className={clsx(classes.window, props.className)}
                title={'Events'}
                loading={true}
            ></InnerWindow>
        );
    }

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title="Events"
            loading={loading}
            leftIcon={
                showFilter
                    ? filterOnIcon
                    : selectedEventCategories.length > 0
                    ? filterSetIcon
                    : filterIcon
            }
            onLeftIconClick={() => {
                setShowFilter(!showFilter);
            }}
            rightView={<SelectTimeBack />}
        >
            <div className={classes.content} ref={infoWindow}>
                {!showFilter && (
                    <>
                        <div className={classes.searchHeader}>
                            <SearchField
                                className={classes.search}
                                placeholder="Search Events"
                                onSearch={setSearchText}
                                value={searchText}
                                backgroundClass={classes.searchField}
                                inputClass={classes.searchFieldInput}
                                iconClass={classes.searchFieldIcon}
                            />
                        </div>

                        {Object.keys(groupedEvents).map((k) => (
                            <div key={k}>
                                <div className={classes.dateHeader}>{k}</div>
                                {groupedEvents[k].map((e) => (
                                    <Event key={e._id} event={e} />
                                ))}
                            </div>
                        ))}
                    </>
                )}
                {showFilter && (
                    <>
                        {FILTER_CATEGORIES.map((cat) => (
                            <div className={classes.filterItem} key={cat}>
                                <Checkbox
                                    checked={selectedEventCategories.includes(
                                        cat
                                    )}
                                    iconClassName={clsx(
                                        classes.itemCheckIcon,
                                        classes.flavourCategoryCheckIcon
                                    )}
                                    checkedIconClassName={clsx(
                                        classes.itemCheckedOnIcon,
                                        classes.itemCheckIcon,
                                        classes.flavourCategoryCheckIcon
                                    )}
                                    onChange={(v) => {
                                        if (v) {
                                            setSelectedEventCategories([
                                                ...selectedEventCategories,
                                                cat,
                                            ]);
                                        } else {
                                            setSelectedEventCategories(
                                                selectedEventCategories.filter(
                                                    (x) => x != cat
                                                )
                                            );
                                        }
                                    }}
                                />
                                <img
                                    className={classes.eventIcon}
                                    src={CATEGORY_TO_ICON[cat]}
                                />
                                <div>{cat}</div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </InnerWindow>
    );
}
