import React, { useEffect, useRef, useState } from 'react';
import clone from 'clone';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import recipeIcon from '../../../res/images/flavour_border.svg';
import DateTime from 'luxon/src/datetime';
import forwardIcon from '../../../res/images/right_circle.svg';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import DateAndTimeField from '../../../components/DateAndTimeField';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../../res/images/close_circle_black.svg';
import TextField from '../../../components/TextField';
import { downloadFile } from '../../../modules/UrlUtils';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    findItem,
    SORT_TYPE_ALPHABETICAL,
    sortItems,
} from '../../../modules/MachineUtils';
import {
    setAdminDefaultCapsuleConfigs,
    setAdminProductionSites,
    setAdminRecipeTypes,
} from '../../../store/actions/admin';

const useStyles = makeStyles({
    successMessage: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'green',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formRowNarrow: {
        marginTop: '0px',
    },
    formSubRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '200px',
    },
    addButton: {
        width: '120px',
    },
});

const gApiClient = Authentication.getAPIClient();

export default function CreateCapsulesForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const defaultConfigs = filter.defaultCapsuleConfigs;
    const [error, setError] = useState(null);
    const formsWindow = useRef(null);
    const [manufactureDate, setManufactureDate] = useState(DateTime.local());
    const [nonUniqueIds, setNonUniqueIds] = useState(false);
    const [prnFile, setPrnFile] = useState(false);
    const [csvFile, setCsvFile] = useState(false);
    const [forceCoreCode, setForceCoreCode] = useState(false);
    const [forceSgltPrefix, setForceSgltPrefix] = useState(false);
    const [addTimeColumn, setAddTimeColumn] = useState(false);
    const [allowFutureDate, setAllowFutureDate] = useState(false);
    const [capsules, setCapsules] = useState([]);
    const disallowFormSubmission =
        capsules.length === 0 ||
        capsules.some(([_, c]) => c === 0) ||
        capsules.some(([r, _]) => r === null);
    const productionSites = filter.productionSites || [];
    const productionSiteItems = productionSites.map((r) => ({
        value: r._id,
        label: r.name,
    }));
    const [selectedProductionSites, setSelectedProductionSites] = useState([]);
    const recipes = filter.recipeTypes || [];
    const selectedProductionSitesIDs = selectedProductionSites.map(
        (x) => x.value
    );
    const filteredRecipes = recipes.filter((x) =>
        selectedProductionSites.length > 0
            ? selectedProductionSitesIDs.includes(x.production_site)
            : true
    );
    const recipeItems = filteredRecipes.map((r) => ({
        value: r._id,
        label: `${r._id} - ${r.title} - ${r.notes ? r.notes : ''}`,
    }));

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];

        if (filter.recipeTypes.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/recipeTypes',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminRecipeTypes(results.data));
                })()
            );
        }

        if (filter.productionSites.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/productionSites',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminProductionSites(results.data));
                })()
            );
        }

        if (Object.keys(defaultConfigs).length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getDefaultCapsuleCreationConfigs',
                        'GET',
                        {}
                    );
                    dispatch(setAdminDefaultCapsuleConfigs(results.data));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!Object.keys(defaultConfigs).length) return;

        setNonUniqueIds(defaultConfigs.non_unique_ids);
        setPrnFile(defaultConfigs.is_prn);
        setForceCoreCode(defaultConfigs.force_core_code);
        setForceSgltPrefix(defaultConfigs.force_sglt_prefix);
        setCsvFile(defaultConfigs.is_csv);
        setAddTimeColumn(defaultConfigs.add_time_column);
    }, [defaultConfigs]);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        const responses = [];
        const filenames = [];

        capsules.forEach(([recipeType, count]) => {
            const params = {
                recipe_type: recipeType,
                count: count,
                manufacture_date: manufactureDate.toMillis() / 1000,
                non_unique: nonUniqueIds ? '1' : '0',
                minute_offset: new Date().getTimezoneOffset() * -1,
                is_prn: prnFile ? '1' : '0',
                is_csv: csvFile ? '1' : '0',
                force_core_code: forceCoreCode ? '1' : '0',
                force_sglt_prefix: forceSgltPrefix ? '1' : '0',
                add_time_column: addTimeColumn ? '1' : '0',
                source: 'noc',
            };

            responses.push(
                gApiClient.callApi('admin/createCapsules', 'POST', {}, params)
            );
            filenames.push(`QR Codes - ${recipeType}`);
        });

        try {
            const results = await Promise.all(responses);
            const files = [];
            const uuids = results.map((result, index) => result.data.uuid);

            function checkStatus(uuid) {
                return new Promise((resolve) => {
                    const check = () => {
                        gApiClient
                            .callApi(
                                `admin/getLambdaResult/${uuid}`,
                                'GET',
                                {},
                                {}
                            )
                            .then((result) => {
                                if (result.data.status === 'complete') {
                                    resolve({
                                        url: result.data.url,
                                        extension: result.data.extension,
                                    });
                                } else {
                                    setTimeout(check, 1000);
                                }
                            })
                            .catch((error) => {
                                console.error(
                                    `Error fetching status for UUID ${uuid}:`,
                                    error
                                );
                                setTimeout(check, 1000);
                            });
                    };
                    check();
                });
            }

            console.log('UUIDs', uuids);
            const finishedResults = await Promise.all(uuids.map(checkStatus));

            console.log('Results', finishedResults);

            finishedResults.forEach((result, index) => {
                const url = result.url;
                const fileExtension = result.extension;
                console.log(filenames[index], url);
                files.push(
                    downloadFile(`${filenames[index]}.${fileExtension}`, url)
                );
            });
            await Promise.all(files);
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        props.onLoading(false);
    };

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <div className={classes.formLabel}>Manufacture Date/Time</div>
            </div>
            <div className={classes.formRow} style={{ marginTop: 0 }}>
                <DateAndTimeField
                    className={classes.field}
                    label="Date:"
                    type="date"
                    value={manufactureDate}
                    onSetValue={setManufactureDate}
                    autoConfirm={true}
                    noFutureDate={!allowFutureDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
                <DateAndTimeField
                    className={classes.field}
                    label="Time:"
                    type="time"
                    value={manufactureDate}
                    onSetValue={setManufactureDate}
                    leftLabelWidth={100}
                    container={() => {
                        return formsWindow.current;
                    }}
                />
            </div>
            <div className={clsx(classes.formRow, classes.formRowColumn)}>
                <Select
                    className={classes.formFieldFull}
                    hint={'Select Production Site'}
                    multiple
                    autoComplete
                    items={productionSiteItems}
                    renderValue={(selected) => selected.join(', ')}
                    value={selectedProductionSites}
                    onChange={setSelectedProductionSites}
                />
            </div>

            {capsules.map(([recipeType, count], index) => (
                <div
                    className={classes.formRow}
                    key={index}
                    style={{ marginTop: 0 }}
                >
                    <Select
                        className={classes.formFieldFull}
                        items={recipeItems}
                        caseSensitive={true}
                        value={
                            recipeType
                                ? recipeItems.find(
                                      (r) => r.value === recipeType
                                  )
                                : null
                        }
                        onChange={(m) => {
                            const capsulesCopy = clone(capsules);
                            capsulesCopy[index][0] = m.value;
                            setCapsules(capsulesCopy);
                        }}
                        rightIcon={recipeIcon}
                        hint={'Recipe Type'}
                        autoComplete
                    />
                    <TextField
                        className={classes.field}
                        style={{ marginBottom: 0, marginLeft: 5, width: 180 }}
                        value={count}
                        leftLabel={'Count:'}
                        leftLabelWidth={60}
                        fullWidth
                        onChange={(v) => {
                            const capsulesCopy = clone(capsules);
                            capsulesCopy[index][1] = v ? parseInt(v) : 0;
                            setCapsules(capsulesCopy);
                        }}
                    />
                    <IconButton
                        className={classes.closeDialog}
                        onClick={() => {
                            setCapsules(
                                capsules
                                    .slice(0, index)
                                    .concat(capsules.slice(index + 1))
                            );
                        }}
                    >
                        <img src={closeIcon} alt="Close" />
                    </IconButton>
                </div>
            ))}

            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.addButton}
                    label="+ Add"
                    onClick={() => {
                        setCapsules(capsules.concat([[null, 0]]));
                    }}
                />
            </div>

            <div className={classes.formRow}>
                <Checkbox checked={nonUniqueIds} onChange={setNonUniqueIds} />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Capsules have non-unique IDs
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox
                    checked={prnFile}
                    onChange={(v) => {
                        if (v) setCsvFile(false);
                        setPrnFile(v);
                    }}
                />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Create PRN printer file instead of Excel spreadsheet
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox checked={forceCoreCode} onChange={setForceCoreCode} />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Print V8 Recipes with Core Code
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox
                    checked={forceSgltPrefix}
                    onChange={setForceSgltPrefix}
                />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Add SGLT Prefix for V8 Recipes
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox
                    checked={csvFile}
                    onChange={(v) => {
                        if (v) setPrnFile(false);
                        setCsvFile(v);
                    }}
                />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Generate CSV file (instead of XLS)
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox
                    checked={allowFutureDate}
                    onChange={setAllowFutureDate}
                />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Allow Future Manufacture Date
                </div>
            </div>

            <div className={clsx(classes.formRow, classes.formRowNarrow)}>
                <Checkbox checked={addTimeColumn} onChange={setAddTimeColumn} />
                <div className={clsx(classes.formLabel, classes.formFieldFull)}>
                    Add Creation Time Column
                </div>
            </div>

            {error && (
                <div
                    className={classes.formRow}
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <div className={classes.error}>{error}</div>
                </div>
            )}

            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.confirmSubmit}
                    label="Create Capsules"
                    disabled={disallowFormSubmission}
                    rightIcon={forwardIcon}
                    onClick={onSubmitForm}
                />
            </div>
        </div>
    );
}
