import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ExpansionPanel as MUIExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import collapsedIcon from '../res/images/right_arrow_circle.svg';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        height: '42px',
        backgroundColor: '#ffffff',
        borderRadius: '21px',
        border: 'solid 2px #2c2925',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    },
    expansionPanelRoot: {
        backgroundColor: '#dee1eb',
    },
    expansionSummaryRoot: {
        color: '#7d90aa',
        fontSize: '12px',
        height: '40px',
        minHeight: '40px',
        paddingLeft: '8px',
        paddingRight: '8px',
        '&.Mui-expanded': {
            height: '40px',
            minHeight: '40px',
        },
    },
    expansionSummaryExpanded: {
        backgroundColor: '#c2c8d7',
        color: '#ffffff',
    },
    expansionPanelDetails: {
        backgroundColor: '#e6e9f1',
        padding: '6px',
    },
    expandCollapseIcon: {
        padding: '3px !important',
        marginRight: '0px',
        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
            '& img': {
                filter: hexColorToCSSFilter('#ffffff'),
            },
        },
    },
    expandCollapseIconLeft: {
        order: -1,
    },
    title: {
        flexGrow: 1,
    },
});

/** Our own custom expand/collapse icon */
function ExpandCollapseIcon(props) {
    return <img src={props.icon ? props.icon : collapsedIcon} alt="Expand" />;
}

/** Our own type of styled expansion panel. */
export default function ExpansionPanel(props) {
    const classes = useStyles();

    return (
        <MUIExpansionPanel
            square
            elevation={0}
            classes={{ root: classes.expansionPanelRoot }}
            className={props.className}
            expanded={props.expanded}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandCollapseIcon icon={props.leftIcon} />}
                classes={{
                    expanded: clsx(
                        classes.expansionSummaryExpanded,
                        props.expandedSummaryClass
                    ),
                    root: clsx(
                        classes.expansionSummaryRoot,
                        props.expansionSummaryClass
                    ),
                    expandIcon: clsx(
                        classes.expandCollapseIcon,
                        props.leftIcon ? classes.expandCollapseIconLeft : null,
                        props.leftIconClass ? props.leftIconClass : null
                    ),
                }}
            >
                <div className={clsx(classes.title, props.titleClass)}>
                    {props.title}
                </div>
                {props.rightTitle && <div>{props.rightTitle}</div>}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                classes={{
                    root: clsx(
                        classes.expansionPanelDetails,
                        props.expandedDetailsClass
                    ),
                }}
            >
                {props.children}
            </ExpansionPanelDetails>
        </MUIExpansionPanel>
    );
}
