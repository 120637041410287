import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import logoIcon from '../res/images/solato_logo.png';
import bgTop from '../res/images/bg_top.svg';
import bgBottom from '../res/images/bg_bottom.svg';
import { APP_BAR_HIDDEN, setAppBarVisibility } from '../store/actions/ui';
import lockBig from '../res/images/keys_big.svg';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height: '100%',
        backgroundImage: 'linear-gradient(60deg, #027c76 0%, #00a89d 100%)',
        paddingLeft: '96px',
        paddingTop: '20px',
    },
    input: {
        width: '450px',
        marginBottom: '5px',
        marginTop: '25px',
        borderRadius: '32px',
        height: '64px',
        '& input': {
            fontSize: '20px',
            paddingLeft: '15px',
        },
        '& button': {
            marginRight: '10px',
            '& img': {
                height: '20px',
            },
        },
    },
    button: {
        width: '450px',
        height: '64px',
        borderRadius: '32px',
        marginTop: '30px',
        '& div': {
            fontSize: '20px',
        },
    },
    success: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        marginTop: '10px',
    },
    error: {
        color: '#ff0000',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '10px',
        marginTop: '10px',
    },
    logo: {
        width: '600px',
    },
    subtitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '50px',
    },
    background: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundImage: {},
    backToSignIn: {
        color: '#000000',
        fontSize: '20px',
        width: '450px',
        textAlign: 'center',
        marginTop: '20px',
    },
    link: {
        fontWeight: 'bold',
        marginBottom: '10px',
        marginLeft: '5px',
        marginRight: '5px',
        '&, &:link, &:visited, &:hover, &:active': {
            color: '#000000',
            textDecoration: 'none',
        },
    },
    innerContainer: {
        width: '714px',
        height: '450px',
        backgroundColor: '#e8e8e8',
        position: 'relative',
        boxShadow: '0 9px 40px 20px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
        color: '#00948d',
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerTitle: {
        fontSize: '60px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    innerSubTitle: {
        fontSize: '24px',
        marginTop: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    successImage: {
        position: 'absolute',
        right: '10px',
    },
    successContainer: {
        justifyContent: 'center',
    },
});

/** The screen shown when the user doesn't have NOC permissions */
export default function Unauthorized() {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAppBarVisibility(APP_BAR_HIDDEN));
    }, [dispatch]);

    return (
        <div className={classes.content}>
            <img src={logoIcon} className={classes.logo} alt="Logo" />
            <div className={classes.subtitle}>Welcome to Solato NOC System</div>

            <div className={classes.background}>
                <img
                    src={bgTop}
                    className={classes.backgroundImage}
                    alt="Background"
                />
                <img
                    src={bgBottom}
                    className={classes.backgroundImage}
                    alt="Background"
                />
            </div>

            <div className={classes.innerContainer}>
                <img
                    className={classes.successImage}
                    src={lockBig}
                    alt="Unauthorized"
                />

                <div className={classes.innerTitle}>Welcome to NOC</div>
                <div className={classes.innerSubTitle}>
                    Please contact your supervisor to get NOC permissions.
                </div>
            </div>
        </div>
    );
}
