import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import InnerWindow from '../../components/InnerWindow';
import closeIcon from '../../res/images/close_circle.svg';
import clone from 'clone';
import upIcon from '../../res/images/up_circle.svg';
import filterIcon from '../../res/images/filter_circle_outline.svg';
import MachineList from '../../components/MachineList';
import { useFilterChanged } from '../../store/reducers/overview';
import { useDispatch, useSelector } from 'react-redux';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import machineIcon from '../../res/images/machine.svg';
import { IconButton } from '@material-ui/core';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import {
    copyMachineListExpansion,
    findAllMachines,
    findItem,
    findSelectedMachines,
    markItem,
    SORT_TYPE_ALPHABETICAL,
    SORT_TYPE_ALPHABETICAL_REVERSE,
    SORT_TYPE_MACHINES_HIGH_TO_LOW,
    SORT_TYPE_MACHINES_LOW_TO_HIGH,
    sortItems,
} from '../../modules/MachineUtils';
import ToggleButton from '../../components/ToggleButton';
import SearchField from '../../components/SearchField';
import locationIcon from '../../res/images/location_circle.svg';
import clientIcon from '../../res/images/store.svg';
import checkmarkIcon from '../../res/images/check_mark.svg';
import Button from '../../components/Button';
import { STATUS_GROUPS } from './StatusGroups';
import ReactTooltip from 'react-tooltip';
import randomColor from 'randomcolor';
import { setOverviewFilter } from '../../store/actions/overview';

const useStyles = makeStyles({
    window: {
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    filterContent: {
        backgroundColor: '#a1adc3',
        height: 'unset',
    },
    list: {
        width: '100%',
        flexGrow: 1,
    },
    itemLevelIcon: {
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemMachineCount: {
        color: '#7d90aa',
        opacity: 0.8,
        fontSize: '16px',
        fontWeight: 300,
        marginLeft: '5px',
        width: '30px',
    },
    itemMachineIcon: {
        width: '21px',
        height: '21px',
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    openMachineIcon: {
        padding: '8px',
    },
    openMachineIconImage: {
        filter: hexColorToCSSFilter('#7d90aa'),
        width: '31px',
        height: '31px',
    },
    itemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '20px',
    },
    machineItemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
    sortButtonsContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#a1adc3',
    },
    sortButton: {
        marginRight: '10px',
        width: '80px',
        borderColor: '#ffffff',
        backgroundColor: '#a1adc3',
        color: '#ffffff',
        '&.Mui-selected': {
            borderColor: '#ffffff',
            backgroundColor: '#69727d',
            color: '#ffffff',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        width: '19px',
        height: '19px',
        marginRight: '4px',
    },
    searchHeader: {
        background: '#f1f4fa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchBackground: {
        backgroundColor: '#abb3c9',
    },
    searchIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    filterSection: {
        paddingTop: '10px',
        paddingLeft: '35px',
        paddingRight: '35px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterIcon: {
        width: '18px',
        height: '18px',
        marginRight: '10px',
        filter: hexColorToCSSFilter('#ffffff'),
        opacity: 0.6,
    },
    filterIconBigger: {
        width: '32px',
        height: '32px',
        marginRight: '3px',
    },
    filterLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#ffffff',
    },
    filterButtonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingTop: '10px',
        paddingLeft: '35px',
        paddingRight: '35px',
    },
    filterButton: {
        width: '120px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    separator: {
        marginBottom: '24px',
        marginTop: '14px',
        height: '1px',
        minHeight: '1px',
        backgroundColor: '#7d90aa',
    },
    closeButton: {
        width: '140px',
        alignSelf: 'center',
        marginTop: '50px',
        marginBottom: '20px',
    },
    machineStatusIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '7px',
    },
    tooltip: {
        borderRadius: '6px',
        fontSize: '14px',
        color: '#000000',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    machineCheckbox: {
        height: '24px',
        width: '24px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        marginLeft: '20px',
        cursor: 'pointer',
    },
    machineCheckboxIcon: {
        height: '24px',
        width: '24px',
        filter: hexColorToCSSFilter('#8290b0'),
    },
});

const ID_ALL_MACHINES = 'ALL_MACHINES';

function createAllMachines(items) {
    return {
        _id: ID_ALL_MACHINES,
        name: 'All Machines',
        children: [],
        machineCount: items
            .map((c) => c.machineCount)
            .reduce((acc, val) => acc + val, 0),
    };
}

/** Initializes newly-selected machines with random colors */
export function initializeMachines(machines) {
    if (machines.length === 0) return;

    // Generate random colors for those new machines, and mark their visibility as true by default
    const colors = randomColor({
        count: machines.length,
        luminosity: 'dark',
    });
    machines.forEach((machine) => {
        machine.color = colors.pop();
        machine.kpiVisible = true; // Visible by default on the KPI graph
    });
}

/** Machine selection inner window - selecting machines for the overview filter,
 * with filtering / sorting capabilities. */
export default function MachineSelection(props) {
    const classes = useStyles();
    const [showFilter, setShowFilter] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [items, setItems] = useState([]);
    const filter = useSelector((state) => state.overview.filter);
    const [sortType, setSortType] = useState(SORT_TYPE_ALPHABETICAL);
    const [selectedCountries, setSelectedCountries] = useState(
        filter.selectedCountries
    );
    const [selectedClientTypes, setSelectedClientTypes] = useState(
        filter.selectedClientTypes
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (items.length > 1) {
            if (filter.allMachinesSelected) {
                if (
                    selectedCountries.size > 0 ||
                    selectedClientTypes.size > 0
                ) {
                    // Re-select all visible (post-filter) machines
                    let itemsCopy = clone(items);
                    itemsCopy[0].selected = true;
                    itemsCopy
                        .slice(1)
                        .forEach((client) => markItem(client, false));

                    const filterFunc = (m) => {
                        const isMachine = m.previous_clusters;
                        const country = isMachine
                            ? m.parent.parent.parent.country
                            : m.country;
                        if (
                            selectedCountries &&
                            selectedCountries.size > 0 &&
                            country
                        ) {
                            if (!selectedCountries.has(country)) {
                                return false;
                            }
                            return true;
                        }
                    };

                    itemsCopy.forEach((c) => {
                        c.visible = filterFunc(c);
                    });
                    setItems(itemsCopy);

                    const selectedMachines = findAllMachines(
                        itemsCopy.slice(1)
                    ).filter(filterFunc);

                    // Find all newly-selected machines
                    const newMachines = [...selectedMachines];
                    filter.selectedMachines.forEach((machine) => {
                        const index = newMachines.findIndex(
                            (x) => x._id === machine._id
                        );
                        if (index > -1) {
                            newMachines.splice(index, 1);
                        }
                    });

                    initializeMachines(newMachines);

                    // Update filter as well
                    dispatch(
                        setOverviewFilter({
                            clients: itemsCopy.slice(1), // Don't include "All machines"
                            selectedMachines: selectedMachines,
                        })
                    );
                } else {
                    let itemsCopy = clone(items);
                    itemsCopy[0].selected = true;
                    itemsCopy
                        .slice(1)
                        .forEach((client) => markItem(client, false));

                    itemsCopy.forEach((c) => {
                        c.visible = true;
                    });
                    setItems(itemsCopy);

                    const selectedMachines = findAllMachines(
                        itemsCopy.slice(1)
                    );

                    // Find all newly-selected machines
                    const newMachines = [...selectedMachines];
                    filter.selectedMachines.forEach((machine) => {
                        const index = newMachines.findIndex(
                            (x) => x._id === machine._id
                        );
                        if (index > -1) {
                            newMachines.splice(index, 1);
                        }
                    });

                    initializeMachines(newMachines);

                    // Update filter as well
                    dispatch(
                        setOverviewFilter({
                            clients: itemsCopy.slice(1), // Don't include "All machines"
                            selectedMachines: selectedMachines,
                        })
                    );
                }
            }
        }

        dispatch(
            setOverviewFilter({
                selectedCountries: selectedCountries,
                selectedClientTypes: selectedClientTypes,
            })
        );
    }, [selectedCountries, selectedClientTypes, filter.allMachinesSelected]);

    useEffect(() => {
        // Sort type has changed - update items
        if (items && items.length > 0) {
            const itemsCopy = clone(items);
            const allMachines = itemsCopy.shift(); // All machines item is always first
            sortItems(itemsCopy, sortType);
            itemsCopy.unshift(allMachines);

            setItems(itemsCopy);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machineId}/dashboard`, '_blank');
        win.focus();
    };

    const toggleItemSelected = (item, isMachine) => {
        const itemsCopy = clone(items);
        const itemCopy = findItem(itemsCopy, item._id);
        const newValue = !itemCopy.selected;
        let allMachinesSelected = false;

        if (newValue) {
            if (item._id !== ID_ALL_MACHINES) {
                // Another item was checked on - Uncheck all machines option
                itemsCopy[0].selected = false;
                allMachinesSelected = false;
            } else {
                // All machines chosen - unselect all other items
                allMachinesSelected = true;
                itemsCopy.slice(1).forEach((client) => markItem(client, false));
            }
        }

        // Select/unselect this item and all its children
        markItem(itemCopy, newValue);

        setItems(itemsCopy);

        // Calculate selected machines
        let selectedMachines = [];

        if (allMachinesSelected) {
            // All machines were selected
            selectedMachines = findAllMachines(itemsCopy.slice(1)).filter(
                (m) => m.visible
            );
        } else {
            // See which machines were selected
            selectedMachines = findSelectedMachines(itemsCopy.slice(1));
        }

        // Find all newly-selected machines
        const newMachines = [...selectedMachines];
        filter.selectedMachines.forEach((machine) => {
            const index = newMachines.findIndex((x) => x._id === machine._id);
            if (index > -1) {
                newMachines.splice(index, 1);
            }
        });

        initializeMachines(newMachines);

        // Update filter as well
        dispatch(
            setOverviewFilter({
                clients: itemsCopy.slice(1), // Don't include "All machines"
                allMachinesSelected: allMachinesSelected,
                selectedMachines: selectedMachines,
            })
        );
    };

    useFilterChanged(filter, () => {
        // Machine list - either changed from the Filters Applied window, or initially downloaded via API
        const itemsCopy = clone(filter.clients);
        sortItems(itemsCopy, sortType);

        // Maintain expansion of the machine tree view
        copyMachineListExpansion(items, itemsCopy);

        // Add the all machines item
        const allMachinesItem = createAllMachines(itemsCopy);
        allMachinesItem.selected = filter.allMachinesSelected;
        itemsCopy.unshift(allMachinesItem);

        setItems(itemsCopy);
    });

    const renderRightPart = (item) => {
        const isMachine = item.previous_clusters;
        let machineCount = item.machineCount;

        if (item._id === ID_ALL_MACHINES) {
            machineCount = items
                .slice(1)
                .filter((c) => c.visible)
                .map((c) => c.machineCount)
                .reduce((acc, val) => acc + val, 0);
        }

        if (!isMachine) {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.itemStatusIcon}
                            alt="Status"
                        />
                    )}
                    <img
                        src={machineIcon}
                        className={classes.itemMachineIcon}
                        alt="Machine Count"
                    />
                    <div className={classes.itemMachineCount}>
                        {machineCount}
                    </div>

                    <div
                        className={classes.machineCheckbox}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            toggleItemSelected(item, false);
                        }}
                    >
                        {item.selected && (
                            <img
                                className={classes.machineCheckboxIcon}
                                src={checkmarkIcon}
                                alt={'Select'}
                            />
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.machineItemStatusIcon}
                            alt="Status"
                        />
                    )}

                    {[...item.statusGroups].map((s) => {
                        const status = STATUS_GROUPS.find(
                            (status) => status.title === s
                        );
                        return (
                            <img
                                key={s}
                                className={classes.machineStatusIcon}
                                src={status.icon}
                                alt={status.title}
                                data-tip={status.title}
                            />
                        );
                    })}

                    <IconButton
                        onClick={() => {
                            onOpenMachine(item._id);
                        }}
                        className={classes.openMachineIcon}
                    >
                        <img
                            src={openMachineIcon}
                            className={classes.openMachineIconImage}
                            alt="Open Machine"
                        />
                    </IconButton>

                    <div
                        className={classes.machineCheckbox}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            toggleItemSelected(item, true);
                        }}
                    >
                        {item.selected && (
                            <img
                                className={classes.machineCheckboxIcon}
                                src={checkmarkIcon}
                                alt={'Select Machine'}
                                style={
                                    item.color
                                        ? {
                                              filter: hexColorToCSSFilter(
                                                  item.color,
                                                  true
                                              ),
                                          }
                                        : null
                                }
                            />
                        )}
                    </div>
                </div>
            );
        }
    };

    const toggleSelectedCountry = (countryId) => {
        const selectedCountriesCopy = clone(selectedCountries);
        if (selectedCountriesCopy.has(countryId)) {
            selectedCountriesCopy.delete(countryId);
        } else {
            selectedCountriesCopy.add(countryId);
        }

        setSelectedCountries(selectedCountriesCopy);
    };

    const toggleSelectedClientType = (clientTypeId) => {
        const selectedClientTypesCopy = clone(selectedClientTypes);
        if (selectedClientTypesCopy.has(clientTypeId)) {
            selectedClientTypesCopy.delete(clientTypeId);
        } else {
            selectedClientTypesCopy.add(clientTypeId);
        }

        setSelectedClientTypes(selectedClientTypesCopy);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'Machine Selection'}
            loading={props.loading}
            icon={closeIcon}
            onIconClick={() => {
                if (props.loading) return;

                // Reset selection - move to all machines selection
                if (!items[0].selected) {
                    toggleItemSelected(items[0]);
                }
                if (showFilter) {
                    setSelectedClientTypes(new Set());
                    setSelectedCountries(new Set());
                }
            }}
            leftIcon={showFilter ? upIcon : filterIcon}
            onLeftIconClick={() => {
                if (props.loading) return;
                setShowFilter(!showFilter);
            }}
        >
            {showFilter ? (
                <div className={clsx(classes.content, classes.filterContent)}>
                    <div className={classes.filterSection}>
                        <img
                            className={classes.filterIcon}
                            src={locationIcon}
                            alt="Location"
                        />
                        <div className={classes.filterLabel}>Location:</div>
                    </div>
                    <div className={classes.filterButtonContainer}>
                        {filter.countries.map((country) => (
                            <ToggleButton
                                key={country._id}
                                className={clsx(
                                    classes.sortButton,
                                    classes.filterButton
                                )}
                                onChange={() =>
                                    toggleSelectedCountry(country._id)
                                }
                                selected={selectedCountries.has(country._id)}
                            >
                                {country.name}
                            </ToggleButton>
                        ))}
                    </div>
                    <div className={classes.separator} />

                    <div className={classes.filterSection}>
                        <img
                            className={clsx(
                                classes.filterIcon,
                                classes.filterIconBigger
                            )}
                            src={clientIcon}
                            alt="Client Type"
                        />
                        <div className={classes.filterLabel}>Client Type:</div>
                    </div>
                    <div className={classes.filterButtonContainer}>
                        {filter.clientTypes.map((clientType) => (
                            <ToggleButton
                                key={clientType._id}
                                className={clsx(
                                    classes.sortButton,
                                    classes.filterButton
                                )}
                                onChange={() =>
                                    toggleSelectedClientType(clientType._id)
                                }
                                selected={selectedClientTypes.has(
                                    clientType._id
                                )}
                            >
                                {clientType.name}
                            </ToggleButton>
                        ))}
                    </div>

                    <Button
                        className={classes.closeButton}
                        label="Close"
                        onClick={() => {
                            setShowFilter(false);
                        }}
                    />
                </div>
            ) : (
                <div className={classes.content}>
                    <div className={classes.sortButtonsContainer}>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() => setSortType(SORT_TYPE_ALPHABETICAL)}
                            selected={sortType === SORT_TYPE_ALPHABETICAL}
                        >
                            A-Z
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_ALPHABETICAL_REVERSE)
                            }
                            selected={
                                sortType === SORT_TYPE_ALPHABETICAL_REVERSE
                            }
                        >
                            Z-A
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_MACHINES_HIGH_TO_LOW)
                            }
                            selected={
                                sortType === SORT_TYPE_MACHINES_HIGH_TO_LOW
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={machineIcon}
                                alt="Machine Count"
                            />
                            High
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_MACHINES_LOW_TO_HIGH)
                            }
                            selected={
                                sortType === SORT_TYPE_MACHINES_LOW_TO_HIGH
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={machineIcon}
                                alt="Machine Count"
                            />
                            Low
                        </ToggleButton>
                    </div>
                    <div className={classes.searchHeader}>
                        <SearchField
                            className={classes.search}
                            placeholder="Search"
                            onSearch={setSearchText}
                            backgroundClass={classes.searchBackground}
                            iconClass={classes.searchIcon}
                        />
                    </div>
                    <MachineList
                        className={classes.list}
                        items={items}
                        filter={searchText}
                        countries={filter.selectedCountries}
                        clientTypes={filter.selectedClientTypes}
                        renderRightPart={renderRightPart}
                        colors={{
                            labelColor: '#7d90aa',
                            backgroundColors: [
                                '#f1f4f9',
                                '#e6ebf1',
                                '#dee4eb',
                                '#dee4eb',
                                '#cfd7e0',
                            ],
                            itemLevelIconClass: classes.itemLevelIcon,
                            expansionIconClass: classes.itemLevelIcon,
                        }}
                    />
                </div>
            )}

            <ReactTooltip
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                className={classes.tooltip}
            />
        </InnerWindow>
    );
}
