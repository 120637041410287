import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import machineIcon from '../../../res/images/machine.svg';
import DateTime from 'luxon/src/datetime';
import {
    addParent,
    findLatestCluster,
    formatClientTitle,
    getAllClustersByClients,
} from '../../../modules/MachineUtils';
import forwardIcon from '../../../res/images/right_circle.svg';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { reloadMachines } from '../AdminSection';
import { setAdminClustersById } from '../../../store/actions/admin';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../../res/images/close_circle_black.svg';
import commandFailedIcon from '../../../res/images/machine_error.svg';
import commandSentIcon from '../../../res/images/broadcast.svg';
import commandReceivedIcon from '../../../res/images/machine_broadcast.svg';
import commandCompleteIcon from '../../../res/images/machine_success.svg';
import Radix64 from '../../../modules/Radix64';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';

const COMMAND_NOT_SENT = 0;
const COMMAND_SENT = 1;
const COMMAND_RECEIVED = 2;
const COMMAND_DONE = 3;
const COMMAND_FAILED = 4;

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        paddingTop: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        flexGrow: 1,
    },
    contentConfirm: {},
    contentStatus: {
        justifyContent: 'flex-start',
    },
    commandStatusIcon: {
        opacity: 0.5,
        marginTop: '44px',
        width: '80px',
        filter: hexColorToCSSFilter('#000000'),
    },
    commandStatusLabel: {
        textTransform: 'uppercase',
        opacity: 0.5,
        marginTop: '17px',
        fontSize: '18px',
        textAlign: 'center',
        color: '#000000',
    },
    commandEnabled: {
        opacity: 1.0,
    },
    separator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#000000',
        marginTop: '87px',
    },
    separator2: {
        marginTop: '30px',
    },
    currentMachineStatus: {
        textTransform: 'uppercase',
        marginTop: '15px',
        color: '#000000',
        fontSize: '18px',
        textAlign: 'center',
    },
    commandFlow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    commandFail: {
        backgroundImage: 'linear-gradient(149deg, #97174b, #e73942 114%)',
    },
    dialog: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
    },
    dialogMessage: {
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formLabelTitle: {
        fontWeight: 'bold',
        width: '100%',
    },
    formField: {
        width: '50%',
    },
    formFieldUser: {
        width: '80%',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '220px',
    },
});

const COMPRESSOR_SETTINGS = [
    { value: 'quiet', label: 'Quiet mode - 40%' },
    { value: 'standard', label: 'Standard - 50%' },
    { value: 'enhanced', label: 'Enhanced - 60%' },
];

const gApiClient = Authentication.getAPIClient();

const formatMachineLabel = (machine, clusterById) => {
    let clusterLabel;
    const latestCluster = findLatestCluster(machine);

    if (!latestCluster) {
        clusterLabel = 'No cluster (disassembled)';
    } else {
        clusterLabel = formatClientTitle(clusterById[latestCluster]);
    }

    return `${machine.alias} - ${clusterLabel}`;
};

export default function MachineCoolingMode(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const [eventTypes, setEventTypes] = useState({});
    const machinesById = filter.allMachines || {};
    const clustersById = filter.clustersById || {};
    const machineItems =
        Object.keys(clustersById).length > 0
            ? Object.values(machinesById).map((m) => ({
                  value: m._id,
                  label: formatMachineLabel(m, clustersById),
              }))
            : [];
    const [compressorSetting, setCompressorSetting] = useState();
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [commandIdToMonitor, setCommandIdToMonitor] = useState(null);
    const [commandProgress, setCommandProgress] = useState(COMMAND_NOT_SENT);
    const formsWindow = useRef(null);
    const [latestMachineEvent2, setLatestMachineEvent2] = useState();

    useEffect(() => {
        console.log('Command progress', commandProgress);
    }, [commandProgress]);

    useEffect(() => {
        // Get mapping between event type and description
        gApiClient
            .callApi('admin/getEventTypes', 'GET', {}, {})
            .then((response) => {
                setEventTypes(response.data);
            });
    }, []);

    useEffect(() => {
        if (commandIdToMonitor) {
            const interval = setInterval(async () => {
                try {
                    const response = await gApiClient.callApi(
                        `admin/getMachineCommandStatus/${commandIdToMonitor}`,
                        'GET',
                        {}
                    );
                    const command = response.data;
                    console.log(
                        `Command status for ${commandIdToMonitor}:`,
                        command
                    );

                    if (command.timed_out) {
                        // Command timed out
                        console.log('Command status: timed out');
                        setCommandProgress(COMMAND_FAILED);
                        setCommandIdToMonitor(null);
                        return;
                    }

                    if (!command || !command.date_retrieved) {
                        // Waiting for machine to receive command
                        console.log('Command status: not retrieved yet');
                        return;
                    }

                    if (command.latest_event2) {
                        // Show latest machine status
                        console.log(
                            `Command status: latest event ${command.latest_event2}`
                        );
                        setLatestMachineEvent2(command.latest_event2);
                    }

                    if (command.date_complete) {
                        // Command was complete
                        console.log('Command status: complete');
                        setCommandProgress(COMMAND_DONE);
                        setCommandIdToMonitor(null);
                        return;
                    }

                    if (command.date_retrieved) {
                        console.log('Command status: received');
                        setCommandProgress(COMMAND_RECEIVED);
                    }
                } catch (e) {
                    console.error(e);
                    setError(e.message);
                    setCommandIdToMonitor(null);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [commandIdToMonitor]);

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];
        if (!filter.allMachines) {
            promises.push(reloadMachines(dispatch));
        }
        if (!filter.clustersById) {
            promises.push(
                (async () => {
                    const today = DateTime.local();
                    const results = await gApiClient.callApi(
                        `admin/getFilterClusters?no_capsule_count=1&return_all=1&start_date=${today.toFormat(
                            'yyyy-MM-dd'
                        )}&end_date=${today.toFormat('yyyy-MM-dd')}`,
                        'GET',
                        {}
                    );
                    let newClients = results.data;
                    newClients.map((item) => addParent(item));
                    let newClusters = getAllClustersByClients(newClients);
                    dispatch(setAdminClustersById(newClusters));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            const response = await gApiClient.callApi(
                'admin/setMachineCoolingMode',
                'POST',
                {},
                {
                    machine_id: selectedMachine._id,
                    compressor_setting: compressorSetting.value,
                }
            );

            const responseText = response.data;
            console.log('setMachineCoolingMode', responseText);

            setSuccessMessage(`The update command has been sent successfully.`);
            setCommandIdToMonitor(response.data._id);
            setCommandProgress(COMMAND_SENT);
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        // Clear form after successful submission
        setSelectedMachine(null);
        setCompressorSetting(null);

        props.onLoading(false);
    };

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    items={machineItems}
                    value={
                        selectedMachine
                            ? machineItems.find(
                                  (m) => m.value === selectedMachine._id
                              )
                            : null
                    }
                    onChange={(m) => {
                        setSelectedMachine(machinesById[m.value]);
                        setSuccessMessage(null);
                        setCommandIdToMonitor(null);
                    }}
                    rightIcon={machineIcon}
                    hint={'Select Machine'}
                    autoComplete
                />
            </div>
            {selectedMachine && (
                <>
                    <div className={classes.formRow}>
                        <div className={classes.formLabel}>
                            Compressor Setting
                        </div>
                        <Select
                            className={classes.formField}
                            items={COMPRESSOR_SETTINGS}
                            value={compressorSetting}
                            onChange={(v) => {
                                setCompressorSetting(v);
                                setSuccessMessage(null);
                                setCommandIdToMonitor(null);
                            }}
                            rightIcon={machineIcon}
                        />
                    </div>

                    {error && (
                        <div
                            className={classes.formRow}
                            style={{
                                justifyContent: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            <div className={classes.error}>{error}</div>
                        </div>
                    )}

                    {compressorSetting && (
                        <div
                            className={classes.formRow}
                            style={{ justifyContent: 'center' }}
                        >
                            <Button
                                className={classes.confirmSubmit}
                                label="Update Machine"
                                rightIcon={forwardIcon}
                                onClick={onSubmitForm}
                            />
                        </div>
                    )}
                </>
            )}
            {successMessage && (
                <div
                    className={clsx(
                        classes.content,
                        classes.contentConfirm,
                        classes.contentStatus,
                        commandProgress === COMMAND_FAILED
                            ? classes.commandFail
                            : null
                    )}
                >
                    <div
                        className={clsx(classes.separator, classes.separator2)}
                    />

                    {commandProgress === COMMAND_FAILED ? (
                        <div className={classes.commandFlow}>
                            <img
                                className={clsx(
                                    classes.commandStatusIcon,
                                    classes.commandEnabled
                                )}
                                alt="Command failed"
                                src={commandFailedIcon}
                            />
                            <div
                                className={clsx(
                                    classes.commandStatusLabel,
                                    classes.commandEnabled
                                )}
                            >
                                Command Failed - Timed out
                            </div>
                        </div>
                    ) : (
                        <div className={classes.commandFlow}>
                            <img
                                className={clsx(
                                    classes.commandStatusIcon,
                                    commandProgress >= COMMAND_SENT
                                        ? classes.commandEnabled
                                        : null
                                )}
                                alt="Command was sent"
                                src={commandSentIcon}
                            />
                            <div
                                className={clsx(
                                    classes.commandStatusLabel,
                                    commandProgress >= COMMAND_SENT
                                        ? classes.commandEnabled
                                        : null
                                )}
                            >
                                Command was sent
                            </div>

                            <img
                                className={clsx(
                                    classes.commandStatusIcon,
                                    commandProgress >= COMMAND_RECEIVED
                                        ? classes.commandEnabled
                                        : null
                                )}
                                alt="Machine Received Command"
                                src={commandReceivedIcon}
                            />
                            <div
                                className={clsx(
                                    classes.commandStatusLabel,
                                    commandProgress >= COMMAND_RECEIVED
                                        ? classes.commandEnabled
                                        : null
                                )}
                            >
                                Machine Received Command
                            </div>

                            <img
                                className={clsx(
                                    classes.commandStatusIcon,
                                    commandProgress >= COMMAND_DONE
                                        ? classes.commandEnabled
                                        : null
                                )}
                                alt="Command Completed"
                                src={commandCompleteIcon}
                            />
                            <div
                                className={clsx(
                                    classes.commandStatusLabel,
                                    commandProgress >= COMMAND_DONE
                                        ? classes.commandEnabled
                                        : null
                                )}
                            >
                                Command Completed Successfully
                            </div>
                        </div>
                    )}
                    <div className={classes.separator} />

                    <div className={classes.currentMachineStatus}>
                        {latestMachineEvent2 &&
                            eventTypes &&
                            eventTypes[
                                Radix64.decodeRadix64(latestMachineEvent2)
                            ]}
                    </div>
                </div>
            )}
        </div>
    );
}
