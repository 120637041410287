import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import TextField from './TextField';
import editIcon from '../res/images/pencil_circle.svg';
import closeIcon from '../res/images/close_circle.svg';
import Dialog from '@material-ui/core/Dialog';
import { hexColorToCSSFilter } from '../modules/CSSUtils';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import Button from './Button';
import { DateTime, Duration } from 'luxon';

const FIELD_TYPE_DATE = 'date';
const FIELD_TYPE_TIME = 'time';
const FIELD_TYPE_TIME_PERIOD = 'time_period';

const useStyles = makeStyles({
    root: {
        height: '42px',
        minHeight: '42px',
        backgroundColor: '#ffffff',
        borderRadius: '21px',
        border: 'solid 2px #2c2925',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    label: {},
    input: {
        textTransform: 'uppercase',
    },
    dialog: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        margin: '0px !important',
        backgroundColor: '#8090a8',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
    },
    dialogHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    dialogTitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '16px',
        flexGrow: 1,
        textAlign: 'center',
    },
    dialogClose: {},
    dialogCloseIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    dialogPickerContainer: {
        marginTop: '36px',
    },
    picker: {
        backgroundColor: 'red',
        '& .MuiPickersToolbar-toolbar': {
            backgroundColor: 'red',
        },
    },
    dialogConfirm: {
        marginTop: '15px',
        width: '150px',
        alignSelf: 'center',
    },
});

/** Formats the date/time value, according to the type (time/date) */
function formatValue(fieldType, value) {
    if (fieldType === FIELD_TYPE_DATE) {
        return value.toFormat('dd MMM yyyy');
    } else if (fieldType === FIELD_TYPE_TIME) {
        return value.toFormat('HH:mm');
    } else if (fieldType === FIELD_TYPE_TIME_PERIOD) {
        return Duration.fromMillis(value * 1000).toFormat('mm:ss');
    } else {
        return value;
    }
}

/** Returns the dialog title */
function getDialogTitle(fieldType, dialogTitle) {
    if (dialogTitle) {
        // Custom dialog title
        return dialogTitle;
    }

    if (fieldType === FIELD_TYPE_DATE) {
        return 'Choose a Date';
    } else if (fieldType === FIELD_TYPE_TIME) {
        return 'Choose a Time';
    } else if (fieldType === FIELD_TYPE_TIME_PERIOD) {
        return 'Choose a Time';
    } else {
        return null;
    }
}

function secondsToDateTime(seconds) {
    const date = DateTime.local()
        .set({ hour: 13, minute: 0, second: 0, millisecond: 0 })
        .plus({ seconds: seconds });

    return date;
}

function dateTimeToSeconds(datetime) {
    return datetime.minute * 60 + datetime.second;
}

/** An input field that allows selecting a date/time. We assume input date is a Luxon DateTime instance */
export default function DateAndTimeField(props) {
    const classes = useStyles();
    const fieldType = props.type || FIELD_TYPE_DATE;
    const [showDialog, setShowDialog] = useState(false);
    const [value, setValue] = useState(props.value);
    const autoConfirm = props.autoConfirm;

    const onConfirm = () => {
        setShowDialog(false);

        if (props.noFutureDate) {
            // Don't allow future date
            const now = DateTime.local();
            if (value > now) {
                // Future date selected - this can happen when selecting today's date,
                // with a future time of day - just reset time to now
                const newValue = now;
                setValue(newValue);
                props.onSetValue(newValue);
                return;
            }
        }

        props.onSetValue(value);
    };

    const onCancel = () => {
        setShowDialog(false);
        // Restore to last saved
        setValue(props.value);
    };

    useEffect(() => {
        if (autoConfirm) {
            onConfirm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        // Given initial value was changed from outside (by parent) - update our value as well
        setValue(props.value);
    }, [props.value]);

    const inputProps = {};
    if (props.noFutureDate) {
        // Don't allow future dates selection
        inputProps.maxDate = DateTime.local();
    }

    return (
        <div className={props.className}>
            <TextField
                inputClass={classes.input}
                leftLabel={props.label}
                leftLabelWidth={props.labelWidth || '88px'}
                className={clsx(classes.field, props.className)}
                readOnly
                value={formatValue(fieldType, value)}
                rightIcon={editIcon}
                onRightIconClick={() => setShowDialog(true)}
            />
            <Dialog
                onClose={onCancel}
                open={showDialog}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                container={props.container}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>
                        {getDialogTitle(fieldType, props.dialogTitle)}
                    </div>

                    <IconButton
                        className={classes.dialogClose}
                        onClick={onCancel}
                    >
                        <img
                            className={classes.dialogCloseIcon}
                            src={closeIcon}
                            alt="Close"
                        />
                    </IconButton>
                </div>
                <div className={classes.dialogPickerContainer}>
                    {fieldType === FIELD_TYPE_DATE && (
                        <DatePicker
                            orientation="landscape"
                            variant="static"
                            openTo="date"
                            value={value.toJSDate()}
                            onChange={(v) => {
                                if (v.getTime) {
                                    setValue(DateTime.fromMillis(v.getTime()));
                                } else {
                                    setValue(v);
                                }
                            }}
                            className={classes.picker}
                            {...inputProps}
                        />
                    )}
                    {fieldType === FIELD_TYPE_TIME && (
                        <TimePicker
                            ampm={false}
                            orientation="landscape"
                            variant="static"
                            openTo="hours"
                            value={value.toJSDate()}
                            onChange={(v) => {
                                if (v.getTime) {
                                    setValue(DateTime.fromMillis(v.getTime()));
                                } else {
                                    setValue(v);
                                }
                            }}
                            className={classes.picker}
                        />
                    )}
                    {fieldType === FIELD_TYPE_TIME_PERIOD && (
                        <TimePicker
                            ampm={false}
                            orientation="landscape"
                            views={['minutes', 'seconds']}
                            variant="static"
                            format="mm:ss"
                            openTo="minutes"
                            value={secondsToDateTime(value)}
                            onChange={(v) => {
                                setValue(dateTimeToSeconds(v));
                            }}
                            className={classes.picker}
                        />
                    )}
                </div>
                {!autoConfirm && (
                    <Button
                        className={classes.dialogConfirm}
                        label="OK"
                        onClick={onConfirm}
                    />
                )}
            </Dialog>
        </div>
    );
}
