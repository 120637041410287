import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import clsx from 'clsx';
import checkmarkIcon from '../res/images/check_mark_white.svg';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '10px',
        width: '32px',
        height: '32px',
        backgroundColor: '#82d9d5',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#00bdb0',
        },
        'input:disabled ~ &': {
            opacity: 0.3,
        },
    },
    checkedIcon: {
        backgroundColor: '#00bdb0',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: '32px',
            height: '32px',
            backgroundImage: `url(${checkmarkIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#007d72',
        },
    },
});

/** Our own type of styled checkbox. */
export default function Checkbox(props) {
    const classes = useStyles();

    return (
        <MUICheckbox
            className={clsx(classes.root, props.className)}
            disableRipple
            color="default"
            checkedIcon={
                <span
                    className={clsx(
                        classes.icon,
                        classes.checkedIcon,
                        props.checkedIconClassName
                    )}
                />
            }
            icon={<span className={clsx(classes.icon, props.iconClassName)} />}
            disabled={props.disabled}
            checked={props.checked}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();

                if (props.onChange) {
                    props.onChange(!props.checked);
                }
            }}
        />
    );
}
