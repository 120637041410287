import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import machineIcon from '../../../res/images/machine.svg';
import TextField from '../../../components/TextField';
import clientIcon from '../../../res/images/client_border.svg';
import DateTime from 'luxon/src/datetime';
import {
    addParent,
    formatClientTitle,
    getAllClustersByClients,
} from '../../../modules/MachineUtils';
import forwardIcon from '../../../res/images/right_circle.svg';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import DateAndTimeField from '../../../components/DateAndTimeField';
import { useDispatch, useSelector } from 'react-redux';
import { reloadMachines } from '../AdminSection';
import {
    setAdminClustersById,
    setAdminUsersById,
} from '../../../store/actions/admin';

const useStyles = makeStyles({
    dialog: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
    },
    dialogMessage: {
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formLabelTitle: {
        fontWeight: 'bold',
        width: '100%',
    },
    formField: {
        width: '50%',
    },
    formFieldUser: {
        width: '80%',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '170px',
    },
});

const ADD_MACHINE = 'add_machine';
const STAGE_PROD = 'prod';
const STAGE_RC = 'rc';
const STAGE_DEV = 'dev';
const ALL_STAGES = [
    { value: STAGE_PROD, label: 'Prod' },
    { value: STAGE_RC, label: 'RC' },
    { value: STAGE_DEV, label: 'Dev' },
];

const gApiClient = Authentication.getAPIClient();

function getMachineM2M(machine) {
    if (!machine.previous_is_m2m) return machine.is_m2m || false;

    // Find latest is_m2m setting
    machine.previous_is_m2m.sort((a, b) => {
        return b.add_date - a.add_date;
    });
    return machine.previous_is_m2m[0].is_m2m;
}

function getMachineStage(machine) {
    if (!machine.previous_stages) return machine.stage;

    // Find latest stage setting
    machine.previous_stages.sort((a, b) => {
        return b.add_date - a.add_date;
    });
    return machine.previous_stages[0].stage;
}

function getLatestCluster(machine) {
    machine.previous_clusters.sort((a, b) => {
        return b.add_date - a.add_date;
    });
    return machine.previous_clusters[0];
}

function getLatestClusterId(machine) {
    return getLatestCluster(machine).cluster;
}

function getUserLabel(user) {
    return user.first_name && user.last_name
        ? `${user.first_name} ${user.last_name} / ${user.email}`
        : user.email;
}

export default function MachineForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const machinesById = filter.allMachines || {};
    const clustersById = filter.clustersById || {};
    const users = filter.usersById || {};
    const machineItems = Object.values(machinesById).map((m) => ({
        value: m._id,
        label: m.alias,
    }));
    const clusterItems = Object.values(clustersById).map((c) => ({
        value: c._id,
        label: formatClientTitle(c),
    }));
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [newCluster, setNewCluster] = useState(null);
    const [moveToNewCluster, setMoveToNewCluster] = useState(false);
    const [removeFromCluster, setRemoveFromCluster] = useState(false);
    const addNewMachine =
        selectedMachine && selectedMachine._id === ADD_MACHINE;
    machineItems.unshift({ value: ADD_MACHINE, label: 'Add New Machine' });
    const previousCluster =
        !addNewMachine && selectedMachine
            ? clustersById[getLatestClusterId(selectedMachine)]
            : null;
    const [removedDate, setRemovedDate] = useState(DateTime.local());
    const [addDate, setAddDate] = useState(DateTime.local());
    const [removalReason, setRemovalReason] = useState('');
    const [installationNotes, setInstallationNotes] = useState('');
    const [removedBy, setRemovedBy] = useState(
        Authentication.getUserDetails()._id
    );
    const [addedBy, setAddedBy] = useState(Authentication.getUserDetails()._id);
    const isMachineRemoved =
        !addNewMachine &&
        selectedMachine &&
        getLatestCluster(selectedMachine).removed_date;
    const missingNewCluster = !newCluster || !installationNotes;
    const missingPrevCluster = !removalReason;
    const disallowFormSubmission =
        (addNewMachine && (!selectedMachine.alias || missingNewCluster)) ||
        (!addNewMachine &&
            removeFromCluster &&
            (missingPrevCluster || (moveToNewCluster && missingNewCluster)));
    const formsWindow = useRef(null);

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];
        if (!filter.allMachines) {
            promises.push(reloadMachines(dispatch));
        }
        if (!filter.clustersById) {
            promises.push(
                (async () => {
                    const today = DateTime.local();
                    const results = await gApiClient.callApi(
                        `admin/getFilterClusters?no_capsule_count=1&return_all=1&start_date=${today.toFormat(
                            'yyyy-MM-dd'
                        )}&end_date=${today.toFormat('yyyy-MM-dd')}`,
                        'GET',
                        {}
                    );
                    let newClients = results.data;
                    newClients.map((item) => addParent(item));
                    let newClusters = getAllClustersByClients(newClients);
                    dispatch(setAdminClustersById(newClusters));
                })()
            );
        }

        if (!filter.usersById) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getAllUsers',
                        'GET',
                        {},
                        {}
                    );
                    dispatch(
                        setAdminUsersById(
                            Object.fromEntries(
                                results.data.map((m) => [m._id, m])
                            )
                        )
                    );
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            if (addNewMachine) {
                const response = await gApiClient.callApi(
                    'admin/addMachine',
                    'POST',
                    {},
                    {
                        alias: selectedMachine.alias,
                        description: selectedMachine.description,
                        stage: selectedMachine.stage,
                        is_m2m: selectedMachine.is_m2m,
                        cluster_id: newCluster._id,
                        // Also account for local timezone
                        installation_time: addDate
                            .minus({ minutes: new Date().getTimezoneOffset() })
                            .toMillis(),
                        installation_notes: installationNotes,
                        installed_by: addedBy,
                    }
                );

                const newMachine = response.data;
                console.log('addNewMachine', newMachine);

                setSuccessMessage(
                    `Added new machine with ID ${newMachine._id}`
                );
            } else {
                const params = {
                    machine_id: selectedMachine._id,
                    description: selectedMachine.description || '',
                    stage: getMachineStage(selectedMachine),
                    is_m2m: getMachineM2M(selectedMachine),
                };

                if (removeFromCluster) {
                    // Also account for local timezone
                    params.removal_time = removedDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis();
                    params.removal_reason = removalReason;
                    params.removed_by = removedBy;
                }

                if (moveToNewCluster) {
                    params.cluster_id = newCluster._id;
                    // Also account for local timezone
                    params.installation_time = addDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis();
                    params.installation_notes = installationNotes;
                    params.installed_by = addedBy;
                }

                params.minute_offset = new Date().getTimezoneOffset() * -1;

                const response = await gApiClient.callApi(
                    'admin/editMachine',
                    'POST',
                    {},
                    params
                );

                const machineDetails = response.data;
                console.log('editMachine', machineDetails);

                setSuccessMessage(
                    `Successfully updated an existing machine with ID ${machineDetails._id}`
                );
            }
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        // Clear form after successful submission
        setSelectedMachine(null);
        setRemoveFromCluster(false);
        setMoveToNewCluster(false);
        setNewCluster(null);
        setRemovedDate(DateTime.local());
        setAddDate(DateTime.local());
        setRemovalReason('');
        setInstallationNotes('');
        setRemovedBy(Authentication.getUserDetails()._id);
        setAddedBy(Authentication.getUserDetails()._id);

        // Reload all machines
        await reloadMachines(dispatch);

        props.onLoading(false);
    };

    if (successMessage) {
        return (
            <div ref={formsWindow}>
                <Dialog
                    onClose={() => setSuccessMessage(null)}
                    open={true}
                    style={{ position: 'absolute' }}
                    BackdropProps={{ style: { position: 'absolute' } }}
                    container={() => {
                        return formsWindow.current;
                    }}
                    classes={{ paper: classes.dialog }}
                >
                    <div className={classes.dialogMessage}>
                        {successMessage}
                    </div>
                    <Button
                        className={classes.confirmSubmit}
                        label="Close"
                        onClick={() => setSuccessMessage(null)}
                    />
                </Dialog>
            </div>
        );
    }

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    items={machineItems}
                    value={
                        selectedMachine
                            ? machineItems.find(
                                  (m) => m.value === selectedMachine._id
                              )
                            : null
                    }
                    onChange={(m) => {
                        setRemoveFromCluster(false);
                        setMoveToNewCluster(false);
                        setSelectedMachine(
                            m.value === ADD_MACHINE
                                ? {
                                      _id: ADD_MACHINE,
                                      alias: '',
                                      description: '',
                                      stage: STAGE_PROD,
                                      is_m2m: true,
                                  }
                                : machinesById[m.value]
                        );
                    }}
                    rightIcon={machineIcon}
                    hint={'Machine'}
                    autoComplete
                />
            </div>
            {selectedMachine && (
                <>
                    {addNewMachine && (
                        <div className={classes.formRow}>
                            <TextField
                                className={classes.field}
                                value={selectedMachine.alias}
                                leftLabel={'Alias*:'}
                                leftLabelWidth={100}
                                fullWidth
                                onChange={(v) => {
                                    setSelectedMachine({
                                        ...selectedMachine,
                                        alias: v,
                                    });
                                }}
                            />
                        </div>
                    )}

                    <div className={classes.formRow}>
                        <TextField
                            className={classes.field}
                            value={selectedMachine.description || ''}
                            leftLabel={'Description:'}
                            leftLabelWidth={100}
                            fullWidth
                            onChange={(v) => {
                                setSelectedMachine({
                                    ...selectedMachine,
                                    description: v,
                                });
                            }}
                        />
                    </div>

                    <div className={classes.formRow}>
                        <div className={classes.formLabel}>Stage</div>
                        <Select
                            className={classes.formField}
                            items={ALL_STAGES}
                            value={ALL_STAGES.find(
                                (x) =>
                                    x.value === getMachineStage(selectedMachine)
                            )}
                            onChange={(v) => {
                                setSelectedMachine({
                                    ...selectedMachine,
                                    previous_stages: [
                                        {
                                            add_date: DateTime.local(),
                                            stage: v.value,
                                        },
                                    ],
                                    stage: v.value,
                                });
                            }}
                            rightIcon={machineIcon}
                        />
                    </div>

                    <div className={classes.formRow}>
                        <div className={classes.formLabel}>Is M2M?</div>
                        <Checkbox
                            checked={getMachineM2M(selectedMachine)}
                            onChange={(v) => {
                                setSelectedMachine({
                                    ...selectedMachine,
                                    previous_is_m2m: [
                                        {
                                            add_date: DateTime.local(),
                                            is_m2m: v,
                                        },
                                    ],
                                    is_m2m: v,
                                });
                            }}
                        />
                    </div>

                    {removeFromCluster && (
                        <>
                            <div className={classes.formRow}>
                                <div
                                    className={clsx(
                                        classes.formLabel,
                                        classes.formLabelTitle
                                    )}
                                >
                                    Previous Cluster (
                                    {formatClientTitle(previousCluster)})
                                </div>
                            </div>
                            <div className={classes.formRow}>
                                <div className={classes.formLabel}>
                                    Removal Date/Time
                                </div>
                            </div>
                            <div
                                className={classes.formRow}
                                style={{ marginTop: 0 }}
                            >
                                <DateAndTimeField
                                    className={classes.field}
                                    label="Date:"
                                    type="date"
                                    value={removedDate}
                                    onSetValue={setRemovedDate}
                                    leftLabelWidth={100}
                                    noFutureDate={true}
                                    container={() => {
                                        return formsWindow.current;
                                    }}
                                />
                                <DateAndTimeField
                                    className={classes.field}
                                    label="Time:"
                                    type="time"
                                    value={removedDate}
                                    onSetValue={setRemovedDate}
                                    leftLabelWidth={100}
                                    noFutureDate={true}
                                    container={() => {
                                        return formsWindow.current;
                                    }}
                                />
                            </div>

                            <div
                                className={classes.formRow}
                                style={{ marginTop: 10 }}
                            >
                                <div className={classes.formLabel}>
                                    Removed By
                                </div>
                                <Select
                                    className={classes.formFieldUser}
                                    items={Object.values(users).map((u) => ({
                                        value: u._id,
                                        label: getUserLabel(u),
                                    }))}
                                    hint={'User'}
                                    autoComplete
                                    value={{
                                        value: removedBy,
                                        label: getUserLabel(users[removedBy]),
                                    }}
                                    onChange={(v) => {
                                        setRemovedBy(v.value);
                                    }}
                                />
                            </div>

                            <div className={classes.formRow}>
                                <TextField
                                    className={classes.field}
                                    value={removalReason}
                                    leftLabel={'Removal Reason*:'}
                                    leftLabelWidth={150}
                                    fullWidth
                                    onChange={(v) => {
                                        setRemovalReason(v);
                                    }}
                                />
                            </div>
                        </>
                    )}

                    {(removeFromCluster ||
                        addNewMachine ||
                        isMachineRemoved) && (
                        <>
                            <>
                                <div className={classes.formRow}>
                                    <div className={classes.formLabel}>
                                        {addNewMachine
                                            ? 'New Cluster'
                                            : 'Move to new cluster?'}
                                    </div>
                                    {!addNewMachine && (
                                        <Checkbox
                                            checked={moveToNewCluster}
                                            onChange={(v) => {
                                                setMoveToNewCluster(v);
                                            }}
                                        />
                                    )}
                                </div>
                                {(addNewMachine || moveToNewCluster) && (
                                    <>
                                        <div className={classes.formRow}>
                                            <Select
                                                className={
                                                    classes.formFieldFull
                                                }
                                                items={clusterItems}
                                                value={
                                                    newCluster
                                                        ? clusterItems.find(
                                                              (c) =>
                                                                  c.value ===
                                                                  newCluster._id
                                                          )
                                                        : null
                                                }
                                                onChange={(c) => {
                                                    setNewCluster(
                                                        clustersById[c.value]
                                                    );
                                                    setInstallationNotes(
                                                        formatClientTitle(
                                                            clustersById[
                                                                c.value
                                                            ]
                                                        )
                                                    );
                                                }}
                                                rightIcon={clientIcon}
                                                hint={'Cluster'}
                                                autoComplete
                                            />
                                        </div>
                                        <div className={classes.formRow}>
                                            <div className={classes.formLabel}>
                                                Installation Date/Time
                                            </div>
                                        </div>
                                        <div
                                            className={classes.formRow}
                                            style={{ marginTop: 0 }}
                                        >
                                            <DateAndTimeField
                                                className={classes.field}
                                                label="Date:"
                                                type="date"
                                                value={addDate}
                                                onSetValue={setAddDate}
                                                leftLabelWidth={100}
                                                noFutureDate={true}
                                                container={() => {
                                                    return formsWindow.current;
                                                }}
                                            />
                                            <DateAndTimeField
                                                className={classes.field}
                                                label="Time:"
                                                type="time"
                                                value={addDate}
                                                onSetValue={setAddDate}
                                                leftLabelWidth={100}
                                                noFutureDate={true}
                                                container={() => {
                                                    return formsWindow.current;
                                                }}
                                            />
                                        </div>

                                        <div
                                            className={classes.formRow}
                                            style={{ marginTop: 10 }}
                                        >
                                            <div className={classes.formLabel}>
                                                Installed By
                                            </div>
                                            <Select
                                                className={
                                                    classes.formFieldUser
                                                }
                                                items={Object.values(users).map(
                                                    (u) => ({
                                                        value: u._id,
                                                        label: getUserLabel(u),
                                                    })
                                                )}
                                                hint={'User'}
                                                autoComplete
                                                value={{
                                                    value: addedBy,
                                                    label: getUserLabel(
                                                        users[addedBy]
                                                    ),
                                                }}
                                                onChange={(v) => {
                                                    setAddedBy(v.value);
                                                }}
                                            />
                                        </div>

                                        <div className={classes.formRow}>
                                            <TextField
                                                className={classes.field}
                                                value={installationNotes}
                                                leftLabel={'Notes*:'}
                                                leftLabelWidth={150}
                                                fullWidth
                                                onChange={(v) => {
                                                    setInstallationNotes(v);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        </>
                    )}

                    {error && (
                        <div
                            className={classes.formRow}
                            style={{
                                justifyContent: 'center',
                            }}
                        >
                            <div className={classes.error}>{error}</div>
                        </div>
                    )}

                    <div
                        className={classes.formRow}
                        style={{ justifyContent: 'center' }}
                    >
                        <Button
                            className={classes.confirmSubmit}
                            label="Submit"
                            disabled={disallowFormSubmission}
                            rightIcon={forwardIcon}
                            onClick={onSubmitForm}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
