import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { APP_BAR_HIDDEN, APP_BAR_VISIBLE } from '../store/actions/ui';
import LogoWithProfile from './LogoWithProfile';

const useStyles = makeStyles({
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        width: 'calc(100% - 74px)',
    },
    toggleBar: {
        marginRight: '35px',
    },
    logo: {
        flexGrow: 1,
    },
});

/** Top bar visible throughout all screens (contains the toggle bar and logo) */
export default function TopBar() {
    const classes = useStyles();
    const ToggleBarComponent = useSelector(
        (state) => state.ui.toggleBarComponent
    );
    const appBarVisible = useSelector((state) => state.ui.appBarVisible);

    if (appBarVisible === APP_BAR_HIDDEN) return null;

    return (
        <AppBar position="relative" className={classes.appBar}>
            <Toolbar>
                {APP_BAR_VISIBLE && (
                    <div className={classes.toggleBar}>
                        {ToggleBarComponent && <ToggleBarComponent />}
                    </div>
                )}

                <LogoWithProfile className={classes.logo} />
            </Toolbar>
        </AppBar>
    );
}
