import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
export default createMuiTheme({
    palette: {
        primary: {
            main: '#00a89d',
        },
        background: {
            paper: '#fff',
            default: '#ffffff',
        },
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#f1f3f6',
                maxWidth: 'unset !important',
                flexGrow: 1,
                justifyContent: 'center !important',
            },
        },
        MuiPickersTimePickerToolbar: {
            hourMinuteLabel: {
                fontSize: '48px !important',
                '& h2, & h3': {
                    fontSize: '48px !important',
                },
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: '#8090a8',
            },
        },
        MuiPickersClockNumber: {
            clockNumberSelected: {
                backgroundColor: '#8090a8',
                color: '#ffffff',
            },
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: '#8090a8',
            },
            thumb: {
                border: '14px solid #8090a8',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#7d90aa',
            },
            daySelected: {
                backgroundColor: '#cdd3db',
                '&:hover': {
                    backgroundColor: '#cdd3db',
                },
            },
        },
    },
});
