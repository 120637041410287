import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    setSideMenuState,
    setToggleBarComponent,
    SIDE_MENU_STATE_RECIPE_TYPES,
} from '../../store/actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InnerWindow from '../../components/InnerWindow';
import Authentication from '../../modules/Authentication';
import arrowDown from '../../res/images/arrow_drop_down.svg';
import arrowUp from '../../res/images/arrow_drop_up.svg';
import editIcon from '../../res/images/pencil_circle.svg';
import {
    setAdminProductionSites,
    setAdminRecipeCategories,
    setAdminRecipeMappings,
    setAdminRecipeTypes,
} from '../../store/actions/admin';
import { SORT_TYPE_ALPHABETICAL, sortItems } from '../../modules/MachineUtils';
import Select from '../../components/Select';
import SearchField from '../../components/SearchField';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import Button from '../../components/Button';
import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@material-ui/core';
import closeIcon from '../../res/images/close_circle.svg';
import TextField from '../../components/TextField';

const useStyles = makeStyles({
    successTitle: {
        fontSize: '25px',
        fontWeight: 'bold',
    },
    error: {
        color: 'red',
        fontSize: '12px',
    },
    rightError: {
        marginLeft: 'auto',
    },
    save: {
        width: '100px',
        minWidth: '100px',
        marginLeft: '10px',
    },
    formTextField: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #5A6C84',
        width: '300px',
    },
    dialogFieldName: {
        width: '150px',
        minWidth: '150px',
    },
    dialog: {
        margin: '0px !important',
        backgroundColor: '#8090a8',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0px',
        paddingBottom: '0px',
        maxWidth: 'unset',
    },
    dialogHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    dialogTitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        flexGrow: 1,
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    dialogClose: {},
    dialogCloseIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    dialogConfirm: {
        marginTop: '15px',
        width: '150px',
        alignSelf: 'center',
        marginBottom: '10px',
    },
    dialogContent: {
        background: '#FFFFFF',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px',
    },
    window: {
        background: '#f0f3f9',
        position: 'relative',
        width: '100%',
        minHeight: '200px',
        flexGrow: 1,
    },
    editButton: {
        cursor: 'pointer',
        width: '50px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flexGrow: 1,
        overflowX: 'hidden',
    },
    search: {
        width: '450px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    button: {
        padding: '10px',
    },
    createRecipeButton: {
        marginLeft: 'auto',
        padding: '20px',
        backgroundColor: '#99D6D4',
        borderRadius: 0,
        borderColor: '#F0F3F9',
    },
    searchField: {
        background: '#ffffff',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        height: '40px',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    filterBar: {
        marginBottom: '10px',
        backgroundColor: '#D7DEEA',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
    },
    formFieldFull: {
        width: 'auto',
        minWidth: '150px',
    },
    formFieldFull2: {
        minWidth: '300px',
    },
    formFieldFull3: {
        minWidth: '220px',
    },
    table: {
        marginTop: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1.5fr 1.5fr 1fr 2fr 1fr 1fr',
        gap: '0px',
    },
    tableHeader: {
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tableCell: {
        borderBottomWidth: '1px',
        borderBottomColor: 'rgb(209 213 219)',
        paddingBottom: '2px',
        paddingTop: '2px',
        borderBottomStyle: 'solid',
        wordWrap: 'break-word',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tableHeaderRow: {
        paddingRight: '13px',
    },
    tableContent: {
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    fwStatus: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: '24px',
        marginRight: '10px',
    },
    smallIcon: {
        height: '24px',
        marginLeft: '0px',
    },
});

const gApiClient = Authentication.getAPIClient();

/**
 * FW Update Status
 */
export default function RecipeTypes(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [submittingForm, setSubmittingForm] = useState(false);
    const filter = useSelector((state) => state.admin);
    const recipeTypes = useSelector((state) => state.admin.recipeTypes);
    const productionSites = useSelector((state) => state.admin.productionSites);
    const recipeMappings = useSelector((state) => state.admin.recipeMappings);
    let recipeMappingKeys = Object.keys(recipeMappings);
    recipeMappingKeys.sort((a, b) =>
        a
            .trim()
            .toLowerCase()
            .localeCompare(b.trim().toLowerCase())
    );
    const recipeMappingsItems = [
        ...recipeMappingKeys.map((x) => ({
            value: x,
            label: x,
        })),
        { value: 'add_new', label: 'Add New' },
    ];
    const [sortAscending, setSortAscending] = useState(true);
    const [sortColumn, setSortColumn] = useState('_id');
    const [showActiveRecipes, setShowActiveRecipes] = useState(true);
    const [productionSiteFilter, setProductionSiteFilter] = useState('all');
    const [recipeSearch, setRecipeSearch] = useState('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [newMapping, setNewMapping] = useState('');
    const [recipeEdited, setRecipeEdited] = useState(null);
    const [recipeFlavorError, setRecipeFlavorError] = useState(null);
    const [recipeTitleError, setRecipeTitleError] = useState(null);
    const [titlePrintError, setTitlePrintError] = useState(null);
    const [serialNumberError, setSerialNumberError] = useState(null);
    const [recipeCategoryError, setRecipeCategoryError] = useState(null);
    const [expirationDaysError, setExpirationDaysError] = useState(null);
    const [productionSiteError, setProductionSiteError] = useState(null);
    const [coreCodeError, setCoreCodeError] = useState(null);
    const currentRecipeMapping = recipeEdited?.flavor_mapping
        ? recipeEdited?.flavor_mapping
        : recipeMappingKeys.find((x) =>
              recipeMappings[x].includes(recipeEdited?._id)
          );

    const RECIPE_ACTIVE_STATUSES = [
        { value: 'active', label: 'Active' },
        { value: 'non_active', label: 'Non-Active' },
    ];

    // Create a list of unique recipe categories (Based on each recipe type's categories)
    const recipeCategories = useMemo(() => {
        let categories = new Set();
        recipeTypes.forEach((x) => {
            x.categories.forEach((y) => categories.add(y));
        });
        return Array.from(categories).map((x) => ({ value: x, label: x }));
    }, [recipeTypes]);

    const productionSiteItems = [
        ...productionSites.map((x) => ({ value: x._id, label: x.name })),
    ];

    useEffect(() => {
        dispatch(setToggleBarComponent(null));
        dispatch(setSideMenuState(SIDE_MENU_STATE_RECIPE_TYPES));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function loadAllData(promises) {
            await Promise.all(promises);
            setLoading(false);
        }
        let promises = [];

        if (filter.productionSites.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/productionSites',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminProductionSites(results.data));
                })()
            );
        }
        if (filter.recipeCategories.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/recipeCategories',
                        'GET',
                        {}
                    );
                    dispatch(setAdminRecipeCategories(results.data));
                })()
            );
        }

        if (Object.keys(filter.recipeMappings).length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/recipeMappings',
                        'GET',
                        {}
                    );
                    dispatch(setAdminRecipeMappings(results.data));
                })()
            );
        }
        if (filter.recipeTypes.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/recipeTypes?return_all=1',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminRecipeTypes(results.data));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        } else {
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderSortIcon = (column) => {
        if (column !== sortColumn) {
            return null;
        }

        return <img src={sortAscending ? arrowUp : arrowDown} />;
    };

    const changeSortColumn = (column) => {
        if (column === sortColumn) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
            setSortColumn(column);
        }
    };

    const sortedAndFilteredRecipeTypes = useMemo(() => {
        let sortFunction = null;
        if (sortColumn === '_id') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a._id.localeCompare(b._id)
                    : b._id.localeCompare(a._id);
            };
        } else if (sortColumn === 'title') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.title.localeCompare(b.title.trim())
                    : b.title.localeCompare(a.title.trim());
            };
        } else if (sortColumn === 'notes') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.notes?.localeCompare(b.notes)
                    : b.notes?.localeCompare(a.notes);
            };
        } else if (sortColumn === 'title_print') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.title_print.localeCompare(b.title_print)
                    : b.title_print.localeCompare(a.title_print);
            };
        } else if (sortColumn === 'categories') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.categories[0]?.localeCompare(b.categories[0])
                    : b.categories[0]?.localeCompare(a.categories[0]);
            };
        } else if (sortColumn === 'production_site') {
            sortFunction = (a, b) => {
                const productionSiteA = productionSites.find(
                    (p) => p._id === a.production_site
                )?.name;
                const productionSiteB = productionSites.find(
                    (p) => p._id === b.production_site
                )?.name;

                return sortAscending
                    ? productionSiteA?.localeCompare(productionSiteB)
                    : productionSiteB?.localeCompare(productionSiteA);
            };
        } else if (sortColumn === 'expiration_days') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.expiration_days - b.expiration_days
                    : b.expiration_days - a.expiration_days;
            };
        }

        let filteredRecipeTypes = recipeTypes.slice().sort(sortFunction);

        filteredRecipeTypes = filteredRecipeTypes.filter((x) =>
            showActiveRecipes
                ? x.is_ready_for_production
                : !x.is_ready_for_production
        );
        filteredRecipeTypes = filteredRecipeTypes.filter(
            (x) =>
                (x.production_site &&
                    x.production_site === productionSiteFilter) ||
                productionSiteFilter === 'all'
        );
        if (recipeSearch && recipeSearch.trim().length > 0) {
            const search = recipeSearch.trim().toLowerCase();
            filteredRecipeTypes = filteredRecipeTypes.filter((x) => {
                return (
                    x._id.toLowerCase().includes(search) ||
                    x.title?.toLowerCase().includes(search) ||
                    x.notes?.toLowerCase().includes(search) ||
                    x.title_print?.toLowerCase().includes(search) ||
                    x.categories[0]?.toLowerCase().includes(search) ||
                    productionSites
                        .find((a) => a._id === x.production_site)
                        ?.name.toLowerCase()
                        .includes(search)
                );
            });
        }

        return filteredRecipeTypes;
    }, [
        productionSites,
        recipeTypes,
        showActiveRecipes,
        sortAscending,
        sortColumn,
        productionSiteFilter,
        recipeSearch,
    ]);

    const clearErrors = () => {
        setRecipeFlavorError(null);
        setRecipeTitleError(null);
        setTitlePrintError(null);
        setSerialNumberError(null);
        setRecipeCategoryError(null);
        setExpirationDaysError(null);
        setProductionSiteError(null);
        setCoreCodeError(null);
    };

    const checkFields = async () => {
        clearErrors();
        let hasErrors = false;

        if (!recipeEdited?.title || recipeEdited?.title.trim().length === 0) {
            setRecipeTitleError(
                'Recipe Title is required. Please enter a valid title'
            );
            hasErrors = true;
        }

        if (
            !recipeEdited?.title_print ||
            recipeEdited?.title_print.trim().length === 0
        ) {
            setTitlePrintError(
                'Title Print is required. Please enter a valid title print'
            );
            hasErrors = true;
        }
        if (recipeEdited?.title_print?.length > 14) {
            setTitlePrintError('Title print is too long');
            hasErrors = true;
        }

        if (!recipeEdited?.notes || recipeEdited?.notes.trim().length === 0) {
            setSerialNumberError(
                'Serial Number is required. Please enter a valid serial number'
            );
            hasErrors = true;
        }
        if (recipeTypes.map((x) => x.notes).includes(recipeEdited?.notes)) {
            const foundSerial = recipeTypes.find(
                (x) => x.notes === recipeEdited?.notes
            );
            if (foundSerial._id !== recipeEdited?._id) {
                setSerialNumberError(
                    'Serial Number already exists. Please enter a unique serial number'
                );
                hasErrors = true;
            }
        }

        if (
            !recipeEdited?.categories ||
            recipeEdited?.categories.length === 0
        ) {
            setRecipeCategoryError(
                'Recipe Category is required. Please select a category from the list'
            );
            hasErrors = true;
        }
        if (!currentRecipeMapping || currentRecipeMapping === 'add_new') {
            setRecipeFlavorError(
                'Flavor is required. Please select a flavor from the list'
            );
            hasErrors = true;
        }

        if (!recipeEdited?.expiration_days) {
            setExpirationDaysError(
                'Expiration Days is required. Please enter a valid number of days'
            );
            hasErrors = true;
        } else if (
            isNaN(parseInt(recipeEdited?.expiration_days)) ||
            parseInt(recipeEdited?.expiration_days) <= 0
        ) {
            setExpirationDaysError(
                'Expiration Days must be a valid number. Please enter a numeric positive number'
            );
            hasErrors = true;
        }

        if (!recipeEdited?.production_site) {
            setProductionSiteError(
                'Production Site is required. Please select a production site from the list'
            );
            hasErrors = true;
        }

        if (
            recipeEdited?.core_code?.length > 0 &&
            recipeEdited?.core_code?.length !== 38
        ) {
            setCoreCodeError('Invalid Core Code length');
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        recipeEdited.expiration_days = parseInt(recipeEdited.expiration_days);

        setSubmittingForm(true);

        const params = {
            recipe_id: recipeEdited._id,
            is_ready_for_production: recipeEdited.is_ready_for_production,
            core_code: recipeEdited.core_code,
            title: recipeEdited.title,
            title_print: recipeEdited.title_print,
            notes: recipeEdited.notes,
            flavor_mapping: currentRecipeMapping,
            category: recipeEdited.categories[0],
            production_site: recipeEdited.production_site,
            expiration_days: recipeEdited.expiration_days,
        };

        await gApiClient.callApi('admin/addEditRecipe', 'POST', {}, params);

        // Refresh recipe types + recipe mappings
        const results = await gApiClient.callApi(
            'admin/recipeTypes?return_all=1',
            'GET',
            {}
        );
        sortItems(results.data, SORT_TYPE_ALPHABETICAL);
        dispatch(setAdminRecipeTypes(results.data));

        const results2 = await gApiClient.callApi(
            'admin/recipeMappings',
            'GET',
            {}
        );
        dispatch(setAdminRecipeMappings(results2.data));

        setSubmittingForm(false);
        setShowEditDialog(false);
        setShowSuccessDialog(true);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'Recipe Types'}
            loading={loading}
        >
            <Dialog
                onClose={() => setShowSuccessDialog(false)}
                open={showSuccessDialog}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>Success</div>

                    <IconButton
                        className={classes.dialogClose}
                        onClick={() => setShowSuccessDialog(false)}
                    >
                        <img
                            className={classes.dialogCloseIcon}
                            src={closeIcon}
                            alt="Close"
                        />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>
                    <div className={classes.successTitle}>
                        Recipe QR Code{' '}
                        {recipeEdited?.is_new ? 'created' : 'updated'}{' '}
                        successfully
                    </div>
                    <Button
                        className={classes.dialogConfirm}
                        label="Close"
                        onClick={() => setShowSuccessDialog(false)}
                    />
                </div>
            </Dialog>

            <Dialog
                onClose={() => setShowEditDialog(false)}
                open={showEditDialog}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>
                        {recipeEdited?.is_new ? 'Add' : 'Edit'} Recipe
                    </div>

                    <IconButton
                        className={classes.dialogClose}
                        onClick={() => setShowEditDialog(false)}
                    >
                        <img
                            className={classes.dialogCloseIcon}
                            src={closeIcon}
                            alt="Close"
                        />
                    </IconButton>
                </div>
                <div className={classes.dialogContent}>
                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>Recipe ID</div>
                        <TextField
                            style={{ width: '100px', fontSize: '20px' }}
                            readOnly
                            value={recipeEdited?._id}
                        />
                    </div>

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>
                            Recipe Status
                        </div>
                        <Select
                            className={classes.formFieldFull}
                            items={RECIPE_ACTIVE_STATUSES}
                            value={{
                                value: recipeEdited?.is_ready_for_production
                                    ? 'active'
                                    : 'non_active',
                            }}
                            hint={'Is Active'}
                            onChange={(value) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    is_ready_for_production:
                                        value.value === 'active',
                                });
                            }}
                        />
                    </div>

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>Core Code</div>
                        <TextField
                            className={classes.formTextField}
                            value={recipeEdited?.core_code}
                            onChange={(x) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    core_code: x,
                                });
                            }}
                        />
                    </div>
                    {coreCodeError && (
                        <div className={classes.error}>{coreCodeError}</div>
                    )}

                    <div
                        className={classes.dialogRow}
                        style={{ marginTop: '50px' }}
                    >
                        <div className={classes.dialogFieldName}>
                            Recipe Title
                        </div>
                        <TextField
                            className={classes.formTextField}
                            value={recipeEdited?.title}
                            onChange={(x) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    title: x,
                                });
                            }}
                        />
                    </div>
                    {recipeTitleError && (
                        <div className={classes.error}>{recipeTitleError}</div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>
                            Title Print
                        </div>
                        <TextField
                            className={classes.formTextField}
                            value={recipeEdited?.title_print}
                            onChange={(x) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    title_print: x,
                                });
                            }}
                        />
                    </div>
                    {titlePrintError && (
                        <div className={classes.error}>{titlePrintError}</div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>
                            Serial Number
                        </div>
                        <TextField
                            className={classes.formTextField}
                            value={recipeEdited?.notes}
                            onChange={(x) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    notes: x,
                                });
                            }}
                        />
                    </div>
                    {serialNumberError && (
                        <div className={classes.error}>{serialNumberError}</div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>Flavor</div>
                        <Select
                            className={clsx(
                                classes.formFieldFull,
                                classes.formFieldFull2
                            )}
                            autoComplete
                            items={recipeMappingsItems}
                            value={{
                                label:
                                    currentRecipeMapping === 'add_new'
                                        ? 'Add New'
                                        : currentRecipeMapping,
                                value: currentRecipeMapping,
                            }}
                            hint={'Flavor'}
                            onChange={(value) => {
                                if (value.value === 'add_new') {
                                    setNewMapping('');
                                    setRecipeFlavorError(null);
                                }

                                setRecipeEdited({
                                    ...recipeEdited,
                                    flavor_mapping: value.value,
                                });
                            }}
                        />
                        {currentRecipeMapping === 'add_new' && (
                            <>
                                <TextField
                                    className={classes.formTextField}
                                    style={{
                                        width: '200px',
                                        marginLeft: '25px',
                                    }}
                                    value={newMapping}
                                    placeholder={'New Flavor'}
                                    onChange={(x) => {
                                        setNewMapping(x);
                                    }}
                                />
                                <Button
                                    className={classes.save}
                                    label="Save"
                                    onClick={() => {
                                        if (newMapping.trim().length > 0) {
                                            // Check if exists in keys
                                            if (
                                                recipeMappingKeys
                                                    .map((x) => x.toLowerCase())
                                                    .includes(
                                                        newMapping.toLowerCase()
                                                    )
                                            ) {
                                                setRecipeFlavorError(
                                                    'Flavor already exists. Please enter a unique flavor or select from the list'
                                                );
                                                return;
                                            }
                                            setRecipeFlavorError(null);

                                            setRecipeEdited({
                                                ...recipeEdited,
                                                flavor_mapping: newMapping,
                                            });
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                    {recipeFlavorError && (
                        <div className={classes.error}>{recipeFlavorError}</div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>Category</div>
                        <Select
                            className={clsx(
                                classes.formFieldFull,
                                classes.formFieldFull2
                            )}
                            items={recipeCategories}
                            value={{
                                value: recipeEdited?.categories
                                    ? recipeEdited?.categories[0]
                                    : null,
                            }}
                            hint={'Category'}
                            onChange={(value) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    categories: [value.value],
                                });
                            }}
                        />
                    </div>
                    {recipeCategoryError && (
                        <div className={classes.error}>
                            {recipeCategoryError}
                        </div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>
                            Production Site
                        </div>
                        <Select
                            className={clsx(
                                classes.formFieldFull,
                                classes.formFieldFull2
                            )}
                            items={productionSiteItems}
                            value={{
                                value: recipeEdited?.production_site,
                            }}
                            hint={'Production Site'}
                            onChange={(value) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    production_site: value.value,
                                });
                            }}
                        />
                    </div>
                    {productionSiteError && (
                        <div className={classes.error}>
                            {productionSiteError}
                        </div>
                    )}

                    <div className={classes.dialogRow}>
                        <div className={classes.dialogFieldName}>
                            Expiration Days
                        </div>
                        <TextField
                            className={classes.formTextField}
                            style={{
                                width: '100px',
                            }}
                            value={recipeEdited?.expiration_days}
                            onChange={(x) => {
                                setRecipeEdited({
                                    ...recipeEdited,
                                    expiration_days: x,
                                });
                            }}
                        />
                    </div>
                    {expirationDaysError && (
                        <div className={classes.error}>
                            {expirationDaysError}
                        </div>
                    )}
                </div>

                <Button
                    className={classes.dialogConfirm}
                    label="Submit"
                    onClick={checkFields}
                    loading={submittingForm}
                />
            </Dialog>

            <div className={classes.content}>
                <div className={classes.filterBar}>
                    <SearchField
                        className={classes.search}
                        placeholder="Search"
                        onSearch={setRecipeSearch}
                        value={recipeSearch}
                        backgroundClass={classes.searchField}
                        inputClass={classes.searchFieldInput}
                        iconClass={classes.searchFieldIcon}
                    />
                    <div>Recipe Status</div>
                    <Select
                        className={classes.formFieldFull}
                        items={RECIPE_ACTIVE_STATUSES}
                        value={{
                            value: showActiveRecipes ? 'active' : 'non_active',
                        }}
                        hint={'Active'}
                        onChange={(value) => {
                            setShowActiveRecipes(value.value === 'active');
                        }}
                    />
                    <div>Production Site</div>
                    <Select
                        className={clsx(
                            classes.formFieldFull,
                            classes.formFieldFull3
                        )}
                        items={[
                            { value: 'all', label: 'All' },
                            ...productionSiteItems,
                        ]}
                        value={{ value: productionSiteFilter }}
                        hint={'Production Site'}
                        onChange={(value) => {
                            setProductionSiteFilter(value.value);
                        }}
                    />
                    <Button
                        className={clsx(
                            classes.button,
                            classes.createRecipeButton
                        )}
                        label="Create Recipe QR Code"
                        onClick={() => {
                            clearErrors();

                            // Generate a unique _id, made from 3 random characters (upper case letters and digits - only 1 to 9)
                            let newId = null;

                            // Make sure it's a new unique ID
                            while (
                                !newId ||
                                recipeTypes.map((x) => x._id).includes(newId) ||
                                newId.includes('0')
                            ) {
                                newId = Math.random()
                                    .toString(36)
                                    .substr(2, 3)
                                    .toUpperCase();
                            }

                            setRecipeEdited({
                                _id: newId,
                                is_ready_for_production: true,
                                is_new: true,
                            });
                            setShowEditDialog(true);
                        }}
                    />
                </div>
                <div className={clsx(classes.table, classes.tableHeaderRow)}>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('_id')}
                    >
                        Recipe ID {renderSortIcon('_id')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('title')}
                    >
                        Recipe Title {renderSortIcon('title')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('notes')}
                    >
                        Serial Number {renderSortIcon('notes')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('title_print')}
                    >
                        QR Code Print {renderSortIcon('title_print')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('categories')}
                    >
                        Category {renderSortIcon('categories')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('production_site')}
                    >
                        Production Site {renderSortIcon('production_site')}
                    </div>
                    <div
                        className={classes.tableHeader}
                        onClick={() => changeSortColumn('expiration_days')}
                    >
                        Expiration Days {renderSortIcon('expiration_days')}
                    </div>
                    <div className={classes.tableHeader}>Edit</div>
                </div>
                <div className={clsx(classes.table, classes.tableContent)}>
                    {sortedAndFilteredRecipeTypes.map((x) => (
                        <>
                            <div className={classes.tableCell}>{x._id}</div>
                            <div className={classes.tableCell}>{x.title}</div>
                            <div className={classes.tableCell}>{x.notes}</div>
                            <div className={classes.tableCell}>
                                {x.title_print}
                            </div>
                            <div className={classes.tableCell}>
                                {x.categories[0]}
                            </div>
                            <div className={classes.tableCell}>
                                {
                                    productionSites.find(
                                        (a) => a._id === x.production_site
                                    )?.name
                                }
                            </div>
                            <div className={classes.tableCell}>
                                {x.expiration_days}
                            </div>
                            <div className={classes.tableCell}>
                                <img
                                    className={classes.editButton}
                                    src={editIcon}
                                    onClick={() => {
                                        setRecipeEdited({ ...x });
                                        clearErrors();
                                        setShowEditDialog(true);
                                    }}
                                />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </InnerWindow>
    );
}
