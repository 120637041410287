import clsx from 'clsx';
import TextField from './TextField';
import capsulesIcon from '../res/images/capsules.svg';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    multipleFieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '10px',
        paddingBottom: '10px',
        height: 'fit-content',
        minHeight: 'fit-content',
        backgroundColor: '#e6e9f1',
    },
    innerFieldsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    field: {
        height: '43px',
        minHeight: '43px',
        marginLeft: '5px',
        '&:first-child': {
            marginLeft: '0px',
        },
        '& input': {
            textTransform: 'uppercase',
        },
    },
    topPart: {
        display: 'flex',
        flexDirection: 'row',
        alignItem: 'center',
    },
    title: {
        marginBottom: '8px',
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#7d90aa',
        flexGrow: 1,
    },
    rightIcon: {
        marginTop: '-10px',
        cursor: 'pointer',
    },
});

/** Utility widget to display several fields horizontally with a title above them - like the "Last clean", "Last maintenance", etc. fields */
export default function MultipleFieldsDisplay(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.multipleFieldsContainer, props.className)}>
            <div className={classes.topPart}>
                <div className={classes.title}>{props.title}</div>
                {props.rightIcon && (
                    <img
                        className={classes.rightIcon}
                        onClick={props.onRightIconClick}
                        src={props.rightIcon}
                        alt={props.title}
                    />
                )}
            </div>
            <div className={classes.innerFieldsContainer}>
                {props.fields.map((field) => {
                    return (
                        <TextField
                            className={classes.field}
                            style={{ width: field.width }}
                            key={field.label || field.value}
                            multiline={field.multiline}
                            readOnly
                            value={field.value}
                            bottomLabel={field.label}
                            align={field.align ? field.align : null}
                            rightIcon={field.capsulesIcon ? capsulesIcon : null}
                        />
                    );
                })}
            </div>
        </div>
    );
}
